import React, { useState, useRef } from 'react'
import exact from 'prop-types-exact'
import user_icon from '../../assets/images/user_icon.svg'
import { useClickAway } from 'react-use'
import PropTypes from 'prop-types'

const propTypes = {
  userSession: PropTypes.object,
}

const defaultProps = {}

function UserDropdownMenu({ userSession }) {
  const [showMenu, setShow] = useState(false)
  const ref = useRef(null)

  useClickAway(ref, () => {
    setShow(false)
  })

  return (
    <>
      <button
        id="ser_dropdown_button"
        onClick={() => setShow(!showMenu)}
        className="dropdown_button"
      >
        <img className="user_icon" src={user_icon} alt="User Icon" />
      </button>
      {showMenu && (
        <div className="dropdown_menu" ref={ref}>
          <ul>
            {userSession.hasDashboardAccess && (
              <li>
                <a className="dropdown_link" href="/dashboard">
                  Go to Dashboard
                </a>
              </li>
            )}
            <li>
              <a
                className="dropdown_link"
                href="/users/log-out"
                data-method="delete" // Attribute needed so that rails can interpret this request as a DELETE request
              >
                Log Out
              </a>
            </li>
          </ul>
        </div>
      )}
    </>
  )
}

UserDropdownMenu.propTypes = exact(propTypes)
UserDropdownMenu.defaultProps = defaultProps

export default React.memo(UserDropdownMenu)
