import React from 'react'
import PropTypes from 'prop-types'
import exact from 'prop-types-exact'
import {
  withFormikAdapterAndVisibilityInput,
  validate,
  formHelpers,
} from '../utils'
import {
  DropdownCheckboxGroup as BaseDropownCheckboxGroup,
  ImageUploadPreview,
  Tooltip,
} from 'components'
import {
  Select as BaseSelectInput,
  Button,
  Checkbox as BaseCheckbox,
  SubmitButton,
} from '@launchpadlab/lp-components'
import { Formik, Form } from 'formik'

const DropdownCheckboxGroup = withFormikAdapterAndVisibilityInput()(
  BaseDropownCheckboxGroup
)
const Select = withFormikAdapterAndVisibilityInput()(BaseSelectInput)
const Checkbox = withFormikAdapterAndVisibilityInput()(BaseCheckbox)

const propTypes = {
  params: PropTypes.object.isRequired,
  formValues: PropTypes.object.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  handleBack: PropTypes.func.isRequired,
  showDynamic: PropTypes.bool,
  sectorIconId: PropTypes.number,
}

const defaultProps = {}

function InvestorPage3Form({
  params,
  formValues,
  handleSubmit,
  handleBack,
  showDynamic,
  sectorIconId,
}) {
  function validateForm(values) {
    const isConsideringNonFundPartnershipsValidation = showDynamic

    const defaultSectorIconSelected = values.defaultImage

    const messages = validate(values, {
      minConsideredAnnualRevenueId: isConsideringNonFundPartnershipsValidation
        ? {
            presence: {
              allowEmpty: false,
              message: '^Please select one',
            },
          }
        : {},
      maxConsideredAnnualRevenueId: isConsideringNonFundPartnershipsValidation
        ? {
            presence: {
              allowEmpty: false,
              message: '^Please select one',
            },
          }
        : {},
      minConsideredAnnualEbitdaId: isConsideringNonFundPartnershipsValidation
        ? {
            presence: {
              allowEmpty: false,
              message: '^Please select one',
            },
          }
        : {},
      maxConsideredAnnualEbitdaId: isConsideringNonFundPartnershipsValidation
        ? {
            presence: {
              allowEmpty: false,
              message: '^Please select one',
            },
          }
        : {},
      minConsideredAnnualEbitId: isConsideringNonFundPartnershipsValidation
        ? {
            presence: {
              allowEmpty: false,
              message: '^Please select one',
            },
          }
        : {},
      maxConsideredAnnualEbitId: isConsideringNonFundPartnershipsValidation
        ? {
            presence: {
              allowEmpty: false,
              message: '^Please select one',
            },
          }
        : {},
      investorConsideredOwnerships: isConsideringNonFundPartnershipsValidation
        ? {
            presence: {
              allowEmpty: false,
            },
          }
        : {},
      defaultImage: function (value, attributes) {
        if (attributes.profileImageUrl === '') {
          return { presence: { allowEmpty: false } }
        } else {
          return { presence: { allowEmpty: true } }
        }
      },
      profileImageUrl: function () {
        if (defaultSectorIconSelected) {
          return
        } else {
          return {
            presence: { allowEmpty: false, message: '^Profile image required' },
          }
        }
      },
      membershipStatusId: {
        presence: {
          allowEmpty: false,
          message: '^Please select one',
        },
      },
    })
    // mix custom stuff here w validate.js messages
    return {
      ...messages,
      //
    }
  }
  function getDefaultImageUrl() {
    return params.investorType.find((obj) => {
      return obj.id === parseInt(sectorIconId)
    })?.sectorImage
  }

  return (
    <Formik
      onSubmit={(values) => handleSubmit(values, true)}
      validate={validateForm}
      initialValues={formValues}
    >
      {({ values, errors, setFieldValue, setFieldTouched, touched }) => (
        <Form className="onboarding-form setup-form">
          {showDynamic && (
            <>
              <h1>Investment Financial Criteria</h1>
              <p className="disclaimer">
                Full financial investment criteria is{' '}
                <span id="underline">not</span> publicized to other members.
                Members only see whether or not your criteria matches their
                criteria or financial details.
              </p>
              <h3>Annual Revenue Sizes</h3>
              <div className="row">
                <Select
                  name="minConsideredAnnualRevenueId"
                  label="Minimum"
                  options={formHelpers.formatOptions(params.financialMetrics)}
                  enablePlaceholderOption={true}
                  placeholder=" "
                  onChange={() => {
                    if (
                      values.minConsideredAnnualRevenueId >
                      values.maxConsideredAnnualRevenueId
                    )
                      setFieldValue('maxConsideredAnnualRevenueId', '')
                  }}
                />
                <Select
                  name="maxConsideredAnnualRevenueId"
                  label="Maximum"
                  options={formHelpers.getMaxOptionsByMinValue(
                    values.minConsideredAnnualRevenueId,
                    params.financialMetrics
                  )}
                  placeholder={
                    values.minConsideredAnnualRevenueId === ''
                      ? 'Must Select Minimum'
                      : ' '
                  }
                  enablePlaceholderOption={true}
                  disabledText="Must Select Minimum"
                  disabled={values.minConsideredAnnualRevenueId === ''}
                />
              </div>
              <h3>Annual EBITDA Sizes</h3>
              <div className="row">
                <Select
                  name="minConsideredAnnualEbitdaId"
                  label="Minimum"
                  tooltip={
                    <Tooltip
                      name="minConsideredAnnualEbitdaId"
                      text="Defined as net income + taxes + interest + depreciation & amortization"
                    />
                  }
                  options={formHelpers.formatOptions(params.financialMetrics)}
                  enablePlaceholderOption={true}
                  placeholder=" "
                  onChange={() => {
                    if (
                      values.minConsideredAnnualEbitdaId >
                      values.maxConsideredAnnualEbitdaId
                    )
                      setFieldValue('maxConsideredAnnualEbitdaId', '')
                  }}
                />
                <Select
                  name="maxConsideredAnnualEbitdaId"
                  label="Maximum"
                  options={formHelpers.getMaxOptionsByMinValue(
                    values.minConsideredAnnualEbitdaId,
                    params.financialMetrics
                  )}
                  enablePlaceholderOption={true}
                  disabledText="Must Select Minimum"
                  placeholder={
                    values.minConsideredAnnualEbitdaId === ''
                      ? 'Must Select Minimum'
                      : ' '
                  }
                  disabled={values.minConsideredAnnualEbitdaId === ''}
                />
              </div>
              <h3>Annual Operating Income Sizes</h3>
              <div className="row">
                <Select
                  name="minConsideredAnnualEbitId"
                  label="Minimum"
                  tooltip={
                    <Tooltip
                      name="minConsideredAnnualEbitId"
                      text="Defined as annual net income + taxes + interest"
                    />
                  }
                  options={formHelpers.formatOptions(params.financialMetrics)}
                  enablePlaceholderOption={true}
                  placeholder=" "
                  onChange={() => {
                    if (
                      values.minConsideredAnnualEbitId >
                      values.maxConsideredAnnualEbitId
                    )
                      setFieldValue('maxConsideredAnnualEbitId', '')
                  }}
                />
                <Select
                  name="maxConsideredAnnualEbitId"
                  label="Maximum"
                  options={formHelpers.getMaxOptionsByMinValue(
                    values.minConsideredAnnualEbitId,
                    params.financialMetrics
                  )}
                  enablePlaceholderOption={true}
                  disabledText="Must Select Minimum"
                  placeholder={
                    values.minConsideredAnnualEbitId === ''
                      ? 'Must Select Minimum'
                      : ' '
                  }
                  disabled={values.minConsideredAnnualEbitId === ''}
                />
              </div>
              <div className="row">
                <DropdownCheckboxGroup
                  name="investorConsideredOwnerships"
                  label="Current Ownership Types Considered"
                  options={formHelpers.formatOptions(params.currentOwnerships)}
                  values={values}
                  setFieldValue={setFieldValue}
                  setFieldTouched={setFieldTouched}
                  tags={true}
                  errorMessage="Please select all that apply"
                />
              </div>
            </>
          )}
          <h1>Investment Exploration Overview</h1>
          <div className="row">
            <Select
              name="membershipStatusId"
              label="Membership Status"
              options={formHelpers.formatOptions(params.membershipStatuses)}
              enablePlaceholderOption={true}
              placeholder=" "
            />
          </div>
          <h1>Profile Image</h1>
          <div className="row">
            <div className="column">
              <Checkbox
                name="defaultImage"
                label="Use Default Sector Icon"
                onChange={() => {
                  setFieldValue('profileImageUrl', '')
                  setFieldTouched('profileImageUrl', false, false)
                }}
              />
            </div>
            <div className="column">
              {values.defaultImage ? (
                <div className="image-upload-preview">
                  <img
                    src={getDefaultImageUrl()}
                    alt="Default Investor Image"
                    style={{ width: '60px' }}
                  />
                </div>
              ) : (
                <div className="profile-image-error">
                  <ImageUploadPreview
                    name="profileImageUrl"
                    hasStoredValue={values.profileImageUrl}
                  />
                </div>
              )}
            </div>
          </div>
          <p className="disclaimer submission-disclaimer">
            By clicking submit you certify that you have only posted factual
            information about yourself, your company, and/or your investment
            fund.
          </p>
          <div className="submission-error-wrapper">
            <div className="button-area">
              <Button
                className="form-button button-secondary"
                onClick={() => handleBack(values)}
              >
                Back
              </Button>
              <SubmitButton className="form-button submit">Submit</SubmitButton>
            </div>
            <div className="error-message-submission">
              {values.defaultImage &&
              Object.keys(errors).length === 1 &&
              Object.keys(errors).includes('profileImageUrl')
                ? null
                : Object.keys(touched).some((key) => {
                    return Object.keys(errors).includes(key)
                  })
                ? 'Please fix the errors presented above'
                : null}
            </div>
          </div>
        </Form>
      )}
    </Formik>
  )
}

InvestorPage3Form.propTypes = exact(propTypes)
InvestorPage3Form.defaultProps = defaultProps

export default InvestorPage3Form
