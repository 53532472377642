import React from 'react'
import PropTypes from 'prop-types'
import exact from 'prop-types-exact'
import { ErrorBoundary } from '../components'
import LockIcon from '../../assets/images/bluelock.svg'
const propTypes = {
  profileType: PropTypes.string,
}

const defaultProps = {}

function AdvisorConnection({ profileType }) {
  return (
    <ErrorBoundary>
      <div className="coming-soon">
        <img className="lock-icon" src={LockIcon}></img>
        <div>Coming soon after our March 15th launch</div>
        {profileType === 'business' && (
          <div>
            Confirm your financial ranges with your accountant, M&A advisor, or
            business broker to show other members the information has been
            confirmed by a third-party
          </div>
        )}
        {profileType === 'investor' && (
          <div>
            Confirm your accredited status with your accountant to show other
            members the information has been confirmed by a third-party
          </div>
        )}
        {profileType === 'advisor' && (
          <div>
            Confirm your clients' financial ranges to show other members their
            information has been confirmed by a third-party and link with
            clients’ profiles to lead capital raises
          </div>
        )}
      </div>
    </ErrorBoundary>
  )
}

AdvisorConnection.propTypes = exact(propTypes)
AdvisorConnection.defaultProps = defaultProps

export default React.memo(AdvisorConnection)
