export const InvestorTypes = {
  FundInvestor: 'Investment Fund',
  IndividualInvestor: 'Individual Investor',
}

export const AdvisorTypes = {
  Accountant: 'Accountant',
  FinancialAdvisor: 'FinancialAdvisor',
}

export const ProfileTypes = {
  BusinessProfile: 'businessProfile',
  InvestorProfile: 'investorProfile',
  AdvisorProfile: 'advisorProfile',
}
