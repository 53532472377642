/* eslint-disable */
import React, { useState } from 'react'
import PropTypes from 'prop-types'
import exact from 'prop-types-exact'
import * as mailchimp from '../services/mailchimp'
import { Helmet } from 'react-helmet'
import { Header, Card, Footer } from '../components'
import { Button } from '@launchpadlab/lp-components'
import { SignUpForm } from '../forms'
import { scrollToSelector } from 'utils'
import PrivateCapital from '../../assets/images/privatecapital.svg'
import Door from '../../assets/images/door.svg'
import Briefcase from '../../assets/images/briefcase.svg'
import Handshake from '../../assets/images/handshake.svg'
import Advisor from '../../assets/images/advisor.svg'
import One from '../../assets/images/one.svg'
import Two from '../../assets/images/two.svg'
import Three from '../../assets/images/three.svg'
import * as api from 'api'

const propTypes = { userSession: PropTypes.object }

//console.log(process.env)

const defaultProps = {}

function Home({ userSession }) {
  const [signUpSucceeded, setSignUpSucceeded] = useState(false)
  return (
    <div className="homeLanding">
      <Helmet>
        <title>Home</title>
      </Helmet>

      <Header
        title="U.S. Small Business Capital Network"
        subtitle={
          <span>
            Businesses <span>&#183;</span> Investors <span>&#183;</span>{' '}
            Advisors
          </span>
        }
        userSession={userSession}
      >
        <Button
          onClick={() => scrollToSelector('#sign-up-section')}
          style="primary"
          className="signUpButton"
          ariaLabel="Sign Up"
        >
          Sign Up
        </Button>
      </Header>

      <main>
        <div className="centered-content">
          <Card
            title="Improving Access to Private Capital, Investments, and Advice"
            className
            subtitle={
              <>
                <p className="about-header">
                  Firm Value is a free technology platform for members seeking
                  to develop private capital networks to prepare for long-term
                  objectives, and access capital, investments, and advice when
                  needed.
                </p>
              </>
            }
          >
            <div className="card-content large left first-box">
              <img
                className="card-icon"
                src={PrivateCapital}
                alt="Private Capital Icon"
              />
              <h3 className="half-width">Build Your Private Capital Network</h3>
              <p>
                Develop your network and prepare for the future by building
                relationships with investors, businesses, and advisors
              </p>
            </div>

            <div className="card-content large">
              <img className="card-icon" src={Door} alt="Door Icon" />
              <h3>Access Capital & Investments</h3>
              <p>
                Post and explore full sale, equity, and/or debt capital raise
                objectives, and investment and/or acquisition criteria
              </p>
            </div>
          </Card>
          <Card title="Who Firm Value Serves">
            <div className="small left-first">
              <div className="card-content">
                <img
                  className="type-icon"
                  src={Briefcase}
                  alt="Briefcase Icon"
                />
                <h3>Businesses</h3>
                <p className="p-margin">U.S. Small Businesses</p>
                <p className="p-margin">Acquisitive Businesses</p>
              </div>
              <div className="button-primary-a">
                <a href="/business" aria-label="Learn More About Businesses">
                  Learn More
                </a>
              </div>
            </div>

            <div className="small left">
              <div className="card-content">
                <img
                  className="type-icon"
                  src={Handshake}
                  alt="Handshake Icon"
                />
                <h3>Investors</h3>
                <p className="p-margin">Individual Investors</p>
                <p className="p-margin">Investment Funds</p>
              </div>
              <div className="button-primary-a">
                <a href="/investors" aria-label="Learn More About Investors">
                  Learn More
                </a>
              </div>
            </div>

            <div className="small">
              <div className="card-content">
                <img className="type-icon" src={Advisor} alt="Advisor Icon" />
                <h3>Advisors</h3>
                <p className="p-margin">Financial Advisors</p>
                <p className="p-margin">Accountants</p>
              </div>
              <div className="button-primary-a">
                <a href="/advisors" aria-label="Learn More About Advisors">
                  Learn More
                </a>
              </div>
            </div>
          </Card>
          <Card
            title="Using Firm Value"
            subtitle={
              <>
                <p className="about-header">
                  Build your profile and explore other business, investor, and
                  advisor members' profiles. Determine whether or not you’d like
                  to connect after reviewing what criteria matches.
                </p>
              </>
            }
          >
            <div className="combo-card medium left">
              <div className="card-header-blue">
                <h3>Profile Components</h3>
              </div>

              <div className="card-content">
                <ul>
                  <li>Overview of business operations/criteria</li>
                  <li>Overview of business financial profile/criteria</li>
                  <li>Additional details you would like to provide</li>
                </ul>
              </div>
            </div>

            <div className="combo-card medium">
              <div className="card-header-blue">
                <h3>Profile Development</h3>
              </div>

              <div className="card-content">
                <ul>
                  <li>Time to complete: 5-10 minutes</li>
                  <li>Primarily clickable fixed-data selection options</li>
                  <li>Only post immediate objectives when needed</li>
                </ul>
              </div>
            </div>

            <div className="subtitle">
              <p className="about-header">
                For sensitive information, profiles only show whether another
                member’s criteria matches or not. Business names, investor last
                names, cities, and financials are not displayed, unless you
                choose to share them.
              </p>
            </div>
          </Card>
          <Card title="We Built Firm Value To:">
            <div className="number-card">
              <div className="card-content flex-width">
                <img className="number-icon" src={One} alt="Number 1" />
                <p>
                  Broaden U.S. small businesses', investors', and advisors'
                  capital networks to increase the potential for greater value
                  creation when it is needed
                </p>
              </div>
              <div className="card-content flex-width">
                <img className="number-icon" src={Two} alt="Number 2" />
                <p>
                  Help address challenges U.S. small businesses, investors, and
                  advisors face in efficiently sourcing meaningful capital
                  formation and deployment opportunities
                </p>
              </div>
              <div className="card-content flex-width">
                <img className="number-icon" src={Three} alt="Number 3" />
                <p>
                  Enhance capital related decision-making by further developing
                  members' knowledge of U.S. small business private capital
                  markets
                </p>
              </div>
            </div>
            <div className="button-primary-a">
              <a href="/about" aria-label="Learn More About Firm Value">
                Learn More
              </a>
            </div>
          </Card>
        </div>
        <div id="sign-up-section">
          <div className="sign-up-information">
            <h2>Launching in Q1 2021</h2>
            <p>
              We're collecting membership interest to continue building the
              network around our founding members while we work towards a Q1
              2021 launch. Sign up today to stay up to date on our launch news.
            </p>
          </div>
          {signUpSucceeded ? (
            <p>Thank you for signing up!</p>
          ) : (
            <SignUpForm
              onSubmit={({ email, userType }) =>
                mailchimp
                  .signup({ email, userType })
                  .then(() => setSignUpSucceeded(true))
                  .catch((e) =>
                    window.alert(e, 'Sign up failed, please try again.')
                  )
              }
            />
          )}
        </div>
      </main>
      <Footer />
    </div>
  )
}

Home.propTypes = exact(propTypes)
Home.defaultProps = defaultProps

export default React.memo(Home)
