// Helper to compose require contexts
// Will attempt resolving paths in the context order given
/*eslint-disable */
function composeContexts(contexts) {
  return function composedRequire(...args) {
    let lastError = null
    for (const context of contexts) {
      try {
        return context(...args)
      } catch (e) {
        lastError = e
      }
    }
    throw lastError
  }
}

module.exports = composeContexts
/*eslint-enable */
