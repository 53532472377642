import React from 'react'
// import PropTypes from 'prop-types'
import exact from 'prop-types-exact'
import { ErrorBoundary } from '../components'
import { Route, Switch, NavLink } from 'react-router-dom'
import { HOVMInvestorProfileView } from 'views'
const propTypes = {}

const defaultProps = {}

function HowOthersViewMeInvestor() {
  return (
    <div className="card">
      <ErrorBoundary>
        <div className="how-others-view-me-page-wrapper">
          <div className="hovm-title">
            <h2>How Others View Me</h2>
          </div>
          <div className="hovm-subtitle">
            Select from member types below to see how others will view your
            profile prior to connecting
          </div>
          <div className="hovm-link-row">
            <NavLink
              className="hovm-link"
              activeClassName="hovm-active"
              to="/edit-profile/how-others-view-me/business"
            >
              Businesses
            </NavLink>
            <NavLink
              className="hovm-link"
              activeClassName="hovm-active"
              to="/edit-profile/how-others-view-me/investor"
            >
              Investors
            </NavLink>
            <NavLink
              className="hovm-link"
              activeClassName="hovm-active"
              to="/edit-profile/how-others-view-me/advisor"
            >
              Advisors
            </NavLink>
          </div>
          <div className="hovm-view-area">
            <div className="hovm-view-box">
              <Switch>
                <Route exact path="/edit-profile/how-others-view-me/business">
                  <HOVMInvestorProfileView viewerType="Business" />
                </Route>
                <Route exact path="/edit-profile/how-others-view-me/investor">
                  <HOVMInvestorProfileView viewerType="Investor" />
                </Route>
                <Route exact path="/edit-profile/how-others-view-me/advisor">
                  <HOVMInvestorProfileView viewerType="Advisor" />
                </Route>
                <Route path="/edit-profile/how-others-view-me/">
                  <HOVMInvestorProfileView viewerType="Business" />
                </Route>
              </Switch>
            </div>
          </div>
        </div>
      </ErrorBoundary>
    </div>
  )
}

HowOthersViewMeInvestor.propTypes = exact(propTypes)
HowOthersViewMeInvestor.defaultProps = defaultProps

export default React.memo(HowOthersViewMeInvestor)
