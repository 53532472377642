// alphabetically lists all industries
const getIndustries = (sectorsIndustriesArr) => {
  const industriesArr = sectorsIndustriesArr.map((sector) => {
    return sector.industries
  })

  return industriesArr.flat().sort(function (a, b) {
    return a.name < b.name ? -1 : a.name > b.name ? 1 : 0
  })
}

export default getIndustries
