import React, { useState } from 'react'
import PropTypes from 'prop-types'
import exact from 'prop-types-exact'
import { ThirdsFormCard, Footer } from '../components'
import NavBar from '../components/NavBar'

import { InvestorPage1Form, InvestorPage2Form, InvestorPage3Form } from 'forms'

import * as api from 'api'

import { scrollToSelector } from 'utils'

const propTypes = {
  userSession: PropTypes.object,
  profileSetupFields: PropTypes.object,
  storedData: PropTypes.object,
}
const defaultProps = {}

const getNestedValues = (profile) => {
  return Object.values(profile)[0]
}

function InvestorProfileSetup({ userSession, profileSetupFields, storedData }) {
  const [pageIndex, setPageIndex] = useState(1)
  const [showDynamic, setShowDynamic] = useState(
    isConsideringNonFundPartnerships(storedData)
  )

  function isConsideringNonFundPartnerships(values) {
    return values.investorConsideredInvestmentMethods.some((el) =>
      [1, 2].includes(el)
    )
  }

  const profile = profileSetupFields
  const profileType = 'investorProfile'
  const page1InitialValues = {
    //page 1
    firstName: storedData?.firstName || '',
    lastName: storedData?.lastName || '',

    usCitizen: storedData?.usCitizen || '',
    termsAndConditions: storedData?.termsAndConditions || '',

    investorTypeId: storedData?.investorTypeId || '',
    investmentFirmName: storedData?.investmentFirmName || '',
    location: storedData?.location || '',
    accreditedCertification: storedData?.accreditedCertification || '',

    currentIndustryId: storedData?.currentIndustryId || '',
    investorPriorSectorExperiences:
      storedData?.investorPriorSectorExperiences || [],
    yearsExperienceId: storedData?.yearsExperienceId || '',
    investorPriorLocationExperiences:
      storedData?.investorPriorLocationExperiences || [],
    description: storedData?.description || '',
  }
  const page2InitialValues = {
    investorConsideredInvestmentMethods:
      storedData?.investorConsideredInvestmentMethods || [],
    investorConsideredOfferingTypes:
      storedData?.investorConsideredOfferingTypes || [],
    investorConsideredEquityStakes:
      storedData?.investorConsideredEquityStakes || [],
    minConsideredEquityCheckId: storedData?.minConsideredEquityCheckId || '',
    maxConsideredEquityCheckId: storedData?.maxConsideredEquityCheckId || '',
    investorConsideredCapitalNeedReasons:
      storedData?.investorConsideredCapitalNeedReasons || [],
    investorPostInvestmentIntentions:
      storedData?.investorPostInvestmentIntentions || [],

    investorConsideredBusinessLocations:
      storedData?.investorConsideredBusinessLocations || [],
    investorConsideredPrimarySectors:
      storedData?.investorConsideredPrimarySectors || [],
    investorConsideredPrimaryIndustries:
      storedData?.investorConsideredPrimaryIndustries || [],
    investorConsideredBusinessModels:
      storedData?.investorConsideredBusinessModels || [],
    investorConsideredBusinessStages:
      storedData?.investorConsideredBusinessStages || [],
  }

  const page3InitialValues = {
    minConsideredAnnualRevenueId:
      storedData?.minConsideredAnnualRevenueId || '',
    maxConsideredAnnualRevenueId:
      storedData?.maxConsideredAnnualRevenueId || '',
    minConsideredAnnualEbitdaId: storedData?.minConsideredAnnualEbitdaId || '',
    maxConsideredAnnualEbitdaId: storedData?.maxConsideredAnnualEbitdaId || '',
    minConsideredAnnualEbitId: storedData?.minConsideredAnnualEbitId || '',
    maxConsideredAnnualEbitId: storedData?.maxConsideredAnnualEbitId || '',
    investorConsideredOwnerships:
      storedData?.investorConsideredOwnerships || [],

    membershipStatusId: storedData?.membershipStatusId || '',

    investorTypeIdForSectorIcon: storedData?.investorTypeId,

    defaultImage: storedData?.defaultImage || false,
    profileImageUrl: storedData?.profileImageUrl || '',
    profileImageMeta: storedData?.profileImageMeta || {},
  }

  const [page1Values, setPage1Values] = useState(page1InitialValues)
  const [page2Values, setPage2Values] = useState(page2InitialValues)
  const [page3Values, setPage3Values] = useState(page3InitialValues)
  const [sectorIconId, setSectorIconId] = useState(1)

  const handleProfileUpdate = async (values, isFullSubmit) => {
    const requestObj = isFullSubmit
      ? {
          [profileType]: {
            profileComplete: true,
            ...values,
          },
        }
      : {
          [profileType]: {
            ...values,
          },
        }

    /* eslint-disable */
    await api
      .updateProfile(requestObj, true)
      .then((res) => console.log('response', res))
      .catch(console.log)
    /* eslint-enable  */
    if (isFullSubmit) window.location.replace('/dashboard')
  }

  function handleSubmit(values, isFullSubmit) {
    const valuesOnChange = {
      ...values,
    }
    handleProfileUpdate(valuesOnChange, isFullSubmit)
  }

  function handleBack(values, pageIndex) {
    const valuesOnChange = {
      ...values,
    }
    if (pageIndex !== 3) {
      handleProfileUpdate(valuesOnChange, false)
    }
    setPageIndex(pageIndex - 1)
    scrollToSelector('.signup-header')
  }
  return (
    <div className="profile-setup">
      <div className="signup-header">
        <div className="centered-content">
          <NavBar userSession={userSession} />
        </div>
      </div>
      <div className="centered-content">
        <ThirdsFormCard pageIndex={pageIndex}>
          {pageIndex === 1 && (
            <InvestorPage1Form
              formValues={page1Values}
              params={getNestedValues(profile)}
              handleProfileUpdate={(values) => handleProfileUpdate(values)}
              handleSubmit={(values) => {
                setPage1Values(values)
                handleSubmit(values)
                setPageIndex(2)
                scrollToSelector('.signup-header')
              }}
              setSectorIconId={setSectorIconId}
            />
          )}
          {pageIndex === 2 && (
            <InvestorPage2Form
              formValues={page2Values}
              params={getNestedValues(profile)}
              handleBack={(values) => {
                setPage2Values(values)
                handleBack(values, 2)
              }}
              handleSubmit={(values) => {
                setPage2Values(values)
                setShowDynamic(isConsideringNonFundPartnerships(values))
                setPageIndex(3)
                handleSubmit(values)
                scrollToSelector('.signup-header')
              }}
              setShowDynamic={setShowDynamic}
            />
          )}
          {pageIndex === 3 && (
            <InvestorPage3Form
              formValues={page3Values}
              params={getNestedValues(profile)}
              handleBack={(values) => {
                setPage3Values(values)
                handleBack(values, 3)
              }}
              handleSubmit={(values) => {
                handleSubmit(values, true)
              }}
              showDynamic={showDynamic}
              sectorIconId={sectorIconId}
            />
          )}
        </ThirdsFormCard>
      </div>
      <Footer />
    </div>
  )
}

InvestorProfileSetup.propTypes = exact(propTypes)
InvestorProfileSetup.defaultProps = defaultProps

export default React.memo(InvestorProfileSetup)
