import React from 'react'
import PropTypes from 'prop-types'
import exact from 'prop-types-exact'
import { LabeledField } from '@launchpadlab/lp-components'
import NumberFormat from 'react-number-format'

const propTypes = {
  input: PropTypes.object,
  className: PropTypes.string,
  disabled: PropTypes.bool,
  id: PropTypes.string,
}

const defaultProps = {}

function CurrencyInput(props) {
  const {
    input: { name, value, onBlur, onChange },
    id,
    className,
    disabled,
  } = props

  function formatValue(value) {
    return Math.ceil(value)
  }
  return (
    <LabeledField className={className} {...props}>
      <div className="input-wrapper">
        <NumberFormat
          type="text"
          disabled={disabled}
          thousandSeparator={true}
          decimalSeparator={false}
          allowNegative={false}
          {...{
            id: id || name,
            name,
            value: formatValue(value),
            onBlur,
            onChange,
          }}
        />
      </div>
    </LabeledField>
  )
}

CurrencyInput.propTypes = exact(propTypes)
CurrencyInput.defaultProps = defaultProps

export default React.memo(CurrencyInput)
