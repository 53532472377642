import React from 'react'
// import PropTypes from 'prop-types'
import exact from 'prop-types-exact'

import { ProfileSectionLineItem } from 'components/profiles'
import { isHumanizedBooleanValue, replaceNullValueWithPlaceholder } from 'utils'

const propTypes = {}

const defaultProps = {}

function BPOperatingCriteriaForAdvisorsProfileSection({
  sectionVisible,
  userData,
}) {
  if (sectionVisible) {
    return (
      <div className="profile-section-wrapper centered-content">
        <h1>Acquisition Operating Criteria</h1>
        <div className="profile-view-section ">
          <div className="profile-view-section-col">
            <ProfileSectionLineItem
              label="Sectors Served"
              value={replaceNullValueWithPlaceholder(
                userData.consideredServedSectors
              )}
              isCriteriaMatch={isHumanizedBooleanValue(
                userData.consideredServedSectors
              )}
            />

            <ProfileSectionLineItem
              label="Business Stages"
              value={replaceNullValueWithPlaceholder(
                userData.consideredBusinessStages
              )}
              isCriteriaMatch={isHumanizedBooleanValue(
                userData.consideredBusinessStages
              )}
            />
          </div>
          <div className="profile-view-section-col">
            <ProfileSectionLineItem
              label="Business Models"
              value={replaceNullValueWithPlaceholder(
                userData?.consideredBusinessModels
              )}
              isCriteriaMatch={isHumanizedBooleanValue(
                userData.consideredBusinessModels
              )}
            />

            <ProfileSectionLineItem
              label="Geographies Served"
              value={replaceNullValueWithPlaceholder(
                userData?.consideredServedLocations
              )}
              isCriteriaMatch={isHumanizedBooleanValue(
                userData.consideredServedLocations
              )}
            />
          </div>
        </div>
      </div>
    )
  } else {
    return <></>
  }
}

BPOperatingCriteriaForAdvisorsProfileSection.propTypes = exact(propTypes)
BPOperatingCriteriaForAdvisorsProfileSection.defaultProps = defaultProps

export default React.memo(BPOperatingCriteriaForAdvisorsProfileSection)
