import React from 'react'
import PropTypes from 'prop-types'
import exact from 'prop-types-exact'
import classnames from 'classnames'

const propTypes = {
  children: PropTypes.node,
  title: PropTypes.string,
  subtitle: PropTypes.node,
  className: PropTypes.string,
  removeTopRow: PropTypes.bool,
}

const defaultProps = {}

function Card({ children, title, subtitle, className, removeTopRow }) {
  return (
    <div className={classnames('card', className)}>
      <div className="grid">
        {!removeTopRow && (
          <div className="card-row">
            <div className="title-area">
              <div className="title">
                <h3>{title}</h3>
              </div>
              {subtitle ? <div className="subtitle">{subtitle}</div> : null}
            </div>
          </div>
        )}
        <div className="card-row">
          <div className="flexbox">{children}</div>
        </div>
      </div>
    </div>
  )
}

Card.propTypes = exact(propTypes)
Card.defaultProps = defaultProps

export default React.memo(Card)
