import React from 'react'
import PropTypes from 'prop-types'
import exact from 'prop-types-exact'
import {
  withFormikAdapterAndVisibilityInput,
  validate,
  formHelpers,
  getIndustriesByAllSelectedSectors,
} from '../utils'
import {
  DropdownCheckboxGroup as BaseDropownCheckboxGroup,
  Tooltip,
} from 'components'

import {
  Button,
  SubmitButton,
  Select as BaseSelectInput,
} from '@launchpadlab/lp-components'
import { Formik, Form } from 'formik'
import { isEmpty } from 'lodash'

const DropdownCheckboxGroup = withFormikAdapterAndVisibilityInput()(
  BaseDropownCheckboxGroup
)
const Select = withFormikAdapterAndVisibilityInput()(BaseSelectInput)

const propTypes = {
  params: PropTypes.object.isRequired,
  formValues: PropTypes.object.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  handleBack: PropTypes.func.isRequired,
}

const defaultProps = {}

function InvestorPage2Form({ params, formValues, handleSubmit, handleBack }) {
  function isConsideringNonFundPartnerships(values) {
    return values.investorConsideredInvestmentMethods.some((el) =>
      [1, 2].includes(el)
    )
  }

  function isConsideringDirectInvestment(values) {
    return values.investorConsideredInvestmentMethods.some((el) =>
      [1].includes(el)
    )
  }

  function validateForm(values) {
    const isConsideringNonFundPartnershipsValidation = isConsideringNonFundPartnerships(
      values
    )

    const isConsideringNonFundWithDirectInvestmentValidation = isConsideringDirectInvestment(
      values
    )

    const messages = validate(values, {
      investorConsideredInvestmentMethods: {
        presence: { allowEmpty: false },
      },
      investorConsideredOfferingTypes: isConsideringNonFundPartnershipsValidation
        ? {
            presence: {
              allowEmpty: false,
            },
          }
        : {},
      investorConsideredCapitalNeedReasons: isConsideringNonFundPartnershipsValidation
        ? {
            presence: {
              allowEmpty: false,
            },
          }
        : {},
      investorConsideredEquityStakes: isConsideringNonFundPartnershipsValidation
        ? {
            presence: {
              allowEmpty: false,
            },
          }
        : {},
      minConsideredEquityCheckId: {
        presence: {
          allowEmpty: false,
          message: '^Please select one',
        },
      },
      maxConsideredEquityCheckId: {
        presence: {
          allowEmpty: false,
          message: '^Please select one',
        },
      },
      investorPostInvestmentIntentions: isConsideringNonFundWithDirectInvestmentValidation
        ? {
            presence: {
              allowEmpty: false,
            },
          }
        : {},

      investorConsideredPrimarySectors: {
        presence: { allowEmpty: false },
      },
      investorConsideredPrimaryIndustries: {
        presence: { allowEmpty: false },
      },
      investorConsideredBusinessLocations: {
        presence: { allowEmpty: false },
      },
      investorConsideredBusinessModels: {
        presence: { allowEmpty: false },
      },

      investorConsideredBusinessStages: {
        presence: { allowEmpty: false },
      },
    })
    // mix custom stuff here w validate.js messages
    return {
      ...messages,
      //
    }
  }

  return (
    <Formik
      onSubmit={handleSubmit}
      validate={validateForm}
      initialValues={formValues}
    >
      {({ values, errors, setFieldValue, setFieldTouched, touched }) => (
        <Form className="onboarding-form setup-form">
          <h1>Investment Opportunity Criteria</h1>
          <p className="disclaimer">
            Full criteria is <span id="underline">not</span> publicized to other
            members. Members only see whether or not each other's criteria
            matches.
          </p>
          {!isConsideringNonFundPartnerships(values) ? (
            <div className="row">
              <DropdownCheckboxGroup
                name="investorConsideredInvestmentMethods"
                label="Investment Methods Considered"
                options={formHelpers.formatOptions(params.investmentMethods)}
                values={values}
                setFieldValue={setFieldValue}
                setFieldTouched={setFieldTouched}
                tags={true}
                errorMessage="Please select all that apply"
              />
            </div>
          ) : (
            <>
              <div className="row">
                <DropdownCheckboxGroup
                  name="investorConsideredInvestmentMethods"
                  label="Investment Methods Considered"
                  options={formHelpers.formatOptions(params.investmentMethods)}
                  values={values}
                  setFieldValue={setFieldValue}
                  setFieldTouched={setFieldTouched}
                  tags={true}
                  errorMessage="Please select all that apply"
                />
                <DropdownCheckboxGroup
                  name="investorConsideredOfferingTypes"
                  label="Offering Types Considered"
                  options={formHelpers.formatOptions(params.offeringTypes)}
                  values={values}
                  setFieldValue={setFieldValue}
                  setFieldTouched={setFieldTouched}
                  tags={true}
                  errorMessage="Please select all that apply"
                />
              </div>
              <div className="row">
                <DropdownCheckboxGroup
                  name="investorConsideredCapitalNeedReasons"
                  label="Reasons For Capital Need Considered"
                  options={formHelpers.formatOptions(params.capitalNeedReasons)}
                  values={values}
                  setFieldValue={setFieldValue}
                  setFieldTouched={setFieldTouched}
                  tags={true}
                  errorMessage="Please select all that apply"
                />
                <DropdownCheckboxGroup
                  name="investorConsideredEquityStakes"
                  label="Equity Stakes Considered"
                  tooltip={
                    <Tooltip
                      name="investorConsideredEquityStakes"
                      text="Please select the amount of equity offered by a business that you would consider"
                    />
                  }
                  options={formHelpers.formatOptions(params.equityStakes)}
                  values={values}
                  setFieldValue={setFieldValue}
                  setFieldTouched={setFieldTouched}
                  tags={true}
                  errorMessage="Please select all that apply"
                />
              </div>
              {isConsideringDirectInvestment(values) && (
                <div className="row">
                  <DropdownCheckboxGroup
                    name="investorPostInvestmentIntentions"
                    label="Intentions for Direct Investments"
                    options={formHelpers.formatOptions(
                      params.postInvestmentIntentions
                    )}
                    values={values}
                    setFieldValue={setFieldValue}
                    setFieldTouched={setFieldTouched}
                    tags={true}
                    errorMessage="Please select all that apply"
                  />
                </div>
              )}
            </>
          )}

          <h3 className="min-max-subheader">Likely Equity Checks</h3>
          <div className="row">
            <Select
              name="minConsideredEquityCheckId"
              label="Minimum Considered"
              options={formHelpers.formatOptions(params.investorCapital)}
              values={values}
              enablePlaceholderOption={true}
              placeholder=" "
              onChange={() => {
                if (
                  values.minConsideredEquityCheckId >
                  values.maxConsideredEquityCheckId
                )
                  setFieldValue('maxConsideredEquityCheckId', '')
              }}
            />
            <Select
              name="maxConsideredEquityCheckId"
              label="Maximum Considered"
              options={formHelpers.getMaxOptionsByMinValue(
                values.minConsideredEquityCheckId,
                params.investorCapital
              )}
              enablePlaceholderOption={true}
              placeholder={
                isEmpty(values.minConsideredEquityCheckId)
                  ? 'Must Select Minimum'
                  : ' '
              }
              disabled={values.minConsideredEquityCheckId === ''}
            />
          </div>

          <h1>Investment Operating Criteria</h1>
          <p className="disclaimer">
            Full operating investment criteria is{' '}
            <span id="underline">not</span> publicized to other members. Members
            only see whether or not your criteria matches their criteria or
            operating details.
          </p>
          <div className="row">
            <DropdownCheckboxGroup
              name="investorConsideredPrimarySectors"
              label="Primary Sectors"
              options={formHelpers.formatOptions(params.sectorsIndustries)}
              values={values}
              setFieldValue={setFieldValue}
              setFieldTouched={setFieldTouched}
              tags={true}
              errorMessage="Please select all that apply"
              allowSelectAll={true}
            />
            <DropdownCheckboxGroup
              name="investorConsideredPrimaryIndustries"
              label="Primary Industries"
              options={getIndustriesByAllSelectedSectors(
                values.investorConsideredPrimarySectors,
                params
              )}
              allowSelectAll={true}
              disabledText="Must Select Sector"
              errorMessage="Please select all that apply"
              setFieldValue={setFieldValue}
              setFieldTouched={setFieldTouched}
              values={values}
              tags={true}
              connectedFieldName="investorConsideredPrimarySectors"
              disabled={!values.investorConsideredPrimarySectors.length}
            />
          </div>
          <div className="row">
            <DropdownCheckboxGroup
              name="investorConsideredBusinessLocations"
              label="Business Locations"
              options={formHelpers.formatOptions(params.territoriesStates)}
              values={values}
              setFieldValue={setFieldValue}
              setFieldTouched={setFieldTouched}
              tags={true}
              errorMessage="Please select all that apply"
            />
            <DropdownCheckboxGroup
              name="investorConsideredBusinessModels"
              label="Business Models"
              options={formHelpers.formatOptions(params.businessModels)}
              values={values}
              setFieldValue={setFieldValue}
              setFieldTouched={setFieldTouched}
              tags={true}
              errorMessage="Please select all that apply"
            />
          </div>

          <div className="row">
            <DropdownCheckboxGroup
              name="investorConsideredBusinessStages"
              label="Business Stages"
              options={formHelpers.formatOptions(params.businessStages)}
              values={values}
              setFieldValue={setFieldValue}
              setFieldTouched={setFieldTouched}
              tags={true}
              errorMessage="Please select all that apply"
            />
          </div>
          <div className="submission-error-wrapper">
            <div className="button-area">
              <Button
                className="form-button button-secondary"
                onClick={() => handleBack(values)}
              >
                Back
              </Button>
              <SubmitButton className="form-button submit">Next</SubmitButton>
            </div>
            <div className="error-message-submission">
              {Object.keys(touched).some((key) => {
                return Object.keys(errors).includes(key)
              })
                ? 'Please fix the errors presented above'
                : null}
            </div>
          </div>
        </Form>
      )}
    </Formik>
  )
}

InvestorPage2Form.propTypes = exact(propTypes)
InvestorPage2Form.defaultProps = defaultProps

export default InvestorPage2Form
