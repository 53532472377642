import React, { useState } from 'react'
import PropTypes from 'prop-types'
import exact from 'prop-types-exact'
import {
  withFormikAdapterAndVisibilityInput,
  validate,
  formHelpers,
} from '../utils'
import {
  Select as BaseSelectInput,
  SubmitButton,
} from '@launchpadlab/lp-components'
import { Formik, Form } from 'formik'

const Select = withFormikAdapterAndVisibilityInput()(BaseSelectInput)

const propTypes = {
  handleSubmit: PropTypes.func,
  params: PropTypes.object,
  membershipStatus: PropTypes.string,
}

const defaultProps = {}

// Add an extra custom validation for the hidden spam input.
function validateForm(values) {
  const messages = validate(values, {
    membershipStatusId: {
      presence: {
        allowEmpty: false,
        message: '^Please select one',
      },
    },
  })
  // mix custom stuff here w validate.js messages
  return {
    ...messages,
    //
  }
}

function StatusAndImageSelectionEditForm({
  params,
  membershipStatus,
  handleSubmit,
}) {
  const [disabled, toggleDisabled] = useState(true)

  const values = {
    membershipStatusId: membershipStatus || '',
  }

  return (
    <Formik
      onSubmit={handleSubmit}
      validate={validateForm}
      initialValues={values}
    >
      {({ handleReset }) => (
        <Form className="onboarding-form">
          <div className="row">
            <h1>Membership Status</h1>
            {disabled ? (
              <button
                className="button-primary-outline"
                onClick={() => {
                  toggleDisabled(false)
                }}
              >
                Edit
              </button>
            ) : (
              <div className="edit-buttons-area">
                <button
                  className="button-primary-outline"
                  onClick={() => {
                    handleReset()
                    toggleDisabled(true)
                  }}
                >
                  Cancel
                </button>
                <SubmitButton>Submit Changes</SubmitButton>
              </div>
            )}
          </div>
          <div className="row">
            <Select
              name="membershipStatusId"
              label="Membership Status"
              options={formHelpers.formatOptions(params.membershipStatuses)}
              enablePlaceholderOption={true}
              placeholder=" "
              disabled={disabled}
            />
          </div>
        </Form>
      )}
    </Formik>
  )
}

StatusAndImageSelectionEditForm.propTypes = exact(propTypes)
StatusAndImageSelectionEditForm.defaultProps = defaultProps

export default StatusAndImageSelectionEditForm
