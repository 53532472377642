import React from 'react'
// import PropTypes from 'prop-types'
import exact from 'prop-types-exact'

import { ProfileSectionLineItem } from 'components/profiles'
import { isHumanizedBooleanValue, replaceNullValueWithPlaceholder } from 'utils'

const propTypes = {}

const defaultProps = {}

function IPInvestorExplorationStatusProfileSection({
  sectionVisible,
  userData,
  seekingOverrideForHOVM,
  isSeekingInvestors,
}) {
  // const areBothInvestorsSeekingInvestors =
  //   userData?.viewerSeekingStatus === 'seeking_investors' &&
  //   userData?.seekingStatus === 'Seeking Investors'
  const isProfileSeekingInvestors =
    userData?.seekingStatus === 'Seeking Investors'

  const isViewerInvestor = userData.viewerProfileType === 'InvestorProfile'

  if (sectionVisible) {
    return (
      <div className="profile-section-wrapper centered-content">
        <h1>Exploration Status</h1>
        <div className="profile-view-section ">
          <div className="profile-view-section-col">
            <ProfileSectionLineItem
              label="Member Status"
              value={userData.membershipStatus}
            />
            {userData?.consideredInvestmentMethods && (
              <ProfileSectionLineItem
                label="Investment Methods Considered"
                value={replaceNullValueWithPlaceholder(
                  userData?.consideredInvestmentMethods
                )}
              />
            )}
          </div>
          <div className="profile-view-section-col">
            <ProfileSectionLineItem
              label="Accredited Investor"
              value={userData.accreditedCertification ? 'Yes' : 'No'}
            />
            {/* {viewerType === 'Investor' && (
              <ProfileSectionLineItem
                label="Currently Seeking Investments Or Investors?"
                value={userData.seekingStatus ? 'Yes' : 'No'}
              />
            )} */}
          </div>
        </div>
        {((isProfileSeekingInvestors && isViewerInvestor) ||
          seekingOverrideForHOVM) && (
          <div className="profile-view-section " style={{ marginTop: '16px' }}>
            <div className="profile-view-section-col">
              <ProfileSectionLineItem
                label="Reason For Seeking Investors"
                value={userData.seekingInvestorReason}
              />
              <ProfileSectionLineItem
                label="Number Of Partners Sought"
                value={userData.numberOfPartners}
              />
            </div>
            <div className="profile-view-section-col">
              <ProfileSectionLineItem
                label={
                  isSeekingInvestors
                    ? 'Equity Check Per Investor Considered'
                    : 'Equity Check'
                }
                value={userData.targetEquityCheckPerInvestor}
                isCriteriaMatch={isHumanizedBooleanValue(
                  userData.targetEquityCheckPerInvestor
                )}
              />
              <ProfileSectionLineItem
                label="Total Raise Considered"
                value={
                  userData.targetFundSize === null
                    ? 'Undisclosed'
                    : userData.targetFundSize
                }
              />
            </div>
          </div>
        )}
      </div>
    )
  } else {
    return <></>
  }
}

IPInvestorExplorationStatusProfileSection.propTypes = exact(propTypes)
IPInvestorExplorationStatusProfileSection.defaultProps = defaultProps

export default React.memo(IPInvestorExplorationStatusProfileSection)
