// formatOptions takes our option objects and reformats them into {key:,value:} for lp-components
const formatOptions = (optionsArray) => {
  let arrayOfKeyValueFields = []

  optionsArray.forEach((option) =>
    arrayOfKeyValueFields.push({ key: option.name, value: option.id })
  )
  return arrayOfKeyValueFields
}

// gets the correct object in array by id
const getArrayObjectById = (id, optionsArray) => {
  return optionsArray.find((obj) => obj.id === parseInt(id))
}

// gets the sub-array key from option groups
const getKeyFromOptionGroups = (optionGroups) => {
  const group = optionGroups[0]
  const keys = Object.keys(group)
  const groupKey = keys.find((el) => el !== 'name' && el !== 'id')

  return groupKey
}

const getMaxOptionsByMinValue = (minValue, options) => {
  return formatOptions(options.filter((option) => option.id >= minValue))
}

export default {
  formatOptions,
  getArrayObjectById,
  getKeyFromOptionGroups,
  getMaxOptionsByMinValue,
}
