import React from 'react'
import PropTypes from 'prop-types'
import exact from 'prop-types-exact'
import Slider from 'react-slick'
import 'slick-carousel/slick/slick.css'
import 'slick-carousel/slick/slick-theme.css'
import { isEmpty } from 'lodash'
import { Spinner } from '@launchpadlab/lp-components'
import {
  AdvisorCarouselCard,
  BusinessCarouselCard,
  InvestorCarouselCard,
} from 'components/carousels'

// import { useWindowSize } from 'utils'

const propTypes = {
  title: PropTypes.string,
  cards: PropTypes.array,
  loading: PropTypes.bool,
  noCardsText: PropTypes.string,
  isBusinessesRepresentedRow: PropTypes.bool,
}

const defaultProps = {}

function CarouselRow({
  title,
  cards,
  loading,
  noCardsText,
  isBusinessesRepresentedRow,
}) {
  const settings = {
    dots: false,
    infinite: false,
    speed: 300,
    slidesToShow: isBusinessesRepresentedRow ? 3 : 4,
    slidesToScroll: 1,
    responsive: [
      {
        breakpoint: 1600,
        settings: {
          slidesToShow: isBusinessesRepresentedRow ? 3 : 4,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 1300,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  }
  // const size = useWindowSize()

  return (
    <div className="carousel-section">
      <h2> {title} </h2>
      <div className="carousel-row">
        {loading && <Spinner></Spinner>}
        {!isEmpty(cards) ? (
          <Slider {...settings}>
            {cards?.map((card, index) => {
              if (card.profileType === 'AdvisorProfile') {
                return <AdvisorCarouselCard key={index} card={card} />
              } else if (card.profileType === 'BusinessProfile') {
                return <BusinessCarouselCard key={index} card={card} />
              } else {
                return <InvestorCarouselCard key={index} card={card} />
              }
            })}
          </Slider>
        ) : !loading ? (
          <div className="carousel-placeholder">{`No ${noCardsText}`}</div>
        ) : null}
      </div>
    </div>
  )
}

CarouselRow.propTypes = exact(propTypes)
CarouselRow.defaultProps = defaultProps

export default React.memo(CarouselRow)
