import React from 'react'
//import PropTypes from 'prop-types'
import exact from 'prop-types-exact'

const propTypes = {}

const defaultProps = {}

function Footer() {
  return (
    <footer>
      <span>© Firm Value, LLC 2021</span>
      <br />
      <span>
        Contact us at:{' '}
        <a href={'mailto:membership@firmvalue.com'}>membership@firmvalue.com</a>
      </span>
      <br />
      <span className={'mar'}>
        <a
          href={'https://www.firmvalue.com/privacy-policy'}
          target="_blank"
          rel="noreferrer"
        >
          Privacy Policy
        </a>
      </span>
      |
      <span>
        <a
          href={'https://www.firmvalue.com/terms-of-service'}
          target="_blank"
          rel="noreferrer"
        >
          Terms of Service
        </a>
      </span>
    </footer>
  )
}

Footer.propTypes = exact(propTypes)
Footer.defaultProps = defaultProps

export default React.memo(Footer)
