import React from 'react'
import PropTypes from 'prop-types'
import exact from 'prop-types-exact'

const propTypes = {
  children: PropTypes.node,
  width: PropTypes.string,
  height: PropTypes.string,
}

const defaultProps = {}

class ErrorBoundary extends React.Component {
  constructor(props) {
    super(props)
    this.state = { error: null, errorInfo: null }
  }

  componentDidCatch(error, errorInfo) {
    // Catch errors in any components below and re-render with error message
    this.setState({
      error: error,
      errorInfo: errorInfo,
    })
    // You can also log error messages to an error reporting service here
  }

  render() {
    if (this.state.errorInfo) {
      // Error path
      return (
        <div className="blurhash-error">
          <img
            src=""
            style={{ width: this.props.width, height: this.props.height }}
          ></img>
        </div>
      )
    }
    // Normally, just render children
    return this.props.children
  }
}

ErrorBoundary.propTypes = exact(propTypes)
ErrorBoundary.defaultProps = defaultProps

export default React.memo(ErrorBoundary)
