import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import exact from 'prop-types-exact'
import {
  withFormikAdapterAndVisibilityInput,
  validate,
  formHelpers,
} from '../utils'
import {
  DropdownCheckboxGroup as BaseDropownCheckboxGroup,
  Tooltip,
} from 'components'
import { SubmitButton, Spinner } from '@launchpadlab/lp-components'
import { Formik, Form } from 'formik'

import * as api from 'api'
import { Select as BaseSelectInput } from '@launchpadlab/lp-components/lib/forms/inputs'

const DropdownCheckboxGroup = withFormikAdapterAndVisibilityInput()(
  BaseDropownCheckboxGroup
)
const Select = withFormikAdapterAndVisibilityInput()(BaseSelectInput)

const propTypes = {
  params: PropTypes.object,
  handleSubmit: PropTypes.func,
}

const defaultProps = {}

// Add an extra custom validation for the hidden spam input.
function validateForm(values) {
  const messages = validate(values, {
    investorConsideredOwnerships: {
      presence: { allowEmpty: false, message: '^Please select all that apply' },
    },
    minConsideredAnnualRevenueId: {
      presence: {
        allowEmpty: false,
        message: '^Please select one',
      },
    },
    maxConsideredAnnualRevenueId: {
      presence: {
        allowEmpty: false,
        message: '^Please select one',
      },
    },
    minConsideredAnnualEbitdaId: {
      presence: {
        allowEmpty: false,
        message: '^Please select one',
      },
    },
    maxConsideredAnnualEbitdaId: {
      presence: {
        allowEmpty: false,
        message: '^Please select one',
      },
    },
    minConsideredAnnualEbitId: {
      presence: {
        allowEmpty: false,
        message: '^Please select one',
      },
    },
    maxConsideredAnnualEbitId: {
      presence: {
        allowEmpty: false,
        message: '^Please select one',
      },
    },
  })
  // mix custom stuff here w validate.js messages
  return {
    ...messages,
    //
  }
}

function InvestorFinancialInvestmentCriteriaEditForm({ params, handleSubmit }) {
  const [disabled, toggleDisabled] = useState(true)
  const [storedData, setStoredData] = useState()

  useEffect(() => {
    async function fetchData() {
      setStoredData(
        await api.getStoredData('/investor/financial-investment-criteria')
      )
    }
    fetchData()
  }, [])
  const initialValues = {
    minConsideredAnnualRevenueId:
      storedData?.minConsideredAnnualRevenueId || '',
    maxConsideredAnnualRevenueId:
      storedData?.maxConsideredAnnualRevenueId || '',
    minConsideredAnnualEbitdaId: storedData?.minConsideredAnnualEbitdaId || '',
    maxConsideredAnnualEbitdaId: storedData?.maxConsideredAnnualEbitdaId || '',
    minConsideredAnnualEbitId: storedData?.minConsideredAnnualEbitId || '',
    maxConsideredAnnualEbitId: storedData?.maxConsideredAnnualEbitId || '',
    investorConsideredOwnerships:
      storedData?.investorConsideredOwnerships || [],
    consideredAnnualRevenuesVisibility:
      storedData?.consideredAnnualRevenuesVisibility || false,
    consideredEbitdasVisibility:
      storedData?.consideredEbitdasVisibility || false,
    consideredEbitsVisibility: storedData?.consideredEbitsVisibility || false,
    consideredOwnershipsVisibility:
      storedData?.consideredOwnershipsVisibility || false,
  }

  if (!storedData) {
    return <Spinner />
  }
  return (
    <Formik
      onSubmit={handleSubmit}
      validate={validateForm}
      initialValues={initialValues}
    >
      {({ values, setFieldTouched, setFieldValue, handleReset }) => (
        <Form className="onboarding-form">
          <div className="row">
            <h1>Investment Financial Criteria</h1>
            {disabled ? (
              <button
                className="button-primary-outline"
                onClick={() => {
                  toggleDisabled(false)
                }}
              >
                Edit
              </button>
            ) : (
              <div className="edit-buttons-area">
                <button
                  className="button-primary-outline"
                  onClick={() => {
                    handleReset()
                    toggleDisabled(true)
                  }}
                >
                  Cancel
                </button>
                <SubmitButton>Submit Changes</SubmitButton>
              </div>
            )}
          </div>
          <p className="disclaimer">
            Full financial investment criteria is{' '}
            <span id="underline">not</span> publicized to other members. Members
            only see whether or not your criteria matches their criteria or
            financial details.
          </p>
          <h3>Annual Revenue Sizes</h3>
          <div className="row">
            <Select
              name="minConsideredAnnualRevenueId"
              label="Minimum"
              options={formHelpers.formatOptions(params.financialMetrics)}
              enablePlaceholderOption={true}
              placeholder=" "
              onChange={() => {
                if (
                  values.minConsideredAnnualRevenueId >
                  values.maxConsideredAnnualRevenueId
                )
                  setFieldValue('maxConsideredAnnualRevenueId', '')
              }}
              disabled={disabled}
            />
            <Select
              name="maxConsideredAnnualRevenueId"
              label="Maximum"
              options={formHelpers.getMaxOptionsByMinValue(
                values.minConsideredAnnualRevenueId,
                params.financialMetrics
              )}
              enablePlaceholderOption={true}
              placeholder={
                values.minConsideredAnnualRevenueId === ''
                  ? 'Must Select Minimum'
                  : ' '
              }
              disabled={disabled || values.minConsideredAnnualRevenueId === ''}
              hasVisibilityField={true}
              visibilityName="consideredAnnualRevenues"
            />
          </div>
          <h3>Annual EBITDA Sizes</h3>
          <div className="row">
            <Select
              name="minConsideredAnnualEbitdaId"
              label="Minimum"
              tooltip={
                <Tooltip
                  name="minConsideredAnnualEbitdaId"
                  text="Defined as net income + taxes + interest + depreciation & amortization"
                />
              }
              options={formHelpers.formatOptions(params.financialMetrics)}
              enablePlaceholderOption={true}
              placeholder=" "
              onChange={() => {
                if (
                  values.minConsideredAnnualEbitdaId >
                  values.maxConsideredAnnualEbitdaId
                )
                  setFieldValue('maxConsideredAnnualEbitdaId', '')
              }}
              disabled={disabled}
            />
            <Select
              name="maxConsideredAnnualEbitdaId"
              label="Maximum"
              options={formHelpers.getMaxOptionsByMinValue(
                values.minConsideredAnnualEbitdaId,
                params.financialMetrics
              )}
              enablePlaceholderOption={true}
              placeholder={
                values.minConsideredAnnualEbitdaId === ''
                  ? 'Must Select Minimum'
                  : ' '
              }
              disabled={disabled || values.minConsideredAnnualEbitdaId === ''}
              hasVisibilityField={true}
              visibilityName="consideredEbitdas"
            />
          </div>
          <h3>Annual Operating Income Sizes</h3>
          <div className="row">
            <Select
              name="minConsideredAnnualEbitId"
              label="Minimum"
              tooltip={
                <Tooltip
                  name="minConsideredAnnualEbitId"
                  text="Defined as annual net income + taxes + interest"
                />
              }
              options={formHelpers.formatOptions(params.financialMetrics)}
              enablePlaceholderOption={true}
              placeholder=" "
              onChange={() => {
                if (
                  values.minConsideredAnnualEbitId >
                  values.maxConsideredAnnualEbitId
                )
                  setFieldValue('maxConsideredAnnualEbitId', '')
              }}
              disabled={disabled}
            />
            <Select
              name="maxConsideredAnnualEbitId"
              label="Maximum"
              options={formHelpers.getMaxOptionsByMinValue(
                values.minConsideredAnnualEbitId,
                params.financialMetrics
              )}
              enablePlaceholderOption={true}
              placeholder={
                values.minConsideredAnnualEbitId === ''
                  ? 'Must Select Minimum'
                  : ' '
              }
              disabled={disabled || values.minConsideredAnnualEbitId === ''}
              hasVisibilityField={true}
              visibilityName="consideredEbits"
            />
          </div>

          <div className="row">
            <DropdownCheckboxGroup
              name="investorConsideredOwnerships"
              label="Current Ownership Types Considered"
              options={formHelpers.formatOptions(params.currentOwnerships)}
              setFieldValue={setFieldValue}
              setFieldTouched={setFieldTouched}
              values={values}
              tags={true}
              disabled={disabled}
              hasVisibilityField
            />
          </div>
        </Form>
      )}
    </Formik>
  )
}

InvestorFinancialInvestmentCriteriaEditForm.propTypes = exact(propTypes)
InvestorFinancialInvestmentCriteriaEditForm.defaultProps = defaultProps

export default InvestorFinancialInvestmentCriteriaEditForm
