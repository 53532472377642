const replaceNullValueWithPlaceholder = (value, placeholder) => {
  if (value === null) {
    if (placeholder) {
      return placeholder
    }
    return '--'
  }

  return value
}

export default replaceNullValueWithPlaceholder
