import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import exact from 'prop-types-exact'
import {
  BPCapitalExplorationProfileSection,
  BPOperationsOverviewProfileSection,
  BPFinancialOverviewProfileSection,
  BPAdditionalDetailsProfileSection,
  BPGeneralOverviewProfileSection,
  BPOperatingCriteriaProfileSection,
  BPFinancialCriteriaProfileSection,
  // BPOperatingCriteriaForAdvisorProfileSection,
  HOVMProfileHeaderCard,
} from 'components/profiles'
import { Spinner } from '@launchpadlab/lp-components'
import * as api from 'api'

const propTypes = {
  viewerType: PropTypes.string,
}

const defaultProps = {}

function HOVMBusinessProfileView({ viewerType }) {
  const [storedData, setStoredData] = useState()
  const params = {
    user_type: viewerType,
  }
  useEffect(() => {
    async function fetchData() {
      setStoredData(
        await api.getHOVMProfileData(`/business/hovm`, { params: params })
      )
    }
    fetchData()
  }, [viewerType])
  const isProfileAcquisitive =
    storedData?.seekingStatus === 'Seeking acquisitions'
  const isViewerAcquisitiveBusiness = viewerType === 'AcquisitiveBusiness'
  const isViewerAdvisor = viewerType === 'Advisor'
  const isViewerInvestor = viewerType === 'Investor'
  const isViewerPassiveInvestorOnly = storedData?.isViewerPassiveInvestorOnly
  if (!storedData) {
    return <Spinner />
  }

  return (
    <div className="profile-view-wrapper hovm-profile-view-wrapper">
      <div className="profile-view">
        <HOVMProfileHeaderCard
          userData={storedData}
          profileType="businessProfile"
        />
        <BPCapitalExplorationProfileSection
          sectionVisible={true}
          userData={storedData}
          isAcquisitive={isProfileAcquisitive}
        />
        <BPOperationsOverviewProfileSection
          sectionVisible={
            !isProfileAcquisitive || isViewerAdvisor || isViewerInvestor
          }
          userData={storedData}
        />
        <BPGeneralOverviewProfileSection
          sectionVisible={
            isProfileAcquisitive && !isViewerAdvisor && !isViewerInvestor
          }
          userData={storedData}
        />
        <BPFinancialOverviewProfileSection
          sectionVisible={
            isViewerAcquisitiveBusiness ||
            (isViewerInvestor && !isViewerPassiveInvestorOnly) ||
            isViewerAdvisor
          }
          userData={storedData}
          hovmOverride={isViewerAdvisor}
        />
        <BPOperatingCriteriaProfileSection
          sectionVisible={
            isProfileAcquisitive && !isViewerAdvisor && !isViewerInvestor
          }
          userData={storedData}
        />
        {/* <BPOperatingCriteriaForAdvisorProfileSection
          sectionVisible={
            isProfileAcquisitive && !isViewerAdvisor && !isViewerInvestor
          }
          userData={storedData}
        /> */}
        <BPFinancialCriteriaProfileSection
          sectionVisible={
            isProfileAcquisitive &&
            !isViewerInvestor &&
            isProfileAcquisitive &&
            !isViewerAdvisor
          }
          userData={storedData}
        />
        <BPAdditionalDetailsProfileSection
          sectionVisible={
            !isProfileAcquisitive || isViewerAdvisor || isViewerInvestor
          }
          userData={storedData}
        />
      </div>
    </div>
  )
}

HOVMBusinessProfileView.propTypes = exact(propTypes)
HOVMBusinessProfileView.defaultProps = defaultProps

export default HOVMBusinessProfileView
