import React, { useState } from 'react'
import PropTypes from 'prop-types'
import exact from 'prop-types-exact'
import { Button } from '@launchpadlab/lp-components'
import * as api from 'api'
import { isEmpty } from 'lodash'
import { Blurhash } from 'react-blurhash'
import { BlurhashErrorBoundary } from 'components'

const propTypes = {
  userData: PropTypes.object,
}

const defaultProps = {}

function BusinessProfileCardDetails({ userData }) {
  const [requestLoading, setRequestLoading] = useState(false)
  const [connectionStatus, setConnectionStatus] = useState(
    userData.connectionStatus
  )
  const [savedForLater, setSavedForLater] = useState(userData.savedForLater)

  const profileId = userData.userId
  const viewingSelf = userData.viewingSelf
  const requestId = userData.requestId

  const handleConnect = () => {
    setRequestLoading(true)
    api
      .requestConnection('users/request-connection', {
        userId: profileId,
      })
      .then(() => {
        setConnectionStatus('requested-them')
      })
      .catch(() => {
        setConnectionStatus(null)
        setRequestLoading(false)
        throw new Error('Connection Request Could Not Be Sent')
      })
    setRequestLoading(false)
    window.location.reload(true)
  }

  // const handleRemoveConnection = () => {
  //   api.removeConnection('users/delete-connection', {
  //     userId: profileId,
  //   })
  //   window.location.reload(true)
  // }

  const handleAcceptRequest = () => {
    setRequestLoading(true)
    api
      .acceptConnectionRequest('users/accept-connection', {
        requestId: requestId,
      })
      .then(() => {
        setConnectionStatus('connected')
      })
      .catch(() => {
        setConnectionStatus(null)
        setRequestLoading(false)
        throw new Error('Accept Request Could Not Be Sent')
      })
    setRequestLoading(false)
    // window.location.reload(true)
  }

  const handleRejectConnection = () => {
    setRequestLoading(true)

    api.rejectConnection('users/reject-connection', {
      requestId: requestId,
    })

    setRequestLoading(false)

    // may want to find a better way..
    window.location.reload(true)
  }

  const handleSave = () => {
    setRequestLoading(true)
    api
      .saveUser('users/save-user', {
        userId: profileId,
      })
      .then(() => {
        setSavedForLater(true)
      })
      .catch(() => {
        setSavedForLater(false)
        setRequestLoading(false)
        throw new Error('Handle Save Request Could Not Be Sent')
      })
    setRequestLoading(false)
  }

  const handleUnsave = () => {
    setRequestLoading(true)
    api
      .unsaveUser('users/remove-saved-user', {
        userId: profileId,
      })
      .then(() => {
        setSavedForLater(false)
      })
      .catch(() => {
        setSavedForLater(true)
        setRequestLoading(false)
        throw new Error('Could not remove saved user')
      })
    setRequestLoading(false)
  }

  const handleMessage = () => {
    api.startConversation('/start-conversation', {
      user: userData.userId,
    })

    window.location.replace(`/dashboard/messages/${userData.userConnectionId}`)
  }

  const blurredImage = userData.blurredImage

  return (
    <div className="profile-card centered-content">
      {blurredImage ? (
        <BlurhashErrorBoundary width="80px" height="80px">
          <Blurhash
            className="profile-picture"
            hash={userData.profileImageUrl}
            width={80}
            height={80}
            resolutionX={32}
            resolutionY={32}
            punch={1}
          />
        </BlurhashErrorBoundary>
      ) : (
        <div className="profile-picture">
          <img src={userData.profileImageUrl} alt="User Profile Image" />
        </div>
      )}
      <div className="profile-information">
        <div className="profile-details">
          <h1>{userData.displayName || userData.businessName}</h1>
          <h2 className="profile-state-subheader">{userData.state}</h2>

          <div className="connections-details">
            <div className="card-line-item">
              <h4 className="card-line-item-label">{`Member Since: `}</h4>
              <div className="card-line-item-value">
                {new Date(userData.createdAt).getFullYear()}
              </div>
            </div>
            {/* <div className="card-line-item">
              <h4 className="card-line-item-label">{`Profile Confirmation: `}</h4>
              <div className="card-line-item-value">Self-Confirmed</div>
            </div> */}
            <div className="card-line-item">
              <h4 className="card-line-item-label">{`Total Connections: `}</h4>
              <div className="card-line-item-value">
                {userData.totalConnections ? userData.totalConnections : '--'}
              </div>
            </div>
            <div className="card-line-item">
              <h4 className="card-line-item-label">{`Mutual Connections: `}</h4>
              <div className="card-line-item-value">Coming soon</div>
            </div>
          </div>
        </div>
        <div className="profile-buttons">
          {isEmpty(connectionStatus) && !viewingSelf && (
            <div className="button-area">
              <Button onClick={handleConnect} submitting={requestLoading}>
                Connect
              </Button>
              {savedForLater ? (
                <Button
                  onClick={handleUnsave}
                  className="button-primary-outline"
                >
                  Unsave
                </Button>
              ) : (
                <Button onClick={handleSave} className="button-primary-outline">
                  Save For Later
                </Button>
              )}
            </div>
          )}
          {connectionStatus === 'connected' && (
            <div className="button-area">
              <Button className="disabled-connections-request">
                Connected
              </Button>
              <Button onClick={handleMessage} submitting={requestLoading}>
                Message/Add Notes
              </Button>
            </div>
          )}
          {connectionStatus === 'requested-them' && (
            <div className="button-area">
              <Button className="disabled-connections-request">
                Request Pending
              </Button>
            </div>
          )}
          {connectionStatus === 'requested-me' && (
            <div className="button-area not-for-me">
              <Button onClick={handleAcceptRequest} submitting={requestLoading}>
                Accept Request
              </Button>
              <a onClick={handleRejectConnection}>Not for Me</a>
            </div>
          )}
        </div>
      </div>
    </div>
  )
}

BusinessProfileCardDetails.propTypes = exact(propTypes)
BusinessProfileCardDetails.defaultProps = defaultProps

export default React.memo(BusinessProfileCardDetails)
