import React from 'react'
import PropTypes from 'prop-types'
import exact from 'prop-types-exact'
import classnames from 'classnames'
import { Button } from '@launchpadlab/lp-components'

const propTypes = {
  onConfirm: PropTypes.func,
  confirmHeader: PropTypes.string,
  confirmText: PropTypes.string,
  showModal: PropTypes.bool,
  toggleShowModal: PropTypes.func,
}

const defaultProps = {}

function ConfirmModal({
  onConfirm,
  confirmHeader,
  confirmText,
  showModal,
  toggleShowModal,
}) {
  return (
    <div className={classnames('delete-modal', { 'modal-open': showModal })}>
      <div className="modal-styles">
        <div className="confirm-modal">
          <div className="confirm-modal-row">
            <h1>{confirmHeader}</h1>
          </div>
          <div className="confirm-modal-row">
            <div className="confirm-modal-button-area">
              <Button onClick={onConfirm} className="button-primary">
                {confirmText}
              </Button>
              <Button
                onClick={() => {
                  toggleShowModal(false)
                }}
                className="button-primary-outline"
              >
                Cancel
              </Button>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

ConfirmModal.propTypes = exact(propTypes)
ConfirmModal.defaultProps = defaultProps

export default React.memo(ConfirmModal)
