import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import exact from 'prop-types-exact'
import {
  HOVMProfileHeaderCard,
  IPInvestorExplorationStatusProfileSection,
  IPGeneralOverviewProfileSection,
  IPOperatingCriteriaProfileSection,
  IPFinancialCriteriaProfileSection,
  IPInvestmentOpportunityCriteriaProfileSection,
} from 'components/profiles'
import { Spinner } from '@launchpadlab/lp-components'
import * as api from 'api'

const propTypes = {
  viewerType: PropTypes.string,
}

const defaultProps = {}

function HOVMInvestorProfileView({ viewerType }) {
  const [storedData, setStoredData] = useState()

  const params = {
    user_type: viewerType,
  }
  useEffect(() => {
    async function fetchData() {
      setStoredData(
        await api.getHOVMProfileData(`/investor/hovm`, { params: params })
      )
    }
    fetchData()
  }, [viewerType])

  if (!storedData) {
    return <Spinner />
  }
  const isPassiveInvestor =
    storedData?.isPassiveInvestorOnly === true ? true : false
  const isSeekingInvestors = storedData?.seekingStatus === 'Seeking Investors'

  return (
    <div className="profile-view-wrapper hovm-profile-view-wrapper">
      <div className="profile-view">
        <HOVMProfileHeaderCard
          userData={storedData}
          profileType="investorProfile"
        />
        <IPInvestorExplorationStatusProfileSection
          sectionVisible={true}
          userData={storedData}
          seekingOverrideForHOVM={
            viewerType === 'Investor' && isSeekingInvestors
          }
          isSeekingInvestors={isSeekingInvestors}
        />
        <IPGeneralOverviewProfileSection
          sectionVisible={true}
          userData={storedData}
        />
        <IPOperatingCriteriaProfileSection
          sectionVisible={true}
          userData={storedData}
          hovmOverride={viewerType === 'Advisor'}
        />
        <IPFinancialCriteriaProfileSection
          sectionVisible={!isPassiveInvestor}
          userData={storedData}
          hovmOverride={viewerType === 'Advisor' && !isPassiveInvestor}
          viewerType={viewerType}
        />
        <IPInvestmentOpportunityCriteriaProfileSection
          sectionVisible={viewerType === 'Business' && !isPassiveInvestor}
          viewerType={viewerType}
          isPassiveInvestor={isPassiveInvestor}
          isSeekingInvestors={isSeekingInvestors}
          userData={storedData}
        />
      </div>
    </div>
  )
}

HOVMInvestorProfileView.propTypes = exact(propTypes)
HOVMInvestorProfileView.defaultProps = defaultProps

export default HOVMInvestorProfileView
