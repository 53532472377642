import PropTypes from 'prop-types'

export const user = PropTypes.shape({
  id: PropTypes.number.isRequired,
  firstName: PropTypes.string.isRequired,
  lastName: PropTypes.string.isRequired,
  dateOfBirth: PropTypes.string.isRequired,
  email: PropTypes.string.isRequired,
  profileImageUrl: PropTypes.string.isRequired,
  active: PropTypes.bool.isRequired,
  membershipStatus: PropTypes.string.isRequired,
  profileType: PropTypes.string.isRequired,
  profileId: PropTypes.number.isRequired,
  createdAt: PropTypes.string.isRequired,
  updatedAt: PropTypes.string.isRequired,
  termsAndConditions: PropTypes.bool.isRequired,
  profileComplete: PropTypes.bool.isRequired,
  businessProfile: businessProfile,
  advisorProfile: advisorProfile,
  InvestorProfile: investorProfile,
  pendingConnections: PropTypes.number,
  totalConnections: PropTypes.number,
  potentialConnections: PropTypes.number,
})

export const advisorProfile = PropTypes.shape({
  advisorType: PropTypes.string.isRequired,
  firmName: PropTypes.string,
  title: PropTypes.string,
  location: PropTypes.string,
})

export const investorProfile = PropTypes.shape({
  investorType: PropTypes.string.isRequired,
  investmentFirmName: PropTypes.string,
  location: PropTypes.string.isRequired,
  currentIndustry: PropTypes.string,
  yearsExperience: PropTypes.string,
})

export const businessProfile = PropTypes.shape({
  businessName: PropTypes.string.isRequired,
  primaryIndustry: PropTypes.string.isRequired,
  location: PropTypes.string.isRequired,
})
