import React, { useState, useRef } from 'react'
import PropTypes from 'prop-types'
import classnames from 'classnames'
import {
  useClickAway,
  // , useIntersection
} from 'react-use'
import { useField } from 'formik'
import { isEmpty } from 'lodash'

const propTypes = {
  children: PropTypes.node.isRequired,
  name: PropTypes.string.isRequired,
  setFieldTouched: PropTypes.func.isRequired,
  disabled: PropTypes.bool,
  disabledText: PropTypes.string,
  meta: PropTypes.object,
  errorMessage: PropTypes.string,
  connectedFieldName: PropTypes.string,
  allPlaceholderText: PropTypes.bool,
}
const defaultProps = {
  selectedValues: [],
}

function DropdownSelect({
  children,
  name,
  disabled,
  disabledText,
  meta,
  errorMessage,
  setFieldTouched,
  connectedFieldName,
  allPlaceholderText,
}) {
  const [expanded, setExpanded] = useState(false)

  const toggleExpanded = () => {
    setExpanded(!expanded)
  }

  const ref = useRef(null)

  useClickAway(ref, () => {
    if (expanded) {
      toggleExpanded()
    }
  })
  // const intersecting = useIntersection(ref, {
  //   root: null,
  //   rootMargin: '-150px',
  //   threshold: 0.8,
  //   once: true,
  // })

  // const shouldShowUp = !intersecting?.isIntersecting ? true : false
  const shouldShowUp = false
  const [connectedField] = useField(connectedFieldName)
  const [currentField] = useField(name)
  const connectedFieldIsEmpty = isEmpty(connectedField.value)

  return (
    <div
      ref={ref}
      tabIndex="0"
      onBlur={() => setFieldTouched(name, true)}
      className={classnames('dropdown-select', { disabled: disabled })}
    >
      <div className="select-input" onClick={disabled ? null : toggleExpanded}>
        <div
          className={classnames(
            { 'disabled-text': disabled },

            { 'expanded-select-text': currentField.value.length > 0 }
          )}
        >
          {disabled && connectedFieldIsEmpty
            ? disabledText
            : currentField.value.length > 0
            ? 'Selected Options Below'
            : null}
          {allPlaceholderText && currentField.value.length === 0 ? (
            <span className="all-placeholder">All</span>
          ) : null}
        </div>
      </div>
      <div
        className={classnames('options checkboxes', {
          'is-active': expanded,
          'show-up': shouldShowUp,
        })}
      >
        <div className="scroll-box">{children}</div>
      </div>

      <div className="error-message">
        {meta.touched && meta.error ? errorMessage || meta.error : null}
      </div>
    </div>
  )
}
DropdownSelect.propTypes = propTypes
DropdownSelect.defaultProps = defaultProps

export default React.memo(DropdownSelect)
