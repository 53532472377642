import React, { useRef } from 'react'
import PropTypes from 'prop-types'
import exact from 'prop-types-exact'
import { NavLink } from 'react-router-dom'
import { formatNavSectionName } from 'utils'
import { kebabCase } from 'lodash'

// import HorizontalScroll from 'react-scroll-horizontal'

const propTypes = {
  sections: PropTypes.arrayOf(PropTypes.object),
  profileType: PropTypes.string,
  isConsideringNonFundPartnerships: PropTypes.bool,
}

const defaultProps = {}

function ProfileSettingsMobileSubNav({
  profileType,
  sections,
  isConsideringNonFundPartnerships,
}) {
  const navRef = useRef(null)

  const handleNav = (direction) => {
    if (direction === 'left') {
      navRef ? (navRef.current.scrollLeft -= 150) : null
    } else {
      navRef ? (navRef.current.scrollLeft += 150) : null
    }
  }

  return (
    <div className="mobile-sub-nav-and-button-container">
      <div className="scroll-button scroll-button-left">
        <button
          onClick={() => handleNav('left')}
          aria-label="Scroll Left"
        ></button>
      </div>
      <nav className="profile-settings-mobile-sub-nav" ref={navRef}>
        {sections ? (
          <>
            {profileType === 'businessProfile' && (
              <NavLink
                to={`/edit-profile/general-overview`}
                activeClassName="is-active"
              >
                <div className="mobile-nav-item">General Overview</div>
              </NavLink>
            )}
            {sections.map((section, i) => {
              if (section.name === 'financial_investment_criteria') {
                if (!isConsideringNonFundPartnerships) {
                  return
                }
              }

              return (
                <NavLink
                  key={i}
                  to={`/edit-profile/${kebabCase(section.name)}`}
                  activeClassName="is-active"
                >
                  <div key={i} className="mobile-nav-item">
                    {formatNavSectionName(section.name)}
                  </div>
                </NavLink>
              )
            })}
            {profileType === 'advisorProfile' && (
              <NavLink
                to={`/edit-profile/status-and-image-selection`}
                activeClassName="is-active"
              >
                <div className="mobile-nav-item">
                  {profileType === 'advisorProfile'
                    ? 'Membership Status'
                    : 'Profile Image'}
                </div>
              </NavLink>
            )}
            <NavLink
              to={`/edit-profile/how-others-view-me`}
              activeClassName="is-active"
            >
              <div className="mobile-nav-item">How Others View Me</div>
            </NavLink>
          </>
        ) : (
          <>
            <NavLink
              to={`/account-settings/memberInformation`}
              activeClassName="is-active"
            >
              <div className="mobile-nav-item">Member Information</div>
            </NavLink>
            <NavLink
              to={`/account-settings/password`}
              activeClassName="is-active"
            >
              <div className="mobile-nav-item">Password</div>
            </NavLink>
            <NavLink
              to={`/account-settings/delete-account`}
              activeClassName="is-active"
            >
              <div className="mobile-nav-item">Delete Account</div>
            </NavLink>
          </>
        )}
      </nav>
      <div className="scroll-button scroll-button-right">
        <button
          onClick={() => handleNav('right')}
          aria-label="Scroll Right"
        ></button>
      </div>
    </div>
  )
}

ProfileSettingsMobileSubNav.propTypes = exact(propTypes)
ProfileSettingsMobileSubNav.defaultProps = defaultProps

export default React.memo(ProfileSettingsMobileSubNav)
