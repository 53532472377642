import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import exact from 'prop-types-exact'
import {
  withFormikAdapterAndVisibilityInput,
  validate,
  formHelpers,
  getIndustries,
} from '../utils'
import {
  DropdownCheckboxGroup as BaseDropownCheckboxGroup,
  Tooltip,
  LabeledRadioButtons,
  ImageUploadPreview,
} from 'components'
import {
  Select as BaseSelectInput,
  Textarea as BaseTextarea,
  Checkbox as BaseCheckbox,
  SubmitButton,
  Spinner,
} from '@launchpadlab/lp-components'
import { Formik, Form } from 'formik'

import * as api from 'api'

const DropdownCheckboxGroup = withFormikAdapterAndVisibilityInput()(
  BaseDropownCheckboxGroup
)
const Textarea = withFormikAdapterAndVisibilityInput()(BaseTextarea)
const Select = withFormikAdapterAndVisibilityInput()(BaseSelectInput)
const Checkbox = withFormikAdapterAndVisibilityInput()(BaseCheckbox)

const propTypes = {
  params: PropTypes.object,
  handleSubmit: PropTypes.func,
  isIndividualInvestor: PropTypes.bool,
  profileImageUrl: PropTypes.string,
}

const defaultProps = {}

function InvestorGeneralOverviewEditForm({
  params,
  handleSubmit,
  isIndividualInvestor,
  profileImageUrl,
}) {
  const [disabled, toggleDisabled] = useState(true)
  const [storedData, setStoredData] = useState()

  useEffect(() => {
    async function fetchData() {
      setStoredData(await api.getStoredData('/investor/general-overview'))
    }
    fetchData()
  }, [])

  const initialValues = {
    currentIndustryId: storedData?.currentIndustryId || '',
    investorPriorSectorExperiences:
      storedData?.investorPriorSectorExperiences || [],
    yearsExperienceId: storedData?.yearsExperienceId || '',
    investorPriorLocationExperiences:
      storedData?.investorPriorLocationExperiences || [],
    accreditedCertification: storedData?.accreditedCertification ? true : false,
    description: storedData?.description || '',
    defaultImage: storedData?.useDefaultSectorIcon || false,
    profileImageUrl: storedData?.profileImageUrl || '',
    showNamePublic: storedData?.showNamePublic ? 'showName' : 'hideName',
    blurImagePublic: storedData?.blurImagePublic ? 'blurImage' : 'showImage',
    showNameConnections: storedData?.showNameConnections
      ? 'showName'
      : 'hideName',
    blurImageConnections: storedData?.blurImageConnections
      ? 'blurImage'
      : 'showImage',
  }
  if (!storedData) {
    return <Spinner />
  }
  function validateForm(values) {
    const defaultSectorIconSelected = values.defaultImage

    const isIndividualInvestorValidation = isIndividualInvestor
      ? {
          presence: {
            allowEmpty: false,
            message: '^Please select one',
          },
        }
      : {}
    const messages = validate(values, {
      currentIndustryId: isIndividualInvestorValidation,
      investorPriorSectorExperiences: {
        presence: { allowEmpty: false },
      },
      yearsExperienceId: {
        presence: {
          allowEmpty: false,
          message: '^Please select one',
        },
      },
      investorPriorLocationExperiences: {
        presence: { allowEmpty: false },
      },
      accreditedCertification: {
        presence: {
          allowEmpty: false,
          message: '^Response is required',
        },
      },
      description: {
        presence: { allowEmpty: true },
      },

      defaultImage: function (value, attributes) {
        if (attributes.profileImageUrl === '') {
          return { presence: { allowEmpty: false } }
        } else {
          return { presence: { allowEmpty: true } }
        }
      },
      profileImageUrl: function () {
        if (defaultSectorIconSelected) {
          return
        } else {
          return {
            presence: { allowEmpty: false, message: '^Profile image required' },
          }
        }
      },
    })
    // mix custom stuff here w validate.js messages
    return {
      ...messages,
      //
    }
  }

  const linkToExternal = () => {
    window.open(
      ' https://www.ecfr.gov/cgi-bin/retrieveECFR?gp=&SID=8edfd12967d69c024485029d968ee737&r=SECTION&n=17y3.0.1.1.12.0.46.176',
      '_blank'
    )
  }
  const isImageADefaultSectorIcon = (image) =>
    image.includes('/assets/DefaultIcons')

  if (isIndividualInvestor) {
    return (
      <Formik
        onSubmit={handleSubmit}
        validate={validateForm}
        initialValues={initialValues}
      >
        {({ values, setFieldTouched, setFieldValue, handleReset }) => (
          <Form className="onboarding-form">
            <div className="row">
              <h1>General Overview</h1>
              {disabled ? (
                <button
                  className="button-primary-outline"
                  onClick={() => {
                    toggleDisabled(false)
                  }}
                >
                  Edit
                </button>
              ) : (
                <div className="edit-buttons-area">
                  <button
                    className="button-primary-outline"
                    onClick={() => {
                      handleReset()
                      toggleDisabled(true)
                    }}
                  >
                    Cancel
                  </button>
                  <SubmitButton>Submit Changes</SubmitButton>
                </div>
              )}
            </div>
            <div className="row">
              <Select
                name="currentIndustryId"
                label="Current Industry"
                options={formHelpers.formatOptions(
                  getIndustries(params.sectorsIndustries)
                )}
                enablePlaceholderOption={true}
                placeholder=" "
                disabled={disabled}
                tooltip={
                  <Tooltip
                    name="currentIndustryId"
                    text="Please select the current or most recent sector in which you worked"
                  />
                }
              />
              <Select
                name="yearsExperienceId"
                label="Years of Experience"
                options={formHelpers.formatOptions(params.years)}
                enablePlaceholderOption={true}
                placeholder=" "
                disabled={disabled}
                tooltip={
                  <Tooltip
                    name="yearsExperienceId"
                    text="Please select total years of professional and/or investment experience"
                  />
                }
              />
            </div>
            <div className="row">
              <DropdownCheckboxGroup
                name="investorPriorSectorExperiences"
                label="Prior Sector Experience"
                options={formHelpers.formatOptions(params.sectorsIndustries)}
                setFieldValue={setFieldValue}
                setFieldTouched={setFieldTouched}
                errorMessage="Please select all that apply"
                values={values}
                tags={true}
                disabled={disabled}
                tooltip={
                  <Tooltip
                    name="investorPriorSectorExperiences"
                    text="Please select the current or most recent sector in which you worked"
                  />
                }
              />
              <DropdownCheckboxGroup
                name="investorPriorLocationExperiences"
                label="Prior Geographic Experience"
                options={formHelpers.formatOptions(params.territoriesStates)}
                setFieldValue={setFieldValue}
                setFieldTouched={setFieldTouched}
                errorMessage="Please select at least one location"
                values={values}
                tags={true}
                disabled={disabled}
                tooltip={
                  <Tooltip
                    name="investorPriorLocationExperiences"
                    text="Please select all geographies you have worked in, invested in, or served in a professional capacity"
                  />
                }
              />
            </div>
            <div className="row">
              <Select
                name="accreditedCertification"
                label="Accredited Certification?"
                options={[
                  { key: 'Yes', value: true },
                  { key: 'No', value: false },
                ]}
                enablePlaceholderOption={true}
                placeholder=" "
                disabled={disabled}
                tooltip={
                  <Tooltip
                    name="accreditedCertification"
                    text={[
                      'Are You an Accredited Investor? Please refer ',
                      {
                        key: 1,
                        value: (
                          <span
                            className="tooltip-link"
                            onClick={linkToExternal}
                          >
                            here
                          </span>
                        ),
                      },
                      ' for additional information',
                    ]}
                  />
                }
              />
            </div>
            <div className="row">
              <Textarea
                className="text-area description-box"
                name="description"
                label="Brief Description About You or Your Fund"
                hint="(Shown to Other Members)"
                maxLength={500}
                disabled={disabled}
              />
              <p className="disclaimer description-disclaimer">
                Only post factual information about yourself, your company,
                and/or your investment fund. Do not include any forward-looking
                statements, including, but not limited to (i) projections or
                predictions about future performance of your company and/or
                investments, and (ii) forecasts or opinions about the future
                value of your company’s securities and/or investments.
              </p>
            </div>

            <h1>Profile Image Selection</h1>
            <div className="row image-description-row">
              You may either choose from pre-made icons based on Sector, or
              upload your own image.
            </div>
            <div className="row photo-edit-row">
              <div className="column">
                <Checkbox
                  name="defaultImage"
                  onChange={(e) => {
                    if (e.target.value === true) {
                      setFieldValue('profileImageUrl', '')
                    }
                  }}
                  disabled={disabled}
                />
              </div>
              <div className="column">
                {values.defaultImage ? (
                  <div className="image-upload-preview">
                    <img
                      src={storedData?.defaultImageUrl}
                      alt="Default Sector Image"
                      style={{ width: '60px' }}
                    />
                  </div>
                ) : (
                  <ImageUploadPreview
                    disabled={disabled}
                    name="profileImageUrl"
                    hasStoredValue={values.profileImageUrl}
                    setFieldValue={setFieldValue}
                  />
                )}
              </div>
            </div>
            <h1>Visibility Options</h1>

            <div className="row general-overview-radio-row">
              <div className="general-overview-radio-col">
                <h3>Public</h3>
                <LabeledRadioButtons
                  label="Display Name"
                  name="showNamePublic"
                  values={[
                    {
                      label: `${storedData.publicDisplayName}`,
                      value: 'hideName',
                    },
                    {
                      label: `${storedData.privateDisplayName}`,
                      value: 'showName',
                    },
                  ]}
                  disabled={disabled}
                />
                {values.defaultImage ||
                isImageADefaultSectorIcon(values.profileImageUrl) ? null : (
                  <LabeledRadioButtons
                    label="Profile Image"
                    name="blurImagePublic"
                    values={[
                      {
                        label: `${
                          values.profileImageUrl
                            ? values.profileImageUrl
                            : profileImageUrl
                        }`,
                        value: 'blurImage',
                      },
                      {
                        label: `${
                          values.profileImageUrl
                            ? values.profileImageUrl
                            : profileImageUrl
                        }`,
                        value: 'showImage',
                      },
                    ]}
                    disabled={disabled}
                    isImageSelector={true}
                  />
                )}
              </div>
              <div className="general-overview-radio-col">
                <h3>Shown To Connections</h3>
                <LabeledRadioButtons
                  label="Display Name"
                  name="showNameConnections"
                  values={[
                    {
                      label: `${storedData.publicDisplayName}`,
                      value: 'hideName',
                    },
                    {
                      label: `${storedData.privateDisplayName}`,
                      value: 'showName',
                    },
                  ]}
                  disabled={disabled}
                />
                {values.defaultImage ||
                isImageADefaultSectorIcon(values.profileImageUrl) ? null : (
                  <LabeledRadioButtons
                    label="Profile Image"
                    name="blurImageConnections"
                    values={[
                      {
                        label: `${
                          values.profileImageUrl
                            ? values.profileImageUrl
                            : profileImageUrl
                        }`,
                        value: 'blurImage',
                      },
                      {
                        label: `${
                          values.profileImageUrl
                            ? values.profileImageUrl
                            : profileImageUrl
                        }`,
                        value: 'showImage',
                      },
                    ]}
                    disabled={disabled}
                    isImageSelector={true}
                  />
                )}
              </div>
            </div>
          </Form>
        )}
      </Formik>
    )
  }

  return (
    <Formik
      onSubmit={handleSubmit}
      validate={validateForm}
      initialValues={initialValues}
    >
      {({ values, setFieldTouched, setFieldValue, handleReset }) => (
        <Form className="onboarding-form">
          <div className="row">
            <h1>General Overview</h1>
            {disabled ? (
              <button
                className="button-primary-outline"
                onClick={() => {
                  toggleDisabled(false)
                }}
              >
                Edit
              </button>
            ) : (
              <div className="edit-buttons-area">
                <button
                  className="button-primary-outline"
                  onClick={() => {
                    handleReset()
                    toggleDisabled(true)
                  }}
                >
                  Cancel
                </button>
                <SubmitButton>Submit Changes</SubmitButton>
              </div>
            )}
          </div>
          <div className="row">
            <DropdownCheckboxGroup
              name="investorPriorSectorExperiences"
              label="Prior Sector Experience"
              options={formHelpers.formatOptions(params.sectorsIndustries)}
              setFieldValue={setFieldValue}
              setFieldTouched={setFieldTouched}
              errorMessage="Please select all that apply"
              values={values}
              tags={true}
              disabled={disabled}
              tooltip={
                <Tooltip text="Please select the current or most recent sector in which you worked" />
              }
            />
            <DropdownCheckboxGroup
              name="investorPriorLocationExperiences"
              label="Prior Geographic Experience"
              options={formHelpers.formatOptions(params.territoriesStates)}
              setFieldValue={setFieldValue}
              setFieldTouched={setFieldTouched}
              errorMessage="Please select all that apply"
              values={values}
              tags={true}
              disabled={disabled}
              tooltip={
                <Tooltip
                  name="investorPriorLocationExperiences"
                  text="Please select all geographies you have worked in, invested in, or served in a professional capacity"
                />
              }
            />
          </div>
          <div className="row">
            <Select
              name="yearsExperienceId"
              label="Years of Experience"
              options={formHelpers.formatOptions(params.years)}
              enablePlaceholderOption={true}
              placeholder=" "
              disabled={disabled}
              tooltip={
                <Tooltip
                  name="yearsExperienceId"
                  text="Please select total years of professional and/or investment experience"
                />
              }
            />

            <Select
              name="accreditedCertification"
              label="Accredited Certification?"
              options={[
                { key: 'Yes', value: true },
                { key: 'No', value: false },
              ]}
              enablePlaceholderOption={true}
              placeholder=" "
              disabled={disabled}
              tooltip={
                <Tooltip
                  name="accreditedCertification"
                  text={[
                    'Are You an Accredited Investor? Please refer ',
                    {
                      key: 1,
                      value: (
                        <span className="tooltip-link" onClick={linkToExternal}>
                          here
                        </span>
                      ),
                    },
                    ' for additional information',
                  ]}
                />
              }
            />
          </div>
          <div className="row">
            <Textarea
              className="text-area description-box"
              name="description"
              label="Brief Description About You or Your Fund"
              hint="(Shown to Other Members)"
              maxLength={500}
              disabled={disabled}
            />
            <p className="disclaimer description-disclaimer">
              Only post factual information about yourself, your company, and/or
              your investment fund. Do not include any forward-looking
              statements, including, but not limited to (i) projections or
              predictions about future performance of your company and/or
              investments, and (ii) forecasts or opinions about the future value
              of your company’s securities and/or investments.
            </p>
          </div>

          <h1>Profile Image Selection</h1>
          <div className="row image-description-row">
            You may either choose from pre-made icons based on Sector, or upload
            your own image.
          </div>
          <div className="row photo-edit-row">
            <div className="column">
              <Checkbox
                name="defaultImage"
                onChange={(e) => {
                  if (e.target.value === true) {
                    setFieldValue('profileImageUrl', '')
                  }
                }}
                disabled={disabled}
              />
            </div>
            <div className="column">
              {values.defaultImage ? (
                <div className="image-upload-preview">
                  <img
                    src={storedData?.defaultImageUrl}
                    alt="Default Sector Image"
                    style={{ width: '60px' }}
                  />
                </div>
              ) : (
                <ImageUploadPreview
                  disabled={disabled}
                  name="profileImageUrl"
                  hasStoredValue={values.profileImageUrl}
                  setFieldValue={setFieldValue}
                />
              )}
            </div>
          </div>
          <h1>Visibility Options</h1>
          <div className="row general-overview-radio-row">
            <div className="general-overview-radio-col">
              <h3>Public</h3>
              <LabeledRadioButtons
                label="Display Name"
                name="showNamePublic"
                values={[
                  {
                    label: `${storedData.publicDisplayName}`,
                    value: 'hideName',
                  },
                  {
                    label: `${storedData.privateDisplayName}`,
                    value: 'showName',
                  },
                ]}
                disabled={disabled}
              />
              {values.defaultImage ||
              isImageADefaultSectorIcon(values.profileImageUrl) ? null : (
                <LabeledRadioButtons
                  label="Profile Image"
                  name="blurImagePublic"
                  values={[
                    {
                      label: `${
                        values.profileImageUrl
                          ? values.profileImageUrl
                          : profileImageUrl
                      }`,
                      value: 'blurImage',
                    },
                    {
                      label: `${
                        values.profileImageUrl
                          ? values.profileImageUrl
                          : profileImageUrl
                      }`,
                      value: 'showImage',
                    },
                  ]}
                  disabled={disabled}
                  isImageSelector={true}
                />
              )}
            </div>
            <div className="general-overview-radio-col">
              <h3>Shown To Connections</h3>
              <LabeledRadioButtons
                label="Display Name"
                name="showNameConnections"
                values={[
                  {
                    label: `${storedData.publicDisplayName}`,
                    value: 'hideName',
                  },
                  {
                    label: `${storedData.privateDisplayName}`,
                    value: 'showName',
                  },
                ]}
                disabled={disabled}
              />
              {values.defaultImage ||
              isImageADefaultSectorIcon(values.profileImageUrl) ? null : (
                <LabeledRadioButtons
                  label="Profile Image"
                  name="blurImageConnections"
                  values={[
                    {
                      label: `${
                        values.profileImageUrl
                          ? values.profileImageUrl
                          : profileImageUrl
                      }`,
                      value: 'blurImage',
                    },
                    {
                      label: `${
                        values.profileImageUrl
                          ? values.profileImageUrl
                          : profileImageUrl
                      }`,
                      value: 'showImage',
                    },
                  ]}
                  disabled={disabled}
                  isImageSelector={true}
                />
              )}
            </div>
          </div>
        </Form>
      )}
    </Formik>
  )
}

InvestorGeneralOverviewEditForm.propTypes = exact(propTypes)
InvestorGeneralOverviewEditForm.defaultProps = defaultProps

export default InvestorGeneralOverviewEditForm
