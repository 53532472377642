import React from 'react'
// import PropTypes from 'prop-types'
import exact from 'prop-types-exact'
import {
  ProfileSectionLineItem,
  ProfileSectionLineItemTwoCol,
} from 'components/profiles'
import { isEmpty } from 'lodash'

const propTypes = {}

const defaultProps = {}

function BPGeneralOverviewProfileSection({ sectionVisible, userData }) {
  if (sectionVisible) {
    return (
      <div className="profile-section-wrapper centered-content">
        <h1>General Overview</h1>
        <div className="profile-view-section ">
          <div className="profile-view-section-col">
            <ProfileSectionLineItem
              label="Primary Sector"
              value={userData?.primarySector}
            />
            <ProfileSectionLineItem
              label="Primary Industry"
              value={userData.primaryIndustry}
            />
            <ProfileSectionLineItem
              label="Special Designation(s)"
              value={userData.specialDesignations}
            />
            <ProfileSectionLineItem
              label="U.S. Small Business"
              value={userData.meetsSba === true ? 'Yes' : 'No'}
            />
          </div>
          <div className="profile-view-section-col">
            <ProfileSectionLineItem
              label="Business Stage"
              value={userData.businessStage}
            />
            <ProfileSectionLineItem
              label="Business Model(s)"
              value={userData.businessModels}
            />
            <ProfileSectionLineItem
              label="Business Age (Years)"
              value={userData.businessAge}
            />
            <ProfileSectionLineItem
              label="Size of Employee Base"
              value={userData.employeeSize}
            />
            <ProfileSectionLineItem
              label="Other Locations (Regions)"
              value={userData.otherLocations}
            />
          </div>
        </div>
        {isEmpty(userData.description) ? null : (
          <ProfileSectionLineItemTwoCol
            label="Business Description"
            value={userData.description}
          />
        )}
      </div>
    )
  } else {
    return <></>
  }
}

BPGeneralOverviewProfileSection.propTypes = exact(propTypes)
BPGeneralOverviewProfileSection.defaultProps = defaultProps

export default React.memo(BPGeneralOverviewProfileSection)
