import React from 'react'
import PropTypes from 'prop-types'
import exact from 'prop-types-exact'

const propTypes = {
  label: PropTypes.string,
  value: PropTypes.string || PropTypes.array,
}

const defaultProps = {}

function formatValue(value) {
  if (Array.isArray(value)) {
    const arrayLen = value.length

    if (value.includes('None')) {
      return 'N/A'
    }
    return value.map((val, index) => {
      if (arrayLen === index + 1) {
        // last one
        return (
          <div key={index} className="connections-card-value-list-item">
            {val}
          </div>
        )
      } else {
        return (
          <div key={index} className="connections-card-value-list-item">
            {val + ', '}
          </div>
        )
      }
    })
  }

  return value
}

function CarouselCardLineItem({ label, value }) {
  return (
    <div className="carousel-line-item">
      <h4 className="carousel-line-item-label">{label + ':'}</h4>
      <div className="carousel-line-item-value">{formatValue(value)}</div>
    </div>
  )
}

CarouselCardLineItem.propTypes = exact(propTypes)
CarouselCardLineItem.defaultProps = defaultProps

export default React.memo(CarouselCardLineItem)
