import React from 'react'
import PropTypes from 'prop-types'
import exact from 'prop-types-exact'
import { Helmet } from 'react-helmet'
import { SideNav, MobileNav } from '../components'
import { DashboardHeader } from '../components/dashboard'
import {
  DashboardHome,
  Messages,
  Connections,
  Explore,
  EditProfile,
  EditAccountSettings,
  Notifications,
} from 'views'
import { BrowserRouter as Router, Route } from 'react-router-dom'

const propTypes = {
  userSession: PropTypes.object,
  profileParams: PropTypes.object,
  profileData: PropTypes.object,
  messageData: PropTypes.object,
}

const defaultProps = {}

function DashboardLayout(props) {
  const { profileParams, profileData, messageData } = props
  const profileType = Object.keys(profileParams)[0]
  const params = profileParams[profileType]
  const displayData = profileData.displayData
  const user = {
    id: profileData?.id || '',
    totalConnections: profileData.displayData.totalConnections,
    pendingConnections: profileData.displayData.pendingConnections,
    profileType: profileType,
    profileImageUrl: profileData?.generalOverview
      ? profileData?.generalOverview?.profileImageUrl
      : profileData?.profileImage?.profileImageUrl,
    firstName: profileData?.firstName || '',
    lastName: profileData?.lastName || '',
    dateOfBirth: profileData?.dateOfBirth || '',
    email: profileData?.email || '',
    state: profileData?.state || '',
    city: profileData?.city,
    seekingStatus: profileData?.seekingStatus,
    businessProfile: {
      displayName: displayData?.displayName,
      businessName: displayData?.businessName || '',
      location: displayData?.location || '',
      primaryIndustry: displayData?.primaryIndustry || '',
      roleWithinBusiness: displayData?.roleWithinBusiness || '',
      membershipStatus: displayData?.membershipStatus || '',
      createdAt: profileData?.createdAt || '',
    },
    advisorProfile: {
      displayName: displayData?.displayName,
      firmName: displayData?.firmName || '',
      title: displayData?.title || '',
      location: displayData?.zipcode || '',
      membershipStatus: displayData?.membershipStatus || '',
      createdAt: profileData?.createdAt || '',
    },
    investorProfile: {
      displayName: displayData?.displayName,
      investorType: displayData?.investorType || '',
      investmentFirmName: displayData?.investmentFirmName || '',
      location: displayData?.location || '',

      accreditedCertification: profileData?.accreditedCertification || false,

      currentIndustry: displayData?.currentIndustry || '',
      membershipStatus: displayData?.membershipStatus || '',
      createdAt: profileData?.createdAt || '',
      yearsExperience: displayData?.yearsExperience || '',
    },
  }
  return (
    <Router basename="/dashboard">
      <div className="dashboard-wrapper">
        <div className="dashboard-layout">
          <Helmet>
            <title>Dashboard</title>
          </Helmet>
          <DashboardHeader userSession={props.userSession} />
          <MobileNav />
          <SideNav />
          <main className="main-content">
            <Route exact path="/">
              <DashboardHome profileType={profileType} user={user} />
            </Route>
            <Route path="/messages">
              <Messages data={messageData} />
            </Route>
            <Route path="/connections">
              <Connections />
            </Route>
            <Route path="/explore">
              <Explore />
            </Route>
            <Route path="/notifications">
              <Notifications />
            </Route>
            <Route path="/edit-profile" match>
              <EditProfile
                profileType={profileType}
                params={params}
                profileData={profileData}
              />
            </Route>
            <Route path="/account-settings">
              <EditAccountSettings
                profileType={profileType}
                params={params}
                profileData={profileData}
                user={user}
              />
            </Route>
          </main>
        </div>
      </div>
    </Router>
  )
}

DashboardLayout.propTypes = exact(propTypes)
DashboardLayout.defaultProps = defaultProps

export default React.memo(DashboardLayout)
