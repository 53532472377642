import React, { useEffect, useState, useRef } from 'react'
// import PropTypes from 'prop-types'
import exact from 'prop-types-exact'
import { ErrorBoundary, Card, Footer } from '../components'
import {
  ExploreCardBusiness,
  ExploreCardInvestor,
  ExploreCardAdvisor,
  ExploreSortRow,
  ExplorePaginationControls,
} from '../components/explore'
import FilterIcon from '../../assets/images/filter.svg'
import classnames from 'classnames'
import ExploreFilterForm from '../forms/ExploreFilterForm'
import { Formik } from 'formik'
import { useWindowSize, useClickAway } from 'react-use'
import * as api from 'api'
import { isEmpty } from 'lodash'
import { Spinner } from '@launchpadlab/lp-components'

const propTypes = {}

const defaultProps = {}

function Explore() {
  const [activePage, setActivePage] = useState(0)
  const [exploreCards, setExploreCards] = useState([])
  const [loading, setLoading] = useState(false)
  const [showExpandedPanel, setShowExpandedPanel] = useState(false)
  const [filterValues, setFilterValues] = useState({
    sortSelection: '',
    memberTypeFilter: 'business',
    page: 0,

    businessMemberSubtypeFilter: null,
    investorMemberSubtypeFilter: null,
    advisorMemberSubtypeFilter: null,
    membershipStatusFilter: [],
    activeObjectiveFilter: [],
    locationFilter: [],
    // business dynamic fields
    sectorFilter: [],
    businessModelFilter: [],
    businessStagesFilter: [],
    specialDesignationsFilter: [],
    // ebitdaFilter: '',
    memberStatusFilter: [],
    // investor dynamic fields
    sectorExperienceFilter: [],
    geographicExperienceFilter: [],
    yearsOfExperienceFilter: [],
    sectorCriteriaFilter: [],
    // ebitdaCriteriaFilter: '',
    // advisor dynamic fields
    clientsServedFilter: [],
    sectorsServedFilter: [],
    businessModelsServedFilter: [],
    feeStructuresOfferedFilter: [],
    minEbitSizeServedFilter: '',
    maxEbitSizeServedFilter: '',
  })
  const ref = useRef(null)

  useClickAway(ref, () => {
    setShowExpandedPanel(false)
  })

  const toggleExpandedPanel = () => {
    setShowExpandedPanel(!showExpandedPanel)
  }
  const { width } = useWindowSize()
  useEffect(() => {
    if (width > 1023) {
      setShowExpandedPanel(false)
    }
  }, [width])

  const submitFilters = async (values) => {
    // nest values in filters:
    const requestObj = {
      ...values,
      page: activePage,
    }

    if (isEmpty(requestObj)) {
      return
    }

    setLoading(true)

    // send requestObj payload to endpoint and get back explore cards
    const cards = await api.updateExploreCards(
      '/users/explore-query',
      requestObj
    )

    // set explore cards
    setExploreCards(cards)
    setLoading(false)
  }

  const updateFilters = (values) => {
    setFilterValues(values)
    submitFilters(values)
  }

  return (
    <>
      <Formik
        // validate={(values) => {
        //   updateFilters(values)
        // }}
        // validateOnChange={true}
        // validateOnBlur={false}
        // validateOnMount={false}
        onSubmit={(values) => updateFilters(values)}
        initialValues={filterValues}
      >
        {({ values, setFieldTouched, setFieldValue, submitForm }) => (
          <ErrorBoundary>
            {useEffect(() => {
              if (activePage === values.page) {
                setFieldValue('page', 0)
                submitForm()
              } else {
                setActivePage(values.page)
                submitForm()
              }
            }, [JSON.stringify(values)])}
            <div
              className={classnames('expandable-filter-panel', {
                visible: showExpandedPanel,
              })}
              ref={ref}
            >
              <ExploreFilterForm
                values={values}
                setFieldValue={setFieldValue}
                setFieldTouched={setFieldTouched}
                filterValues={filterValues}
              />
            </div>
            <div
              className={classnames('explore-viewport', {
                visible: showExpandedPanel,
              })}
            >
              <div className="expandable-panel-trigger">
                <button
                  disabled={showExpandedPanel}
                  onClick={() => toggleExpandedPanel()}
                >
                  <img src={FilterIcon} alt="filter" />
                </button>
              </div>

              <div className="grid-container">
                <div className="filters">
                  <Card removeTopRow={true}>
                    <ExploreFilterForm
                      values={values}
                      setFieldValue={setFieldValue}
                      setFieldTouched={setFieldTouched}
                      filterValues={filterValues}
                    />
                  </Card>
                </div>
                <div className="connections-layout-wrapper">
                  <ExploreSortRow />
                  {loading ? (
                    <Spinner />
                  ) : isEmpty(exploreCards?.results) ? (
                    <div className="explore-is-empty-message">
                      No matches found
                    </div>
                  ) : (
                    <>
                      {filterValues.memberTypeFilter === 'business' &&
                        exploreCards?.results?.map((cardData, index) => {
                          return (
                            <ExploreCardBusiness
                              key={index}
                              cardData={cardData}
                            />
                          )
                        })}
                      {filterValues.memberTypeFilter === 'investor' &&
                        exploreCards?.results?.map((cardData, index) => {
                          return (
                            <ExploreCardInvestor
                              key={index}
                              cardData={cardData}
                            />
                          )
                        })}
                      {filterValues.memberTypeFilter === 'advisor' &&
                        exploreCards?.results?.map((cardData, index) => {
                          return (
                            <ExploreCardAdvisor
                              key={index}
                              cardData={cardData}
                            />
                          )
                        })}
                    </>
                  )}
                  <ExplorePaginationControls
                    name="page"
                    activePage={activePage}
                    setFieldValue={setFieldValue}
                    setActivePage={setActivePage}
                    totalPages={
                      isEmpty(exploreCards) ? 1 : exploreCards?.totalPages
                    }
                  ></ExplorePaginationControls>
                </div>
              </div>
            </div>
          </ErrorBoundary>
        )}
      </Formik>
      <Footer />
    </>
  )
}

Explore.propTypes = exact(propTypes)
Explore.defaultProps = defaultProps

export default React.memo(Explore)
