import { formHelpers } from 'utils'

// multiple sectors > multiple industry groups filter
const getIndustriesByAllSelectedSectors = (sectorIds, params) => {
  if (sectorIds) {
    let industries = []
    sectorIds.forEach((sectorId) => {
      const sectorObj = formHelpers.getArrayObjectById(
        sectorId,
        params.sectorsIndustries
      )
      const formattedIndustries = formHelpers.formatOptions(
        sectorObj.industries
      )
      industries.push(formattedIndustries)
    })

    return industries.flat()
  }
  return []
}

export default getIndustriesByAllSelectedSectors
