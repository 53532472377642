import React from 'react'
import PropTypes from 'prop-types'
import exact from 'prop-types-exact'
import {
  withFormikAdapterAndVisibilityInput,
  validate,
  formHelpers,
  validationPatterns,
  getIndustries,
} from '../utils'
import {
  DropdownCheckboxGroup as BaseDropownCheckboxGroup,
  Tooltip,
} from 'components'
import {
  Input as BaseInput,
  Select as BaseSelectInput,
  Textarea as BaseTextarea,
  Checkbox as BaseCheckbox,
  SubmitButton,
} from '@launchpadlab/lp-components'
import { Formik, Form } from 'formik'

const Input = withFormikAdapterAndVisibilityInput()(BaseInput)
const DropdownCheckboxGroup = withFormikAdapterAndVisibilityInput()(
  BaseDropownCheckboxGroup
)
const Select = withFormikAdapterAndVisibilityInput()(BaseSelectInput)
const Textarea = withFormikAdapterAndVisibilityInput()(BaseTextarea)
const Checkbox = withFormikAdapterAndVisibilityInput()(BaseCheckbox)

const propTypes = {
  params: PropTypes.object.isRequired,
  formValues: PropTypes.object.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  setSectorIconId: PropTypes.func.isRequired,
}

const defaultProps = {}

// Add an extra custom validation for the hidden spam input.
function validateForm(values) {
  const messages = validate(values, {
    firstName: function (value, attributes) {
      if (attributes.firstName === '')
        return {
          presence: {
            allowEmpty: false,
            message: '^Please provide a response',
          },
        }
      return {
        format: {
          pattern: validationPatterns.namePattern,
          message: 'cannot contain special characters',
        },
      }
    },
    lastName: function (value, attributes) {
      if (attributes.lastName === '')
        return {
          presence: {
            allowEmpty: false,
            message: '^Please provide a response',
          },
        }
      return {
        format: {
          pattern: validationPatterns.namePattern,
          message: 'cannot contain special characters',
        },
      }
    },
    investmentFirmName: function (value, attributes) {
      if (attributes.investorTypeId === 2) {
        return {
          presence: {
            allowEmpty: false,
            message: '^Please provide a response',
          },
        }
      }
      return {}
    },
    investorTypeId: {
      presence: { allowEmpty: false, message: '^Please select one' },
    },
    location: function (value, attributes) {
      if (attributes.location === '')
        return {
          presence: {
            allowEmpty: false,
            message: '^Please provide a response',
          },
        }
      return {
        format: {
          pattern: validationPatterns.zipCodePattern,
          message: '^%{value} is not a valid zip code.',
        },
      }
    },
    accreditedCertification: {
      presence: {
        allowEmpty: false,
        message: '^Please select one',
      },
    },

    currentIndustryId: function (value, attributes) {
      if (
        attributes.investorTypeId === '1' ||
        attributes.investorTypeId === ''
      ) {
        return {
          presence: {
            allowEmpty: false,
            message: '^Please select one',
          },
        }
      }
      return {}
    },

    investorPriorSectorExperiences: {
      presence: { allowEmpty: false },
    },
    yearsExperienceId: {
      presence: {
        allowEmpty: false,
        message: '^Please select one',
      },
    },
    investorPriorLocationExperiences: {
      presence: { allowEmpty: false },
    },
    description: {
      presence: { allowEmpty: true },
    },
    usCitizen: {
      presence: {
        message:
          '^Use of Firm Value is currently restricted to U.S. citizens and entities domiciled in the U.S.',
      },
      inclusion: {
        within: [true],
        message:
          '^Use of Firm Value is currently restricted to U.S. citizens and entities domiciled in the U.S.',
      },
    },
    termsAndConditions: {
      presence: {
        message:
          '^You must agree to our Terms of Service and Privacy Policy to join',
      },
      inclusion: {
        within: [true],
        message:
          '^You must agree to our Terms of Service and Privacy Policy to join',
      },
    },
  })
  // mix custom stuff here w validate.js messages
  return {
    ...messages,
    //
  }
}

function InvestorPage1Form({
  params,
  formValues,
  handleSubmit,
  setSectorIconId,
}) {
  const linkToExternal = () => {
    window.open(
      ' https://www.ecfr.gov/cgi-bin/retrieveECFR?gp=&SID=8edfd12967d69c024485029d968ee737&r=SECTION&n=17y3.0.1.1.12.0.46.176',
      '_blank'
    )
  }
  function isInvestmentFund(values) {
    return parseInt(values.investorTypeId) === 2
  }
  return (
    <Formik
      onSubmit={handleSubmit}
      validate={validateForm}
      initialValues={formValues}
    >
      {({ values, errors, setFieldValue, setFieldTouched, touched }) => (
        <Form className="onboarding-form setup-form">
          <h1>Member Information</h1>
          <p className="disclaimer">
            Names, firm and fund names, contact information, and cities are{' '}
            <span id="underline">not</span> publicized to other members unless
            you choose to do so in your profile settings.
          </p>
          <div className="row">
            <Input name="firstName" type="text" />
            <Input name="lastName" type="text" />
          </div>
          {isInvestmentFund(values) ? (
            <>
              <div className="row">
                <Select
                  name="investorTypeId"
                  label="Type Of Investor"
                  options={formHelpers.formatOptions(params.investorType)}
                  enablePlaceholderOption={true}
                  placeholder=" "
                  onChange={(e) => {
                    e.preventDefault()
                    setSectorIconId(e.target.value)
                  }}
                />
                <Input
                  name="investmentFirmName"
                  label="Investment Firm or Fund Name"
                  type="text"
                />
              </div>
              <div className="row">
                <Select
                  name="accreditedCertification"
                  label="Are You an Accredited Investor?"
                  tooltip={
                    <Tooltip
                      name="accreditedCertification"
                      text={[
                        'Are You an Accredited Investor? Please refer ',
                        {
                          key: 1,
                          value: (
                            <span
                              className="tooltip-link"
                              onClick={linkToExternal}
                            >
                              here
                            </span>
                          ),
                        },
                        ' for additional information',
                      ]}
                    />
                  }
                  options={[
                    { key: 'Yes', value: true },
                    { key: 'No', value: false },
                  ]}
                  enablePlaceholderOption={true}
                  placeholder=" "
                />
                <Input
                  name="location"
                  label="Location (Zip Code)"
                  type="text"
                  maxLength={10}
                />
              </div>
            </>
          ) : (
            <>
              <div className="row">
                <Select
                  name="investorTypeId"
                  label="Type Of Investor"
                  options={formHelpers.formatOptions(params.investorType)}
                  enablePlaceholderOption={true}
                  placeholder=" "
                  onChange={(e) => {
                    e.preventDefault()
                    setSectorIconId(e.target.value)
                  }}
                />
                <Select
                  name="accreditedCertification"
                  label="Are You an Accredited Investor?"
                  tooltip={
                    <Tooltip
                      name="accreditedCertification"
                      text={[
                        'Are You an Accredited Investor? Please refer ',
                        {
                          key: 1,
                          value: (
                            <span
                              className="tooltip-link"
                              onClick={linkToExternal}
                            >
                              here
                            </span>
                          ),
                        },
                        ' for additional information',
                      ]}
                    />
                  }
                  options={[
                    { key: 'Yes', value: true },
                    { key: 'No', value: false },
                  ]}
                  enablePlaceholderOption={true}
                  placeholder=" "
                />
              </div>
              <div className="row">
                <Input
                  name="location"
                  label="Location (Zip Code)"
                  type="text"
                  maxLength={10}
                />
              </div>
            </>
          )}
          <div className="row">
            <Checkbox
              name="usCitizen"
              label="I am a U.S. Citizen or U.S. Domiciled Entity"
            />
            <Checkbox
              name="termsAndConditions"
              label={
                <div className="policy-agreement-label">
                  <span>I agree to the </span>
                  <a
                    href={'https://www.firmvalue.com/terms-of-service'}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    Terms of Service
                  </a>
                  <span> and </span>
                  <a
                    href={'https://www.firmvalue.com/privacy-policy'}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    Privacy Policy
                  </a>
                </div>
              }
            />
          </div>
          <h1>General Overview</h1>
          {isInvestmentFund(values) ? (
            <>
              <div className="row">
                <DropdownCheckboxGroup
                  name="investorPriorSectorExperiences"
                  label="Prior Sector Experience"
                  tooltip={
                    <Tooltip
                      name="investorPriorSectorExperiences"
                      text="Please select all sectors in which you have worked or invested"
                    />
                  }
                  options={formHelpers.formatOptions(params.sectorsIndustries)}
                  values={values}
                  setFieldValue={setFieldValue}
                  setFieldTouched={setFieldTouched}
                  errorMessage="Please select all that apply"
                  tags={true}
                />
                <Select
                  name="yearsExperienceId"
                  label="Years of Experience"
                  tooltip={
                    <Tooltip
                      name="yearsExperienceId"
                      text="Please select total years of professional and/or investment experience"
                    />
                  }
                  options={formHelpers.formatOptions(params.years)}
                  enablePlaceholderOption={true}
                  placeholder=" "
                />
              </div>
              <div className="row">
                <DropdownCheckboxGroup
                  name="investorPriorLocationExperiences"
                  label="Prior Geographic Experience"
                  tooltip={
                    <Tooltip
                      name="investorPriorLocationExperiences"
                      text="Please select all geographies you have worked in, invested in, or served in a professional capacity"
                    />
                  }
                  options={formHelpers.formatOptions(params.territoriesStates)}
                  values={values}
                  setFieldValue={setFieldValue}
                  setFieldTouched={setFieldTouched}
                  errorMessage="Please select all that apply"
                  tags={true}
                />
              </div>
            </>
          ) : (
            <>
              <div className="row">
                <Select
                  name="currentIndustryId"
                  label="Current Industry"
                  tooltip={
                    <Tooltip
                      name="currentIndustryId"
                      text="Please select the current or most recent industry in which you worked"
                    />
                  }
                  options={formHelpers.formatOptions(
                    getIndustries(params.sectorsIndustries)
                  )}
                  enablePlaceholderOption={true}
                  placeholder=" "
                />
                <DropdownCheckboxGroup
                  name="investorPriorSectorExperiences"
                  label="Prior Sector Experience"
                  tooltip={
                    <Tooltip
                      name="investorPriorSectorExperiences"
                      text="Please select all sectors in which you have worked or invested"
                    />
                  }
                  options={formHelpers.formatOptions(params.sectorsIndustries)}
                  values={values}
                  setFieldValue={setFieldValue}
                  setFieldTouched={setFieldTouched}
                  errorMessage="Please select all that apply"
                  tags={true}
                />
              </div>
              <div className="row">
                <Select
                  name="yearsExperienceId"
                  label="Years of Experience"
                  tooltip={
                    <Tooltip
                      name="yearsExperienceId"
                      text="Please select total years of professional and/or investment experience"
                    />
                  }
                  options={formHelpers.formatOptions(params.years)}
                  enablePlaceholderOption={true}
                  placeholder=" "
                />
                <DropdownCheckboxGroup
                  name="investorPriorLocationExperiences"
                  label="Prior Geographic Experience"
                  tooltip={
                    <Tooltip
                      name="investorPriorLocationExperiences"
                      text="Please select all geographies you have worked in, invested in, or served in a professional capacity"
                    />
                  }
                  options={formHelpers.formatOptions(params.territoriesStates)}
                  values={values}
                  setFieldValue={setFieldValue}
                  setFieldTouched={setFieldTouched}
                  errorMessage="Please select all that apply"
                  tags={true}
                />
              </div>
            </>
          )}
          <div className="row">
            <Textarea
              className="description-box"
              name="description"
              label="Brief Description About You or Your Fund"
              hint="  (Shown to Other Members) "
              maxLength={500}
            />
            <p className="disclaimer description-disclaimer">
              Only post factual information about yourself, your company, and/or
              your investment fund. Do not include any forward-looking
              statements, including, but not limited to (i) projections or
              predictions about future performance of your company and/or
              investments, and (ii) forecasts or opinions about the future value
              of your company’s securities and/or investments.
            </p>
          </div>

          <div className="submission-error-wrapper">
            <div className="button-area">
              <SubmitButton className="form-button">Next</SubmitButton>
            </div>
            <div className="error-message-submission">
              {Object.keys(touched).some((key) => {
                return Object.keys(errors).includes(key)
              })
                ? 'Please fix the errors presented above'
                : null}
            </div>
          </div>
        </Form>
      )}
    </Formik>
  )
}

InvestorPage1Form.propTypes = exact(propTypes)
InvestorPage1Form.defaultProps = defaultProps

export default InvestorPage1Form
