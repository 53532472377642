import React from 'react'
import PropTypes from 'prop-types'
import exact from 'prop-types-exact'
import {
  ProfileHeaderCard,
  APAdvisoryStatusProfileSection,
  APGeneralOverviewProfileSection,
  APAdvisoryOfferingOverviewProfileSection,
  APBusinessesRepresentedCarouselProfileSection,
} from 'components/profiles'
import { Footer } from 'components'

import { DashboardHeader } from 'components/dashboard'

const propTypes = {
  userSession: PropTypes.object,
  userData: PropTypes.object,
}

const defaultProps = {}

function AdvisorProfileView(props) {
  const { userData } = props
  return (
    <div className="profile-view-wrapper">
      <DashboardHeader userSession={props.userSession} />
      <div className="profile-view">
        <ProfileHeaderCard {...props} />
        <APAdvisoryStatusProfileSection
          sectionVisible={true}
          userData={userData}
        />
        <APGeneralOverviewProfileSection
          sectionVisible={true}
          userData={userData}
        />
        <APAdvisoryOfferingOverviewProfileSection
          sectionVisible={true}
          userData={userData}
        />
        <APBusinessesRepresentedCarouselProfileSection
          sectionVisible={true}
          userData={userData}
        />
      </div>
      <Footer />
    </div>
  )
}

AdvisorProfileView.propTypes = exact(propTypes)
AdvisorProfileView.defaultProps = defaultProps

export default React.memo(AdvisorProfileView)
