import { validate as baseValidate, formatters } from 'validate.js'
import { set, forEach, capitalize, lowerCase, mapValues } from 'lodash'

// Exclude namespace from formatted error
function stripNamespace(errors, key) {
  // If attr is not namespaced, no need to format
  if (!key.includes('.')) return errors
  const namespaces = key.split('.').slice(0, -1)
  const excludeString = capitalize(namespaces.map(lowerCase).join(' ')) + ' '
  return errors.map((error) => capitalize(error.replace(excludeString, '')))
}
// Custom format function strips the namespace from each error
// E.g. person.profile.firstName -> firstName
function stripNamespaceFormatter(errors) {
  return mapValues(formatters.grouped(errors), stripNamespace)
}
// Register formatter
formatters.stripNamespace = stripNamespaceFormatter
// Returns an object where the keys are converted from string paths to nested objects.
function flatToNested(flatObj) {
  const nestedObj = {}
  forEach(flatObj, (value, key) => {
    set(nestedObj, key, value)
  })
  return nestedObj
}
// Validation function that takes in validate.js arguments and returns a nested object of errors in the format we expect.
// Ported from lp-form.
function validate(values, constraints, options) {
  const errors = baseValidate(values, constraints, {
    format: 'stripNamespace',
    ...options,
  })
  return flatToNested(errors)
}
export default validate
