import React from 'react'
// import PropTypes from 'prop-types'
import exact from 'prop-types-exact'

import {
  ProfileSectionLineItem,
  ProfileSectionLineItemTwoCol,
} from 'components/profiles'
import { replaceNullValueWithPlaceholder } from 'utils'

const propTypes = {}

const defaultProps = {}

function IPIndividualInvestorGeneralOverviewProfileSection({
  sectionVisible,
  userData,
}) {
  if (sectionVisible) {
    return (
      <div className="profile-section-wrapper centered-content">
        <h1>General Overview</h1>
        <div className="profile-view-section ">
          <div className="profile-view-section-col">
            <ProfileSectionLineItem
              label="Sector Experience"
              value={replaceNullValueWithPlaceholder(
                userData?.priorSectorExperiences
              )}
            />
          </div>
          <div className="profile-view-section-col">
            <ProfileSectionLineItem
              label="Years Of Professional Experience"
              value={replaceNullValueWithPlaceholder(
                userData?.yearsOfExperience
              )}
            />
            <ProfileSectionLineItem
              label="Geographic Experience"
              value={replaceNullValueWithPlaceholder(
                userData?.priorLocationExperiences
              )}
            />
          </div>
          <ProfileSectionLineItemTwoCol
            label="Investor Description"
            value={userData.description}
          />
        </div>
      </div>
    )
  } else {
    return <></>
  }
}

IPIndividualInvestorGeneralOverviewProfileSection.propTypes = exact(propTypes)
IPIndividualInvestorGeneralOverviewProfileSection.defaultProps = defaultProps

export default React.memo(IPIndividualInvestorGeneralOverviewProfileSection)
