import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import exact from 'prop-types-exact'
import { withFormikAdapterAndVisibilityInput, formHelpers } from '../utils'
import { DropdownCheckboxGroup as BaseDropownCheckboxGroup } from 'components'
import { Form } from 'formik'
import { isEmpty } from 'lodash'
import * as api from 'api'
import { Spinner } from '@launchpadlab/lp-components'

import { Select as BaseSelectInput } from '@launchpadlab/lp-components/lib/forms/inputs'

const DropdownCheckboxGroup = withFormikAdapterAndVisibilityInput()(
  BaseDropownCheckboxGroup
)
const Select = withFormikAdapterAndVisibilityInput()(BaseSelectInput)

const propTypes = {
  values: PropTypes.object,
  setFieldValue: PropTypes.func,
  setFieldTouched: PropTypes.func,
}

const defaultProps = {}

function ExploreFilterForm({ values, setFieldValue, setFieldTouched }) {
  const [params, setParams] = useState()
  useEffect(() => {
    async function fetchData() {
      setParams(await api.getInitialExploreParams('users/explore-initial-data'))
    }
    fetchData()
  }, [])

  useEffect(() => {
    setFieldValue('businessMemberSubtypeFilter', null)
    setFieldValue('investorMemberSubtypeFilter', null)
    setFieldValue('advisorMemberSubtypeFilter', null)
    setFieldValue('membershipStatusFilter', [])
    setFieldValue('activeObjectiveFilter', [])
    setFieldValue('locationFilter', [])
    setFieldValue('sectorFilter', [])
    setFieldValue('businessModelFilter', [])
    setFieldValue('businessStagesFilter', [])
    setFieldValue('specialDesignationsFilter', [])
    // setFieldValue('ebitdaFilter', '')
    setFieldValue('sectorExperienceFilter', [])
    setFieldValue('geographicExperienceFilter', [])
    setFieldValue('yearsOfExperienceFilter', [])
    setFieldValue('sectorCriteriaFilter', [])
    // setFieldValue('ebitdaCriteriaFilter', '')
    setFieldValue('clientsServedFilter', [])
    setFieldValue('sectorsServedFilter', [])
    setFieldValue('businessModelsServedFilter', [])
    setFieldValue('feeStructuresOfferedFilter', [])
    setFieldValue('operatingIncomeServedFilter', [])
  }, [values.memberTypeFilter])

  // console.log(params)
  // console.log('values', values)

  if (!params) {
    return <Spinner />
  }

  return (
    <Form className="filter-form">
      <div className="filter-row">
        <Select
          name="memberTypeFilter"
          label="Member Type"
          options={[
            {
              key: 'Business',
              value: 'business',
            },
            {
              key: 'Investor',
              value: 'investor',
            },
            {
              key: 'Advisor',
              value: 'advisor',
            },
          ]}
          placeholder=" "
          enablePlaceholderOption={true}
        />
      </div>
      {/* {values.memberTypeFilter === 'business' && (
        <div className="filter-row">
          <Select
            name="businessMemberSubtypeFilter"
            label="Business Type"
            options={[
              {
                key: 'Both',
                value: [],
              },
              {
                key: 'U.S. Small Business',
                value: 'smallBusiness',
              },
              {
                key: 'Acquisitive Business',
                value: 'acquisitiveBusiness',
              },
            ]}
            enablePlaceholderOption={true}
          />
        </div>
      )} */}
      {values.memberTypeFilter === 'investor' && (
        <div className="filter-row">
          <Select
            name="investorMemberSubtypeFilter"
            label="Investor Type"
            options={[
              {
                key: 'All',
                value: [],
              },
              {
                key: 'Investment Funds',
                value: [
                  params.dropdownData.investorTypes.find(
                    (obj) => obj.name === 'Investment Fund'
                  ).id,
                ],
              },
              {
                key: 'Individual Investors',
                value: [
                  params.dropdownData.investorTypes.find(
                    (obj) => obj.name === 'Individual Investor'
                  ).id,
                ],
              },
            ]}
            enablePlaceholderOption={true}
          />
        </div>
      )}
      {values.memberTypeFilter === 'advisor' && (
        <div className="filter-row">
          <Select
            name="advisorMemberSubtypeFilter"
            label="Advisor Type"
            options={[
              {
                key: 'All',
                value: [],
              },
              {
                key: 'Financial Advisors',
                value: 'financialAdvisors',
              },
              {
                key: 'Accountants',
                value: 'accountants',
              },
            ]}
            enablePlaceholderOption={true}
          />
        </div>
      )}

      {values.memberTypeFilter === 'advisor' ? (
        <div className="filter-row">
          <DropdownCheckboxGroup
            name="membershipStatusFilter"
            label="Member Status"
            options={formHelpers.formatOptions(
              params.dropdownData.advisorMemberStatus
            )}
            values={values}
            setFieldValue={setFieldValue}
            setFieldTouched={setFieldTouched}
            errorMessage="Please select all that apply"
            tags={true}
            disabled={isEmpty(values.memberTypeFilter)}
            allPlaceholderText={true}
          />
        </div>
      ) : (
        <div className="filter-row">
          <DropdownCheckboxGroup
            name="membershipStatusFilter"
            label="Member Status"
            options={formHelpers.formatOptions(
              params.dropdownData.membershipStatus
            )}
            values={values}
            setFieldValue={setFieldValue}
            setFieldTouched={setFieldTouched}
            errorMessage="Please select all that apply"
            tags={true}
            disabled={isEmpty(values.memberTypeFilter)}
            allPlaceholderText={true}
          />
        </div>
      )}
      {values.memberTypeFilter === 'business' && (
        <div className="filter-row">
          <Select
            name="activeObjectiveFilter"
            label="Active Objectives"
            options={[
              {
                key: 'All',
                value: '',
              },
              {
                key: 'Seeking Capital',
                value: 'seeking_capital',
              },
              {
                key: 'Seeking Acquisitions',
                value: 'seeking_acquisitions',
              },
            ]}
            enablePlaceholderOption={true}
          />
        </div>
      )}
      {values.memberTypeFilter === 'investor' && (
        <div className="filter-row">
          <Select
            name="activeObjectiveFilter"
            label="Active Objectives"
            options={[
              {
                key: 'All',
                value: '',
              },
              {
                key: 'Seeking Investors',
                value: 'seeking_investors',
              },
            ]}
            enablePlaceholderOption={true}
          />
        </div>
      )}
      <div className="filter-row">
        <DropdownCheckboxGroup
          name="locationFilter"
          label="Location(s)"
          options={formHelpers.formatOptions(params.dropdownData.territories)}
          errorMessage="Please select all that apply"
          setFieldValue={setFieldValue}
          setFieldTouched={setFieldTouched}
          values={values}
          tags={true}
          disabled={isEmpty(values.memberTypeFilter)}
          allPlaceholderText={true}
        />
      </div>
      {values.memberTypeFilter === 'business' && (
        <div className="explore-dynamic-fields">
          <div className="filter-row">
            <DropdownCheckboxGroup
              name="sectorFilter"
              label="Sector(s)"
              options={formHelpers.formatOptions(params.dropdownData.sectors)}
              values={values}
              setFieldValue={setFieldValue}
              setFieldTouched={setFieldTouched}
              errorMessage="Please select all that apply"
              tags={true}
              allPlaceholderText={true}
            />
          </div>
          {/* <div className="filter-row">
            <DropdownCheckboxGroup
              name="businessModelFilter"
              label="Business Model(s)"
              options={formHelpers.formatOptions(
                params.dropdownData.businessModels
              )}
              values={values}
              setFieldValue={setFieldValue}
              setFieldTouched={setFieldTouched}
              errorMessage="Please select all that apply"
              tags={true}
              allPlaceholderText={true}
            />
          </div> */}
          <div className="filter-row">
            <DropdownCheckboxGroup
              name="businessStagesFilter"
              label="Business Stage(s)"
              options={formHelpers.formatOptions(
                params.dropdownData.businessStages
              )}
              values={values}
              setFieldValue={setFieldValue}
              setFieldTouched={setFieldTouched}
              errorMessage="Please select all that apply"
              tags={true}
              allPlaceholderText={true}
            />
          </div>
          <div className="filter-row">
            <DropdownCheckboxGroup
              name="specialDesignationsFilter"
              label="Special Designations(s)"
              options={formHelpers.formatOptions(
                params.dropdownData.specialDesignations
              )}
              values={values}
              setFieldValue={setFieldValue}
              setFieldTouched={setFieldTouched}
              errorMessage="Please select all that apply"
              tags={true}
              allPlaceholderText={true}
            />
          </div>
          {/* <div className="filter-row">
            <Select
              name="ebitdaFilter"
              label="Annual EBITDA(s)"
              options={[
                {
                  key: 'All',
                  value: '',
                },
                {
                  key: 'Only Show Matches',
                  value: 'match',
                },
              ]}
              enablePlaceholderOption={true}
            />
          </div> */}
        </div>
      )}
      {values.memberTypeFilter === 'investor' && (
        <div className="explore-dynamic-fields">
          <div className="filter-row">
            <DropdownCheckboxGroup
              name="sectorExperienceFilter"
              label="Sector Experience(s)"
              options={formHelpers.formatOptions(params.dropdownData.sectors)}
              values={values}
              setFieldValue={setFieldValue}
              setFieldTouched={setFieldTouched}
              errorMessage="Please select all that apply"
              tags={true}
              disabled={isEmpty(values.memberTypeFilter)}
              allPlaceholderText={true}
            />
          </div>
          {/* <div className="filter-row">
            <DropdownCheckboxGroup
              name="geographicExperienceFilter"
              label="Geographic Experience(s)"
              options={formHelpers.formatOptions(
                params.dropdownData.territories
              )}
              values={values}
              setFieldValue={setFieldValue}
              setFieldTouched={setFieldTouched}
              errorMessage="Please select all that apply"
              tags={true}
              disabled={isEmpty(values.memberTypeFilter)}
              allPlaceholderText={true}
            />
          </div>
          <div className="filter-row">
            <DropdownCheckboxGroup
              name="yearsOfExperienceFilter"
              label="Years of Experience"
              options={formHelpers.formatOptions(params.dropdownData.years)}
              values={values}
              setFieldValue={setFieldValue}
              setFieldTouched={setFieldTouched}
              errorMessage="Please select all that apply"
              tags={true}
              disabled={isEmpty(values.memberTypeFilter)}
              allPlaceholderText={true}
            />
          </div> */}
          <div className="filter-row">
            <DropdownCheckboxGroup
              name="sectorCriteriaFilter"
              label="Sector Criteria"
              options={formHelpers.formatOptions(params.dropdownData.sectors)}
              values={values}
              setFieldValue={setFieldValue}
              setFieldTouched={setFieldTouched}
              errorMessage="Please select all that apply"
              tags={true}
              disabled={isEmpty(values.memberTypeFilter)}
              allPlaceholderText={true}
            />
          </div>
          {/* <div className="filter-row">
            <Select
              name="ebitdaCriteriaFilter"
              label="EBITDA Criteria"
              options={[
                {
                  key: 'All',
                  value: '',
                },
                {
                  key: 'Only Show Matches',
                  value: 'match',
                },
              ]}
              enablePlaceholderOption={true}
            />
          </div> */}
        </div>
      )}
      {values.memberTypeFilter === 'advisor' && (
        <div className="explore-dynamic-fields">
          <div className="filter-row">
            <DropdownCheckboxGroup
              name="clientsServedFilter"
              label="Clients Served"
              options={formHelpers.formatOptions(
                params.dropdownData.clientTypes
              )}
              values={values}
              setFieldValue={setFieldValue}
              setFieldTouched={setFieldTouched}
              errorMessage="Please select all that apply"
              tags={true}
              disabled={isEmpty(values.memberTypeFilter)}
              allPlaceholderText={true}
            />
          </div>
          <div className="filter-row">
            <DropdownCheckboxGroup
              name="sectorsServedFilter"
              label="Sectors Served"
              options={formHelpers.formatOptions(params.dropdownData.sectors)}
              values={values}
              setFieldValue={setFieldValue}
              setFieldTouched={setFieldTouched}
              errorMessage="Please select all that apply"
              tags={true}
              disabled={isEmpty(values.memberTypeFilter)}
              allPlaceholderText={true}
            />
          </div>
          {/* <div className="filter-row">
            <DropdownCheckboxGroup
              name="businessModelsServedFilter"
              label="Business Models Served"
              options={formHelpers.formatOptions(
                params.dropdownData.businessModels
              )}
              values={values}
              setFieldValue={setFieldValue}
              setFieldTouched={setFieldTouched}
              errorMessage="Please select all that apply"
              tags={true}
              disabled={isEmpty(values.memberTypeFilter)}
              allPlaceholderText={true}
            />
          </div>
          <div className="filter-row">
            <DropdownCheckboxGroup
              name="feeStructuresOfferedFilter"
              label="Fee Structures Offered"
              options={formHelpers.formatOptions(
                params.dropdownData.feeStructures
              )}
              values={values}
              setFieldValue={setFieldValue}
              setFieldTouched={setFieldTouched}
              errorMessage="Please select all that apply"
              tags={true}
              disabled={isEmpty(values.memberTypeFilter)}
              allPlaceholderText={true}
            />
          </div>
          <div className="filter-row">
            <Select
              name="minEbitSizeServedFilter"
              label="Minimum Operating Income Size Served"
              options={formHelpers.formatOptions(
                params.dropdownData.financialMetrics
              )}
              placeholder="None"
              enablePlaceholderOption={true}
            />
          </div>
          <div className="filter-row">
            <Select
              name="maxEbitSizeServedFilter"
              label="Maximum Operating Income Size Served"
              options={formHelpers.getMaxOptionsByMinValue(
                values.minEbitSizeServedFilter,
                params.dropdownData.financialMetrics
              )}
              placeholder="None"
              enablePlaceholderOption={true}
            />
          </div> */}
        </div>
      )}
    </Form>
  )
}

ExploreFilterForm.propTypes = exact(propTypes)
ExploreFilterForm.defaultProps = defaultProps

export default ExploreFilterForm
