import React from 'react'
import PropTypes from 'prop-types'
import exact from 'prop-types-exact'

const propTypes = {
  label: PropTypes.string,
  value: PropTypes.string || PropTypes.array,
  isCriteriaMatch: PropTypes.bool,
}

const defaultProps = {}

function ProfileSectionLineItem({ label, value, isCriteriaMatch }) {
  function formatValue(value) {
    if (Array.isArray(value)) {
      if (value.includes('None')) {
        return 'N/A'
      }
      return value.map((val, index) => {
        return (
          <span key={index} className="value-list-item">
            {val}
          </span>
        )
      })
    }

    return value
  }

  function formatCriteriaMatch(label) {
    return label + ' Criteria Match'
  }

  return (
    <p className="profile-view-section-line-item">
      <span className="profile-view-section-line-item-label">
        {isCriteriaMatch ? formatCriteriaMatch(label) : label}
      </span>
      <span className="profile-view-section-line-item-value">
        {formatValue(value)}
      </span>
    </p>
  )
}

ProfileSectionLineItem.propTypes = exact(propTypes)
ProfileSectionLineItem.defaultProps = defaultProps

export default React.memo(ProfileSectionLineItem)
