import React, { useState } from 'react'
import PropTypes from 'prop-types'
import exact from 'prop-types-exact'
import { FormCard, Footer } from '../components'
import NavBar from '../components/NavBar'

import { BusinessPage1Form, BusinessPage2Form } from 'forms'

import * as api from 'api'

import { scrollToSelector } from 'utils'

const propTypes = {
  userSession: PropTypes.object,
  profileSetupFields: PropTypes.object,
  storedData: PropTypes.object,
}
const defaultProps = {}

const getNestedValues = (profile) => {
  return Object.values(profile)[0]
}

function ProfileSetup({ userSession, profileSetupFields, storedData }) {
  const [isNextPageActive, setNextPageActive] = useState(false)
  const profile = profileSetupFields
  const profileType = 'businessProfile'

  const initialValues = {
    firstName: storedData?.firstName || '',
    lastName: storedData?.lastName || '',

    usCitizen: storedData?.usCitizen || '',
    termsAndConditions: storedData?.termsAndConditions || '',

    businessName: storedData?.businessName || '',
    roleWithinBusiness: storedData?.roleWithinBusiness || [],
    location: storedData?.location || '',
    primarySectorId: storedData?.primarySectorId || '',
    primaryIndustryId: storedData?.primaryIndustryId || ' ',
    businessProfileBusinessModels:
      storedData?.businessProfileBusinessModels || [],
    businessStageId: storedData?.businessStageId || '',
    businessAgeId: storedData?.businessAgeId || '',
    businessProfileServedSectors:
      storedData?.businessProfileServedSectors || [],
    businessServedLocations: storedData?.businessServedLocations || [],
    employeeSizeId: storedData?.employeeSizeId || '',

    annualRevenueId: storedData?.annualRevenueId || '',
    annualEbitdaId: storedData?.annualEbitdaId || '',
    annualEbitId: storedData?.annualEbitId || '',
    businessCurrentOwnerships: storedData?.businessCurrentOwnerships || [],
    businessSpecialDesignations: storedData?.businessSpecialDesignations || [],
    meetsSba:
      storedData?.meetsSba === true
        ? true
        : storedData?.meetsSba === false
        ? false
        : '',
    businessLocations: storedData?.businessLocations || [],
    description: storedData?.description || '',
    membershipStatusId: storedData?.membershipStatusId || '',
    defaultImage: storedData?.defaultImage || false,
    profileImageUrl: storedData?.profileImageUrl || '',
    profileImageMeta: storedData?.profileImageMeta || {},
  }
  const [formValues, setFormValues] = useState(initialValues)
  const handleProfileUpdate = async (values, isFullSubmit) => {
    const requestObj = isFullSubmit
      ? {
          [profileType]: {
            profileComplete: true,
            ...values,
          },
        }
      : {
          [profileType]: {
            ...values,
          },
        }

    /* eslint-disable */
    await api
      .updateProfile(requestObj, true)
      .then((res) => {
        console.log('response', res)
      })
      .catch(console.log)
    /* eslint-enable  */
    if (isFullSubmit) window.location.replace('/dashboard')
  }
  function handleSubmit(values, isFullSubmit) {
    const valuesOnChange = {
      ...values,
    }
    setFormValues(valuesOnChange)
    handleProfileUpdate(valuesOnChange, isFullSubmit)
  }

  function handleBack(values) {
    const valuesOnChange = {
      ...values,
    }

    setFormValues(valuesOnChange)
    handleProfileUpdate(valuesOnChange, false)
    setNextPageActive(false)
    scrollToSelector('.signup-header')
  }
  return (
    <div className="profile-setup">
      <div className="signup-header">
        <div className="centered-content">
          <NavBar userSession={userSession} />
        </div>
      </div>
      <div className="centered-content">
        <FormCard isNextPageActive={isNextPageActive}>
          {isNextPageActive ? (
            <BusinessPage2Form
              formValues={formValues}
              params={getNestedValues(profile)}
              setNextPageActive={setNextPageActive}
              setFormValues={setFormValues}
              handleBack={handleBack}
              handleSubmit={(values) => {
                handleSubmit(values, true)
              }}
            />
          ) : (
            <BusinessPage1Form
              formValues={formValues}
              params={getNestedValues(profile)}
              setNextPageActive={setNextPageActive}
              setFormValues={setFormValues}
              handleProfileUpdate={(values) => handleProfileUpdate(values)}
              handleSubmit={(values) => {
                handleSubmit(values)
                setNextPageActive(true)
                scrollToSelector('.signup-header')
              }}
            />
          )}
        </FormCard>
      </div>

      <Footer />
    </div>
  )
}

ProfileSetup.propTypes = exact(propTypes)
ProfileSetup.defaultProps = defaultProps

export default React.memo(ProfileSetup)
