import React, { useState, useEffect } from 'react'
// import PropTypes from 'prop-types'
import exact from 'prop-types-exact'
import {
  ConnectionsSortRow,
  ConnectionsActiveConnectionsCard,
} from 'components/connections'
import { PaginationControls } from 'components'
import { scrollToSelector } from 'utils'
import { Spinner } from '@launchpadlab/lp-components'
import * as api from 'api'
import { isEmpty } from 'lodash'

const propTypes = {}

const defaultProps = {}

function ConnectionsActiveConnections() {
  const [activeConnections, setActiveConnections] = useState([])
  const [activePage, setActivePage] = useState(0) // 0 index in Pagination
  const [sortOption, setSortOption] = useState('most-recent') // 0 index in Pagination

  const cards = activeConnections.data

  useEffect(() => {
    const params = {
      page: activePage + 1,
      sortOption: sortOption,
    }
    async function fetchActiveConnections() {
      setActiveConnections(
        await api.getConnections('users/active-connections', {
          params: params,
        })
      )
    }
    fetchActiveConnections()
  }, [activePage, sortOption])

  const totalPages = activeConnections.totalPages

  const handleSortChange = (sortValue) => {
    scrollToSelector('.dashboard-header')
    setSortOption(sortValue)
  }

  const handlePage = (pageNumber) => {
    scrollToSelector('.dashboard-header')
    setActivePage(pageNumber.selected)
  }

  if (!cards) {
    return <Spinner />
  }

  return (
    <div className="connections-viewport">
      <ConnectionsSortRow handleSortChange={handleSortChange} />
      {isEmpty(cards) ? (
        <div className="no-connections">No active connections</div>
      ) : (
        <>
          {cards?.map((card, index) => {
            return (
              <ConnectionsActiveConnectionsCard key={index} cardData={card} />
            )
          })}
          <PaginationControls
            activePage={activePage}
            totalPages={totalPages}
            handlePageClick={handlePage}
          />
        </>
      )}
    </div>
  )
}

ConnectionsActiveConnections.propTypes = exact(propTypes)
ConnectionsActiveConnections.defaultProps = defaultProps

export default React.memo(ConnectionsActiveConnections)
