import React from 'react'
import PropTypes from 'prop-types'
import exact from 'prop-types-exact'
import { CarouselRow } from 'components/carousels'
import { isEmpty } from 'lodash'

const propTypes = {
  userData: PropTypes.object,
}

const defaultProps = {}

function APBusinessesRepresentedCarouselProfileSection({ userData }) {
  const businessesRepresented = userData.businessesAdvising
  const isVisible = !isEmpty(businessesRepresented)

  return (
    <>
      {isVisible && (
        <div className="profile-section-wrapper centered-content businesses-represented">
          <CarouselRow
            title="Current Businesses Represented"
            isBusinessesRepresentedRow={true}
            cards={businessesRepresented}
            noCardsText=" current businesses represented"
          />
        </div>
      )}
    </>
  )
}

APBusinessesRepresentedCarouselProfileSection.propTypes = exact(propTypes)
APBusinessesRepresentedCarouselProfileSection.defaultProps = defaultProps

export default React.memo(APBusinessesRepresentedCarouselProfileSection)
