import React from 'react'
import PropTypes from 'prop-types'
import exact from 'prop-types-exact'
import { formHelpers } from 'utils'
import { MultiSelectTag } from 'components'

const propTypes = {
  name: PropTypes.string.isRequired,
  options: PropTypes.array,
  optionGroups: PropTypes.array,
  setFieldValue: PropTypes.func.isRequired,
  selectedValues: PropTypes.array.isRequired,
  disabled: PropTypes.bool.isRequired,
}

const defaultProps = {}

const getTagName = (options, value) => {
  const option = options.find((obj) => obj.value === value)
  return option.key
}

const getOptionFromOptionGroups = (optionGroups, key) => {
  const optionsArray = optionGroups.map((group) => {
    return group[key]
  })
  return optionsArray.flat()
}

const optionsContainValue = (options, value) => {
  return options.some((e) => e.value === value)
}

function SelectionTags({
  name,
  options,
  optionGroups,
  setFieldValue,
  selectedValues,
  disabled,
}) {
  const handleRemoveTag = (name, value) => {
    let updatedFieldValues = selectedValues.filter((val) => val !== value)

    setFieldValue(name, updatedFieldValues, false)
  }

  const optionSelection = (value) => {
    // if options contain the value display, else remove from selectedValues
    if (optionsContainValue(options, value)) {
      return getTagName(options, value)
    } else {
      const updatedSelectedValues = selectedValues.filter(
        (selected) => selected !== value
      )
      setFieldValue(name, updatedSelectedValues)
    }
  }
  const optionGroupsSelection = (value) =>
    getTagName(
      formHelpers.formatOptions(
        getOptionFromOptionGroups(
          optionGroups,
          formHelpers.getKeyFromOptionGroups(optionGroups)
        )
      ),
      value
    )
  return (
    <div className="multi-select-tag-row">
      {(options && options.length) || (optionGroups && optionGroups.length)
        ? selectedValues.map((value) => (
            <MultiSelectTag
              key={value}
              disabled={disabled}
              selection={
                options ? optionSelection(value) : optionGroupsSelection(value)
              }
              removeTag={() => {
                disabled ? null : handleRemoveTag(name, value)
              }}
            />
          ))
        : null}
    </div>
  )
}

SelectionTags.propTypes = exact(propTypes)
SelectionTags.defaultProps = defaultProps

export default SelectionTags
