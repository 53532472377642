import React from 'react'
// import PropTypes from 'prop-types'
import exact from 'prop-types-exact'

import { ProfileSectionLineItem } from 'components/profiles'
import { replaceNullValueWithPlaceholder, isHumanizedBooleanValue } from 'utils'

const propTypes = {}

const defaultProps = {}

function BPFinancialOverviewProfileSection({
  sectionVisible,
  userData,
  hovmOverride,
  advisorRepresentsBusinesses,
}) {
  const isViewerAdvisor = userData.viewerProfileType === 'AdvisorProfile'
  if (!hovmOverride && isViewerAdvisor && !advisorRepresentsBusinesses) {
    return null
  }

  if ((isViewerAdvisor && advisorRepresentsBusinesses) || hovmOverride) {
    return (
      <div className="profile-section-wrapper centered-content">
        <h1>Financial Overview</h1>
        <div className="profile-view-section ">
          <div className="profile-view-section-col">
            <ProfileSectionLineItem
              label="Annual Operating Income"
              value={replaceNullValueWithPlaceholder(
                userData.annualEbit,
                'Undisclosed'
              )}
              isCriteriaMatch={isHumanizedBooleanValue(userData.annualEbit)}
            />
          </div>
          <div className="profile-view-section-col"></div>
        </div>
      </div>
    )
  }
  if (sectionVisible) {
    return (
      <div className="profile-section-wrapper centered-content">
        <h1>Financial Overview</h1>
        <div className="profile-view-section ">
          <div className="profile-view-section-col">
            <ProfileSectionLineItem
              label="Annual Revenue"
              value={replaceNullValueWithPlaceholder(
                userData.annualRevenue,
                'Undisclosed'
              )}
              isCriteriaMatch={isHumanizedBooleanValue(userData.annualRevenue)}
            />
            <ProfileSectionLineItem
              label="Annual EBITDA"
              value={replaceNullValueWithPlaceholder(
                userData.annualEbitda,
                'Undisclosed'
              )}
              isCriteriaMatch={isHumanizedBooleanValue(userData.annualEbitda)}
            />
          </div>
          <div className="profile-view-section-col">
            <ProfileSectionLineItem
              label="Annual Operating Income"
              value={replaceNullValueWithPlaceholder(
                userData.annualEbit,
                'Undisclosed'
              )}
              isCriteriaMatch={isHumanizedBooleanValue(userData.annualEbit)}
            />
            <ProfileSectionLineItem
              label="Current Ownership(s)"
              value={replaceNullValueWithPlaceholder(
                userData.currentOwnerships,
                'Undisclosed'
              )}
              isCriteriaMatch={isHumanizedBooleanValue(
                userData.currentOwnerships
              )}
            />
          </div>
        </div>
      </div>
    )
  } else {
    return <></>
  }
}

BPFinancialOverviewProfileSection.propTypes = exact(propTypes)
BPFinancialOverviewProfileSection.defaultProps = defaultProps

export default React.memo(BPFinancialOverviewProfileSection)
