import React from 'react'
import PropTypes from 'prop-types'
import { Route, Switch } from 'react-router-dom'
import exact from 'prop-types-exact'
import {
  ProfileSettingsSubSideNav,
  ProfileSettingsMobileSubNav,
  Card,
  Footer,
} from 'components'
import {
  BusinessCapitalExplorationEditForm,
  BusinessOperationsEditForm,
  BusinessFinancialOverviewEditForm,
  BusinessAdditionalDetailsEditForm,
  BusinessProfileDetailsEditForm,
  AdvisorGeneralOverviewEditForm,
  AdvisorAdvisoryOfferingOverviewEditForm,
  InvestorGeneralOverviewEditForm,
  InvestorInvestmentExplorationOverviewEditForm,
  InvestorInvestmentOpportunityCriteriaEditForm,
  InvestorOperatingInvestmentCriteriaEditForm,
  InvestorFinancialInvestmentCriteriaEditForm,
  StatusAndImageSelectionEditForm,
} from 'forms'
import {
  HowOthersViewMeBusiness,
  HowOthersViewMeInvestor,
  HowOthersViewMeAdvisor,
  AdvisorConnection,
} from 'views'
import * as api from 'api'

const propTypes = {
  params: PropTypes.object,
  profileType: PropTypes.string,
  profileData: PropTypes.object,
}

const defaultProps = {}

function EditProfile({ profileType, params, profileData }) {
  // storedData will be replaced with a list of categories each containing the correct endpoint to query for storedValues
  const handleProfileUpdate = (values) => {
    const requestObj = {
      [profileType]: {
        ...values,
      },
    }
    /* eslint-disable */
    api
      .updateProfile(requestObj)
      .then((res) => console.log('response', res))
      .catch(console.log)
      .then(() => {
        window.location.reload(true)
      })
    /* eslint-enable  */
  }
  const isIndividualInvestor = profileData.investorTypeId === 1
  const selectedConsideredInvestmentMethods = profileData.consideredInvestmentMethods
    ? profileData.consideredInvestmentMethods
    : null

  const isConsideringNonFundPartnerships = selectedConsideredInvestmentMethods
    ? selectedConsideredInvestmentMethods.some((el) => {
        return el === 'Direct Investment' || el === 'Co-Investment'
      })
    : false

  function hasNonFundPartnerships(values) {
    return values.investorConsideredInvestmentMethods.some((el) => {
      return el === 1 || el === 2
    })
  }

  function servesBusinesses(values) {
    return values.advisorServedClients.includes(3)
  }

  const handleAdvisoryOverviewUpdate = (values) => {
    let updateValues = values
    if (!servesBusinesses(values)) {
      updateValues = {
        ...values,
        advisorServedSectors: [],
        advisorServedOperatingModels: [],
        advisorServedBusinessStages: [],
      }
    }
    const requestObj = {
      [profileType]: {
        ...updateValues,
      },
    }
    /* eslint-disable */
    api
      .updateProfile(requestObj)
      .then((res) => console.log('response', res))
      .catch(console.log)
      .then(() => {
        window.location.reload(true)
      })
    /* eslint-enable  */
  }

  const handleInvestorOpportunityCriteriaUpdate = (values) => {
    let updateValues = values
    if (!hasNonFundPartnerships(values)) {
      updateValues = {
        ...values,
        investorConsideredOfferingTypes: [],
        investorConsideredEquityStakes: [],
        investorConsideredCapitalNeedReasons: [],
        investorPostInvestmentIntentions: [],
        consideredOfferingTypesVisibility: false,
        consideredEquityStakesVisibility: false,
        consideredCapitalNeedReasonsVisibility: false,
        postInvestmentIntentionsVisibility: false,
      }
    }
    const requestObj = {
      [profileType]: {
        ...updateValues,
      },
    }
    /* eslint-disable */
    api
      .updateProfile(requestObj)
      .then((res) => console.log('response', res))
      .catch(console.log)
      .then(() => {
        window.location.reload(true)
      })
    /* eslint-enable  */
  }

  let businessEditRoutes = (
    <Switch>
      <Route path="/edit-profile/advisor-connection">
        <AdvisorConnection profileType="business" />
      </Route>
      <Route path="/edit-profile/operations-overview">
        <Card>
          <BusinessOperationsEditForm
            params={params}
            handleSubmit={(values) => {
              handleProfileUpdate(values)
            }}
          />
        </Card>
      </Route>
      <Route path="/edit-profile/financial-overview">
        <Card>
          <BusinessFinancialOverviewEditForm
            params={params}
            handleSubmit={(values) => {
              handleProfileUpdate(values)
            }}
          />
        </Card>
      </Route>
      <Route path="/edit-profile/additional-details">
        <Card>
          <BusinessAdditionalDetailsEditForm
            params={params}
            handleSubmit={(values) => {
              handleProfileUpdate(values)
            }}
          />
        </Card>
      </Route>

      <Route path="/edit-profile/how-others-view-me">
        <HowOthersViewMeBusiness />
      </Route>
      <Route path="/edit-profile/capital-exploration">
        <Card>
          <BusinessCapitalExplorationEditForm
            params={params}
            handleSubmit={(values) => {
              handleProfileUpdate(values)
            }}
          />
        </Card>
      </Route>
      <Route>
        <Card>
          <BusinessProfileDetailsEditForm
            storedData={profileData.generalOverview}
            handleSubmit={(values) => {
              handleProfileUpdate(values)
            }}
            profileImageUrl={profileData?.generalOverview?.profileImageUrl}
            profileType={profileType}
            useDefaultSectorIcon={
              profileData.generalOverview?.useDefaultSectorIcon
            }
          />
        </Card>
      </Route>
    </Switch>
  )
  let investorEditRoutes = (
    <Switch>
      <Route path="/edit-profile/advisor-connection">
        <AdvisorConnection profileType="investor" />
      </Route>
      <Route path="/edit-profile/how-others-view-me">
        <HowOthersViewMeInvestor />
      </Route>

      <Route path="/edit-profile/operating-investment-criteria">
        <Card>
          <InvestorOperatingInvestmentCriteriaEditForm
            params={params}
            handleSubmit={(values) => {
              handleProfileUpdate(values)
            }}
          />
        </Card>
      </Route>
      <Route path="/edit-profile/financial-investment-criteria">
        <Card>
          <InvestorFinancialInvestmentCriteriaEditForm
            params={params}
            handleSubmit={(values) => {
              handleProfileUpdate(values)
            }}
          />
        </Card>
      </Route>
      <Route path="/edit-profile/investment-opportunity-criteria">
        <Card>
          <InvestorInvestmentOpportunityCriteriaEditForm
            params={params}
            handleSubmit={(values) => {
              handleInvestorOpportunityCriteriaUpdate(values)
            }}
          />
        </Card>
      </Route>
      <Route path="/edit-profile/investment-exploration-overview">
        <Card>
          <InvestorInvestmentExplorationOverviewEditForm
            params={params}
            handleSubmit={(values) => {
              handleProfileUpdate(values)
            }}
          />
        </Card>
      </Route>
      <Route>
        <Card>
          <InvestorGeneralOverviewEditForm
            params={params}
            handleSubmit={(values) => {
              handleProfileUpdate(values)
            }}
            isIndividualInvestor={isIndividualInvestor}
            profileImageUrl={profileData?.profileImage?.profileImageUrl}
            useDefaultSectorIcon={
              profileData.profileImage?.useDefaultSectorIcon
            }
          />
        </Card>
      </Route>
    </Switch>
  )
  let advisorEditRoutes = (
    <Switch>
      <Route path="/edit-profile/advisor-connection">
        <AdvisorConnection profileType="advisor" />
      </Route>
      <Route path="/edit-profile/how-others-view-me">
        <HowOthersViewMeAdvisor />
      </Route>
      <Route path="/edit-profile/advisory-offering-overview">
        <Card>
          <AdvisorAdvisoryOfferingOverviewEditForm
            params={params}
            handleSubmit={(values) => {
              handleAdvisoryOverviewUpdate(values)
            }}
          />
        </Card>
      </Route>
      <Route path="/edit-profile/status-and-image-selection">
        <Card>
          <StatusAndImageSelectionEditForm
            params={params}
            storedData={profileData.profileImage}
            membershipStatus={profileData.membershipStatus || ''}
            handleSubmit={(values) => {
              handleProfileUpdate(values)
            }}
          />
        </Card>
      </Route>
      <Route>
        <Card>
          <AdvisorGeneralOverviewEditForm
            params={params}
            handleSubmit={(values) => {
              handleProfileUpdate(values)
            }}
            profileImageUrl={profileData?.profileImage?.profileImageUrl}
          />
        </Card>
      </Route>
    </Switch>
  )

  const editRoutes = {
    businessProfile: businessEditRoutes,
    advisorProfile: advisorEditRoutes,
    investorProfile: investorEditRoutes,
  }

  return (
    <>
      <div className="edit-profile-settings">
        <ProfileSettingsMobileSubNav
          profileType={profileType}
          isConsideringNonFundPartnerships={isConsideringNonFundPartnerships}
          sections={profileData.sections}
        />
        <ProfileSettingsSubSideNav
          profileType={profileType}
          isConsideringNonFundPartnerships={isConsideringNonFundPartnerships}
          sections={profileData.sections}
        />
        <div className="settings-area">
          <div className="settings-wrapper">{editRoutes[profileType]}</div>
        </div>
      </div>
      <Footer></Footer>
    </>
  )
}

EditProfile.propTypes = exact(propTypes)
EditProfile.defaultProps = defaultProps

export default React.memo(EditProfile)
