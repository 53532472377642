import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import exact from 'prop-types-exact'
import { ErrorBoundary, Footer } from 'components'
import {
  MessagesSideSubNav,
  MessagesMobileSubNav,
  MessagesUserPage,
} from 'components/messages'
import { Route, Switch } from 'react-router-dom'
import * as api from 'api'
import { Spinner } from '@launchpadlab/lp-components'
import { isUndefined } from 'lodash'
import classnames from 'classnames'

const propTypes = {
  data: PropTypes.object,
  match: PropTypes.object,
}

const defaultProps = {}

function Messages({ data = {} }) {
  useEffect(() => {
    if (connections) return

    async function fetchConversations() {
      const { data } = await api.fetchConversations()
      setMessageData(data)
    }
    fetchConversations()
  }, [])

  const [messageData, setMessageData] = useState(data)

  const {
    connections,
    messages,
    connection,
    currentUser,
    conversationIds,
    connectionId,
  } = messageData

  const [activeConnectionId, setActiveConnectionId] = useState(connectionId)

  const isLoaded = !isUndefined(connections)
  const anyConnections = (connections || []).length !== 0
  return (
    <ErrorBoundary>
      <div
        className={classnames('messages-layout-wrapper', {
          'no-connections': !anyConnections,
        })}
      >
        {isLoaded ? (
          anyConnections ? (
            <>
              <MessagesSideSubNav
                connections={connections}
                conversationIds={conversationIds}
                activeConnectionId={activeConnectionId}
              />
              <MessagesMobileSubNav
                connections={connections}
                conversationIds={conversationIds}
                activeConnectionId={activeConnectionId}
              />
              <div className="messages-view-area">
                <Switch>
                  <Route
                    path={`/messages/:connectionId`}
                    render={(props) => {
                      const {
                        match: {
                          params: { connectionId },
                        },
                      } = props
                      setActiveConnectionId(connectionId)
                      return (
                        <MessagesUserPage
                          {...props}
                          activeConnectionId={activeConnectionId}
                          messages={messages}
                          connection={connection}
                          currentUser={currentUser}
                        />
                      )
                    }}
                  />
                  <div className="messages-placeholder">
                    Select a connection to view your active conversation.
                  </div>
                </Switch>
              </div>
            </>
          ) : (
            <div className="messages-placeholder">
              <p>Start a new conversation on a connection's profile page.</p>
            </div>
          )
        ) : (
          <Spinner />
        )}
      </div>
      <Footer />
    </ErrorBoundary>
  )
}

Messages.propTypes = exact(propTypes)
Messages.defaultProps = defaultProps

export default React.memo(Messages)
