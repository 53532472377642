import React, { useState, useEffect } from 'react'
// import PropTypes from 'prop-types'
import exact from 'prop-types-exact'
import {
  ConnectionsSortRow,
  ConnectionsPendingConnectionsCard,
} from 'components/connections'
import { PaginationControls } from 'components'
import * as api from 'api'
import { scrollToSelector } from 'utils'
import { isEmpty } from 'lodash'

const propTypes = {}

const defaultProps = {}

function ConnectionsPendingConnections() {
  const [pendingConnections, setPendingConnections] = useState([])
  const [activePage, setActivePage] = useState(0) // 0 index in Pagination
  const [sortOption, setSortOption] = useState('most-recent') // 0 index in Pagination

  useEffect(() => {
    const params = {
      page: activePage + 1,
      sortOption: sortOption,
    }

    async function fetchPendingConnections() {
      setPendingConnections(
        await api.getConnections('users/pending-connections', {
          params: params,
        })
      )
    }

    fetchPendingConnections()
  }, [activePage, sortOption])
  const cards = pendingConnections.data

  const totalPages = pendingConnections.totalPages

  const handleSortChange = (sortValue) => {
    scrollToSelector('.dashboard-header')
    setSortOption(sortValue)
  }

  const handlePage = (pageNumber) => {
    scrollToSelector('.dashboard-header')
    setActivePage(pageNumber.selected)
  }

  return (
    <div className="connections-viewport">
      <ConnectionsSortRow handleSortChange={handleSortChange} />
      {isEmpty(cards) ? (
        <div className="no-connections">No pending connections</div>
      ) : (
        <>
          {cards?.map((card, index) => {
            return (
              <ConnectionsPendingConnectionsCard key={index} cardData={card} />
            )
          })}
          <PaginationControls
            activePage={activePage}
            totalPages={totalPages}
            handlePageClick={handlePage}
          />
        </>
      )}
    </div>
  )
}

ConnectionsPendingConnections.propTypes = exact(propTypes)
ConnectionsPendingConnections.defaultProps = defaultProps

export default React.memo(ConnectionsPendingConnections)
