import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import exact from 'prop-types-exact'
import { LabeledField, Checkbox } from '@launchpadlab/lp-components'

const propTypes = {
  options: PropTypes.array.isRequired,
  name: PropTypes.string.isRequired,
  input: PropTypes.object.isRequired,
  setFieldValue: PropTypes.func.isRequired,
  meta: PropTypes.object,
  allowNoneValue: PropTypes.bool,
  allowSelectAll: PropTypes.bool,
}

const defaultProps = {}

function CustomCheckboxGroup(props) {
  const {
    options,
    name,
    input,
    setFieldValue,
    meta,
    allowNoneValue,
    allowSelectAll,
  } = props
  const allOptions = getAllOptionValues(options)

  useEffect(() => {
    setAllSelected(categoryIsAllSelected(input.value, allOptions))
  }, [options])

  const [allSelected, setAllSelected] = useState(
    categoryIsAllSelected(input.value, allOptions)
  )

  const [noneSelected, setNoneSelected] = useState(
    optionsHaveNone(options)
      ? input.value.includes(getNoneOptionIndex(options))
      : false
  )

  useEffect(() => {
    !categoryIsAllSelected(input.value, allOptions)
      ? setAllSelected(false)
      : setAllSelected(true)
  }, [input.value])

  function getAllOptionValues(options) {
    const allOptionValues = options.map((option) => {
      return option.value
    })
    return allOptionValues
  }

  function categoryIsAllSelected(superset, subset) {
    return subset.every(function (value) {
      return superset.indexOf(value) >= 0
    })
  }
  // on selection if category isn't all selected, set category checkbox to false
  function handleChange(option) {
    let selectedValues = input.value

    if (input.value.includes(option.value)) {
      selectedValues = input.value.filter((val) => {
        return val !== option.value
      })
    } else {
      selectedValues.push(option.value)
    }

    if (optionsHaveNone(options)) {
      if (
        noneSelected !== false &&
        selectedValues.filter((value) => {
          return value !== getNoneOptionIndex(options)
        }).length >= 1
      ) {
        selectedValues = selectedValues.filter((value) => {
          return value !== getNoneOptionIndex(options)
        })
      }
    }

    setFieldValue(name, selectedValues)

    if (!categoryIsAllSelected(selectedValues, allOptions)) {
      setAllSelected(false)
    }

    if (categoryIsAllSelected(selectedValues, allOptions)) {
      setAllSelected(true)
    }
  }

  // upon toggle of category-all checkbox selector i.e. Midwest, select all in ids in category
  function handleSelectAllToggle() {
    let currentlySelected = input.value
    let updatedValues

    // categoryAllSelected begins false, so the all id push happens first. Cannot rely on setCategoryAll hook to complete during function runtime

    if (allSelected) {
      updatedValues = []
    } else {
      updatedValues = currentlySelected
      allOptions.forEach((id) => {
        if (!updatedValues.includes(id)) {
          updatedValues.push(id)
        }
      })
    }
    setFieldValue(name, updatedValues)
    setAllSelected(!allSelected)
  }

  function handleSelectNone() {
    const selectedValues = input.value
    if (noneSelected) {
      setNoneSelected(false)
      const updatedValues = selectedValues.filter(
        (value) => value !== getNoneOptionIndex(options)
      )
      setFieldValue(name, updatedValues)
      return
    }

    const noneOptionIndex = getNoneOptionIndex(options)
    setFieldValue(name, [noneOptionIndex])
    setNoneSelected(true)
  }

  function optionsHaveNone(options) {
    let hasNoneOption = false
    options.forEach((option) => {
      if (option.key === 'None') {
        hasNoneOption = true
      }
    })
    return hasNoneOption
  }

  function getNoneOptionIndex(options) {
    const noneOption = options.filter((option) => {
      return option.key === 'None'
    })
    if (noneOption) return noneOption[0].value
  }
  return (
    <LabeledField hideErrorLabel={true} className="CheckboxGroup" {...props}>
      {allowSelectAll && (
        <Checkbox
          className="checkbox"
          {...{
            input: {
              ...input,
              name: `${name}.select-all`,
              value: allSelected,
              onChange: () => handleSelectAllToggle(options),
            },
            meta: meta,
            label: 'Select All',
          }}
        />
      )}
      {allowNoneValue && optionsHaveNone(options) && (
        <Checkbox
          className="checkbox"
          {...{
            input: {
              ...input,
              name: `${name}.select-none`,
              value:
                optionsHaveNone(options) &&
                noneSelected &&
                input.value.includes(getNoneOptionIndex(options)),
              onChange: () => handleSelectNone(options),
            },
            meta: meta,
            label: 'None',
          }}
        />
      )}
      {options.map((option) => {
        if (option.key === 'None') {
          return
        }
        return (
          <Checkbox
            key={option.value}
            className="checkbox"
            {...{
              input: {
                ...input,
                name: `${name}.${option.value}`,
                value: input.value.includes(option.value),
                onChange: () => handleChange(option),
              },
              meta: meta,
              label: option.key,
            }}
          />
        )
      })}
    </LabeledField>
  )
}

CustomCheckboxGroup.propTypes = exact(propTypes)
CustomCheckboxGroup.defaultProps = defaultProps
export default React.memo(CustomCheckboxGroup)
