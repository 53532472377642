import React from 'react'
import PropTypes from 'prop-types'
import exact from 'prop-types-exact'

const propTypes = {
  children: PropTypes.node,
  isNextPageActive: PropTypes.bool,
}

const defaultProps = {}

function FormCard({ children, isNextPageActive }) {
  return (
    <div className="form-card">
      <div
        className={isNextPageActive ? 'progress-bar-full' : 'progress-bar-half'}
      ></div>
      <div className="form-card-content">{children}</div>
    </div>
  )
}

FormCard.propTypes = exact(propTypes)
FormCard.defaultProps = defaultProps

export default React.memo(FormCard)
