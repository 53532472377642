import React from 'react'
// import PropTypes from 'prop-types'
import exact from 'prop-types-exact'
import { Field } from 'formik'

const propTypes = {}

const defaultProps = {}

function ExploreSortRow() {
  return (
    <div className="sort-row">
      <Field
        as="select"
        className="sort-select"
        name="sortSelection"
        id="sortselection"
      >
        <option value="" disabled selected>
          Sort by
        </option>
        <option value="most-recent">Date Added (Most Recent)</option>
        <option value="oldest">Date Added (Oldest)</option>
        <option value="name-sector-asc">Name (Ascending)</option>
        <option value="name-sector-desc">Name (Descending)</option>
        <option value="state-asc">State (Ascending)</option>
        <option value="state-desc">State (Descending)</option>
      </Field>
    </div>
  )
}

ExploreSortRow.propTypes = exact(propTypes)
ExploreSortRow.defaultProps = defaultProps

export default React.memo(ExploreSortRow)
