import React from 'react'
import PropTypes from 'prop-types'
import exact from 'prop-types-exact'
import {
  withFormikAdapterAndVisibilityInput,
  validate,
  formHelpers,
  validationPatterns,
} from '../utils'
import {
  DropdownCheckboxGroup as BaseDropownCheckboxGroup,
  Tooltip,
} from 'components'
import {
  Input as BaseInput,
  Select as BaseSelectInput,
  Textarea as BaseTextarea,
  Checkbox as BaseCheckbox,
  SubmitButton,
} from '@launchpadlab/lp-components'
import { Formik, Form } from 'formik'

const Input = withFormikAdapterAndVisibilityInput()(BaseInput)
const DropdownCheckboxGroup = withFormikAdapterAndVisibilityInput()(
  BaseDropownCheckboxGroup
)
const Select = withFormikAdapterAndVisibilityInput()(BaseSelectInput)
const Textarea = withFormikAdapterAndVisibilityInput()(BaseTextarea)
const Checkbox = withFormikAdapterAndVisibilityInput()(BaseCheckbox)

const propTypes = {
  params: PropTypes.object.isRequired,
  formValues: PropTypes.object.isRequired,
  handleSubmit: PropTypes.func.isRequired,
}

const defaultProps = {}

// Add an extra custom validation for the hidden spam input.
function validateForm(values) {
  const messages = validate(values, {
    firstName: function (value, attributes) {
      if (attributes.firstName === '')
        return {
          presence: {
            allowEmpty: false,
            message: '^Please provide a response',
          },
        }
      return {
        format: {
          pattern: validationPatterns.namePattern,
          message: 'cannot contain special characters',
        },
      }
    },
    lastName: function (value, attributes) {
      if (attributes.lastName === '')
        return {
          presence: {
            allowEmpty: false,
            message: '^Please provide a response',
          },
        }
      return {
        format: {
          pattern: validationPatterns.namePattern,
          message: 'cannot contain special characters',
        },
      }
    },
    title: {
      presence: { allowEmpty: false, message: '^Please provide a response' },
    },
    firmName: {
      presence: { allowEmpty: false, message: '^Please provide a response' },
      type: 'string',
    },
    location: function (value, attributes) {
      if (attributes.location === '')
        return {
          presence: {
            allowEmpty: false,
            message: '^Please provide a response',
          },
        }
      return {
        format: {
          pattern: validationPatterns.zipCodePattern,
          message: '^%{value} is not a valid zip code.',
        },
      }
    },
    firmWebsite: function (value, attributes) {
      if (attributes.firmWebsite !== '')
        return {
          format: {
            pattern: validationPatterns.websitePattern,
            message: '^Website address is invalid',
          },
        }
      return {}
    },
    advisorTypeId: {
      presence: { allowEmpty: false, message: '^Please select one' },
    },
    advisorPriorExperiences: {
      presence: { allowEmpty: false },
    },
    advisorLocationExperiences: {
      presence: { allowEmpty: false },
    },
    yearsExperienceId: {
      presence: {
        allowEmpty: false,
        message: '^Please select one',
      },
    },
    description: {
      presence: { allowEmpty: true },
    },
    usCitizen: {
      presence: {
        message:
          '^Use of Firm Value is currently restricted to U.S. citizens and entities domiciled in the U.S.',
      },
      inclusion: {
        within: [true],
        message:
          '^Use of Firm Value is currently restricted to U.S. citizens and entities domiciled in the U.S.',
      },
    },
    termsAndConditions: {
      presence: {
        message:
          '^You must agree to our Terms of Service and Privacy Policy to join',
      },
      inclusion: {
        within: [true],
        message:
          '^You must agree to our Terms of Service and Privacy Policy to join',
      },
    },
  })
  // mix custom stuff here w validate.js messages
  return {
    ...messages,
    //
  }
}

function AdvisorPage1Form({ params, formValues, handleSubmit }) {
  return (
    <Formik
      onSubmit={handleSubmit}
      validate={validateForm}
      initialValues={formValues}
    >
      {({ values, errors, setFieldValue, setFieldTouched, touched }) => (
        <Form className="onboarding-form setup-form">
          <h1>Member Information</h1>
          <p className="disclaimer">
            Last names, contact information, and cities are{' '}
            <span id="underline">not</span> publicized to other members unless
            you choose to share them with connections through messaging.
          </p>
          <div className="row">
            <Input name="firstName" type="text" />
            <Input name="lastName" type="text" />
          </div>
          <div className="row">
            <Input name="firmName" type="text" />
            <Input name="title" label="Title" type="text" />
          </div>
          <div className="row">
            <Input name="location" label="Location (Zip Code)" maxLength={10} />
            <Input name="firmWebsite" type="text" placeholder="Optional" />
          </div>
          <div className="row">
            <Checkbox
              name="usCitizen"
              label="I am a U.S. Citizen or U.S. Domiciled Entity"
            />
            <Checkbox
              name="termsAndConditions"
              label={
                <div className="policy-agreement-label">
                  <span>I agree to the </span>
                  <a
                    href={'https://www.firmvalue.com/terms-of-service'}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    Terms of Service
                  </a>
                  <span> and </span>
                  <a
                    href={'https://www.firmvalue.com/privacy-policy'}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    Privacy Policy
                  </a>
                </div>
              }
            />
          </div>
          <h1>General Overview</h1>
          <div className="row">
            <Select
              name="advisorTypeId"
              label="Type of Advisor"
              options={formHelpers.formatOptions(params.advisorTypes)}
              enablePlaceholderOption={true}
              placeholder=" "
            />
            <Select
              name="yearsExperienceId"
              tooltip={
                <Tooltip
                  name="yearsExperienceId"
                  text="Please select total years of professional experience"
                />
              }
              label="Years of Professional Experience"
              options={formHelpers.formatOptions(params.years)}
              enablePlaceholderOption={true}
              placeholder=" "
            />
          </div>
          <div className="row">
            <DropdownCheckboxGroup
              name="advisorPriorExperiences"
              tooltip={
                <Tooltip
                  name="advisorPriorExperiences"
                  text="Please select all sectors in which you have worked (not including companies advised)"
                />
              }
              label="Prior Professional Experience"
              options={formHelpers.formatOptions(params.sectorsIndustries)}
              values={values}
              setFieldValue={setFieldValue}
              setFieldTouched={setFieldTouched}
              errorMessage="Please select all that apply"
              tags={true}
            />
            <DropdownCheckboxGroup
              name="advisorLocationExperiences"
              label="Prior Geographic Experience"
              tooltip={
                <Tooltip
                  name="advisorLocationExperiences"
                  text="Please select all geographies in which you have worked in or served in an advisory capacity"
                />
              }
              options={formHelpers.formatOptions(params.territories)}
              values={values}
              setFieldValue={setFieldValue}
              setFieldTouched={setFieldTouched}
              errorMessage="Please select all that apply"
              tags={true}
            />
          </div>
          <div className="row">
            <Textarea
              className="text-area"
              name="description"
              label="Brief Description About You And Your Offering"
              hint=" (Shown to Other Members)"
              maxLength={500}
            />
            <p className="disclaimer description-disclaimer">
              Only post factual information about yourself, your company, and/or
              your investment fund. Do not include any forward-looking
              statements, including, but not limited to (i) projections or
              predictions about future performance of your company and/or
              investments, and (ii) forecasts or opinions about the future value
              of your company’s securities and/or investments.
            </p>
          </div>

          <div className="submission-error-wrapper">
            <div className="button-area">
              <SubmitButton className="form-button">Next</SubmitButton>
            </div>
            <div className="error-message-submission">
              {Object.keys(touched).some((key) => {
                return Object.keys(errors).includes(key)
              })
                ? 'Please fix the errors presented above'
                : null}
            </div>
          </div>
        </Form>
      )}
    </Formik>
  )
}

AdvisorPage1Form.propTypes = exact(propTypes)
AdvisorPage1Form.defaultProps = defaultProps

export default AdvisorPage1Form
