/* eslint-disable */
import axios from 'axios'
import { camelizeKeys, decamelizeKeys } from 'humps'
import { pickBy, isEmpty, has, set, pick } from 'lodash'
// Re-case keys between server and client

function transformRequest(data) {
  return decamelizeKeys(data)
}

function transformResponse(data) {
  return camelizeKeys(data)
}

const api = axios.create({
  baseURL: '/',
  transformRequest: [transformRequest, ...axios.defaults.transformRequest],
  transformResponse: [...axios.defaults.transformResponse, transformResponse],
})

/* Interactions with the JSON api go here */

const userKeys = [
  'firstName',
  'lastName',
  'email',
  'dateOfBirth',
  'membershipStatusId',
  'profileComplete',
  'termsAndConditions',
  'usCitizen',
]
const profilePictureKeys = ['profileImageUrl', 'defaultImage']

// map backend names to the requisite value ids
// from business_capital_need_reasons: [1,2,3]
// business_capital_need_reasons: [{capital_need_reason_id: 1}, {capital_need_reason_id: 2}, {capital_need_reason_id: 3}]
const matches = {
  business_capital_need_reasons: 'capital_need_reason_id',
  business_likely_equity_stakes: 'equity_stake_id',
  business_considered_offering_types: 'offering_type_id',
  business_considered_investor_types: 'investor_type_id',
  business_profile_served_sectors: 'sector_id',
  business_post_investment_intentions: 'post_investment_intention_id',
  business_served_locations: 'territory_id',
  business_profile_served_sectors: 'sector_id',
  business_profile_business_models: 'business_model_id',
  business_locations: 'territory_id',
  business_special_designations: 'special_designation_id',
  business_current_ownerships: 'current_ownership_id',

  business_considered_primary_sectors: 'sector_id',
  business_considered_primary_industries: 'industry_id',
  business_considered_locations: 'state_id',
  business_considered_business_models: 'business_model_id',
  business_considered_served_sectors: 'sector_id',
  business_considered_served_locations: 'state_id',
  business_considered_ages: 'year_id',
  business_considered_business_stages: 'business_stage_id',
  business_considered_employee_sizes: 'employee_size_id',
  business_considered_annual_revenues: 'annual_revenue_id',
  business_considered_ebitdas: 'ebitda_id',
  business_considered_ebits: 'ebit_id',
  business_considered_ownerships: 'current_ownership_id',
  business_considered_offering_types: 'offering_type_id',

  investor_post_investment_intentions: 'post_investment_intention_id',
  investor_prior_sector_experiences: 'sector_id',
  investor_prior_location_experiences: 'territory_id',
  investor_considered_business_locations: 'state_id',
  investor_considered_capital_need_reasons: 'capital_need_reason_id',
  investor_considered_served_locations: 'state_id',
  investor_considered_served_sectors: 'sector_id',
  investor_considered_annual_revenues: 'annual_revenue_id',
  investor_considered_business_ages: 'year_id',
  investor_considered_business_models: 'business_model_id',
  investor_considered_business_stages: 'business_stage_id',
  investor_considered_ebits: 'ebit_id',
  investor_considered_ebitdas: 'ebitda_id',
  investor_considered_employee_sizes: 'employee_size_id',
  investor_considered_equity_stakes: 'equity_stake_id',
  investor_considered_investment_methods: 'investment_method_id',
  investor_considered_offering_types: 'offering_type_id',
  investor_considered_ownerships: 'current_ownership_id',
  investor_considered_primary_industries: 'industry_id',
  investor_considered_primary_sectors: 'sector_id',

  advisor_advisor_types: 'advisor_type_id',
  advisor_fee_structures: 'fee_structure_id',
  advisor_prior_experiences: 'sector_id',
  advisor_location_experiences: 'territory_id',
  advisor_served_business_stages: 'business_stage_id',
  advisor_served_clients: 'served_client_id',
  advisor_served_locations: 'territory_id',
  advisor_served_sectors: 'sector_id',
  advisor_served_operating_models: 'business_model_id',
}

// transform snake to camel
const snakeToCamel = (str) =>
  str.replace(/([-_][a-z])/g, (group) =>
    group.toUpperCase().replace('-', '').replace('_', '')
  )

// for all the array value keys, appends 'Attributes' for backend request and camelizes the key name 'businessCapitalNeedReasonsAttributes: [{capital_need_reason_id: 1}, ...]'
const hasManyKeys = Object.keys(matches).reduce((acc, key) => {
  acc[snakeToCamel(key) + 'Attributes'] = matches[key]
  return acc
}, {})

// formats hasMany request fields into [{business_capital_need_reasons_attributes: 1}, ...]
const formatRequestWithHasManyIds = (request) => {
  return Object.keys(request).reduce((acc, key) => {
    if (hasManyKeys[key + 'Attributes']) {
      let result = []
      const arrayOfIds = request[key]
      arrayOfIds.forEach((id) => {
        result.push({ [hasManyKeys[key + 'Attributes']]: id })
      })
      acc[key + 'Attributes'] = result
    } else {
      acc[key] = request[key]
    }
    return acc
  }, {})
}

// updateProfile is called on form page 1 next and again on form page 2 submit.
// it takes only completed values, picking out user and profile picture values that need to be submitted to separate backend end points

export async function updateProfile(values, onboarding = false) {
  const profileType = Object.keys(values)[0]
  // remove incomplete values
  const completedValues = pickBy(Object.values(values)[0], function (
    value,
    key
  ) {
    return (
      !isEmpty(value) ||
      key === 'profileComplete' ||
      key === 'targetCapitalRaise' ||
      key === 'defaultImage' ||
      key.includes('Visibility') ||
      key.includes('Disclosed') ||
      key === 'businessLikelyEquityStakes' ||
      key === 'investorConsideredAnnualRevenues' ||
      key === 'investorConsideredEbitdas' ||
      key === 'investorConsideredEbits' ||
      key === 'investorConsideredOwnerships' ||
      key === 'currentIndustryId' ||
      key === 'investorConsideredOfferingTypes' ||
      key === 'investorConsideredCapitalNeedReasons' ||
      key === 'investorConsideredEquityStakes' ||
      key === 'investorPostInvestmentIntentions' ||
      key === 'advisorServedBusinessStages' ||
      key === 'advisorServedSectors' ||
      key === 'advisorServedOperatingModels' ||
      key === 'seekingStatus' ||
      key === 'firmWebsite' ||
      key === 'minTargetFundSizeId' ||
      key === 'maxTargetFundSizeId' ||
      key === 'description'
    )
  })
  // pick out user values
  const userValues = pickBy(completedValues, function (value, key) {
    return userKeys.includes(key)
  })

  // pick out profile pic related values
  const profilePictureValues = pickBy(completedValues, function (value, key) {
    return profilePictureKeys.includes(key)
  })

  // remove profile pic and user values from rest
  const request = pickBy(completedValues, function (value, key) {
    return !profilePictureKeys.includes(key) && !userKeys.includes(key)
  })

  // convert general overview options from string to bool
  Object.entries(request).forEach(([key, val]) => {
    if (val === 'showName') request[key] = true
    if (val === 'hideName') request[key] = false
    if (val === 'blurImage') request[key] = true
    if (val === 'showImage') request[key] = false
  })

  // convert hasMany arrays to arrays of objects with the correct id ref
  const requestWithHasMany = formatRequestWithHasManyIds(request)

  // build the final request object
  const requestObj = {
    [profileType]: { ...requestWithHasMany, userAttributes: userValues },
  }
  const csrf_token = document.querySelector('meta[name="csrf-token"]').content

  // if profile image values have been updated, save
  if (!isEmpty(profilePictureValues)) {
    const profilePicRes = await api.post(
      'users/save-profile-picture',
      profilePictureValues,
      {
        headers: { 'X-CSRF-Token': csrf_token },
      }
    )
  }

  let url = 'users/save-profile'
  if (onboarding) url = 'users/save-onboarding'
  // send rest of request to respective endpoint
  const res = await api.patch(url, requestObj, {
    headers: { 'X-CSRF-Token': csrf_token },
  })
  return JSON.parse(res.config.data)
}

export async function updatePassword(values) {
  const csrf_token = document.querySelector('meta[name="csrf-token"]').content
  const res = await api.patch('users', values, {
    headers: {
      'X-CSRF-Token': csrf_token,
      'X-Requested-With': 'XMLHttpRequest',
    },
  })
  return JSON.parse(res.config.data)
}

// get storedData by section url
export async function getStoredData(url) {
  const response = await api.get(url)
  return response.data
}

export async function deleteAccount(values) {
  const csrf_token = document.querySelector('meta[name="csrf-token"]').content
  const response = await api.delete('users/delete-account', {
    headers: {
      'X-CSRF-Token': csrf_token,
      'X-Requested-With': 'XMLHttpRequest',
    },
    data: values,
  })

  return response
}

export async function getHOVMProfileData(url, params) {
  const response = await api.get(url, params)
  return response.data
}

export async function getConnections(url, params) {
  const response = await api.get(url, params)
  return response.data
}

export async function requestConnection(url, params) {
  const csrf_token = document.querySelector('meta[name="csrf-token"]').content

  const response = await api.post(url, params, {
    headers: {
      'X-CSRF-Token': csrf_token,
      'X-Requested-With': 'XMLHttpRequest',
    },
  })
  return response.data
}

export async function acceptConnectionRequest(url, params) {
  const csrf_token = document.querySelector('meta[name="csrf-token"]').content

  const response = await api.post(url, params, {
    headers: {
      'X-CSRF-Token': csrf_token,
      'X-Requested-With': 'XMLHttpRequest',
    },
  })
  return response.data
}

export async function removeConnection(url, params) {
  const csrf_token = document.querySelector('meta[name="csrf-token"]').content

  const response = await api.delete(url, {
    headers: {
      'X-CSRF-Token': csrf_token,
      'X-Requested-With': 'XMLHttpRequest',
    },
    data: params,
  })
  return response.data
}

export async function rejectConnection(url, params) {
  const csrf_token = document.querySelector('meta[name="csrf-token"]').content

  const response = await api.post(url, params, {
    headers: {
      'X-CSRF-Token': csrf_token,
      'X-Requested-With': 'XMLHttpRequest',
    },
  })
  return response.data
}

export async function cancelConnectionRequest(url, params) {
  const csrf_token = document.querySelector('meta[name="csrf-token"]').content

  const response = await api.delete(url, {
    headers: {
      'X-CSRF-Token': csrf_token,
      'X-Requested-With': 'XMLHttpRequest',
    },
    data: params,
  })
  return response.data
}

export async function saveUser(url, params) {
  const csrf_token = document.querySelector('meta[name="csrf-token"]').content

  const response = await api.post(url, params, {
    headers: {
      'X-CSRF-Token': csrf_token,
      'X-Requested-With': 'XMLHttpRequest',
    },
  })
  return response.data
}

export async function unsaveUser(url, params) {
  const csrf_token = document.querySelector('meta[name="csrf-token"]').content

  const response = await api.delete(url, {
    headers: {
      'X-CSRF-Token': csrf_token,
      'X-Requested-With': 'XMLHttpRequest',
    },
    data: params,
  })
  return response.data
}

export async function getInitialExploreParams(url) {
  const response = await api.get(url)
  return response.data
}

export async function updateExploreCards(url, params) {
  const csrf_token = document.querySelector('meta[name="csrf-token"]').content
  const completedValues = pickBy(params, function (value, key) {
    return !isEmpty(value) && key !== 'sortSelection'
  })
  const requestObj = {
    filters: {
      ...completedValues,
    },
    sortSelection: params.sortSelection,
    page: params.page + 1,
  }

  if (isEmpty(completedValues)) {
    return
  }

  const response = await api.post(url, requestObj, {
    headers: {
      'X-CSRF-Token': csrf_token,
      'X-Requested-With': 'XMLHttpRequest',
    },
  })

  return response.data
}

export async function fetchConversation(conversationId) {
  const response = await api.get(`/api/messages/${conversationId}`)
  return response.data
}

export async function fetchConversations() {
  const response = await api.get('/dashboard/messages.json')
  return response.data
}

export async function saveMessage(params) {
  const csrf_token = document.querySelector('meta[name="csrf-token"]').content

  const response = await api.post(
    '/api/messages',
    {
      data: params,
    },
    {
      headers: {
        'X-CSRF-Token': csrf_token,
        'X-Requested-With': 'XMLHttpRequest',
      },
    }
  )
  return response.data
}

export async function toggleMute({ id }) {
  const csrf_token = document.querySelector('meta[name="csrf-token"]').content

  const response = await api.patch(
    `/api/messages/${id}/toggle_mute`,
    {},
    {
      headers: {
        'X-CSRF-Token': csrf_token,
        'X-Requested-With': 'XMLHttpRequest',
      },
    }
  )
  return response.data
}

export async function setNotificationSettings(url, requestObj) {
  const csrf_token = document.querySelector('meta[name="csrf-token"]').content

  const res = await api.patch(url, requestObj, {
    headers: { 'X-CSRF-Token': csrf_token },
  })

  return res.data
}

export async function startConversation(url, params) {
  const response = await api.get(`start-conversation?user=${params.user}`)
  return response.data
}

export async function fetchCarouselCards(url) {
  const response = await api.get(url)
  return response.data
}

export async function getNote(url, params) {
  const response = await api.get(`users/get-note?user_id=${params.user_id}`)
  return response.data
}

export async function saveNote(params) {
  const csrf_token = document.querySelector('meta[name="csrf-token"]').content

  const response = await api.post(
    'users/save-note',
    {
      ...params,
    },
    {
      headers: {
        'X-CSRF-Token': csrf_token,
        'X-Requested-With': 'XMLHttpRequest',
      },
    }
  )
  return response.data
}

export async function sendAdvisorConnectionEmail(url, params) {
  const csrf_token = document.querySelector('meta[name="csrf-token"]').content

  const response = await api.post(
    url,
    { ...params },
    {
      headers: {
        'X-CSRF-Token': csrf_token,
        'X-Requested-With': 'XMLHttpRequest',
      },
    }
  )

  console.log(response)

  return response
}

export async function cancelAdvisorConnectionRequest(url, params) {
  const csrf_token = document.querySelector('meta[name="csrf-token"]').content

  const response = await api.delete(url, {
    headers: {
      'X-CSRF-Token': csrf_token,
      'X-Requested-With': 'XMLHttpRequest',
    },
    data: params,
  })

  return response
}
