import React from 'react'
// import PropTypes from 'prop-types'
import exact from 'prop-types-exact'

import {
  ProfileSectionLineItem,
  ProfileSectionLineItemTwoCol,
} from 'components/profiles'
import { replaceNullValueWithPlaceholder } from 'utils'

const propTypes = {}

const defaultProps = {}

function APGeneralOverviewProfileSection({ sectionVisible, userData }) {
  if (sectionVisible) {
    return (
      <div className="profile-section-wrapper centered-content">
        <h1>General Overview</h1>
        <div className="profile-view-section ">
          <div className="profile-view-section-col">
            <ProfileSectionLineItem
              label="Advisor Type"
              value={replaceNullValueWithPlaceholder(userData?.advisorType)}
            />
            <ProfileSectionLineItem
              label="Professional Experience"
              value={replaceNullValueWithPlaceholder(
                userData?.priorSectorExperiences
              )}
            />
          </div>
          <div className="profile-view-section-col">
            <ProfileSectionLineItem
              label="Years Experience"
              value={replaceNullValueWithPlaceholder(userData?.yearsExperience)}
            />
            <ProfileSectionLineItem
              label="Geographic Experience"
              value={replaceNullValueWithPlaceholder(
                userData?.territoryExperiences
              )}
            />
          </div>
          {userData?.description && (
            <ProfileSectionLineItemTwoCol
              label="Advisor Description"
              value={userData.description}
            />
          )}
        </div>
      </div>
    )
  } else {
    return <></>
  }
}

APGeneralOverviewProfileSection.propTypes = exact(propTypes)
APGeneralOverviewProfileSection.defaultProps = defaultProps

export default React.memo(APGeneralOverviewProfileSection)
