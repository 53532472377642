import React from 'react'
import PropTypes from 'prop-types'
import exact from 'prop-types-exact'

import * as Types from 'types'

import { InvestorTypes } from 'config'
import { DashboardHeaderLabel } from 'components/dashboard'

import { formatDate, getConnectionCountLink } from 'utils'

const propTypes = {
  user: Types.user,
  children: PropTypes.node,
}

const defaultProps = {}

function InvestorDashboardDetails({ user, children }) {
  return (
    <div className="profile-information">
      {user.investorProfile.investorType ===
      InvestorTypes.IndividualInvestor ? (
        <div className="profile-details">
          <h1>{user.investorProfile.displayName}</h1>
          <h2 className="profile-state-subheader">{user.state}</h2>
          <DashboardHeaderLabel
            label="Current Industry: "
            value={user.investorProfile.currentIndustry}
          />
          <DashboardHeaderLabel
            label="Member Status: "
            value={user.investorProfile.membershipStatus}
          />
          <DashboardHeaderLabel
            label="Firm Value Member Since: "
            value={formatDate(user.investorProfile.createdAt)}
          />
          {/* <DashboardHeaderLabel
            label="Profile Confirmation: "
            value="Self-Confirmed"
          /> */}

          <div className="connections-details">
            <DashboardHeaderLabel
              label="Pending Connections: "
              value={getConnectionCountLink(
                user.pendingConnections,
                '/dashboard/connections/pending-connections'
              )}
            />
            <DashboardHeaderLabel
              label="Total Connections: "
              value={getConnectionCountLink(
                user.totalConnections,
                '/dashboard/connections/active-connections'
              )}
            />
            <DashboardHeaderLabel
              label="Potential Matches: "
              value="Coming soon"
            />
          </div>
        </div>
      ) : (
        <div className="profile-details">
          <h1>{user.investorProfile.displayName}</h1>
          <h2 className="profile-state-subheader">{user.state}</h2>
          <DashboardHeaderLabel
            label="Years Experience: "
            value={user.investorProfile.yearsExperience}
          />
          <DashboardHeaderLabel
            label="Member Status: "
            value={user.investorProfile.membershipStatus}
          />
          <DashboardHeaderLabel
            label="Firm Value Member Since: "
            value={formatDate(user.investorProfile.createdAt)}
          />
          {/* <DashboardHeaderLabel
            label="Profile Confirmation: "
            value="Self-Confirmed"
          /> */}
          <div className="connections-details">
            <DashboardHeaderLabel
              label="Pending Connections: "
              value={getConnectionCountLink(
                user.pendingConnections,
                '/dashboard/connections/pending-connections'
              )}
            />
            <DashboardHeaderLabel
              label="Total Connections: "
              value={getConnectionCountLink(
                user.totalConnections,
                '/dashboard/connections/active-connections'
              )}
            />
            <DashboardHeaderLabel
              label="Potential Matches: "
              value="Coming soon"
            />
          </div>
        </div>
      )}
      <div className="profile-buttons">
        <div className="button-area">{children}</div>
      </div>
    </div>
  )
}

InvestorDashboardDetails.propTypes = exact(propTypes)
InvestorDashboardDetails.defaultProps = defaultProps

export default React.memo(InvestorDashboardDetails)
