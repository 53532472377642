import React, { useEffect } from 'react'
import PropTypes from 'prop-types'
import exact from 'prop-types-exact'
import { MessagesMessage } from 'components/messages'
import { scrollToSelector } from 'utils'
import { isEmpty } from 'lodash'
import classnames from 'classnames'

const propTypes = {
  messages: PropTypes.array,
  currentUser: PropTypes.object,
  connection: PropTypes.object,
  deletedThread: PropTypes.bool,
}

const defaultProps = {}

function MessagesActiveMessageThread({
  messages,
  currentUser,
  connection,
  deletedThread,
}) {
  useEffect(() => {
    const container = document.querySelector('.active-message-thread')
    if (!container) return
    const selector = window.location.hash
    if (selector) {
      scrollToSelector(selector)
      const element = container.querySelector(selector)
      if (element)
        container.scrollTo({ top: element.getBoundingClientRect().y })
    } else {
      container.scrollTop = container.scrollHeight
    }
  }, [])
  return (
    <div
      className={classnames('active-message-thread', {
        'deleted-thread': deletedThread,
      })}
    >
      <div className="message-list">
        {isEmpty(messages) ? (
          <div className="no-messages"></div>
        ) : (
          messages.map((message) => {
            const received = message.sentById !== currentUser.id
            const user = received ? connection : currentUser
            return (
              <MessagesMessage
                key={message.id}
                received={received}
                message={message}
                user={user}
              />
            )
          })
        )}
      </div>
    </div>
  )
}

MessagesActiveMessageThread.propTypes = exact(propTypes)
MessagesActiveMessageThread.defaultProps = defaultProps

export default React.memo(MessagesActiveMessageThread)
