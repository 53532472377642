import React from 'react'
import PropTypes from 'prop-types'
import exact from 'prop-types-exact'
import classnames from 'classnames'
import { MessagesInfoBadge } from 'components/messages'
import attachmentIcon from '../../../assets/images/paperclip.svg'

const propTypes = {
  received: PropTypes.bool,
  message: PropTypes.object,
  user: PropTypes.object,
}

const defaultProps = {}

function MessagesMessage({
  received,
  message: { id, text, createdAt, hasAttachedFile },
  user,
}) {
  return (
    <div className={classnames('message-flexbox', { right: !received })}>
      <MessagesInfoBadge
        received={received}
        createdAt={createdAt}
        user={user}
      />
      <div className="message" id={`message-${id}`} title={text}>
        {hasAttachedFile ? (
          <a href={`/messages/${id}/download`} target="_blank" rel="noreferrer">
            <img src={attachmentIcon} className="file-icon" />
            {text}
          </a>
        ) : (
          <>{text}</>
        )}
      </div>
    </div>
  )
}

MessagesMessage.propTypes = exact(propTypes)
MessagesMessage.defaultProps = defaultProps

export default React.memo(MessagesMessage)
