import React from 'react'
// import PropTypes from 'prop-types'
import exact from 'prop-types-exact'

import {
  ProfileSectionLineItem,
  ProfileSectionLineItemTwoCol,
} from 'components/profiles'

const propTypes = {}

const defaultProps = {}

function BPAdditionalDetailsProfileSection({ sectionVisible, userData }) {
  if (sectionVisible) {
    return (
      <div className="profile-section-wrapper centered-content">
        <h1>Additional Details</h1>
        <div className="business-description">
          {userData?.description && (
            <ProfileSectionLineItemTwoCol
              label="Business Description"
              value={userData.description}
            />
          )}
        </div>
        <div className="profile-view-section ">
          <div className="profile-view-section-col">
            <ProfileSectionLineItem
              label="Special Designation(s)"
              value={userData.specialDesignations}
            />
            <ProfileSectionLineItem
              label="U.S. Small Business"
              value={userData.meetsSba === true ? 'Yes' : 'No'}
            />
          </div>
          <div className="profile-view-section-col">
            <ProfileSectionLineItem
              label="Other Locations (Regions)"
              value={userData.otherLocations}
            />
          </div>
        </div>
      </div>
    )
  } else {
    return <></>
  }
}

BPAdditionalDetailsProfileSection.propTypes = exact(propTypes)
BPAdditionalDetailsProfileSection.defaultProps = defaultProps

export default React.memo(BPAdditionalDetailsProfileSection)
