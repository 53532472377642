import React from 'react'
import PropTypes from 'prop-types'
import exact from 'prop-types-exact'

const propTypes = {
  children: PropTypes.node,
  pageIndex: PropTypes.number,
}

const defaultProps = {}

function ThirdsFormCard({ children, pageIndex }) {
  return (
    <div className="form-card">
      <div
        className={
          pageIndex === 1
            ? 'progress-bar-one-third'
            : pageIndex === 2
            ? 'progress-bar-two-third'
            : 'progress-bar-full'
        }
      ></div>
      <div className="form-card-content">{children}</div>
    </div>
  )
}

ThirdsFormCard.propTypes = exact(propTypes)
ThirdsFormCard.defaultProps = defaultProps

export default React.memo(ThirdsFormCard)
