import React from 'react'
import PropTypes from 'prop-types'
import exact from 'prop-types-exact'

const propTypes = {
  handleSortChange: PropTypes.func,
}

const defaultProps = {}

function ConnectionsSortRow({ handleSortChange }) {
  return (
    <div className="sort-row">
      <select
        onChange={(e) => {
          e.preventDefault()
          handleSortChange(e.target.value)
        }}
        className="sort-select"
        name="sortselection"
        id="sortselection"
      >
        <option value="" disabled selected>
          Sort by
        </option>
        <option value="most-recent">Date Added (Most Recent)</option>
        <option value="oldest">Date Added (Oldest)</option>
        <option value="name-sector-asc">Name (Ascending)</option>
        <option value="name-sector-desc">Name (Descending)</option>
        <option value="state-asc">State (Ascending)</option>
        <option value="state-desc">State (Descending)</option>
        <option value="member-type-asc">Member Type (Ascending)</option>
        <option value="member-type-desc">Member Type (Descending)</option>
      </select>
    </div>
  )
}

ConnectionsSortRow.propTypes = exact(propTypes)
ConnectionsSortRow.defaultProps = defaultProps

export default React.memo(ConnectionsSortRow)
