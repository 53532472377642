import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import exact from 'prop-types-exact'
import { Card } from 'components'
import {
  Button,
  Textarea as BaseTextarea,
  SubmitButton,
} from '@launchpadlab/lp-components'
import * as api from 'api'
import classnames from 'classnames'
import moment from 'moment'
import { Formik, Form } from 'formik'
import { withFormikAdapterAndVisibilityInput } from 'utils'
import closeIcon from '../../../assets/images/close.svg'

const Textarea = withFormikAdapterAndVisibilityInput()(BaseTextarea)

const propTypes = {
  userId: PropTypes.number,
  removedConnection: PropTypes.bool,
  deletedProfile: PropTypes.bool,
}

const defaultProps = {}

function MessagesUserNotes({ userId, removedConnection, deletedProfile }) {
  const [expandModal, setExpandModal] = useState(false)
  const [disabled, toggleDisabled] = useState(true)
  const [note, setNote] = useState('')

  const noteLastUpdatedTimestamp = note?.updatedAt

  useEffect(() => {
    async function getUserNote() {
      setNote(
        userId
          ? await api.getNote('/get-note', {
              user_id: userId,
            })
          : ''
      )
    }
    getUserNote()
  }, [])

  const handleNoteSubmit = (values) => {
    const requestObj = {
      userId: userId,
      ...values,
    }
    api
      .saveNote(requestObj)
      .then((res) => {
        setNote(res.note)
        setExpandModal(false)
        toggleDisabled(true)
        window.location.reload(true)
      })
      .catch(() => {
        throw new Error('Note could not be saved')
      })
  }

  const initialValues = {
    note: note?.note,
  }

  const parsedLastUpdatedDate = Date.parse(noteLastUpdatedTimestamp)

  return (
    <Card removeTopRow={true}>
      <div className="notes-flex-container">
        <div className="notes-area-col">
          <h4>Notes</h4>
          {noteLastUpdatedTimestamp && note.note !== '' && (
            <div className="row">
              <div className="note-updated-timestamp">
                <p>
                  {`Last updated: ${moment(parsedLastUpdatedDate).format(
                    'MMMM D, YYYY'
                  )}`}
                </p>
              </div>
            </div>
          )}
          <p className="notes-preview">{note ? note.note : null}</p>
        </div>
        <div className="notes-button-col">
          <Button
            onClick={() => {
              setExpandModal(!expandModal)
            }}
            className="connections-button button-primary"
          >
            {removedConnection || deletedProfile
              ? 'View Notes'
              : 'View/Add Notes'}
          </Button>
        </div>
      </div>
      <div className={classnames('notes-edit-modal', { visible: expandModal })}>
        <div className="modal-fade-screen">
          <div className="notes-form">
            <Formik
              onSubmit={handleNoteSubmit}
              initialValues={initialValues}
              enableReinitialize
            >
              {({ resetForm }) => (
                <Form>
                  <div className="top-row">
                    <h3>
                      {deletedProfile || removedConnection
                        ? 'Notes'
                        : 'Edit Notes'}
                    </h3>
                    <button
                      onClick={() => {
                        resetForm()
                        toggleDisabled(true)
                        setExpandModal(false)
                      }}
                    >
                      <img src={closeIcon} alt="Close Icon" />
                    </button>
                  </div>
                  {noteLastUpdatedTimestamp && (
                    <div className="row">
                      <div className="note-updated-timestamp expanded">
                        <p>
                          {`Last updated: ${moment(
                            parsedLastUpdatedDate
                          ).format('MMMM DD YYYY')}`}
                        </p>
                      </div>
                    </div>
                  )}
                  <div className="row">
                    <Textarea
                      className="text-area"
                      name="note"
                      label=" "
                      placeholder="You currently have no notes"
                      disabled={disabled}
                    />
                  </div>
                  {deletedProfile || removedConnection ? null : (
                    <div className="row">
                      <div className="button-area">
                        {disabled ? (
                          <button
                            className="button-primary-outline"
                            onClick={() => {
                              toggleDisabled(false)
                            }}
                          >
                            Edit
                          </button>
                        ) : (
                          <div className="edit-buttons-area">
                            <SubmitButton>Submit Changes</SubmitButton>
                            <a
                              onClick={() => {
                                resetForm()
                                toggleDisabled(true)
                                setExpandModal(false)
                              }}
                            >
                              Cancel
                            </a>
                          </div>
                        )}
                      </div>
                    </div>
                  )}
                </Form>
              )}
            </Formik>
          </div>
        </div>
      </div>
    </Card>
  )
}

MessagesUserNotes.propTypes = exact(propTypes)
MessagesUserNotes.defaultProps = defaultProps

export default React.memo(MessagesUserNotes)
