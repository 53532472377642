import React from 'react'
// import PropTypes from 'prop-types'
import exact from 'prop-types-exact'
import {
  ConnectionsSubSideNav,
  ConnectionsMobileSubNav,
  ConnectionsActiveConnections,
  ConnectionsPendingConnections,
  ConnectionsSavedForLater,
} from 'components/connections'
import { ErrorBoundary, Footer } from 'components'
import { Route, Switch } from 'react-router-dom'

const propTypes = {}

const defaultProps = {}

function Connections() {
  return (
    <ErrorBoundary>
      <div className="connections-layout-wrapper">
        <ConnectionsMobileSubNav />
        <ConnectionsSubSideNav />
        <div className="connections-view-area">
          <Switch>
            <Route path="/connections/active-connections">
              <ConnectionsActiveConnections />
            </Route>
            <Route path="/connections/pending-connections">
              <ConnectionsPendingConnections />
            </Route>
            <Route path="/connections/saved-for-later">
              <ConnectionsSavedForLater />
            </Route>
          </Switch>
        </div>
      </div>
      <Footer></Footer>
    </ErrorBoundary>
  )
}

Connections.propTypes = exact(propTypes)
Connections.defaultProps = defaultProps

export default React.memo(Connections)
