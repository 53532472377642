import React from 'react'
// import PropTypes from 'prop-types'
import exact from 'prop-types-exact'

import { ProfileSectionLineItem } from 'components/profiles'
import { isHumanizedBooleanValue, replaceNullValueWithPlaceholder } from 'utils'

const propTypes = {}

const defaultProps = {}

function BPOperatingCriteriaMatch({ sectionVisible, userData }) {
  if (sectionVisible) {
    return (
      <div className="profile-section-wrapper centered-content">
        <h1>Acquisition Operating Criteria</h1>
        <div className="profile-view-section ">
          <div className="profile-view-section-col">
            <ProfileSectionLineItem
              label="Business Location"
              value={replaceNullValueWithPlaceholder(
                userData?.consideredLocations
              )}
              isCriteriaMatch={isHumanizedBooleanValue(
                userData.consideredLocations
              )}
            />
            <ProfileSectionLineItem
              label="Primary Sector"
              value={replaceNullValueWithPlaceholder(
                userData?.consideredPrimarySectors
              )}
              isCriteriaMatch={isHumanizedBooleanValue(
                userData.consideredPrimarySectors
              )}
            />
            <ProfileSectionLineItem
              label="Primary Industry"
              value={replaceNullValueWithPlaceholder(
                userData.consideredPrimaryIndustries
              )}
              isCriteriaMatch={isHumanizedBooleanValue(
                userData.consideredPrimaryIndustries
              )}
            />
          </div>
          <div className="profile-view-section-col">
            <ProfileSectionLineItem
              label="Business Stage"
              value={replaceNullValueWithPlaceholder(
                userData.consideredBusinessStages
              )}
              isCriteriaMatch={isHumanizedBooleanValue(
                userData.consideredBusinessStages
              )}
            />
            <ProfileSectionLineItem
              label="Business Model(s)"
              value={replaceNullValueWithPlaceholder(
                userData?.consideredBusinessModels
              )}
              isCriteriaMatch={isHumanizedBooleanValue(
                userData.consideredBusinessModels
              )}
            />
            {/* <ProfileSectionLineItem
              label="Current Ownership"
              value={replaceNullValueWithPlaceholder(
                userData.consideredOwnerships
              )}
              isCriteriaMatch={isHumanizedBooleanValue(
                userData.consideredOwnerships
              )}
            /> */}
            {/* <ProfileSectionLineItem
              label="Sectors Served"
              value={replaceNullValueWithPlaceholder(
                userData.consideredServedSectors
              )}
              isCriteriaMatch={isHumanizedBooleanValue(
                userData.consideredServedSectors
              )}
            />
            <ProfileSectionLineItem
              label="Geographies Served"
              value={replaceNullValueWithPlaceholder(
                userData?.consideredServedLocations
              )}
              isCriteriaMatch={isHumanizedBooleanValue(
                userData.consideredServedLocations
              )}
            /> */}
          </div>
        </div>
      </div>
    )
  } else {
    return <></>
  }
}

BPOperatingCriteriaMatch.propTypes = exact(propTypes)
BPOperatingCriteriaMatch.defaultProps = defaultProps

export default React.memo(BPOperatingCriteriaMatch)
