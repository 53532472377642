import React from 'react'
// import PropTypes from 'prop-types'
import exact from 'prop-types-exact'
import {
  ProfileSectionLineItem,
  ProfileSectionLineItemTwoCol,
} from 'components/profiles'
import { replaceNullValueWithPlaceholder } from 'utils'
import { isEmpty } from 'lodash'

const propTypes = {}

const defaultProps = {}

function IPGeneralOverviewProfileSection({ sectionVisible, userData }) {
  if (sectionVisible) {
    return (
      <div className="profile-section-wrapper centered-content">
        <h1>General Overview</h1>
        <div className="profile-view-section ">
          <div className="profile-view-section-col">
            {userData?.investorType === 'Individual Investor' &&
              userData?.currentIndustry && (
                <ProfileSectionLineItem
                  label="Current Industry"
                  value={replaceNullValueWithPlaceholder(
                    userData?.currentIndustry
                  )}
                />
              )}
            <ProfileSectionLineItem
              label="Sector Experience"
              value={replaceNullValueWithPlaceholder(
                userData?.priorSectorExperiences
              )}
            />
          </div>
          <div className="profile-view-section-col">
            <ProfileSectionLineItem
              label="Years Experience"
              value={replaceNullValueWithPlaceholder(
                userData?.yearsOfExperience
              )}
            />
            <ProfileSectionLineItem
              label="Geographic Experience"
              value={replaceNullValueWithPlaceholder(
                userData?.priorLocationExperiences
              )}
            />
          </div>
          {isEmpty(userData.description) ? null : (
            <ProfileSectionLineItemTwoCol
              label="Investor Description"
              value={userData.description}
            />
          )}
        </div>
      </div>
    )
  } else {
    return <></>
  }
}

IPGeneralOverviewProfileSection.propTypes = exact(propTypes)
IPGeneralOverviewProfileSection.defaultProps = defaultProps

export default React.memo(IPGeneralOverviewProfileSection)
