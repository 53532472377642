import React from 'react'
import PropTypes from 'prop-types'
import exact from 'prop-types-exact'
import {
  ProfileHeaderCard,
  IPInvestorExplorationStatusProfileSection,
  IPGeneralOverviewProfileSection,
  IPOperatingCriteriaProfileSection,
  IPFinancialCriteriaProfileSection,
  IPInvestmentOpportunityCriteriaProfileSection,
} from 'components/profiles'

import { DashboardHeader } from 'components/dashboard'

import { Footer } from 'components'

const propTypes = {
  userSession: PropTypes.object,
  userData: PropTypes.object,
}

const defaultProps = {}

function InvestorProfileView(props) {
  const { userSession, userData } = props
  const isPassiveInvestor =
    userData?.isPassiveInvestorOnly === true ? true : false
  const isViewerPassiveInvestor = userData?.isViewerPassiveInvestorOnly

  const isSeekingInvestors = userData?.seekingStatus === 'Seeking Investors'
  const viewerType = userData?.viewerProfileType
  const isViewerBusinessSeekingCapital =
    userData?.viewerProfileType === 'BusinessProfile' &&
    userData?.viewerSeekingStatus === 'seeking_capital'

  const advisorRepresentsBusinesses = userData?.advisorRepresentsBusinesses
  const isDirectInvestor = userData?.isDirectInvestor

  return (
    <div className="profile-view-wrapper">
      <DashboardHeader userSession={userSession} />
      <div className="profile-view">
        <ProfileHeaderCard {...props} />
        <IPInvestorExplorationStatusProfileSection
          sectionVisible={true}
          userData={userData}
          isSeekingInvestors={isSeekingInvestors}
          viewerType={viewerType}
        />
        <IPGeneralOverviewProfileSection
          sectionVisible={true}
          userData={userData}
        />

        <IPOperatingCriteriaProfileSection
          sectionVisible={
            !(viewerType === 'AdvisorProfile' && !advisorRepresentsBusinesses)
          }
          userData={userData}
        />
        <IPFinancialCriteriaProfileSection
          sectionVisible={!isPassiveInvestor && !isViewerPassiveInvestor}
          advisorRepresentsBusinesses={advisorRepresentsBusinesses}
          userData={userData}
        />

        <IPInvestmentOpportunityCriteriaProfileSection
          sectionVisible={isViewerBusinessSeekingCapital && isDirectInvestor}
          userData={userData}
          viewerType={viewerType}
          isPassiveInvestor={isPassiveInvestor}
          isSeekingInvestors={isSeekingInvestors}
        />
      </div>
      <Footer />
    </div>
  )
}

InvestorProfileView.propTypes = exact(propTypes)
InvestorProfileView.defaultProps = defaultProps

export default React.memo(InvestorProfileView)
