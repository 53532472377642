import React, { useState } from 'react'
import PropTypes from 'prop-types'
import exact from 'prop-types-exact'
import { withFormikAdapterAndVisibilityInput, validate } from '../utils'
import { ImageUploadPreview } from 'components'
import {
  Checkbox as BaseCheckbox,
  SubmitButton,
} from '@launchpadlab/lp-components'
import { Formik, Form } from 'formik'

const Checkbox = withFormikAdapterAndVisibilityInput()(BaseCheckbox)

const propTypes = {
  storedData: PropTypes.object,
  handleSubmit: PropTypes.func,
}

const defaultProps = {}

// Add an extra custom validation for the hidden spam input.
function validateForm(values) {
  const defaultSectorIconSelected = values.defaultImage
  const messages = validate(values, {
    defaultImage: function (value, attributes) {
      if (attributes.profileImageUrl === '') {
        return { presence: { allowEmpty: false } }
      } else {
        return { presence: { allowEmpty: true } }
      }
    },
    profileImageUrl: function () {
      if (defaultSectorIconSelected) {
        return
      } else {
        return {
          presence: { allowEmpty: false, message: '^Profile image required' },
        }
      }
    },
  })
  // mix custom stuff here w validate.js messages
  return {
    ...messages,
    //
  }
}

function ProfileImageEditForm({ storedData, handleSubmit }) {
  const [disabled, toggleDisabled] = useState(true)
  const values = {
    defaultImage: storedData?.useDefaultSectorIcon || false,
    profileImageUrl: storedData.profileImageUrl || '',
  }

  return (
    <Formik
      onSubmit={(values) => {
        handleSubmit(values)
      }}
      validate={validateForm}
      initialValues={values}
    >
      {({ values, setFieldValue, handleReset }) => (
        <Form className="onboarding-form">
          <div className="row">
            <h1>Profile Image</h1>
            {disabled ? (
              <button
                className="button-primary-outline"
                onClick={() => {
                  toggleDisabled(false)
                }}
              >
                Edit
              </button>
            ) : (
              <div className="edit-buttons-area">
                <button
                  className="button-primary-outline"
                  onClick={() => {
                    handleReset()
                    toggleDisabled(true)
                  }}
                >
                  Cancel
                </button>
                <SubmitButton>Submit Changes</SubmitButton>
              </div>
            )}
          </div>
          <div className="row">
            You may either choose from pre-made icons based on Sector, or upload
            your own image.
          </div>
          <div className="row">
            <div className="column">
              <Checkbox
                name="defaultImage"
                onChange={(e) => {
                  if (e.target.value === true) {
                    setFieldValue('profileImageUrl', '')
                  }
                }}
                disabled={disabled}
              />
            </div>
            <div className="column">
              {values.defaultImage ? (
                <div className="image-upload-preview">
                  <img
                    src={storedData?.defaultImageUrl}
                    alt="Default Investor Image"
                    style={{ width: '60px' }}
                  />
                </div>
              ) : (
                <ImageUploadPreview
                  disabled={disabled}
                  name="profileImageUrl"
                  hasStoredValue={values.profileImageUrl}
                  setFieldValue={setFieldValue}
                />
              )}
            </div>
          </div>
        </Form>
      )}
    </Formik>
  )
}

ProfileImageEditForm.propTypes = exact(propTypes)
ProfileImageEditForm.defaultProps = defaultProps

export default ProfileImageEditForm
