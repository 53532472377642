import { formHelpers } from 'utils'

// used for sector > industries filter
const getIndustriesBySector = (sectorId, params) => {
  if (sectorId) {
    const sectorObj = formHelpers.getArrayObjectById(sectorId, params)
    return formHelpers.formatOptions(sectorObj.industries)
  }
  return []
}

export default getIndustriesBySector
