// take a connections count and return as href link
import React from 'react'

const getConnectionCountLink = (connectionsCount, href) => {
  return (
    <a className="connections-count-link" href={href}>
      {connectionsCount ? connectionsCount : '--'}
    </a>
  )
}

export default getConnectionCountLink
