import React from 'react'
import PropTypes from 'prop-types'
import exact from 'prop-types-exact'
import { Helmet } from 'react-helmet'
import { Header, Card, Footer } from '../components'

const defaultProps = {}

const propTypes = { userSession: PropTypes.object }

function PrivacyPolicy({ userSession }) {
  return (
    <>
      <Helmet>
        <title>Privacy Policy</title>
      </Helmet>
      <Header
        className="mini-header"
        title="Privacy Policy"
        userSession={userSession}
      />

      <Card>
        <div className={'policy-text'}>
          <p>Last updated: November 11, 2020</p>
          <p>
            This Privacy Policy describes how Firm Value, LLC (“Firm Value,”
            “we,” or “us”) processes personal information when you visit any of
            our websites or platforms that link to this Privacy Policy
            (collectively, the “Services”). It describes what types of
            information Firm Value collects, how that information is used, Firm
            Value’s sharing practices, and your choices.
          </p>
          <p>
            <u>
              <b>Information We Collect</b>
            </u>
          </p>
          <p>
            We collect information from you directly when you provide it to us,
            automatically through the Services, and from third parties. Each
            collection mechanism is described in more detail in this Privacy
            Policy.
          </p>
          <p>
            <u>
              <b>Information You Provide Directly</b>
            </u>
          </p>
          <p>
            <u>Sign Up for Membership.</u> When you sign up for membership, we
            collect your email address, password, and membership type. We use
            this information to administer the Services.
          </p>
          <p>
            <u>Build Your Profile.</u> When you build a profile with us we
            collect your first name, last name, zip code, city, and state, in
            addition to any personal information that you may share in
            membership-specific profile entries related to you and your
            business, investment fund, role as an investor, or role as an
            advisor for the purpose of using our Services. We use this
            information in a data-driven matching system allowing members to see
            which of their criteria matches that of other members. Members are
            then given the option to connect for the purpose of sharing more
            information directly with other members.
          </p>
          <p>
            <u>Sign Up for News.</u> When you sign up to receive news and
            updates from us, we collect your email address and member type. We
            collect this information so that we can send you updates about Firm
            Value. You can unsubscribe from these communications at any time by
            clicking on the “Unsubscribe” link at the footer of any email you
            receive from us.
          </p>
          <p>
            <u>Identity Verification.</u> When you choose to verify your
            identity with us, we collect information relating to your identity
            or the identity of the entity that you are representing. Individual
            identity information that you may share includes your first name,
            last name, address, date of birth, license number, and passport
            number. Entity identity information that you may share includes the
            entity’s name, state of domicile, address, registration number, and
            employer identification number. We use this information to verify
            the identities of our members against various databases and provide
            an indication on profiles that the individual or entity represented
            in a profile matches records held by various databases. Identity
            verification is optional and completed at each member’s discretion.
          </p>
          <p>
            <u>Communications With Us.</u> When you communicate with us through
            the Services, we use that information to respond to your inquiries.
          </p>
          <p>
            <u>Communications With Other Firm Value Members.</u> When you
            communicate or share documents or materials with other members
            through the Services, we store those communications, documents, and
            materials within the Services and we may use those communications,
            documents, or materials for any purpose set forth in this Privacy
            Policy.
          </p>
          <p>
            <u>
              <b>Automatically Collected Information</b>
            </u>
          </p>
          <p>
            We automatically collect the following types of information when you
            use our Services, including:
          </p>

          <p>
            <u>Technical Data.</u> We collect information about the device you
            use to access our Services, including your internet connection,
            including your internet protocol (IP) address.
          </p>

          <p>
            <u>Cookies and Similar Tracking Technologies.</u> A cookie is a
            small text file that is stored on your device through your browser.
            The cookies used by our Services include both session-based cookies,
            which expire after you close your browser or turn off your computer,
            and persistent cookies, which remain on your device until you
            affirmatively remove them. Most web browsers automatically accept
            cookies, but you can set your browser to decline cookies.
          </p>
          <p>
            We use this automatically collected information to analyze trends,
            to provide, administer, and improve our Services, to track members’
            movements while using the Services, to gather demographic
            information about our member base, and to market our products and
            services and monitor interactions with our marketing communications.
          </p>
          <p>
            <u>
              <b>How We Use Your Information</b>
            </u>
          </p>

          <p>
            In addition to the uses described above, we also use your
            information:
          </p>
          <ul>
            <li>
              to send you alert messages and notifications related to the
              Services;
            </li>
            <li> to personalize the Services;</li>
            <li>to prevent abuse of our Services;</li>
            <li>to maintain and enhance the security of our Services;</li>
            <li>
              {' '}
              to protect our interests or the interests of third parties;
            </li>
            <li>to comply with our legal obligations;</li>
            <li>
              to perform or enforce our agreements, including our{' '}
              <a
                href={'/terms-and-conditions'}
                target={'_blank'}
                rel="noreferrer"
              >
                Terms of Service
              </a>
            </li>
            <li>for any other purpose with your consent.</li>
          </ul>
          <p>
            <u>
              <b>How We Share Your Information</b>
            </u>
          </p>
          <p>We share your information:</p>

          <ul>
            <li>
              with contractors, service providers, and other third parties we
              use to support our Services;
            </li>
            <li>to fulfill any purpose for which you provide it;</li>
            <li>
              for any other purpose disclosed to you when you provide the
              information; or
            </li>
            <li>with other Firm Value members with your consent.</li>
          </ul>
          <p>We may also share your information:</p>

          <ul>
            <li>
              to comply with any court order, law, or legal process, including
              to respond to any government or regulatory request;
            </li>
            <li>
              in connection with a merger, divestiture, restructuring,
              reorganization, dissolution, or other sale or transfer of some or
              all of our assets;
            </li>
            <li>
              if we believe disclosure is necessary or appropriate to protect
              our rights, property, or interests, or the rights, property, or
              interests of third parties.
            </li>
          </ul>

          <p>
            <u>
              <b>Third Parties</b>
            </u>
          </p>
          <p>
            You may choose to share your information with other Firm Value
            members and you may use our Services to communicate with other
            members. All interactions with other members in the Services must
            comply with our{' '}
            <a
              href={'/terms-and-conditions'}
              target={'_blank'}
              rel="noreferrer"
            >
              Terms of Service
            </a>
            . This Privacy Policy does not apply to any interactions you have
            with other Firm Value members outside of our Services.
          </p>
          <p>
            Our Services may contain links to other platforms, websites, or
            applications that we believe may be of interest to you. This Privacy
            Policy applies only to Firm Value’s Services and not to any other
            platforms or websites operated by third parties. Firm Value is not
            responsible for the privacy practices of any platforms, websites, or
            mobile applications not controlled by us.
          </p>
          <p>
            You may also be able to engage with our Services through third-party
            platforms, plug-ins, integrations, or applications or social media
            services. When you engage with our content on or through third-party
            platforms or social media services, you may allow us to have access
            to certain information in your profile and information about your
            interaction with our content. For a description on how social media
            services and other third-party platforms, plug-ins, integrations, or
            applications handle your information, please refer to their
            respective privacy policies and terms of use, which may permit you
            to modify your privacy settings.
          </p>
          <p>
            Third parties may also use cookies or similar technologies on our
            Services for advertising, retargeting, analytics, and tracking.
            Please see “More About Analytics and Advertising” and “Your Choices”
            below for more information about third party cookies.
          </p>
          <p>
            <u>
              <b>More About Analytics and Advertising</b>
            </u>
          </p>
          <p>
            We use third-party analytics providers, including Google Analytics,
            to help track how members interact with our Services. Third parties
            may also use cookies or web pixels for online advertising
            personalization. Third-party tracking technologies are not
            controlled by us, even if those technologies store or collect data
            through our Services. Statements regarding our practices do not
            apply to the methods for collecting information used by third-party
            advertisers and others or the use of the information that such third
            parties collect. The relevant third party’s{' '}
            <a
              href={'/terms-and-conditions'}
              target={'_blank'}
              rel="noreferrer"
            >
              Terms of Service
            </a>
            , privacy policy, permissions, notices, and choices should be
            reviewed regarding their collection, storage, and sharing practices.
            To understand how Google Analytics collects and processes data,
            please visit{' '}
            <a
              href={'https://www.google.com/policies/privacy/partners/'}
              style={{ 'word-break': 'break-word' }}
              target={'_blank'}
              rel="noreferrer"
            >
              www.google.com/policies/privacy/partners/
            </a>
          </p>
          <p>
            <u>
              <b>Data Retention</b>
            </u>
          </p>
          <p>
            We only store information for as long as is needed to fulfill our
            contractual or legal obligations or any other permissible purpose
            for which we have collected it, including to prevent fraud or
            security incidents. When the applicable retention period has
            expired, we take measures to permanently delete the information,
            aggregate it, or anonymize it.
          </p>
          <p>
            <u>
              <b>Security</b>
            </u>
          </p>
          <p>
            We use commercially reasonable administrative, physical, and
            technical measures to safeguard your information and help protect it
            from unauthorized access. However, we cannot guarantee complete
            protection of your information since transmission via the internet
            is not 100% secure. You play an important role in keeping your
            information secure. You should not share your password, or other
            security information for your account with anyone. If you have
            reason to believe that your interaction with us is no longer secure,
            please immediately notify us by contacting us as detailed in the
            “Contact Us” section.
          </p>
          <p>
            <u>
              <b>Children</b>
            </u>
          </p>
          <p>
            Our Services are not directed to children, as defined under
            applicable law. If you believe that a child has provided their
            information to us through our Services, please contact us as
            detailed in the “Contact Us” section, so that we can take steps to
            delete it.
          </p>
          <p>
            <u>
              <b>Your Choices</b>
            </u>
          </p>
          <p>
            You can control the use of cookies within your browser. However,
            please note that disabling some or all cookies may affect the
            functionality of our Services. Our Services do not respond to
            browser “Do Not Track” signals. We do not control third parties’
            collection or use of your information to serve interest-based
            advertising. However, these third parties may provide you with ways
            to choose not to have your information collected or used in this
            way. You can opt-out of receiving targeted ads from members of the
            Network Advertising Initiative (“NAI”) on the NAI’s website at{' '}
            <a
              href={'https://www.networkadvertising.org'}
              target={'_blank'}
              rel="noreferrer"
            >
              https://www.networkadvertising.org
            </a>
            . You may also be able to opt-out of receiving third-party
            behavioral ads by visiting the Digital Advertising Alliance (“DAA”)
            website at
            <a
              href={'https://www.aboutads.info/choices'}
              target={'_blank'}
              rel="noreferrer"
            >
              {' '}
              https://www.aboutads.info/choices
            </a>
            . We are not responsible for effectiveness of or compliance with any
            third-parties’ opt-out options. You can access or change your
            information in your member account or you can contact us as detailed
            in the “Contact Us” section.
          </p>
          <p>
            <u>
              <b>Changes to This Privacy Policy</b>
            </u>
          </p>
          <p>
            We encourage you to periodically review this Privacy Policy for the
            latest information on our privacy practices. If we make any material
            changes to our privacy practices, we will notify you in accordance
            with applicable law prior to the changes becoming effective.
          </p>
          <p>
            <u>
              <b>Contact Us</b>
            </u>
          </p>
          <p>
            If you have any questions or comments regarding this Privacy Policy,
            our privacy practices, or if you wish to exercise your individual
            rights, please contact us by emailing us at{' '}
            <a
              href={'mailto:membership@firmvalue.com'}
              target={'_blank'}
              rel="noreferrer"
            >
              membership@firmvalue.com
            </a>
            .
          </p>
        </div>
      </Card>
      <Footer />
    </>
  )
}

PrivacyPolicy.propTypes = exact(propTypes)
PrivacyPolicy.defaultProps = defaultProps

export default React.memo(PrivacyPolicy)
