import React from 'react'
// import PropTypes from 'prop-types'
import exact from 'prop-types-exact'

import { ProfileSectionLineItem } from 'components/profiles'
import { isEmpty } from 'lodash'

const propTypes = {}

const defaultProps = {}

function BPCapitalExplorationProfileSection({ sectionVisible, userData }) {
  // const isViewerSeekingCapitalBusiness =
  //   userData.viewerProfileType === 'BusinessProfile' &&
  //   userData.viewerSeekingStatus === 'seeking_capital'
  const isViewerAcquistiveBusiness =
    userData.viewerProfileType === 'BusinessProfile' &&
    userData.viewerSeekingStatus === 'seeking_acquisitions'

  const isViewerAdvisor = userData.viewerProfileType === 'AdvisorProfile'

  const isViewerInvestorOrAcquistiveBusiness =
    isViewerAcquistiveBusiness ||
    userData.viewerProfileType === 'InvestorProfile'

  const formatter = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
    minimumFractionDigits: 0,
  })

  const isProfileSeekingCapital = userData.seekingStatus === 'Seeking capital'

  const isRepresentedByAnAdvisor = !isEmpty(userData.representedBy)

  if (sectionVisible) {
    if (!isProfileSeekingCapital) {
      return (
        <div className="profile-section-wrapper centered-content">
          <h1>Exploration Status</h1>
          <div className="profile-view-section ">
            <div className="profile-view-section-col">
              {userData?.membershipStatus && (
                <ProfileSectionLineItem
                  label="Member Status"
                  value={userData.membershipStatus}
                />
              )}
            </div>
            <div className="profile-view-section-col">
              {/* {userData.consideredOfferingTypes && (
                <ProfileSectionLineItem
                  label="Likely Offering Type(s)"
                  value={userData.consideredOfferingTypes}
                />
              )} */}
            </div>
          </div>
        </div>
      )
    }

    return (
      <div className="profile-section-wrapper centered-content">
        <h1>Exploration Status</h1>
        <div className="profile-view-section ">
          <div className="profile-view-section-col">
            {userData?.membershipStatus && (
              <ProfileSectionLineItem
                label="Member Status"
                value={userData.membershipStatus}
              />
            )}
            {isRepresentedByAnAdvisor && (
              <ProfileSectionLineItem
                label="Represented By"
                value={
                  <a
                    className="bp-advisor-link"
                    href={userData.representedBy.url}
                  >
                    {userData.representedBy.name}
                  </a>
                }
              />
            )}
          </div>
          <div className="profile-view-section-col"></div>
        </div>
        {(isViewerInvestorOrAcquistiveBusiness || isViewerAdvisor) &&
          (userData?.likelyEquityOffered ||
            userData?.capitalNeedReasons ||
            userData?.consideredInvestorTypes ||
            userData.consideredOfferingTypes ||
            userData?.consideredPostInvestmentIntentions) && (
            <div
              className="profile-view-section "
              style={{ marginTop: '16px' }}
            >
              <div className="profile-view-section-col">
                {userData.consideredOfferingTypes && (
                  <ProfileSectionLineItem
                    label="Likely Offering Type(s)"
                    value={userData.consideredOfferingTypes}
                  />
                )}
                {userData?.likelyEquityOffered && (
                  <ProfileSectionLineItem
                    label="Likely Offering Amount (percentage)"
                    value={userData.likelyEquityOffered}
                  />
                )}
                {userData?.targetCapitalRaise === 'Undisclosed' ? (
                  <ProfileSectionLineItem
                    label="Likely Offering Amount (dollars)"
                    value={userData.targetCapitalRaise}
                  />
                ) : (
                  <ProfileSectionLineItem
                    label="Likely Offering Amount (dollars)"
                    value={formatter.format(
                      parseInt(userData.targetCapitalRaise)
                    )}
                  />
                )}
              </div>
              <div className="profile-view-section-col">
                {userData?.capitalNeedReasons && (
                  <ProfileSectionLineItem
                    label="Reasons For Capital Raise"
                    value={userData.capitalNeedReasons}
                  />
                )}
                {userData?.consideredInvestorTypes && (
                  <ProfileSectionLineItem
                    label="Investor Type(s) Considered"
                    value={userData.consideredInvestorTypes}
                  />
                )}
                {userData?.consideredPostInvestmentIntentions && (
                  <ProfileSectionLineItem
                    label="Intentions Post-Investment"
                    value={userData.consideredPostInvestmentIntentions}
                  />
                )}
              </div>
            </div>
          )}
      </div>
    )
  } else {
    return <></>
  }
}

BPCapitalExplorationProfileSection.propTypes = exact(propTypes)
BPCapitalExplorationProfileSection.defaultProps = defaultProps

export default React.memo(BPCapitalExplorationProfileSection)
