import React, { useState } from 'react'
import PropTypes from 'prop-types'
import exact from 'prop-types-exact'
import {
  withFormikAdapterAndVisibilityInput,
  validate,
  validationPatterns,
  formHelpers,
} from '../utils'
import {
  Input as BaseInput,
  Select as BaseSelectInput,
  SubmitButton,
} from '@launchpadlab/lp-components'
import { Formik, Form } from 'formik'

const Input = withFormikAdapterAndVisibilityInput()(BaseInput)
const Select = withFormikAdapterAndVisibilityInput()(BaseSelectInput)

const propTypes = {
  profileData: PropTypes.object,
  handleSubmit: PropTypes.func,
  params: PropTypes.object,
}

const defaultProps = {}

// Add an extra custom validation for the hidden spam input.
function validateForm(values) {
  const messages = validate(values, {
    firstName: {
      presence: { allowEmpty: false, message: '^Please provide a response' },
      type: 'string',
      format: validationPatterns.namePattern,
    },
    lastName: {
      presence: { allowEmpty: false, message: '^Please provide a response' },
      type: 'string',
      format: validationPatterns.namePattern,
    },
    // email: {
    //   presence: { allowEmpty: false, message: '^Please provide a response' },
    //   format: validationPatterns.emailPattern,
    // },
    // advisorTypeId: {
    //   presence: { allowEmpty: false, message: '^Please select one' },
    // },
    location: function (value, attributes) {
      if (attributes.location === '') {
        return {
          presence: {
            allowEmpty: false,
            message: '^Please provide a response',
          },
        }
      }
      return {
        format: {
          pattern: validationPatterns.zipCodePattern,
          message: '^Zip code is invalid',
        },
      }
    },
  })
  // mix custom stuff here w validate.js messages
  return {
    ...messages,
    //
  }
}

function AdvisorMemberInformationEditForm({
  profileData,
  handleSubmit,
  params,
}) {
  const [disabled, toggleDisabled] = useState(true)
  const initialValues = {
    firstName: profileData?.firstName || '',
    lastName: profileData?.lastName || '',
    email: profileData?.email || '',
    advisorTypeId: profileData?.advisorTypeId || '',
    location: profileData?.location || '',
  }

  return (
    <Formik
      onSubmit={handleSubmit}
      validate={validateForm}
      initialValues={initialValues}
    >
      {({ handleReset }) => (
        <Form className="onboarding-form">
          <div className="row">
            <h1>Member Information</h1>
            {disabled ? (
              <button
                className="button-primary-outline"
                onClick={() => {
                  toggleDisabled(false)
                }}
              >
                Edit
              </button>
            ) : (
              <div className="edit-buttons-area">
                <button
                  className="button-primary-outline"
                  onClick={() => {
                    handleReset()
                    toggleDisabled(true)
                  }}
                >
                  Cancel
                </button>
                <SubmitButton>Submit Changes</SubmitButton>
              </div>
            )}
          </div>
          <div className="row">
            <Input name="firstName" type="text" disabled={disabled} />
            <Input name="lastName" type="text" disabled={disabled} />
          </div>
          <div className="row">
            <Input
              id="read-only-email"
              name="email"
              type="text"
              disabled={true}
            >
              <div className="email-notice">
                Please email{' '}
                <a href="mailto:membership@firmvalue.com?subject=Change my email address">
                  membership@firmvalue.com
                </a>
                {` to update your email address`}
              </div>
            </Input>
            <Select
              name="advisorTypeId"
              label="Type of Advisor"
              options={formHelpers.formatOptions(params.advisorTypes)}
              enablePlaceholderOption={true}
              placeholder=" "
              disabled={true}
              emailNoticeText="advisor type"
            />
          </div>

          <div className="row">
            <Input name="location" type="text" disabled={disabled} />
          </div>
        </Form>
      )}
    </Formik>
  )
}

AdvisorMemberInformationEditForm.propTypes = exact(propTypes)
AdvisorMemberInformationEditForm.defaultProps = defaultProps

export default AdvisorMemberInformationEditForm
AdvisorMemberInformationEditForm
