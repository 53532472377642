import React, { useState } from 'react'
import PropTypes from 'prop-types'
import exact from 'prop-types-exact'
import { Card, ConfirmModal, BlurhashErrorBoundary } from 'components'
import { ConnectionsCardLineItem } from 'components/connections'
import { Button } from '@launchpadlab/lp-components'
import * as api from 'api'
import { Blurhash } from 'react-blurhash'

const propTypes = {
  cardData: PropTypes.object,
}

const defaultProps = {}

function ActiveConnectionsCard({ cardData }) {
  const [showModal, toggleShowModal] = useState(false)
  const userId = cardData.userId

  const handleViewProfile = () => {
    window.open(`/users/profile?user=${cardData.userId}`)
    return null
  }

  const handleRemoveConnection = () => {
    api.removeConnection('users/delete-connection', {
      userId: userId,
    })
    window.location.reload(true)
  }

  const handleMessageAddNote = () => {
    api.startConversation('/start-conversation', {
      user: cardData.userId,
    })

    window.location.replace(`/dashboard/messages/${cardData.userConnectionId}`)
  }

  const isAdvisorCard = cardData.profileType === 'AdvisorProfile'
  const isInvestorCard = cardData.profileType === 'InvestorProfile'
  const isBusinessCard = cardData.profileType === 'BusinessProfile'

  const blurredImage = cardData.blurredImage

  return (
    <Card removeTopRow={true}>
      <div className="connections-card-content">
        <div className="connections-display-details">
          {blurredImage ? (
            <BlurhashErrorBoundary width="80px" height="80px">
              <Blurhash
                className="profile-picture"
                hash={cardData.imageUrl}
                width={80}
                height={80}
                resolutionX={32}
                resolutionY={32}
                punch={1}
              />
            </BlurhashErrorBoundary>
          ) : (
            <div className="profile-picture">
              <img src={cardData.imageUrl} alt="User Profile Image" />
            </div>
          )}

          <div className="profile-information">
            <div className="connection-profile-details">
              <h1>{cardData.name}</h1>
              <h4 className="state-subheader">{cardData.state}</h4>
              <div className="two-col-flexbox">
                <div className="left-col">
                  {cardData.yearsOfExperience && (
                    <ConnectionsCardLineItem
                      label={
                        isBusinessCard
                          ? 'Business Age (Years):'
                          : 'Years of Experience:'
                      }
                      value={cardData.yearsOfExperience}
                    />
                  )}
                  <ConnectionsCardLineItem
                    label="Member Status:"
                    value={cardData.membershipStatus}
                  />
                  <ConnectionsCardLineItem
                    label="Total Connections:"
                    value={cardData.totalConnections}
                  />
                </div>
                {isAdvisorCard && (
                  <div className="right-col">
                    <ConnectionsCardLineItem
                      label="Advisory Firm: "
                      value={cardData.advisoryFirm}
                    ></ConnectionsCardLineItem>
                    <ConnectionsCardLineItem
                      label="Title: "
                      value={cardData.title}
                    ></ConnectionsCardLineItem>
                    <ConnectionsCardLineItem
                      label="Clients Served: "
                      value={cardData.clientsServed}
                    ></ConnectionsCardLineItem>
                  </div>
                )}
                {isInvestorCard && (
                  <div className="right-col">
                    <ConnectionsCardLineItem
                      label="Accredited Investor: "
                      value={cardData.accreditedInvestor ? 'Yes' : 'No'}
                    ></ConnectionsCardLineItem>
                    <ConnectionsCardLineItem
                      label="Business Location Criteria Match: "
                      value={cardData.locationCriteriaMatch}
                    ></ConnectionsCardLineItem>
                    <ConnectionsCardLineItem
                      label="Sector Criteria Match: "
                      value={cardData.sectorCriteriaMatch}
                    ></ConnectionsCardLineItem>
                  </div>
                )}
                {isBusinessCard && (
                  <div className="right-col">
                    <ConnectionsCardLineItem
                      label="Primary Industry: "
                      value={cardData.primaryIndustry}
                    ></ConnectionsCardLineItem>
                    <ConnectionsCardLineItem
                      label="Business Model(s): "
                      value={cardData.businessModel}
                    ></ConnectionsCardLineItem>
                    <ConnectionsCardLineItem
                      label="Business Stage: "
                      value={cardData.businessStage}
                    ></ConnectionsCardLineItem>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
        <div className="connections-buttons">
          <div className="main-buttons">
            <Button onClick={handleViewProfile} className="connections-button">
              View Profile
            </Button>
            <Button
              onClick={() => handleMessageAddNote()}
              className="connections-button button-primary-outline"
            >
              Message/Add Notes
            </Button>
          </div>
          <a
            onClick={() => {
              toggleShowModal(true)
            }}
          >
            Remove
          </a>
          <ConfirmModal
            onConfirm={handleRemoveConnection}
            confirmHeader="Are you sure you want to remove connection?"
            confirmText="Remove"
            showModal={showModal}
            toggleShowModal={toggleShowModal}
          />
        </div>
      </div>
    </Card>
  )
}

ActiveConnectionsCard.propTypes = exact(propTypes)
ActiveConnectionsCard.defaultProps = defaultProps

export default React.memo(ActiveConnectionsCard)
