import React from 'react'
// import PropTypes from 'prop-types'
import exact from 'prop-types-exact'

import { ProfileSectionLineItem } from 'components/profiles'
import { isHumanizedBooleanValue, replaceNullValueWithPlaceholder } from 'utils'

const propTypes = {}

const defaultProps = {}

function BPFinancialCriteriaProfileSection({ sectionVisible, userData }) {
  const isViewerAdvisor = userData?.viewerProfileType === 'AdvisorProfile'
  if (sectionVisible) {
    if (isViewerAdvisor) {
      return (
        <div className="profile-section-wrapper centered-content">
          <h1>Acquisition Financial Criteria</h1>
          <div className="profile-view-section ">
            <div className="profile-view-section-col">
              <ProfileSectionLineItem
                label="Annual Operating Income"
                value={replaceNullValueWithPlaceholder(
                  userData.consideredEbits
                )}
                isCriteriaMatch={isHumanizedBooleanValue(
                  userData.consideredEbits
                )}
              />
            </div>
            <div className="profile-view-section-col"></div>
          </div>
        </div>
      )
    }

    return (
      <div className="profile-section-wrapper centered-content">
        <h1>Acquisition Financial Criteria</h1>
        <div className="profile-view-section ">
          <div className="profile-view-section-col">
            <ProfileSectionLineItem
              label="Annual Revenue"
              value={replaceNullValueWithPlaceholder(
                userData?.consideredAnnualRevenues
              )}
              isCriteriaMatch={isHumanizedBooleanValue(
                userData.consideredAnnualRevenues
              )}
            />
            <ProfileSectionLineItem
              label="Annual EBITDA"
              value={replaceNullValueWithPlaceholder(
                userData?.consideredEbitdas
              )}
              isCriteriaMatch={isHumanizedBooleanValue(
                userData.consideredEbitdas
              )}
            />
          </div>
          <div className="profile-view-section-col">
            <ProfileSectionLineItem
              label="Annual Operating Income"
              value={replaceNullValueWithPlaceholder(userData.consideredEbits)}
              isCriteriaMatch={isHumanizedBooleanValue(
                userData.consideredEbits
              )}
            />
            <ProfileSectionLineItem
              label="Current Ownership"
              value={replaceNullValueWithPlaceholder(
                userData?.consideredOwnerships
              )}
              isCriteriaMatch={isHumanizedBooleanValue(
                userData.consideredOwnerships
              )}
            />
          </div>
        </div>
      </div>
    )
  } else {
    return <></>
  }
}

BPFinancialCriteriaProfileSection.propTypes = exact(propTypes)
BPFinancialCriteriaProfileSection.defaultProps = defaultProps

export default React.memo(BPFinancialCriteriaProfileSection)
