import React from 'react'
import PropTypes from 'prop-types'
import exact from 'prop-types-exact'
import {
  withFormikAdapterAndVisibilityInput,
  validate,
  formHelpers,
} from '../utils'
import {
  DropdownCheckboxGroup as BaseDropownCheckboxGroup,
  ImageUploadPreview,
  Tooltip,
} from 'components'
import {
  Select as BaseSelectInput,
  Textarea as BaseTextarea,
  Checkbox as BaseCheckbox,
  Button,
  SubmitButton,
} from '@launchpadlab/lp-components'
import { Formik, Form } from 'formik'

const DropdownCheckboxGroup = withFormikAdapterAndVisibilityInput()(
  BaseDropownCheckboxGroup
)
const Select = withFormikAdapterAndVisibilityInput()(BaseSelectInput)
const Textarea = withFormikAdapterAndVisibilityInput()(BaseTextarea)
const Checkbox = withFormikAdapterAndVisibilityInput()(BaseCheckbox)

const propTypes = {
  params: PropTypes.object.isRequired,
  formValues: PropTypes.object.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  handleBack: PropTypes.func.isRequired,
}

const defaultProps = {}

// Add an extra custom validation for the hidden spam input.
function validateForm(values) {
  const defaultSectorIconSelected = values.defaultImage

  const messages = validate(values, {
    annualRevenueId: {
      presence: { allowEmpty: false, message: '^Please select one' },
    },
    annualEbitdaId: {
      presence: { allowEmpty: false, message: '^Please select one' },
    },
    annualEbitId: {
      presence: { allowEmpty: false, message: '^Please select one' },
    },
    businessCurrentOwnerships: {
      presence: { allowEmpty: false },
    },
    businessSpecialDesignations: {
      presence: {
        allowEmpty: false,
        message: '^Please select one',
      },
    },
    meetsSba: {
      presence: {
        allowEmpty: false,
        message: '^Please select one',
      },
    },
    businessLocations: {
      presence: { allowEmpty: false },
    },
    description: {
      presence: { allowEmpty: true },
    },
    membershipStatusId: {
      presence: {
        allowEmpty: false,
        message: '^Please select one',
      },
    },
    defaultImage: function (value, attributes) {
      if (attributes.profileImageUrl === '') {
        return { presence: { allowEmpty: false } }
      } else {
        return { presence: { allowEmpty: true } }
      }
    },
    profileImageUrl: function () {
      if (defaultSectorIconSelected) {
        return
      } else {
        return {
          presence: { allowEmpty: false, message: '^Profile image required' },
        }
      }
    },
  })
  // mix custom stuff here w validate.js messages
  return {
    ...messages,
    //
  }
}

function BusinessPage2Form({ params, formValues, handleSubmit, handleBack }) {
  const linkToExternal = () => {
    window.open(
      'https://www.sba.gov/document/support--table-size-standards',
      '_blank'
    )
  }

  function getDefaultImageUrl() {
    return params.sectorsIndustries.find((obj) => {
      return obj.id === parseInt(formValues?.primarySectorId)
    })?.sectorImage
  }

  return (
    <Formik
      onSubmit={(values) => handleSubmit(values, true)}
      validate={validateForm}
      initialValues={formValues}
    >
      {({ setFieldValue, setFieldTouched, values, errors, touched }) => (
        <Form className="onboarding-form setup-form">
          <h1>Financial Overview</h1>
          <p className="disclaimer">
            Financial ranges are <span id="underline">not</span> publicized to
            other members. Members only see whether or not their criteria
            matches instead of actual ranges selected, unless you choose to do
            so in your profile settings.
          </p>
          <div className="row">
            <Select
              name="annualRevenueId"
              label="Annual Revenue"
              tooltip={
                <Tooltip
                  name="annualRevenueId"
                  text="Please select the applicable range of your most recent annual revenue"
                />
              }
              options={formHelpers.formatOptions(params.financialMetrics)}
              enablePlaceholderOption={true}
              placeholder=" "
            />
            <Select
              name="annualEbitdaId"
              label="Annual EBITDA"
              tooltip={
                <Tooltip
                  name="annualEbitdaId"
                  text="Defined as most recent annual net income + taxes + interest + depreciation & amortization"
                />
              }
              options={formHelpers.formatOptions(params.financialMetrics)}
              enablePlaceholderOption={true}
              placeholder=" "
            />
          </div>
          <div className="row">
            <Select
              name="annualEbitId"
              label="Annual Operating Income"
              tooltip={
                <Tooltip
                  name="annualEbitId"
                  text="Defined as most recent annual net income + taxes + interest"
                />
              }
              options={formHelpers.formatOptions(params.financialMetrics)}
              enablePlaceholderOption={true}
              placeholder=" "
            />
            <DropdownCheckboxGroup
              name="businessCurrentOwnerships"
              label="Current Ownership"
              options={formHelpers.formatOptions(params.currentOwnerships)}
              values={values}
              setFieldValue={setFieldValue}
              setFieldTouched={setFieldTouched}
              errorMessage="Please select all that apply"
              tags={true}
            />
          </div>
          <h1>Additional Details</h1>
          <div className="row">
            <DropdownCheckboxGroup
              name="businessSpecialDesignations"
              tooltip={
                <Tooltip
                  name="businessSpecialDesignations"
                  text="Please select all that apply"
                />
              }
              label="Special Designation(s)"
              options={formHelpers.formatOptions(params.specialDesignations)}
              values={values}
              setFieldValue={setFieldValue}
              setFieldTouched={setFieldTouched}
              tags={true}
              allowNoneValue
            />
            <Select
              name="meetsSba"
              label="Are you a U.S. small business?"
              tooltip={
                <Tooltip
                  name="meetsSba"
                  text={[
                    'Do you meet SBA size standards? Please refer ',
                    {
                      key: 1,
                      value: (
                        <span className="tooltip-link" onClick={linkToExternal}>
                          here
                        </span>
                      ),
                    },
                    ' for additional information',
                  ]}
                />
              }
              options={[
                { key: 'Yes', value: true },
                { key: 'No', value: false },
              ]}
              enablePlaceholderOption={true}
              placeholder=" "
            />
          </div>
          <div className="row">
            <DropdownCheckboxGroup
              name="businessLocations"
              label="Additional Physical Locations"
              tooltip={
                <Tooltip
                  name="businessLocations"
                  text="Please select all regions with additional locations beyond your company headquarters"
                />
              }
              options={formHelpers.formatOptions(params.territoriesStates)}
              values={values}
              setFieldValue={setFieldValue}
              setFieldTouched={setFieldTouched}
              errorMessage="Please select all that apply"
              tags={true}
              allowNoneValue
            />
          </div>
          <div className="row">
            <Textarea
              className="text-area"
              name="description"
              label="Brief Description of Your Business "
              hint=" (Shown to Other Members)"
              maxLength={500}
            />
            <p className="disclaimer description-disclaimer">
              Only post factual information about yourself, your company, and/or
              your investment fund. Do not include any forward-looking
              statements, including, but not limited to (i) projections or
              predictions about future performance of your company and/or
              investments, and (ii) forecasts or opinions about the future value
              of your company’s securities and/or investments.
            </p>
          </div>
          <h1>Capital Exploration Overview</h1>
          <div className="row">
            <Select
              name="membershipStatusId"
              label="Membership Status"
              options={formHelpers.formatOptions(params.membershipStatuses)}
              enablePlaceholderOption={true}
              placeholder=" "
            />
          </div>
          <h1>Profile Image</h1>
          <div className="row">
            <div className="column">
              <Checkbox
                name="defaultImage"
                label="Use Default Sector Icon"
                onChange={(e) => {
                  if (e.target.value === true) {
                    setFieldValue('profileImageUrl', '')
                  }
                }}
              />
            </div>
            <div className="column">
              {values.defaultImage ? (
                <div className="image-upload-preview">
                  <img
                    src={getDefaultImageUrl()}
                    alt="Default Sector Image"
                    style={{ width: '60px' }}
                  />
                </div>
              ) : (
                <div className="profile-image-error">
                  <ImageUploadPreview
                    name="profileImageUrl"
                    hasStoredValue={values.profileImageUrl}
                  />
                </div>
              )}
            </div>
          </div>
          <p className="disclaimer submission-disclaimer">
            By clicking submit you certify that you have only posted factual
            information about yourself, your company, and/or your investment
            fund.
          </p>
          <div className="submission-error-wrapper">
            <div className="button-area">
              <Button
                className="form-button button-secondary"
                onClick={() => handleBack(values)}
              >
                Back
              </Button>
              <SubmitButton className="form-button submit">Submit</SubmitButton>
            </div>
            <div className="error-message-submission">
              {values.defaultImage &&
              Object.keys(errors).length === 1 &&
              Object.keys(errors).includes('profileImageUrl')
                ? null
                : Object.keys(touched).some((key) => {
                    return Object.keys(errors).includes(key)
                  })
                ? 'Please fix the errors presented above'
                : null}
            </div>
          </div>
        </Form>
      )}
    </Formik>
  )
}

BusinessPage2Form.propTypes = exact(propTypes)
BusinessPage2Form.defaultProps = defaultProps

export default BusinessPage2Form
