import React, { useState, useEffect, useRef } from 'react'
import PropTypes from 'prop-types'
import exact from 'prop-types-exact'
import {
  withFormikAdapterAndVisibilityInput,
  validate,
  formHelpers,
  getIndustriesByAllSelectedSectors,
} from '../utils'
import {
  Select as BaseSelectInput,
  SubmitButton,
  Spinner,
} from '@launchpadlab/lp-components'
import {
  DropdownCheckboxGroup as BaseDropownCheckboxGroup,
  CurrencyInput as BaseCurrencyInput,
} from 'components'
import { startCase } from 'lodash'

import { BusinessCapitalExplorationAdvisorEmailForm } from 'forms'

import { Formik, Form } from 'formik'

import * as api from 'api'

const Select = withFormikAdapterAndVisibilityInput()(BaseSelectInput)
const DropdownCheckboxGroup = withFormikAdapterAndVisibilityInput()(
  BaseDropownCheckboxGroup
)
const CurrencyInput = withFormikAdapterAndVisibilityInput()(BaseCurrencyInput)

const propTypes = {
  params: PropTypes.object,
  handleSubmit: PropTypes.func,
}

const defaultProps = {}

const formatOptionsToStartCase = (options) => {
  const result = []
  options.forEach((option) => {
    let formattedOption = {
      key: startCase(option.key),
      value: option.value,
    }
    result.push(formattedOption)
  })
  return result
}

function BusinessCapitalExplorationEditForm({ params, handleSubmit }) {
  const [disabled, toggleDisabled] = useState(true)
  const [storedData, setStoredData] = useState()
  const [seekingStatus, setSeekingStatus] = useState(storedData?.seekingStatus)
  const [serverError, setServerError] = useState(null)

  const innerFormRef = useRef()

  useEffect(() => {
    async function fetchData() {
      setStoredData(await api.getStoredData('/business/capital-exploration'))
    }
    fetchData()
  }, [seekingStatus])

  useEffect(() => {
    setSeekingStatus(storedData?.seekingStatus)
  }, [storedData])

  const initialValues = {
    membershipStatusId: storedData?.membershipStatusId || '',
    seekingStatus: storedData?.seekingStatus || '',

    // acquisition
    businessConsideredPrimarySectors:
      storedData?.businessConsideredPrimarySectors || [],
    businessConsideredPrimaryIndustries:
      storedData?.businessConsideredPrimaryIndustries || [],
    businessConsideredLocations: storedData?.businessConsideredLocations || [],
    businessConsideredBusinessModels:
      storedData?.businessConsideredBusinessModels || [],
    // businessConsideredServedSectors:
    //   storedData?.businessConsideredServedSectors || [],
    // businessConsideredServedLocations:
    //   storedData?.businessConsideredServedLocations || [],
    // businessConsideredAges: storedData?.businessConsideredAges || [],
    businessConsideredBusinessStages:
      storedData?.businessConsideredBusinessStages || [],
    // businessConsideredEmployeeSizes:
    //   storedData?.businessConsideredEmployeeSizes || [],
    // businessConsideredAnnualRevenues:
    //   storedData?.businessConsideredAnnualRevenues || [],
    // businessConsideredEbitdas: storedData?.businessConsideredEbitdas || [],
    // businessConsideredEbits: storedData?.businessConsideredEbits || [],
    minConsideredAnnualRevenueId:
      storedData?.minConsideredAnnualRevenueId || '',
    maxConsideredAnnualRevenueId:
      storedData?.maxConsideredAnnualRevenueId || '',
    minConsideredAnnualEbitdaId: storedData?.minConsideredAnnualEbitdaId || '',
    maxConsideredAnnualEbitdaId: storedData?.maxConsideredAnnualEbitdaId || '',
    minConsideredAnnualEbitId: storedData?.minConsideredAnnualEbitId || '',
    maxConsideredAnnualEbitId: storedData?.maxConsideredAnnualEbitId || '',
    consideredAnnualRevenuesVisibility:
      storedData?.consideredAnnualRevenuesVisibility || false,
    consideredEbitdasVisibility:
      storedData?.consideredEbitdasVisibility || false,
    consideredEbitsVisibility: storedData?.consideredEbitsVisibility || false,

    businessConsideredOwnerships:
      storedData?.businessConsideredOwnerships || [],
    businessConsideredOfferingTypes:
      storedData?.offeringTypePotentiallyConsidered ||
      storedData?.businessConsideredOfferingTypes ||
      [],

    consideredPrimarySectorsVisibility:
      storedData?.consideredPrimarySectorsVisibility || false,
    consideredPrimaryIndustriesVisibility:
      storedData?.consideredPrimaryIndustriesVisibility || false,
    consideredLocationsVisibility:
      storedData?.consideredLocationsVisibility || false,
    consideredBusinessModelsVisibility:
      storedData?.consideredBusinessModelsVisibility || false,
    consideredServedSectorsVisibility:
      storedData?.consideredServedSectorsVisibility || false,
    consideredServedLocationsVisibility:
      storedData?.consideredServedLocationsVisibility || false,
    consideredAgesVisibility: storedData?.consideredAgesVisibility || false,
    consideredBusinessStagesVisibility:
      storedData?.consideredBusinessStagesVisibility || false,
    // consideredEmployeeSizesVisibility:
    //   storedData?.consideredEmployeeSizes || false,

    consideredOwnershipsVisibility:
      storedData?.consideredOwnershipsVisibility || false,
    consideredOfferingTypesVisibility:
      storedData?.consideredOfferingTypesVisibility || false,

    // capital
    subformik: {
      advisorEmail: storedData?.advisorEmail || '',
    },
    targetCapitalRaise: storedData?.targetCapitalRaise || '',
    targetCapitalRaiseNotDisclosed:
      storedData?.targetCapitalRaiseNotDisclosed || false,
    businessCapitalNeedReasons: storedData?.reasonForCapitalNeed || [],
    businessLikelyEquityStakes: storedData?.likelyEquityOffered || [],
    businessLikelyEquityStakesNotDisclosed:
      storedData?.businessLikelyEquityStakesNotDisclosed || false,
    businessConsideredInvestorTypes:
      storedData?.investorTypePotentiallyConsidered || [],
    businessPostInvestmentIntentions:
      storedData?.intentionPostInvestmentConsidered || [],
  }

  const initialAdvisorEmailValue = {
    advisorEmail: storedData?.advisorEmail || '',
  }

  // Add an extra custom validation for the hidden spam input.
  function validateForm(values) {
    const capitalValidation = (name) => {
      if (values.seekingStatus === 'seeking_capital') {
        if (name === 'Target capital raise') {
          if (values.targetCapitalRaiseNotDisclosed) {
            return {}
          }

          if (values.targetCapitalRaise === '') {
            return {
              presence: {
                allowEmpty: false,
                message: name && `^Please provide a response`,
              },
            }
          }

          return {
            numericality: {
              greaterThan: 0,
              message: '^Offering amount must be greater than 0',
            },
          }
        }

        if (name === 'businessLikelyEquityStakes') {
          if (values.businessLikelyEquityStakesNotDisclosed) {
            return {}
          }
          return {
            presence: {
              allowEmpty: false,
              message: '^Please select one',
            },
          }
        }
        return {
          presence: {
            allowEmpty: false,
            message: `^Please select one`,
          },
        }
      }
      return {}
    }

    const acquisitionValidation = () => {
      if (values.seekingStatus === 'seeking_acquisitions')
        return {
          presence: {
            allowEmpty: false,
            message: `^Please select one`,
          },
        }
      return {}
    }

    const offerTypeValidation = () => {
      if (values.seekingStatus === '') {
        return {}
      }
      return { presence: { allowEmpty: false } }
    }

    const messages = validate(values, {
      membershipStatusId: {
        presence: {
          allowEmpty: false,
          message: '^Please select one',
        },
      },
      seekingStatus: {
        presence: { allowEmpty: true },
      },
      // Seeking Capital
      businessConsideredOfferingTypes: offerTypeValidation,
      targetCapitalRaise: capitalValidation('Target capital raise'),
      businessCapitalNeedReasons: capitalValidation,
      businessConsideredInvestorTypes: capitalValidation,
      businessPostInvestmentIntentions: capitalValidation,
      businessLikelyEquityStakes: capitalValidation(
        'businessLikelyEquityStakes'
      ),

      // Seeking Acquisition
      businessConsideredPrimarySectors: acquisitionValidation,
      businessConsideredPrimaryIndustries: acquisitionValidation,
      businessConsideredLocations: acquisitionValidation,
      businessConsideredBusinessModels: acquisitionValidation,
      // businessConsideredServedSectors: acquisitionValidation,
      // businessConsideredServedLocations: acquisitionValidation,
      // businessConsideredAges: acquisitionValidation,
      businessConsideredBusinessStages: acquisitionValidation,
      // businessConsideredEmployeeSizes: acquisitionValidation,

      minConsideredAnnualRevenueId: acquisitionValidation,
      maxConsideredAnnualRevenueId: acquisitionValidation,
      minConsideredAnnualEbitdaId: acquisitionValidation,
      maxConsideredAnnualEbitdaId: acquisitionValidation,
      minConsideredAnnualEbitId: acquisitionValidation,
      maxConsideredAnnualEbitId: acquisitionValidation,
      // businessConsideredAnnualRevenues: acquisitionValidation,
      // businessConsideredEbitdas: acquisitionValidation,
      // businessConsideredEbits: acquisitionValidation,
      businessConsideredOwnerships: acquisitionValidation,
    })

    // mix custom stuff here w validate.js messages
    return {
      ...messages,
      //
    }
  }

  if (!storedData) {
    return <Spinner />
  }

  function formatCurrencyStringToNumber(currString) {
    return parseFloat(currString.replace(/,/g, ''))
  }

  // advisor connection details
  const hasActiveAdvisorConnection = storedData?.active
    ? storedData?.active
    : false
  const hasPendingAdvisorConnection =
    storedData?.active === false &&
    storedData?.advisorEmail &&
    storedData?.advisorEmail.length > 1
      ? storedData?.advisorEmail
      : null
  const advisorConnectionId = storedData?.advisorConnection

  return (
    <Formik
      onSubmit={handleSubmit}
      validate={validateForm}
      initialValues={initialValues}
    >
      {({ values, setFieldValue, setFieldTouched, handleReset }) => (
        <Form className="onboarding-form">
          {useEffect(() => {
            setSeekingStatus(values.seekingStatus)
          }, [values.seekingStatus])}
          <div className="row">
            <h1>Capital Exploration</h1>
            {disabled ? (
              <button
                className="button-primary-outline"
                onClick={() => {
                  toggleDisabled(false)
                }}
              >
                Edit
              </button>
            ) : (
              <div className="edit-buttons-area">
                <button
                  className="button-primary-outline"
                  onClick={() => {
                    handleReset()
                    innerFormRef.current.resetForm()
                    setServerError(null)
                    toggleDisabled(true)
                  }}
                >
                  Cancel
                </button>
                <SubmitButton>Submit Changes</SubmitButton>
              </div>
            )}
          </div>
          <div className="row">
            <Select
              name="membershipStatusId"
              label="Membership Status"
              options={formHelpers.formatOptions(params.membershipStatuses)}
              enablePlaceholderOption={true}
              placeholder=" "
              disabled={disabled}
            />
            <Select
              name="seekingStatus"
              label="Currently Seeking Capital or Acquisitions?"
              options={formatOptionsToStartCase(
                formHelpers.formatOptions(params.seekingStatus)
              )}
              enablePlaceholderOption={true}
              placeholder="No"
              disabled={disabled}
              onChange={() => {
                setFieldValue('businessConsideredPrimarySectors', [])
                setFieldValue('businessConsideredPrimaryIndustries', [])
                setFieldValue('businessConsideredLocations', [])
                setFieldValue('businessConsideredBusinessModels', [])
                setFieldValue('businessConsideredServedSectors', [])
                setFieldValue('businessConsideredServedLocations', [])
                setFieldValue('businessConsideredBusinessStages', [])

                // setFieldValue('businessConsideredAnnualRevenues', [])
                // setFieldValue('businessConsideredEbitdas', [])
                // setFieldValue('businessConsideredEbits', [])
                setFieldValue('minConsideredAnnualRevenueId', '')
                setFieldValue('maxConsideredAnnualRevenueId', '')
                setFieldValue('minConsideredAnnualEbitdaId', '')
                setFieldValue('maxConsideredAnnualEbitdaId', '')
                setFieldValue('minConsideredAnnualEbitId', '')
                setFieldValue('maxConsideredAnnualEbitId', '')
                setFieldValue('businessConsideredOwnerships', [])
                setFieldValue('businessConsideredOfferingTypes', [])
                setFieldValue('consideredAnnualRevenuesVisibility', false)
                setFieldValue('consideredEbitdasVisibility', false)
                setFieldValue('consideredEbitsVisibility', false)

                setFieldValue('consideredPrimarySectorsVisibility', false)
                setFieldValue('consideredPrimaryIndustriesVisibility', false)
                setFieldValue('consideredLocationsVisibility', false)
                setFieldValue('consideredBusinessModelsVisibility', false)
                setFieldValue('consideredServedSectorsVisibility', false)
                setFieldValue('consideredServedLocationsVisibility', false)
                setFieldValue('consideredAgesVisibility', false)
                setFieldValue('consideredBusinessStagesVisibility', false)
                setFieldValue('consideredOwnershipsVisibility', false)
                setFieldValue('consideredOfferingTypesVisibility', false)

                setFieldValue('targetCapitalRaise', '')
                setFieldValue('targetCapitalRaiseNotDisclosed', false)
                setFieldValue('businessCapitalNeedReasons', [])
                setFieldValue('businessLikelyEquityStakes', [])
                setFieldValue('businessLikelyEquityStakesNotDisclosed', false)
                setFieldValue('businessConsideredInvestorTypes', [])
                setFieldValue('businessPostInvestmentIntentions', [])
              }}
            />
          </div>
          {values.seekingStatus === 'seeking_capital' ? (
            <div className="business-capital-exploration-optional-section">
              <div className="row">
                <BusinessCapitalExplorationAdvisorEmailForm
                  initialAdvisorEmailValue={initialAdvisorEmailValue}
                  disabled={disabled}
                  hasActiveAdvisorConnection={hasActiveAdvisorConnection}
                  hasPendingAdvisorConnection={hasPendingAdvisorConnection}
                  advisorConnectionId={advisorConnectionId}
                  serverError={serverError}
                  setServerError={setServerError}
                  innerFormRef={innerFormRef}
                />
              </div>
              <div className="row secondary-section">
                <DropdownCheckboxGroup
                  name="businessCapitalNeedReasons"
                  label="Purpose of Capital Raise"
                  options={formHelpers.formatOptions(params.capitalNeedReasons)}
                  setFieldValue={setFieldValue}
                  setFieldTouched={setFieldTouched}
                  errorMessage="Please select all that apply"
                  values={values}
                  tags={true}
                  disabled={disabled}
                />
                <DropdownCheckboxGroup
                  name="businessConsideredOfferingTypes"
                  label="Likely Offering Type(s)"
                  options={formHelpers.formatOptions(params.offeringTypes)}
                  setFieldValue={setFieldValue}
                  setFieldTouched={setFieldTouched}
                  errorMessage="Please select all that apply"
                  values={values}
                  tags={true}
                  disabled={disabled}
                />
              </div>
              <div className="row">
                <CurrencyInput
                  className="currency"
                  label="Likely Offering Amount (dollars)"
                  input={{
                    name: 'targetCapitalRaise',
                    value: values.targetCapitalRaise,
                    onChange: (e) =>
                      setFieldValue(
                        'targetCapitalRaise',
                        formatCurrencyStringToNumber(e.target.value)
                      ),
                  }}
                  name="targetCapitalRaise"
                  placeholder="1234"
                  disabled={disabled || values.targetCapitalRaiseNotDisclosed}
                  disclosureDisabled={disabled}
                  hasVisibilityField={true}
                  onVisibilityChange={(e) => {
                    if (e.target.value) {
                      setFieldValue('targetCapitalRaise', null)
                    }
                  }}
                />
                <DropdownCheckboxGroup
                  name="businessLikelyEquityStakes"
                  label="Potential Equity Offered (percentage)"
                  options={formHelpers.formatOptions(params.equityStakes)}
                  setFieldValue={setFieldValue}
                  setFieldTouched={setFieldTouched}
                  errorMessage="Please select all that apply"
                  tags={true}
                  disabled={
                    disabled || values.businessLikelyEquityStakesNotDisclosed
                  }
                  disclosureDisabled={disabled}
                  allowNoneValue={true}
                  hasVisibilityField={true}
                  onVisibilityChange={(e) => {
                    if (e.target.value) {
                      setFieldValue('businessLikelyEquityStakes', [])
                    }
                  }}
                />
              </div>
              <div className="row">
                <DropdownCheckboxGroup
                  name="businessConsideredInvestorTypes"
                  label="Investor Types Considered"
                  options={formHelpers.formatOptions(params.investorTypes)}
                  setFieldValue={setFieldValue}
                  setFieldTouched={setFieldTouched}
                  errorMessage="Please select all that apply"
                  values={values}
                  tags={true}
                  disabled={disabled}
                />
                <DropdownCheckboxGroup
                  name="businessPostInvestmentIntentions"
                  label="Intentions Post-Investment"
                  options={formHelpers.formatOptions(
                    params.postInvestmentIntentions
                  )}
                  setFieldValue={setFieldValue}
                  setFieldTouched={setFieldTouched}
                  errorMessage="Please select all that apply"
                  values={values}
                  tags={true}
                  disabled={disabled}
                />
              </div>
              <p className="disclaimer capital-disclaimer">
                By clicking submit changes, you certify that you have (i)
                consulted with your legal counsel, and/or (ii) fully understand
                how posting your capital raise objectives may implicate certain
                securities laws and regulations.
              </p>
            </div>
          ) : values.seekingStatus === 'seeking_acquisitions' ? (
            <div className="business-capital-exploration-optional-section">
              <h1 className="secondary-header">
                Acquisition Operating Criteria
              </h1>
              <div className="row secondary-section">
                <DropdownCheckboxGroup
                  name="businessConsideredPrimarySectors"
                  label="Primary Sectors"
                  options={formHelpers.formatOptions(params.sectorsIndustries)}
                  setFieldValue={setFieldValue}
                  setFieldTouched={setFieldTouched}
                  errorMessage="Please select all that apply"
                  values={values}
                  tags={true}
                  disabled={disabled}
                  hasVisibilityField={true}
                />
                <DropdownCheckboxGroup
                  name="businessConsideredPrimaryIndustries"
                  label="Primary Industries"
                  options={getIndustriesByAllSelectedSectors(
                    values.businessConsideredPrimarySectors,
                    params
                  )}
                  errorMessage="Please select all that apply"
                  setFieldValue={setFieldValue}
                  setFieldTouched={setFieldTouched}
                  values={values}
                  tags={true}
                  disabled={
                    !values.businessConsideredPrimarySectors.length || disabled
                  }
                  connectedFieldName="businessConsideredPrimarySectors"
                  disabledText="Must Select Sector"
                  hasVisibilityField={false}
                  allowSelectAll={true}
                />
              </div>
              <div className="row">
                <DropdownCheckboxGroup
                  name="businessConsideredLocations"
                  label="Business Locations"
                  options={formHelpers.formatOptions(params.territoriesStates)}
                  errorMessage="Please select all that apply"
                  setFieldValue={setFieldValue}
                  setFieldTouched={setFieldTouched}
                  values={values}
                  disabled={disabled}
                  tags={true}
                  hasVisibilityField={true}
                />
                <DropdownCheckboxGroup
                  name="businessConsideredBusinessModels"
                  label="Business Models"
                  errorMessage="Please select all that apply"
                  options={formHelpers.formatOptions(params.businessModels)}
                  setFieldValue={setFieldValue}
                  setFieldTouched={setFieldTouched}
                  values={values}
                  tags={true}
                  disabled={disabled}
                  hasVisibilityField={true}
                />
              </div>
              <div className="row">
                <DropdownCheckboxGroup
                  name="businessConsideredBusinessStages"
                  label="Business Stages"
                  errorMessage="Please select all that apply"
                  options={formHelpers.formatOptions(params.businessStages)}
                  setFieldValue={setFieldValue}
                  setFieldTouched={setFieldTouched}
                  values={values}
                  tags={true}
                  disabled={disabled}
                  hasVisibilityField={true}
                />
              </div>
              <h1>Acquisition Financial Criteria</h1>
              <div className="row">
                <Select
                  name="minConsideredAnnualRevenueId"
                  label="Minimum Annual Revenue Considered"
                  options={formHelpers.formatOptions(params.financialMetrics)}
                  enablePlaceholderOption={true}
                  placeholder=" "
                  onChange={() => {
                    if (
                      values.minConsideredAnnualRevenueId >
                      values.maxConsideredAnnualRevenueId
                    )
                      setFieldValue('maxConsideredAnnualRevenueId', '')
                  }}
                  disabled={disabled}
                />
                <Select
                  name="maxConsideredAnnualRevenueId"
                  label="Maximium Annual Revenue Considered"
                  options={formHelpers.getMaxOptionsByMinValue(
                    values.minConsideredAnnualRevenueId,
                    params.financialMetrics
                  )}
                  enablePlaceholderOption={true}
                  placeholder={
                    values.minConsideredAnnualRevenueId === ''
                      ? 'Must Select Minimum'
                      : ' '
                  }
                  disabled={
                    disabled || values.minConsideredAnnualRevenueId === ''
                  }
                  hasVisibilityField={true}
                  visibilityName="consideredAnnualRevenues"
                />
              </div>
              <div className="row">
                <Select
                  name="minConsideredAnnualEbitdaId"
                  label="Minimum Annual EBITDA Considered"
                  options={formHelpers.formatOptions(params.financialMetrics)}
                  enablePlaceholderOption={true}
                  placeholder=" "
                  onChange={() => {
                    if (
                      values.minConsideredAnnualEbitdaId >
                      values.maxConsideredAnnualEbitdaId
                    )
                      setFieldValue('maxConsideredAnnualEbitdaId', '')
                  }}
                  disabled={disabled}
                />
                <Select
                  name="maxConsideredAnnualEbitdaId"
                  label="Maximium Annual EBITDA Considered"
                  options={formHelpers.getMaxOptionsByMinValue(
                    values.minConsideredAnnualEbitdaId,
                    params.financialMetrics
                  )}
                  enablePlaceholderOption={true}
                  placeholder={
                    values.minConsideredAnnualEbitdaId === ''
                      ? 'Must Select Minimum'
                      : ' '
                  }
                  disabled={
                    disabled || values.minConsideredAnnualEbitdaId === ''
                  }
                  hasVisibilityField={true}
                  visibilityName="consideredEbitdas"
                />
              </div>
              <div className="row">
                <Select
                  name="minConsideredAnnualEbitId"
                  label="Minimum Annual Operating Income Considered"
                  options={formHelpers.formatOptions(params.financialMetrics)}
                  enablePlaceholderOption={true}
                  placeholder=" "
                  onChange={() => {
                    if (
                      values.minConsideredAnnualEbitId >
                      values.maxConsideredAnnualEbitId
                    )
                      setFieldValue('maxConsideredAnnualEbitId', '')
                  }}
                  disabled={disabled}
                />
                <Select
                  name="maxConsideredAnnualEbitId"
                  label="Maximium Annual Operating Income Considered"
                  options={formHelpers.getMaxOptionsByMinValue(
                    values.minConsideredAnnualEbitId,
                    params.financialMetrics
                  )}
                  enablePlaceholderOption={true}
                  placeholder={
                    values.minConsideredAnnualEbitId === ''
                      ? 'Must Select Minimum'
                      : ' '
                  }
                  disabled={disabled || values.minConsideredAnnualEbitId === ''}
                  hasVisibilityField={true}
                  visibilityName="consideredEbits"
                />
              </div>
              <div className="row">
                <DropdownCheckboxGroup
                  name="businessConsideredOwnerships"
                  label="Current Ownership Types Considered"
                  errorMessage="Please select all that apply"
                  options={formHelpers.formatOptions(params.currentOwnerships)}
                  setFieldValue={setFieldValue}
                  setFieldTouched={setFieldTouched}
                  values={values}
                  tags={true}
                  disabled={disabled}
                  hasVisibilityField={true}
                />
                <DropdownCheckboxGroup
                  name="businessConsideredOfferingTypes"
                  label="Likely Offering Type(s)"
                  errorMessage="Please select all that apply"
                  options={formHelpers.formatOptions(params.offeringTypes)}
                  setFieldValue={setFieldValue}
                  setFieldTouched={setFieldTouched}
                  values={values}
                  tags={true}
                  disabled={disabled}
                  hasVisibilityField={true}
                />
              </div>
            </div>
          ) : (
            () => {
              setSeekingStatus(values.seekingStatus)
            }
          )}
        </Form>
      )}
    </Formik>
  )
}

BusinessCapitalExplorationEditForm.propTypes = exact(propTypes)
BusinessCapitalExplorationEditForm.defaultProps = defaultProps

export default BusinessCapitalExplorationEditForm
