import React, { useState } from 'react'
import PropTypes from 'prop-types'
import exact from 'prop-types-exact'
import { NavLink } from 'react-router-dom'
import * as api from 'api'
import classnames from 'classnames'
import muteIcon from '../../../assets/images/muted.svg'
import unmuteIcon from '../../../assets/images/unmuted.svg'
import DefaultUserIcon from '../../../assets/images/deleted_avatar.svg'
import { Blurhash } from 'react-blurhash'
import { BlurhashErrorBoundary } from 'components'

const propTypes = {
  connection: PropTypes.object,
  isActive: PropTypes.bool,
}

const defaultProps = {}

function MessagesActiveThreadLink({
  connection: {
    id,
    blurredImage,
    removedConnection,
    deleted,
    displayName,
    state,
    user: {
      profile: { profileImageUrl },
    },
    isMuted,
  },
  isActive,
}) {
  const [conversationMuted, setConversationMuted] = useState(isMuted)
  const muteActionName = conversationMuted ? 'Unmute' : 'Mute'
  const muteIconSrc = conversationMuted ? muteIcon : unmuteIcon
  return (
    <div
      className={classnames('thread-link-container', { 'is-active': isActive })}
    >
      <NavLink to={`/messages/${id}`}>
        <div className="side-item">
          <div className="active-thread-link" activeClassName="is-active">
            <div className="active-thread-link-details">
              {!blurredImage ? (
                <div className="profile-picture">
                  <img src={deleted ? DefaultUserIcon : profileImageUrl}></img>
                </div>
              ) : (
                <BlurhashErrorBoundary width="30px" height="30px">
                  <Blurhash
                    className="profile-picture"
                    hash={profileImageUrl}
                    width={30}
                    height={30}
                    resolutionX={32}
                    resolutionY={32}
                    punch={1}
                  />
                </BlurhashErrorBoundary>
              )}
              <span className="profile-settings-label">
                {displayName} | {state?.name ? state.name : 'Unknown'}
              </span>
            </div>
          </div>
        </div>
      </NavLink>
      <div
        className={classnames('active-thread-buttons', {
          disabled: removedConnection || deleted,
        })}
      >
        {removedConnection || deleted ? null : (
          <a
            className="active-thread-link-kabob-button"
            onClick={
              removedConnection || deleted
                ? null
                : (e) => {
                    e.preventDefault()
                    const toggleMute = confirm(
                      `Are you sure you want to ${muteActionName} notifications for this conversation?${
                        !conversationMuted
                          ? '\n\nMuting this conversation will remove notifications of new messages associated with this member unless you unmute the conversation.'
                          : ''
                      }`
                    )
                    if (toggleMute)
                      api
                        .toggleMute({ id: id })
                        .then(({ isMuted }) => setConversationMuted(isMuted))
                  }
            }
          >
            <img
              src={muteIconSrc}
              alt={`${muteActionName} this conversation`}
            />
          </a>
        )}
      </div>
    </div>
  )
}

MessagesActiveThreadLink.propTypes = exact(propTypes)
MessagesActiveThreadLink.defaultProps = defaultProps

export default React.memo(MessagesActiveThreadLink)
