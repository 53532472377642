import React from 'react'
// import PropTypes from 'prop-types'
import exact from 'prop-types-exact'
import { ErrorBoundary } from '../components'
import { HOVMAdvisorProfileView } from 'views'
const propTypes = {}

const defaultProps = {}

function HowOthersViewMeInvestor() {
  return (
    <div className="card">
      <ErrorBoundary>
        <div className="how-others-view-me-page-wrapper">
          <div className="hovm-title">
            <h2>How Others View Me</h2>
          </div>
          <div className="hovm-subtitle">
            All members view your profile as the following
          </div>

          <div className="hovm-view-area">
            <div className="hovm-view-box">
              <HOVMAdvisorProfileView></HOVMAdvisorProfileView>
            </div>
          </div>
        </div>
      </ErrorBoundary>
    </div>
  )
}

HowOthersViewMeInvestor.propTypes = exact(propTypes)
HowOthersViewMeInvestor.defaultProps = defaultProps

export default React.memo(HowOthersViewMeInvestor)
