import React from 'react'
// import PropTypes from 'prop-types'
import exact from 'prop-types-exact'

import Logo from './Logo'
import NavLinks from './NavLinks'
import PropTypes from 'prop-types'

const propTypes = { userSession: PropTypes.object }

const defaultProps = {}

function NavBar({ userSession }) {
  // const [navExpanded, setnavExpanded] = useState(false)
  return (
    <div className="navbar">
      {userSession.loggedIn === true &&
      userSession.hasDashboardAccess === true ? (
        <Logo />
      ) : (
        <a href="https://www.firmvalue.com/">
          <Logo />
        </a>
      )}

      {/* <button
        className={
          'mobile-menu-toggle js-toggle-menu hamburger-menu ' +
          (navExpanded ? 'is-active' : '')
        }
        href="#"
        id="nav-hamburger"
        onClick={() => setnavExpanded(!navExpanded)}
      >
        <span className="menu-item"></span>
        <span className="menu-item"></span>
        <span className="menu-item"></span>
      </button> */}
      {/* <div className={'navigation ' + (navExpanded ? 'is-active' : '')}> */}
      <div className="login-links nav-block">
        <NavLinks userSession={userSession} />
      </div>
      {/* </div> */}
    </div>
  )
}

NavBar.propTypes = exact(propTypes)
NavBar.defaultProps = defaultProps

export default NavBar
