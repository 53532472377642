import React, { useState } from 'react'
import PropTypes from 'prop-types'
import exact from 'prop-types-exact'
import { FileInput as BaseFileInput } from '@launchpadlab/lp-components'
import { withFormikAdapterAndVisibilityInput } from 'utils'
import classnames from 'classnames'

const FileInput = withFormikAdapterAndVisibilityInput()(BaseFileInput)

const propTypes = {
  disabled: PropTypes.bool,
  hasStoredValue: PropTypes.string,
  setFieldValue: PropTypes.func,
}

const defaultProps = {}

// lp-component FileInput didn't return File and FileData on onLoad as expected
// so I built on custom input with functions borrowed from lp-components for base64ing the image
function ImageUploadPreview({ disabled, hasStoredValue, setFieldValue }) {
  const [typeError, setTypeError] = useState(false)
  const acceptedFileTypes = ['image/png', 'image/jpeg']
  return (
    <div className="image-upload-preview">
      <FileInput
        id="fileButton"
        label="Upload from Computer"
        name="profileImageUrl"
        className={classnames('image-upload', {
          disabled: disabled,
        })}
        onLoad={(fileData, file) => {
          if (
            !acceptedFileTypes.includes(file.type) ||
            file.size / 1000 > 5000
          ) {
            setTypeError(true)
            setFieldValue('profileImageUrl', '')
            return
          }
          setTypeError(false)
        }}
        hidePreview={typeError}
        thumbnail={hasStoredValue}
      />
      {typeError && (
        <div style={{ marginTop: '12px' }} className="error-message">
          File must be less than 5MB and a png, jpg, or jpeg
        </div>
      )}
      <div className="profile-image-sub-text">
        Accepted formats include png, jpg, and jpeg. Your file must be less than
        5 MB and will be cropped to a circle when uploaded - square resolutions
        are best.
      </div>
    </div>
  )
}

ImageUploadPreview.propTypes = exact(propTypes)
ImageUploadPreview.defaultProps = defaultProps

export default React.memo(ImageUploadPreview)
