import React, { useState } from 'react'
import PropTypes from 'prop-types'
import exact from 'prop-types-exact'
import {
  withFormikAdapterAndVisibilityInput,
  validate,
  formHelpers,
  validationPatterns,
} from '../utils'
import {
  Input as BaseInput,
  Select as BaseSelectInput,
  SubmitButton,
} from '@launchpadlab/lp-components'
import { Formik, Form } from 'formik'

const Input = withFormikAdapterAndVisibilityInput()(BaseInput)
const Select = withFormikAdapterAndVisibilityInput()(BaseSelectInput)

const propTypes = {
  params: PropTypes.object,
  storedData: PropTypes.object,
  handleSubmit: PropTypes.func,
  profileData: PropTypes.object,
  user: PropTypes.object,
}

const defaultProps = {}

// Add an extra custom validation for the hidden spam input.
function validateForm(values) {
  const messages = validate(values, {
    firstName: {
      presence: { allowEmpty: false, message: '^Please provide a response' },
      type: 'string',
    },
    lastName: {
      presence: { allowEmpty: false, message: '^Please provide a response' },
      type: 'string',
    },
    // email: {
    //   presence: { allowEmpty: false, message: '^Please provide a response' },
    // },
    // investorTypeId: {
    //   presence: { allowEmpty: false, message: '^Please select one' },
    // },
    // investmentFirmName: function (value, attributes) {
    //   if (attributes.investorTypeId === 2) {
    //     return {
    //       presence: {
    //         allowEmpty: false,
    //         message: '^Please provide a response',
    //       },
    //     }
    //   }
    //   return {}
    // },
    location: function (value, attributes) {
      if (attributes.location === '')
        return {
          presence: {
            allowEmpty: false,
            message: '^Please provide a response',
          },
        }
      return {
        format: {
          pattern: validationPatterns.zipCodePattern,
          message: '^%{value} is not a valid zip code.',
        },
      }
    },
  })
  // mix custom stuff here w validate.js messages
  return {
    ...messages,
    //
  }
}

function InvestorMemberInformationEditForm({
  params,
  storedData,
  profileData,
  user,
  handleSubmit,
}) {
  const [disabled, toggleDisabled] = useState(true)
  const initialValues = {
    firstName: user?.firstName || '',
    lastName: user?.lastName || '',
    email: user?.email || '',
    investorTypeId: profileData?.investorTypeId || '',
    investmentFirmName: profileData?.investmentFirmName || '',
    location: storedData?.location || '',
  }

  function isInvestmentFund(values) {
    return parseInt(values.investorTypeId) === 2
  }
  return (
    <Formik
      onSubmit={handleSubmit}
      validate={validateForm}
      initialValues={initialValues}
    >
      {({ values, handleReset }) => (
        <Form className="onboarding-form">
          <div className="row">
            <h1>Member Information</h1>
            {disabled ? (
              <button
                className="button-primary-outline"
                onClick={() => {
                  toggleDisabled(false)
                }}
              >
                Edit
              </button>
            ) : (
              <div className="edit-buttons-area">
                <button
                  className="button-primary-outline"
                  onClick={() => {
                    handleReset()
                    toggleDisabled(true)
                  }}
                >
                  Cancel
                </button>
                <SubmitButton>Submit Changes</SubmitButton>
              </div>
            )}
          </div>
          <div className="row">
            <Input name="firstName" type="text" disabled={disabled} />
            <Input name="lastName" type="text" disabled={disabled} />
          </div>
          <div className="row">
            <Input
              id="read-only-email"
              name="email"
              type="text"
              disabled={true}
            >
              <div className="email-notice">
                Please email{' '}
                <a href="mailto:membership@firmvalue.com?subject=Change my email address">
                  membership@firmvalue.com
                </a>
                {` to update your email address`}
              </div>
            </Input>
            <Select
              name="investorTypeId"
              label="Type Of Investor"
              options={formHelpers.formatOptions(params.investorType)}
              enablePlaceholderOption={true}
              placeholder=" "
              disabled={true}
              emailNoticeText="investor type"
            />
          </div>
          <div className="row">
            <Input
              name="location"
              label="Location (Zip Code)"
              type="text"
              maxLength={10}
              disabled={disabled}
            />
          </div>
          {isInvestmentFund(values) && (
            <Input
              name="investmentFirmName"
              type="text"
              disabled={true}
              emailNoticeText="investment fund name"
            />
          )}
        </Form>
      )}
    </Formik>
  )
}

InvestorMemberInformationEditForm.propTypes = exact(propTypes)
InvestorMemberInformationEditForm.defaultProps = defaultProps

export default InvestorMemberInformationEditForm
