import React from 'react'
import PropTypes from 'prop-types'
import exact from 'prop-types-exact'
import { NavLink } from 'react-router-dom'
import { formatNavSectionName } from 'utils'
import AdditionalDetailsLogo from '../../assets/images/SettingsIcons/additionaldetails.svg'
import CapitalExplorationLogo from '../../assets/images/SettingsIcons/capitalexploration.svg'
import FinancialOverviewLogo from '../../assets/images/SettingsIcons/financialoverview.svg'
import OperationsOverviewLogo from '../../assets/images/SettingsIcons/operationsoverview.svg'
import ProfileImageEditLogo from '../../assets/images/SettingsIcons/profileimage.svg'
import MemberInfoLogo from '../../assets/images/SettingsIcons/memberinfo.svg'
import PasswordLogo from '../../assets/images/SettingsIcons/password.svg'
import HowOthersViewMeLogo from '../../assets/images/SettingsIcons/howothersviewme.svg'
import GeneralOverviewEditLogo from '../../assets/images/SettingsIcons/generaloverview.svg'
import OperatingInvestmentCriteriaEditLogo from '../../assets/images/SettingsIcons/operatinginvestment.svg'
import FinancialInvestmentCriteriaEditLogo from '../../assets/images/SettingsIcons/financialinvestment.svg'
import InvestmentOpportunityCriteriaEditLogo from '../../assets/images/SettingsIcons/investmentopportunity.svg'
import InvestmentExplorationOverviewEditLogo from '../../assets/images/SettingsIcons/investmentexploration.svg'
import AdvisoryOfferingOverviewEditLogo from '../../assets/images/SettingsIcons/advisoryoffering.svg'
import ChangeMembershipEditLogo from '../../assets/images/SettingsIcons/changemembership.svg'

import { kebabCase } from 'lodash'

const propTypes = {
  sections: PropTypes.arrayOf(PropTypes.object),
  profileType: PropTypes.string,
  isConsideringNonFundPartnerships: PropTypes.bool,
}

const defaultProps = {}

const imageMap = {
  capital_exploration: CapitalExplorationLogo,
  operations_overview: OperationsOverviewLogo,
  financial_overview: FinancialOverviewLogo,
  additional_details: AdditionalDetailsLogo,
  profile_image: ProfileImageEditLogo,
  general_overview: GeneralOverviewEditLogo,
  operating_investment_criteria: OperatingInvestmentCriteriaEditLogo,
  financial_investment_criteria: FinancialInvestmentCriteriaEditLogo,
  investment_opportunity_criteria: InvestmentOpportunityCriteriaEditLogo,
  investment_exploration_overview: InvestmentExplorationOverviewEditLogo,
  advisory_offering_overview: AdvisoryOfferingOverviewEditLogo,
}

function ProfileSettingsSubSideNav({
  profileType,
  sections,
  isConsideringNonFundPartnerships,
}) {
  return (
    <nav className="profile-settings-sub-nav">
      <div className="profile-settings-list">
        {profileType === 'businessProfile' && (
          <NavLink
            to={`/edit-profile/general-overview`}
            activeClassName="is-active"
          >
            <div className="side-item">
              <img
                className="settings-nav-logo"
                src={ProfileImageEditLogo}
              ></img>
              <span className="profile-settings-label">General Overview</span>
            </div>
          </NavLink>
        )}
        {sections ? (
          <>
            {sections.map((section, i) => {
              if (section.name === 'financial_investment_criteria') {
                if (!isConsideringNonFundPartnerships) {
                  return
                }
              }
              return (
                <NavLink
                  key={i}
                  to={`/edit-profile/${kebabCase(section.name)}`}
                  activeClassName="is-active"
                >
                  <div className="side-item">
                    <img
                      className="settings-nav-logo"
                      src={imageMap[section.name]}
                    ></img>
                    <span className="profile-settings-label">
                      {formatNavSectionName(section.name)}
                    </span>
                  </div>
                </NavLink>
              )
            })}
            {profileType === 'advisorProfile' && (
              <NavLink
                to={`/edit-profile/status-and-image-selection`}
                activeClassName="is-active"
              >
                <div className="side-item">
                  <img
                    className="settings-nav-logo"
                    src={ProfileImageEditLogo}
                  ></img>
                  <span className="profile-settings-label">
                    Membership Status
                  </span>
                </div>
              </NavLink>
            )}
            <NavLink
              to={`/edit-profile/how-others-view-me`}
              activeClassName="is-active"
            >
              <div className="side-item">
                <img
                  className="settings-nav-logo"
                  src={HowOthersViewMeLogo}
                ></img>
                <span className="profile-settings-label">
                  How Others View Me
                </span>
              </div>
            </NavLink>
          </>
        ) : (
          <>
            <NavLink
              to={`/account-settings/memberInformation`}
              activeClassName="is-active"
            >
              <div className="side-item">
                <img className="settings-nav-logo" src={MemberInfoLogo}></img>
                <span className="profile-settings-label">
                  Member Information
                </span>
              </div>
            </NavLink>
            <NavLink
              to={`/account-settings/password`}
              activeClassName="is-active"
            >
              <div className="side-item">
                <img className="settings-nav-logo" src={PasswordLogo}></img>
                <span className="profile-settings-label">Password</span>
              </div>
            </NavLink>
            <NavLink
              to={`/account-settings/delete-account`}
              activeClassName="is-active"
            >
              <div className="side-item">
                <img
                  className="settings-nav-logo"
                  src={ChangeMembershipEditLogo}
                ></img>
                <span className="profile-settings-label">
                  Change Membership
                </span>
              </div>
            </NavLink>
          </>
        )}
      </div>
    </nav>
  )
}

ProfileSettingsSubSideNav.propTypes = exact(propTypes)
ProfileSettingsSubSideNav.defaultProps = defaultProps

export default React.memo(ProfileSettingsSubSideNav)
