import React, { useState } from 'react'
import PropTypes from 'prop-types'
import exact from 'prop-types-exact'
import {
  withFormikAdapterAndVisibilityInput,
  validate,
  validationPatterns,
} from '../utils'

import { Input as BaseInput } from '@launchpadlab/lp-components'

import { Formik, Form } from 'formik'

import * as api from 'api'

const Input = withFormikAdapterAndVisibilityInput()(BaseInput)

const propTypes = {
  initialAdvisorEmailValue: PropTypes.object,
  disabled: PropTypes.bool,
  hasActiveAdvisorConnection: PropTypes.bool,
  hasPendingAdvisorConnection: PropTypes.string,
  advisorConnectionId: PropTypes.number,
  serverError: PropTypes.string,
  setServerError: PropTypes.func,
  innerFormRef: PropTypes.object,
}

const defaultProps = {}

function BusinessCapitalExplorationAdvisorEmailForm({
  initialAdvisorEmailValue,
  disabled,
  hasPendingAdvisorConnection,
  hasActiveAdvisorConnection,
  advisorConnectionId,
  serverError,
  setServerError,
  innerFormRef,
}) {
  const [pendingRequestEmail, setPendingRequestEmail] = useState(
    hasPendingAdvisorConnection
  )
  const [activeAdvisorConnection, setActiveAdvisorConnection] = useState(
    hasActiveAdvisorConnection
  )

  const [submissionMessage, setSubmissionMessage] = useState(
    hasPendingAdvisorConnection ? 'Successfully submitted advisor email' : null
  )

  const handleAdvisorEmailSubmit = (values, actions) => {
    const params = {
      email: values.advisorEmail.toLowerCase(),
    }
    setSubmissionMessage(null)

    api
      .sendAdvisorConnectionEmail('/advisor-request-connection', params)
      .then(function (response) {
        setServerError(null)
        setPendingRequestEmail(values.advisorEmail.toLowerCase())
        setSubmissionMessage(
          'Your Advisor will receive a confirmation email shortly'
        )
        return response
      })
      .catch(function (error) {
        actions.resetForm()

        if (error.response) {
          // console.log(error.response.data);
          // console.log(error.response.status);
          setServerError(error.response.data)
        }
      })

    actions.setSubmitting(false)
  }

  const handleCancelAdvisorConnectionRequest = (values, actions) => {
    const params = {
      advisor_connection_id: advisorConnectionId,
    }

    setSubmissionMessage(null)

    api
      .cancelAdvisorConnectionRequest('/advisor-delete-connection', params)
      .then(function () {
        setServerError(null)
        setPendingRequestEmail(null)
        setActiveAdvisorConnection(false)
        actions.setFieldValue({ advisorEmail: '' })
        setSubmissionMessage('Advisor email request cancelled')
      })
      .catch(function (error) {
        if (error.response) {
          // console.log(error.response.data);
          // console.log(error.response.status);
          setServerError(error.response.data)
        }
      })

    actions.setSubmitting(false)
  }

  const handleRemoveAdvisorConnection = (values, actions) => {
    const params = {
      advisor_connection_id: advisorConnectionId,
    }

    setSubmissionMessage(null)

    api
      .cancelAdvisorConnectionRequest('/advisor-delete-connection', params)
      .then(function () {
        setServerError(null)
        setPendingRequestEmail(null)
        setActiveAdvisorConnection(false)
        actions.setFieldValue({ advisorEmail: '' })
        setSubmissionMessage('Advisor connection successfully removed')
      })
      .catch(function (error) {
        if (error.response) {
          // console.log(error.response.data);
          // console.log(error.response.status);
          setServerError(error.response.data)
        }
      })

    actions.setSubmitting(false)
  }

  function validateAdvisorEmail(values) {
    setServerError(null)
    const messages = validate(values, {
      advisorEmail: function (value, attributes) {
        if (attributes.advisorEmail === '') {
          return {
            presence: {
              allowEmpty: false,
              message: '^Enter a valid advisor email to create connection',
            },
          }
        } else {
          {
            return {
              format: {
                pattern: validationPatterns.emailPattern,
                message: '^%{value} is not a valid email.',
              },
            }
          }
        }
      },
    })

    return {
      ...messages,
    }
  }

  const optionalText = (
    <>
      Advisor's Email Address
      {<i className="optional-italic">Optional</i>}
    </>
  )

  return (
    <Formik
      initialValues={initialAdvisorEmailValue}
      onSubmit={(values, actions) => {
        if (pendingRequestEmail) {
          actions.setFieldValue('advisorEmail', '')
          handleCancelAdvisorConnectionRequest(values, actions)
        }

        if (activeAdvisorConnection) {
          actions.setFieldValue('advisorEmail', '')
          handleRemoveAdvisorConnection(values, actions)
        }

        if (!pendingRequestEmail && !activeAdvisorConnection) {
          handleAdvisorEmailSubmit(values, actions)
        }
      }}
      validate={validateAdvisorEmail}
      validateOnChange={false}
      validateOnBlur={false}
      validateOnMount={false}
      ref={innerFormRef}
      enableReinitialize
    >
      {(subformik) => (
        <Form>
          <Input
            name="advisorEmail"
            label={optionalText}
            value={subformik.values.advisorEmail}
            disabled={
              disabled || activeAdvisorConnection || pendingRequestEmail
            }
            disableButtons={disabled}
            placeholder="Enter email here"
            submitting={subformik.isSubmitting}
            serverError={serverError}
            submissionMessage={submissionMessage}
            hasActiveAdvisorConnection={activeAdvisorConnection}
            pendingRequestEmail={pendingRequestEmail}
          />
          <p className="disclaimer advisor-email-disclaimer">
            If you are being represented by an advisor and would like them to
            field inquiries related to your capital objectives, please provide
            the advisor’s email address here. Your advisor’s name will show in
            your profile and a link to your business will appear in their
            profile.
          </p>
          {/** i also have access to formik here too */}
        </Form>
      )}
    </Formik>
  )
}

BusinessCapitalExplorationAdvisorEmailForm.propTypes = exact(propTypes)
BusinessCapitalExplorationAdvisorEmailForm.defaultProps = defaultProps

export default BusinessCapitalExplorationAdvisorEmailForm
