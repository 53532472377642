import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import exact from 'prop-types-exact'
import { CarouselRow } from 'components/carousels'
import * as api from 'api'

const propTypes = {
  user: PropTypes.object,
}

const defaultProps = {}

function AdvisorCarouselArea({ user }) {
  const [carouselCards, setCarouselCards] = useState()
  const [loading, setLoading] = useState(false)

  useEffect(() => {
    async function fetchCarouselCards() {
      setLoading(true)
      setCarouselCards(await api.fetchCarouselCards('/advisor/carousels'))
      setLoading(false)
    }
    fetchCarouselCards()
  }, [user])
  return (
    <div className="carousels-area">
      <CarouselRow
        title="Pending Connections"
        cards={carouselCards?.pendingConnections}
        loading={loading}
        noCardsText=" pending connections"
      />
      <CarouselRow
        title="Businesses"
        cards={carouselCards?.businesses}
        loading={loading}
        noCardsText=" new businesses"
      />
      <CarouselRow
        title="Investors"
        cards={carouselCards?.investors}
        loading={loading}
        noCardsText=" new investors"
      />
    </div>
  )
}

AdvisorCarouselArea.propTypes = exact(propTypes)
AdvisorCarouselArea.defaultProps = defaultProps

export default React.memo(AdvisorCarouselArea)
