import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import exact from 'prop-types-exact'
import {
  withFormikAdapterAndVisibilityInput,
  validate,
  formHelpers,
  validationPatterns,
} from '../utils'
import {
  DropdownCheckboxGroup as BaseDropownCheckboxGroup,
  Tooltip,
  ImageUploadPreview,
} from 'components'
import {
  Select as BaseSelectInput,
  Textarea as BaseTextarea,
  Input as BaseInput,
  Checkbox as BaseCheckbox,
  SubmitButton,
  Spinner,
} from '@launchpadlab/lp-components'
import { Formik, Form } from 'formik'
// import classnames from 'classnames'
import * as api from 'api'

const DropdownCheckboxGroup = withFormikAdapterAndVisibilityInput()(
  BaseDropownCheckboxGroup
)
const Select = withFormikAdapterAndVisibilityInput()(BaseSelectInput)
const Textarea = withFormikAdapterAndVisibilityInput()(BaseTextarea)
const Input = withFormikAdapterAndVisibilityInput()(BaseInput)
const Checkbox = withFormikAdapterAndVisibilityInput()(BaseCheckbox)

const propTypes = {
  params: PropTypes.object,
  handleSubmit: PropTypes.func,
}

const defaultProps = {}

// Add an extra custom validation for the hidden spam input.
function validateForm(values) {
  const defaultSectorIconSelected = values.defaultImage

  const messages = validate(values, {
    title: {
      presence: { allowEmpty: false, message: '^Please provide a response' },
      type: 'string',
    },
    firmWebsite: function (value, attributes) {
      if (attributes.firmWebsite === '') {
        return {}
      }
      return {
        format: {
          pattern: validationPatterns.websitePattern,
          message: '^Website address is invalid',
        },
      }
    },
    firmName: {
      presence: { allowEmpty: false, message: '^Please provide a response' },
      type: 'string',
    },
    advisorPriorExperiences: {
      presence: { allowEmpty: false },
    },
    advisorLocationExperiences: {
      presence: { allowEmpty: false },
    },
    yearsExperienceId: {
      presence: {
        allowEmpty: false,
        message: '^Please select one',
      },
    },
    description: {
      presence: { allowEmpty: true },
    },

    defaultImage: function (value, attributes) {
      if (attributes.profileImageUrl === '') {
        return { presence: { allowEmpty: false } }
      } else {
        return { presence: { allowEmpty: true } }
      }
    },
    profileImageUrl: function () {
      if (defaultSectorIconSelected) {
        return
      } else {
        return {
          presence: { allowEmpty: false, message: '^Profile image required' },
        }
      }
    },
  })
  // mix custom stuff here w validate.js messages
  return {
    ...messages,
    //
  }
}

function AdvisorGeneralOverviewEditForm({ params, handleSubmit }) {
  const [disabled, toggleDisabled] = useState(true)
  const [storedData, setStoredData] = useState()

  useEffect(() => {
    async function fetchData() {
      setStoredData(await api.getStoredData('/advisor/general-overview'))
    }
    fetchData()
  }, [])
  const initialValues = {
    firmName: storedData?.firmName || '',
    title: storedData?.title || '',
    firmWebsite: storedData?.firmWebsite || '',
    advisorLocationExperiences: storedData?.advisorLocationExperiences || '',
    advisorPriorExperiences: storedData?.advisorPriorSectorExperiences || [],
    yearsExperienceId: storedData?.yearsExperienceId || '',
    description: storedData?.description || '',

    defaultImage: storedData?.useDefaultSectorIcon || false,
    profileImageUrl: storedData?.profileImageUrl || '',
  }

  if (!storedData) {
    return <Spinner />
  }

  return (
    <Formik
      onSubmit={handleSubmit}
      validate={validateForm}
      initialValues={initialValues}
    >
      {({ values, setFieldTouched, setFieldValue, handleReset }) => (
        <Form className="onboarding-form">
          <div className="row">
            <h1>General Overview</h1>
            {disabled ? (
              <button
                className="button-primary-outline"
                onClick={() => {
                  toggleDisabled(false)
                }}
              >
                Edit
              </button>
            ) : (
              <div className="edit-buttons-area">
                <button
                  className="button-primary-outline"
                  onClick={() => {
                    handleReset()
                    toggleDisabled(true)
                  }}
                >
                  Cancel
                </button>
                <SubmitButton>Submit Changes</SubmitButton>
              </div>
            )}
          </div>
          <div className="row">
            <Input name="firmName" type="text" disabled={disabled} />

            <Input name="title" type="text" disabled={disabled} />
          </div>
          <div className="row">
            <Input
              name="firmWebsite"
              type="text"
              disabled={disabled}
              placeholder="Optional"
            />

            <Select
              name="yearsExperienceId"
              label="Years Of Professional Experience"
              tooltip={
                <Tooltip
                  name="yearsExperienceId"
                  text="Please select total years of professional experience"
                />
              }
              options={formHelpers.formatOptions(params.years)}
              enablePlaceholderOption={true}
              placeholder=" "
              disabled={disabled}
            />
          </div>
          <div className="row">
            <DropdownCheckboxGroup
              name="advisorPriorExperiences"
              label="Prior Professional Experience"
              tooltip={
                <Tooltip
                  name="advisorPriorExperiences"
                  text="Please select all sectors in which you have worked (not including companies advised)"
                />
              }
              errorMessage="Please select all that apply"
              options={formHelpers.formatOptions(params.sectorsIndustries)}
              values={values}
              setFieldValue={setFieldValue}
              setFieldTouched={setFieldTouched}
              tags={true}
              disabled={disabled}
            />
            <DropdownCheckboxGroup
              name="advisorLocationExperiences"
              label="Prior Geographic Experience"
              tooltip={
                <Tooltip
                  name="advisorLocationExperiences"
                  text="Please select all geographies in which you have worked in or served in an advisory capacity"
                />
              }
              errorMessage="Please select all that apply"
              values={values}
              options={formHelpers.formatOptions(params.territories)}
              setFieldValue={setFieldValue}
              setFieldTouched={setFieldTouched}
              tags={true}
              disabled={disabled}
            />
          </div>
          <div className="row">
            <Textarea
              className="text-area"
              name="description"
              label="Brief Description About You And Your Offering"
              hint="  (Shown to Other Members) "
              maxLength={500}
              disabled={disabled}
            />
            <p className="disclaimer description-disclaimer">
              Only post factual information about yourself, your company, and/or
              your investment fund. Do not include any forward-looking
              statements, including, but not limited to (i) projections or
              predictions about future performance of your company and/or
              investments, and (ii) forecasts or opinions about the future value
              of your company’s securities and/or investments.
            </p>
          </div>

          <h1>Profile Image Selection</h1>
          <div className="row image-description-row">
            You may either choose from pre-made icons based on Sector, or upload
            your own image.
          </div>
          <div className="row">
            <div className="column">
              <Checkbox
                name="defaultImage"
                onChange={(e) => {
                  if (e.target.value === true) {
                    setFieldValue('profileImageUrl', '')
                  }
                }}
                disabled={disabled}
              />
            </div>
            <div className="column">
              {values.defaultImage ? (
                <div className="image-upload-preview">
                  <img
                    src={params.defaultImage}
                    alt="Default Advisor Image"
                    style={{ width: '60px' }}
                  />
                </div>
              ) : (
                <ImageUploadPreview
                  disabled={disabled}
                  name="profileImageUrl"
                  hasStoredValue={values.profileImageUrl}
                />
              )}
            </div>
          </div>
          {/* <div className="row general-overview-radio-row"> 
            <div className="general-overview-radio-col">
              <h3>Public</h3>
              <div className="radio-group">
                <div className="radio-group-label">Name</div>
                <div className="radio-group-values">
                  {storedData.publicDisplayName}
                </div>
              </div>
              <div className="radio-group">
                <div className="radio-group-label">Profile Image</div>
                <div className="radio-group-values">
                  <div
                    className={classnames('image-radio-label', {
                      disabled: disabled,
                    })}
                  >
                    <img src={profileImageUrl} alt="img" />
                  </div>
                </div>
              </div>
            </div>
            <div className="general-overview-radio-col">
              <h3>Shown To Connections</h3>
              <div className="radio-group">
                <div className="radio-group-label">Name</div>
                <div className="radio-group-values">
                  {storedData.publicDisplayName}
                </div>
              </div>
              <div className="radio-group">
                <div className="radio-group-label">Profile Image</div>
                <div className="radio-group-values">
                  <div
                    className={classnames('image-radio-label', {
                      disabled: disabled,
                    })}
                  >
                    <img src={profileImageUrl} alt="img" />
                  </div>
                </div>
              </div>
            </div>
          </div>
              */}
        </Form>
      )}
    </Formik>
  )
}

AdvisorGeneralOverviewEditForm.propTypes = exact(propTypes)
AdvisorGeneralOverviewEditForm.defaultProps = defaultProps

export default AdvisorGeneralOverviewEditForm
