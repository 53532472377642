import React from 'react'
import PropTypes from 'prop-types'
import exact from 'prop-types-exact'
import { Field } from 'formik'
import classnames from 'classnames'

const propTypes = {
  name: PropTypes.string,
  label: PropTypes.string,
  values: PropTypes.object,
  disabled: PropTypes.bool,
  isImageSelector: PropTypes.bool,
}

const defaultProps = {}

const LabeledRadioButtons = ({
  name,
  label,
  values,
  disabled,
  isImageSelector,
}) => {
  return (
    <div className="radio-group">
      <div className="radio-group-label">{label}</div>
      <div
        className={classnames('radio-group-values', {
          'radio-group-image': isImageSelector,
        })}
        role="group"
      >
        {values.map((value, index) => {
          return (
            <label key={index}>
              <Field
                type="radio"
                name={name}
                value={value.value}
                disabled={disabled}
              />
              {isImageSelector ? (
                value.value === 'blurImage' ? (
                  <div
                    className={classnames('image-radio-label blurred', {
                      disabled: disabled,
                    })}
                  >
                    <img src={value.label} alt="img" />
                  </div>
                ) : (
                  <div
                    className={classnames('image-radio-label', {
                      disabled: disabled,
                    })}
                  >
                    <img src={value.label} alt="img" />
                  </div>
                )
              ) : (
                value.label
              )}
            </label>
          )
        })}
      </div>
    </div>
  )
}

LabeledRadioButtons.propTypes = exact(propTypes)
LabeledRadioButtons.defaultProps = defaultProps

export default React.memo(LabeledRadioButtons)
