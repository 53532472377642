import React from 'react'
import PropTypes from 'prop-types'
import exact from 'prop-types-exact'
import { Card, BlurhashErrorBoundary } from 'components'
import { ConnectionsCardLineItem } from 'components/connections'
import { Button } from '@launchpadlab/lp-components'
import { Blurhash } from 'react-blurhash'
import DefaultUserIcon from '../../../assets/images/deleted_avatar.svg'

const propTypes = {
  user: PropTypes.object,
  removedConnection: PropTypes.bool,
  deletedProfile: PropTypes.bool,
}

const defaultProps = {}

function MessagesHeaderCard({ user, removedConnection, deletedProfile }) {
  const {
    name,
    yearsOfExperience,
    membershipStatus,
    totalConnections,
    advisoryFirm,
    title,
    clientsServed,
    accreditedInvestor,
    locationCriteriaMatch,
    sectorCriteriaMatch,
    primaryIndustry,
    businessModel,
    businessStage,
    profileType,
    imageUrl,
    state,
    userId,
    blurredImage,
  } = user

  const isAdvisorCard = profileType === 'AdvisorProfile'
  const isInvestorCard = profileType === 'InvestorProfile'
  const isBusinessCard = profileType === 'BusinessProfile'

  const handleViewProfile = () => {
    window.open(`/users/profile?user=${userId}`)
  }

  return (
    <Card removeTopRow={true}>
      <div className="messages-card-content">
        <div className="messages-display-details">
          {blurredImage ? (
            <BlurhashErrorBoundary width="80px" height="80px">
              <Blurhash
                className="profile-picture"
                hash={imageUrl}
                width={80}
                height={80}
                resolutionX={32}
                resolutionY={32}
                punch={1}
              />
            </BlurhashErrorBoundary>
          ) : (
            <div className="profile-picture">
              <img
                src={deletedProfile ? DefaultUserIcon : imageUrl}
                alt="User Profile Image"
              />
            </div>
          )}

          <div className="profile-information">
            <div className="messages-profile-details">
              <h1>{removedConnection ? name + ' (DISCONNECTED)' : name}</h1>
              <h4 className="state-subheader">{state}</h4>
              {deletedProfile ? null : (
                <div className="two-col-flexbox">
                  <div className="left-col">
                    {yearsOfExperience && (
                      <ConnectionsCardLineItem
                        label={
                          isBusinessCard
                            ? 'Business Age (Years):'
                            : 'Years of Experience:'
                        }
                        value={yearsOfExperience}
                      />
                    )}
                    <ConnectionsCardLineItem
                      label="Member Status:"
                      value={membershipStatus}
                    />
                    <ConnectionsCardLineItem
                      label="Total Connections:"
                      value={totalConnections}
                    />
                  </div>
                  {isAdvisorCard && (
                    <div className="right-col">
                      <ConnectionsCardLineItem
                        label="Advisory Firm: "
                        value={advisoryFirm}
                      ></ConnectionsCardLineItem>
                      <ConnectionsCardLineItem
                        label="Title: "
                        value={title}
                      ></ConnectionsCardLineItem>
                      <ConnectionsCardLineItem
                        label="Clients Served: "
                        value={clientsServed}
                      ></ConnectionsCardLineItem>
                    </div>
                  )}
                  {isInvestorCard && (
                    <div className="right-col">
                      <ConnectionsCardLineItem
                        label="Accredited Investor: "
                        value={accreditedInvestor ? 'Yes' : 'No'}
                      ></ConnectionsCardLineItem>
                      <ConnectionsCardLineItem
                        label="Business Location Criteria Match: "
                        value={locationCriteriaMatch}
                      ></ConnectionsCardLineItem>
                      <ConnectionsCardLineItem
                        label="Sector Criteria Match: "
                        value={sectorCriteriaMatch}
                      ></ConnectionsCardLineItem>
                    </div>
                  )}
                  {isBusinessCard && (
                    <div className="right-col">
                      <ConnectionsCardLineItem
                        label="Primary Industry: "
                        value={primaryIndustry}
                      ></ConnectionsCardLineItem>
                      <ConnectionsCardLineItem
                        label="Business Model(s): "
                        value={businessModel}
                      ></ConnectionsCardLineItem>
                      <ConnectionsCardLineItem
                        label="Business Stage: "
                        value={businessStage}
                      ></ConnectionsCardLineItem>
                    </div>
                  )}
                </div>
              )}
            </div>
          </div>
        </div>
        <div className="connections-buttons">
          <div className="main-buttons">
            {deletedProfile ? (
              <div className="messages-deleted-profile-badge">
                <h2 className="messages-deleted-profile-text">Deleted</h2>
              </div>
            ) : (
              <Button
                onClick={handleViewProfile}
                className="connections-button button-primary-outline"
              >
                View Profile
              </Button>
            )}
          </div>
        </div>
      </div>
    </Card>
  )
}

MessagesHeaderCard.propTypes = exact(propTypes)
MessagesHeaderCard.defaultProps = defaultProps

export default React.memo(MessagesHeaderCard)
