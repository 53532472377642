import React from 'react'
import PropTypes from 'prop-types'
import exact from 'prop-types-exact'
import classnames from 'classnames'
import moment from 'moment'
import { Blurhash } from 'react-blurhash'
import { BlurhashErrorBoundary } from 'components'
import DefaultUserIcon from '../../../assets/images/deleted_avatar.svg'

const propTypes = {
  received: PropTypes.bool,
  createdAt: PropTypes.string,
  user: PropTypes.object,
}

const defaultProps = {}

const formatMessageDate = (date) => {
  return moment(date).format('LL | LT')
}

function MessagesInfoBadge({
  received,
  createdAt,
  user: {
    name,
    profile: { imageUrl },
    state,
    blurredImage,
    deleted,
  },
}) {
  const fullName = received ? `${name} ${state ? `| ${state}` : ''}` : 'Me'

  return (
    <div
      className={classnames('message-info-badge-flexbox', { right: !received })}
    >
      {deleted ? (
        <div className="message-profile-pic">
          <img src={DefaultUserIcon} alt="deleted-user" />
        </div>
      ) : blurredImage ? (
        <BlurhashErrorBoundary width="30px" height="30px">
          <Blurhash
            className="message-profile-pic"
            hash={imageUrl}
            width={30}
            height={30}
            resolutionX={32}
            resolutionY={32}
            punch={1}
          />
        </BlurhashErrorBoundary>
      ) : (
        <div className="message-profile-pic">
          <img src={imageUrl} alt="" />
        </div>
      )}
      <div className="message-badge-user-details">
        <div className="message-badge-user-details-name">{fullName}</div>
        <div className="message-badge-user-details-time-sent">
          {formatMessageDate(createdAt)}
        </div>
      </div>
    </div>
  )
}

MessagesInfoBadge.propTypes = exact(propTypes)
MessagesInfoBadge.defaultProps = defaultProps

export default React.memo(MessagesInfoBadge)
