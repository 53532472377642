import React from 'react'
import PropTypes from 'prop-types'
import exact from 'prop-types-exact'
import ReactPaginate from 'react-paginate'
import { Field } from 'formik'
import { scrollToSelector } from 'utils'

const propTypes = {
  setFieldValue: PropTypes.func,
  activePage: PropTypes.number,
  totalPages: PropTypes.number,
  name: PropTypes.string,
}

const defaultProps = {}

function ExplorePaginationControls({
  name,
  activePage,
  totalPages,
  setFieldValue,
}) {
  const handlePageClick = (pageNumber) => {
    scrollToSelector('.dashboard-header')
    setFieldValue(name, pageNumber.selected)
  }

  return (
    <Field
      name={name}
      component={() => (
        <ReactPaginate
          initialPage={activePage}
          previousLabel={'<'}
          nextLabel={'>'}
          breakLabel={'...'}
          breakClassName={'break-me'}
          pageCount={totalPages}
          marginPagesDisplayed={1}
          pageRangeDisplayed={2}
          onPageChange={handlePageClick}
          containerClassName={'pagination'}
          subContainerClassName={'pages pagination'}
          activeClassName={'active'}
        />
      )}
    ></Field>
  )
}

ExplorePaginationControls.propTypes = exact(propTypes)
ExplorePaginationControls.defaultProps = defaultProps

export default React.memo(ExplorePaginationControls)
