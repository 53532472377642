import React from 'react'
import PropTypes from 'prop-types'
import exact from 'prop-types-exact'
import { Button } from '@launchpadlab/lp-components'
import { CarouselCardLineItem } from 'components/carousels'
// import classnames from 'classnames'
import { Blurhash } from 'react-blurhash'
import { BlurhashErrorBoundary } from 'components'

const propTypes = {
  card: PropTypes.object,
}

const defaultProps = {}

function CarouselCard({ card }) {
  const handleViewProfile = () => {
    window.open(`/users/profile?user=${card.userId}`)
  }
  const blurredImage = card.blurredImage

  return (
    <div className="carousel-card">
      <div className="inner">
        {blurredImage ? (
          <div className="carousel-img-row">
            <BlurhashErrorBoundary width="60px" height="60px">
              <Blurhash
                className="carousel-img"
                hash={card.imageUrl}
                width={60}
                height={60}
                resolutionX={32}
                resolutionY={32}
                punch={1}
              />
            </BlurhashErrorBoundary>
          </div>
        ) : (
          <div className="carousel-img-row">
            <img src={card.imageUrl} className="carousel-img" />
          </div>
        )}
        <div className="carousel-title">
          <h1>{card.name}</h1>
        </div>
        <div className="carousel-subtitle">
          <p>{card.state}</p>
        </div>
        <div className="carousel-card-fields">
          {card.investorType === 'Individual Investor' ? (
            <CarouselCardLineItem
              label="Industry"
              value={card.currentIndustry}
            />
          ) : (
            <CarouselCardLineItem
              label="Sector Criteria Match"
              value={card.sectorCriteriaMatch}
            />
          )}
          <CarouselCardLineItem
            label="Years Experience"
            value={card.yearsOfExperience}
          />
          <CarouselCardLineItem
            label="Accredited Investor"
            value={card.accreditedInvestor === true ? 'Yes' : 'No'}
          />
          <CarouselCardLineItem label="Status" value={card.membershipStatus} />
        </div>
      </div>
      <Button onClick={handleViewProfile}>View</Button>
    </div>
  )
}

CarouselCard.propTypes = exact(propTypes)
CarouselCard.defaultProps = defaultProps

export default React.memo(CarouselCard)
