import React, { useState } from 'react'
import PropTypes from 'prop-types'
import exact from 'prop-types-exact'
import {
  withFormikAdapterAndVisibilityInput,
  validate,
  formHelpers,
  validationPatterns,
} from '../utils'
import {
  Input as BaseInput,
  Select as BaseSelectInput,
  SubmitButton,
} from '@launchpadlab/lp-components'
import { Formik, Form } from 'formik'
import { Tooltip } from 'components'

const Input = withFormikAdapterAndVisibilityInput()(BaseInput)
const Select = withFormikAdapterAndVisibilityInput()(BaseSelectInput)

const propTypes = {
  params: PropTypes.object,
  profileData: PropTypes.object,
  handleSubmit: PropTypes.func,
  user: PropTypes.object,
}

const defaultProps = {}

// Add an extra custom validation for the hidden spam input.
function validateForm(values) {
  const messages = validate(values, {
    firstName: {
      presence: { allowEmpty: false, message: '^Please provide a response' },
      type: 'string',
      format: validationPatterns.namePattern,
    },
    lastName: {
      presence: { allowEmpty: false, message: '^Please provide a response' },
      type: 'string',
      format: validationPatterns.namePattern,
    },
    // email: {
    //   presence: { allowEmpty: false, message: '^Please provide a response' },
    //   format: validationPatterns.emailPattern,
    // },
    roleWithinBusiness: {
      presence: { allowEmpty: false, message: '^Please select one' },
    },
  })

  // mix custom stuff here w validate.js messages
  return {
    ...messages,
    //
  }
}

function BusinessMemberInformationEditForm({
  params,
  profileData,
  user,
  handleSubmit,
}) {
  const [disabled, toggleDisabled] = useState(true)

  const initialValues = {
    firstName: user?.firstName || '',
    lastName: user?.lastName || '',
    email: user?.email || '',
    roleWithinBusiness: profileData?.roleWithinBusiness || '',
  }

  return (
    <Formik
      onSubmit={handleSubmit}
      validate={validateForm}
      initialValues={initialValues}
      validateOnChange={false}
    >
      {({ handleReset }) => (
        <Form className="onboarding-form">
          <div className="row">
            <h1>Member Information</h1>
            {disabled ? (
              <button
                className="button-primary-outline"
                onClick={() => {
                  toggleDisabled(false)
                }}
              >
                Edit
              </button>
            ) : (
              <div className="edit-buttons-area">
                <button
                  className="button-primary-outline"
                  onClick={() => {
                    handleReset()
                    toggleDisabled(true)
                  }}
                >
                  Cancel
                </button>
                <SubmitButton>Submit Changes</SubmitButton>
              </div>
            )}
          </div>
          <div className="row">
            <Input name="firstName" type="text" disabled={disabled} />
            <Input name="lastName" type="text" disabled={disabled} />
          </div>
          <div className="row">
            <Input
              id="read-only-email"
              name="email"
              type="text"
              disabled={true}
            >
              <div className="email-notice">
                Please email{' '}
                <a href="mailto:membership@firmvalue.com?subject=Change my email address">
                  membership@firmvalue.com
                </a>
                {` to update your email address`}
              </div>
            </Input>
            <Select
              name="roleWithinBusiness"
              tooltip={
                <Tooltip
                  name="roleWithinBusiness"
                  text="Please select your role or the nearest equivalent"
                />
              }
              options={formHelpers.formatOptions(params.roleWithinBusiness)}
              enablePlaceholderOption={true}
              placeholder=" "
              disabled={disabled}
            />
          </div>
        </Form>
      )}
    </Formik>
  )
}

BusinessMemberInformationEditForm.propTypes = exact(propTypes)
BusinessMemberInformationEditForm.defaultProps = defaultProps

export default BusinessMemberInformationEditForm
