import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import exact from 'prop-types-exact'
import {
  withFormikAdapterAndVisibilityInput,
  validate,
  formHelpers,
} from '../utils'
import {
  Select as BaseSelectInput,
  SubmitButton,
  Spinner,
} from '@launchpadlab/lp-components'
import { Formik, Form } from 'formik'

import * as api from 'api'

const Select = withFormikAdapterAndVisibilityInput()(BaseSelectInput)

const propTypes = {
  params: PropTypes.object,
  handleSubmit: PropTypes.func,
}

const defaultProps = {}

function InvestorInvestmentExplorationOverviewEditForm({
  params,
  handleSubmit,
}) {
  const [disabled, toggleDisabled] = useState(true)
  const [storedData, setStoredData] = useState()

  useEffect(() => {
    async function fetchData() {
      setStoredData(
        await api.getStoredData('/investor/investment-exploration-overview')
      )
    }
    fetchData()
  }, [])

  const initialValues = {
    membershipStatusId: storedData?.membershipStatusId || '',
    seekingStatus: storedData?.seekingStatus || '',
    seekingInvestorReasonId: storedData?.seekingInvestorReason || '',
    minNumberOfPartnersId: storedData?.minNumberOfPartners || '',
    maxNumberOfPartnersId: storedData?.maxNumberOfPartners || '',
    minConsideredEquityCheckPerInvestorId:
      storedData?.minConsideredEquityCheck || '',
    maxConsideredEquityCheckPerInvestorId:
      storedData?.maxConsideredEquityCheck || '',
    minTargetFundSizeId: storedData?.minTargetFundSize || '',
    maxTargetFundSizeId: storedData?.maxTargetFundSize || '',

    targetFundSizeNotDisclosed: storedData?.targetFundSizeNotDisclosed || '',
    minConsideredEquityCheckPerInvestorVisibility:
      storedData?.minConsideredEquityCheckPerInvestorVisibility || false,
  }

  if (!storedData) {
    return <Spinner />
  }

  function validateForm(values) {
    const optionalValidation = () => {
      if (values.seekingStatus === 'seeking_investors')
        return {
          presence: { allowEmpty: false, message: `^Please select one` },
        }
      return {}
    }

    const targetFundSizeNotDisclosedValidation = () => {
      if (
        values.seekingStatus === 'seeking_investors' &&
        !values.targetFundSizeNotDisclosed
      ) {
        return {
          presence: { allowEmpty: false, message: `^Please select one` },
        }
      }
      return {}
    }

    const messages = validate(values, {
      membershipStatusId: {
        presence: {
          allowEmpty: false,
          message: '^Please select one',
        },
      },
      seekingStatus: {
        presence: { allowEmpty: true },
      },
      seekingInvestorReasonId: optionalValidation('Investor seeking reason'),
      minNumberOfPartnersId: optionalValidation('Minimum number of partners'),
      maxNumberOfPartnersId: optionalValidation('Maximum number of partners'),
      minConsideredEquityCheckPerInvestorId: optionalValidation(
        'Minimum equity check per investor'
      ),
      maxConsideredEquityCheckPerInvestorId: optionalValidation(
        'Maximum equity check per investor'
      ),
      minTargetFundSizeId: targetFundSizeNotDisclosedValidation,
      maxTargetFundSizeId: targetFundSizeNotDisclosedValidation,
    })
    // mix custom stuff here w validate.js messages
    return {
      ...messages,
    }
  }
  return (
    <Formik
      onSubmit={handleSubmit}
      validate={validateForm}
      initialValues={initialValues}
    >
      {({ values, handleReset, setFieldValue }) => (
        <Form className="onboarding-form">
          <div className="row">
            <h1>Investment Exploration Overview</h1>
            {disabled ? (
              <button
                className="button-primary-outline"
                onClick={() => {
                  toggleDisabled(false)
                }}
              >
                Edit
              </button>
            ) : (
              <div className="edit-buttons-area">
                <button
                  className="button-primary-outline"
                  onClick={() => {
                    handleReset()
                    toggleDisabled(true)
                  }}
                >
                  Cancel
                </button>
                <SubmitButton>Submit Changes</SubmitButton>
              </div>
            )}
          </div>
          <div className="row">
            <Select
              name="membershipStatusId"
              label="Membership Status"
              options={formHelpers.formatOptions(params.membershipStatuses)}
              enablePlaceholderOption={true}
              placeholder=" "
              disabled={disabled}
            />
            <Select
              name="seekingStatus"
              label="Currently Seeking Investors?"
              options={formHelpers.formatOptions(params.seekingStatus)}
              enablePlaceholderOption={true}
              placeholder="No"
              disabled={disabled}
              onChange={() => {
                setFieldValue('seekingInvestorReasonId', '')
                setFieldValue('minNumberOfPartnersId', '')
                setFieldValue('maxNumberOfPartnersId', '')
                setFieldValue('minConsideredEquityCheckPerInvestorId', '')
                setFieldValue('maxConsideredEquityCheckPerInvestorId', '')
                setFieldValue('minTargetFundSizeId', '')
                setFieldValue('maxTargetFundSizeId', '')
                setFieldValue(
                  'minConsideredEquityCheckPerInvestorVisibility',
                  false
                )
              }}
            />
          </div>
          {values.seekingStatus === 'seeking_investors' ? (
            <div className="investment-exploration-optional-section">
              <div className="row">
                <Select
                  name="seekingInvestorReasonId"
                  label="Reason For Seeking Investors"
                  options={formHelpers.formatOptions(
                    params.seekingInvestorReason
                  )}
                  enablePlaceholderOption={true}
                  placeholder=" "
                  disabled={disabled}
                />
              </div>
              <div className="row">
                <Select
                  name="minNumberOfPartnersId"
                  label="Minimum Number Of Partners Considered"
                  options={formHelpers.formatOptions(params.investorNumbers)}
                  enablePlaceholderOption={true}
                  placeholder=" "
                  onChange={() => {
                    if (
                      values.minNumberOfPartnersId >
                      values.maxNumberOfPartnersId
                    )
                      setFieldValue('maxNumberOfPartnersId', '')
                  }}
                  disabled={disabled}
                />
                <Select
                  name="maxNumberOfPartnersId"
                  label="Maximum Number Of Partners Considered"
                  options={formHelpers.getMaxOptionsByMinValue(
                    values.minNumberOfPartnersId,
                    params.investorNumbers
                  )}
                  enablePlaceholderOption={true}
                  placeholder={
                    values.minNumberOfPartnersId === ''
                      ? 'Must Select Minimum'
                      : ' '
                  }
                  disabled={disabled || values.minNumberOfPartnersId === ''}
                />
              </div>
              <div className="row">
                <Select
                  name="minConsideredEquityCheckPerInvestorId"
                  label="Minimum Equity Check Per Investor Considered"
                  options={formHelpers.formatOptions(params.investorCapital)}
                  enablePlaceholderOption={true}
                  placeholder=" "
                  onChange={() => {
                    if (
                      values.minConsideredEquityCheckPerInvestorId >
                      values.maxConsideredEquityCheckPerInvestorId
                    )
                      setFieldValue('maxConsideredEquityCheckPerInvestorId', '')
                  }}
                  disabled={disabled}
                />
                <Select
                  name="maxConsideredEquityCheckPerInvestorId"
                  label="Maximum Equity Check Per Investor Considered"
                  options={formHelpers.getMaxOptionsByMinValue(
                    values.minConsideredEquityCheckPerInvestorId,
                    params.investorCapital
                  )}
                  enablePlaceholderOption={true}
                  placeholder={
                    values.minConsideredEquityCheckPerInvestorId === ''
                      ? 'Must Select Minimum'
                      : ' '
                  }
                  disabled={
                    disabled ||
                    values.minConsideredEquityCheckPerInvestorId === ''
                  }
                  hasVisibilityField
                  visibilityName="minConsideredEquityCheckPerInvestorId"
                />
              </div>
              <div className="row">
                <Select
                  name="minTargetFundSizeId"
                  label="Minimum Total Raise Considered"
                  options={formHelpers.formatOptions(params.investorCapital)}
                  enablePlaceholderOption={true}
                  placeholder={
                    values.targetFundSizeNotDisclosed ? 'Undisclosed' : ' '
                  }
                  onChange={() => {
                    if (values.minTargetFundSizeId > values.maxTargetFundSizeId)
                      setFieldValue('maxTargetFundSizeId', '')
                  }}
                  disabled={disabled || values.targetFundSizeNotDisclosed}
                />
                <Select
                  name="maxTargetFundSizeId"
                  label="Maximum Total Raise Considered"
                  options={formHelpers.getMaxOptionsByMinValue(
                    values.minTargetFundSizeId,
                    params.investorCapital
                  )}
                  enablePlaceholderOption={true}
                  placeholder={
                    values.minTargetFundSizeId === '' &&
                    values.targetFundSizeNotDisclosed
                      ? 'Undisclosed'
                      : values.minTargetFundSizeId === ''
                      ? 'Must Select Minimum'
                      : ' '
                  }
                  disclosureDisabled={disabled}
                  hasVisibilityField={true}
                  visibilityName="targetFundSize"
                  onVisibilityChange={(e) => {
                    if (e.target.value) {
                      setFieldValue('minTargetFundSizeId', '')
                      setFieldValue('maxTargetFundSizeId', '')
                    }
                  }}
                  disabled={
                    disabled ||
                    values.minTargetFundSizeId === '' ||
                    values.targetFundSizeNotDisclosed
                  }
                />
              </div>
              <p className="disclaimer investor-disclaimer">
                By clicking submit changes, you certify that you have (i)
                consulted with your legal counsel, and/or (ii) fully understand
                how posting your capital raise or co-investment objectives may
                implicate certain securities laws and regulations.
              </p>
            </div>
          ) : null}
        </Form>
      )}
    </Formik>
  )
}

InvestorInvestmentExplorationOverviewEditForm.propTypes = exact(propTypes)
InvestorInvestmentExplorationOverviewEditForm.defaultProps = defaultProps

export default InvestorInvestmentExplorationOverviewEditForm
