import React, { useState } from 'react'
import PropTypes from 'prop-types'
import exact from 'prop-types-exact'
import { Helmet } from 'react-helmet'
import { Header, Card, Footer } from '../components'
import { Button } from '@launchpadlab/lp-components'
import * as mailchimp from '../services/mailchimp'
import { SignUpForm } from '../forms'
import { scrollToSelector } from 'utils'

const propTypes = { userSession: PropTypes.object }

const defaultProps = {}

function AboutLanding({ userSession }) {
  const [signUpSucceeded, setSignUpSucceeded] = useState(false)
  return (
    <div className="aboutLanding">
      <Helmet>
        <title>About Page</title>
      </Helmet>
      <Header
        title="The Firm Value Model"
        subtitle={<span>Solving Historical Challenges</span>}
        className="about-subtitle"
        userSession={userSession}
      >
        <Button
          onClick={() => scrollToSelector('#sign-up-section')}
          style="primary"
          className="signUpButton"
          ariaLabel="Sign Up"
        >
          Sign Up
        </Button>
      </Header>
      <main>
        <Card
          title="The Firm Value Solution To Historical Headwinds"
          subtitle={
            <>
              <p className="about-header">
                Accessing the right partners, opportunities, and solutions
                doesn't have to be so inefficient. Capital networks can be
                powerful, but they shouldn't wait to be built - strong
                relationships are developed over time.
              </p>
            </>
          }
        >
          <div className="combo-card large left">
            <div className="grey-arrow">
              <h3>Challenges of the Past</h3>
            </div>

            <div className="card-content">
              <ul className="image-bullet-x">
                <li>Not wanting sensitive information shared broadly</li>
                <li>Time wasted only to find out criteria did not match</li>
                <li>
                  Digital platforms primarily built for active capital needs
                </li>
                <li>Networks often limited to family, friends, and location</li>
                <li>Inefficient searches and cold outreach processes</li>
                <li>High fees or commissions for digital platforms</li>
                <li>Limited exposure if marketed privately</li>
              </ul>
            </div>
          </div>
          <div className="combo-card large margin-bottom">
            <div className="blue-arrow">
              <h3>The Firm Value Solution</h3>
            </div>

            <div className="card-content">
              <ul className="image-bullet-check">
                <li>No requirement to publicize sensitive information</li>
                <li>Determine what criteria matches prior to discussions</li>
                <li>No active capital needs or objectives required to join</li>
                <li>Nationwide accessibility for potential partners</li>
                <li>Centralized community to improve efficiency</li>
                <li>Free to develop networks, low fees for active needs</li>
                <li>Easily share active objectives with the network</li>
              </ul>
            </div>
          </div>
        </Card>

        <Card title="About Firm Value">
          <div className="about-information">
            <p>
              Firm Value is an independent, newly-formed technology platform
              that helps U.S. small businesses, investors, and advisors develop
              their capital networks and gain better access to private capital
              and investment opportunities.
            </p>
            <p>
              Over the course of working with businesses, investors, and
              advisors, we learned that the U.S. small business landscape is
              underserved relative to its larger counterparts. We also know that
              relying on existing networks, cold outreach, and a fragmented
              internet can lead to significant inefficiencies and missed value.
              Firm Value was established to help address challenges in sourcing
              capital formation and deployment opportunities, broaden private
              capital networks, and increase the potential for greater value
              creation when it is needed.
            </p>
            <p>
              Prior to establishing Firm Value in 2020, Andrew von Kuhn
              partnered with founders, businesses, investors, and advisors
              across a variety of sectors and matters at BDT & Company and J.P.
              Morgan’s investment bank and private bank, after serving in the
              United States Marine Corps. Andrew’s academic background includes
              an MBA from Harvard Business School and an AB from Dartmouth
              College. Firm Value is advised by an Advisory Board comprised of
              executives with proven experience as founders, CEOs, investors,
              advisors, and owner/operators of small to medium sized U.S.
              business enterprises.
            </p>
            <p>
              Our mission is simple: to help the U.S. small business ecosystem
              open the door to greater access and value. Our vision is clear: to
              be the trusted partner that U.S. small businesses, investors, and
              advisors turn to for accessing meaningful connections and sourcing
              private capital & investment opportunities.
            </p>
          </div>
        </Card>
        <div id="sign-up-section">
          <div className="sign-up-information">
            <h2>Launching in Q1 2021</h2>
            <p>
              We're collecting membership interest to continue building the
              network around our founding members while we work towards a Q1
              2021 launch. Sign up today to stay up to date on our launch news.
            </p>
          </div>
          {signUpSucceeded ? (
            <p>Thank you for signing up!</p>
          ) : (
            <SignUpForm
              onSubmit={({ email, userType }) =>
                mailchimp
                  .signup({ email, userType })
                  .then(() => setSignUpSucceeded(true))
                  .catch(() =>
                    window.alert('Sign up failed, please try again.')
                  )
              }
            />
          )}
        </div>
      </main>
      <Footer />
    </div>
  )
}

AboutLanding.propTypes = exact(propTypes)
AboutLanding.defaultProps = defaultProps

export default React.memo(AboutLanding)
