import React from 'react'
import PropTypes from 'prop-types'
import exact from 'prop-types-exact'
import { DashboardHeaderLabel } from 'components/dashboard'

import * as Types from 'types'

import { formatDate, getConnectionCountLink } from 'utils'

const propTypes = {
  user: Types.user,
  children: PropTypes.node,
}

const defaultProps = {}

function BusinessDashboardDetails({ user, children }) {
  return (
    <div className="profile-information">
      <div className="profile-details">
        <h1>{user.businessProfile.displayName}</h1>
        <h2 className="profile-state-subheader">{user.state}</h2>
        <DashboardHeaderLabel
          label="Industry: "
          value={user.businessProfile.primaryIndustry}
        />
        <DashboardHeaderLabel
          label="Member Status: "
          value={user.businessProfile.membershipStatus}
        />
        <DashboardHeaderLabel
          label="Firm Value Member Since: "
          value={formatDate(user.businessProfile.createdAt)}
        />
        {/* <DashboardHeaderLabel
          label="Profile Confirmation: "
          value="Self-Confirmed"
        /> */}

        <div className="connections-details">
          <DashboardHeaderLabel
            label="Pending Connections: "
            value={getConnectionCountLink(
              user.pendingConnections,
              '/dashboard/connections/pending-connections'
            )}
          />
          <DashboardHeaderLabel
            label="Total Connections: "
            value={getConnectionCountLink(
              user.totalConnections,
              '/dashboard/connections/active-connections'
            )}
          />
          <DashboardHeaderLabel
            label="Potential Matches: "
            value="Coming soon"
          />
        </div>
      </div>
      <div className="profile-buttons">
        <div className="button-area">{children}</div>
      </div>
    </div>
  )
}

BusinessDashboardDetails.propTypes = exact(propTypes)
BusinessDashboardDetails.defaultProps = defaultProps

export default React.memo(BusinessDashboardDetails)
