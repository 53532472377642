import React, { useRef } from 'react'
// import PropTypes from 'prop-types'
import exact from 'prop-types-exact'
import { NavLink } from 'react-router-dom'
// import HorizontalScroll from 'react-scroll-horizontal'
const propTypes = {}

const defaultProps = {}

function MobileNav() {
  const navRef = useRef(null)

  const handleNav = (direction) => {
    if (direction === 'left') {
      navRef ? (navRef.current.scrollLeft -= 150) : null
    } else {
      navRef ? (navRef.current.scrollLeft += 150) : null
    }
  }
  return (
    <div className="mobile-nav-and-button-container">
      <div className="scroll-button scroll-button-left">
        <button
          onClick={() => handleNav('left')}
          aria-label="Scroll Left"
        ></button>
      </div>
      <nav className="mobile-nav" activeClassName="active-setting" ref={navRef}>
        <NavLink exact to="/" activeClassName="active-setting">
          <div className="mobile-nav-item">Dashboard</div>
        </NavLink>
        <NavLink to="/explore" activeClassName="active-setting">
          <div className="mobile-nav-item">Explore</div>
        </NavLink>
        <NavLink to="/messages" activeClassName="active-setting">
          <div className="mobile-nav-item">Messages</div>
        </NavLink>
        <NavLink
          to="/connections/active-connections"
          activeClassName="active-setting"
        >
          <div className="mobile-nav-item">Connections</div>
        </NavLink>
        <NavLink to="/edit-profile" activeClassName="active-setting">
          <div className="mobile-nav-item">Profile Settings</div>
        </NavLink>
        <NavLink to="/account-settings" activeClassName="active-setting">
          <div className="mobile-nav-item">Account Settings</div>
        </NavLink>
        <NavLink to="/notifications" activeClassName="active-setting">
          <div className="mobile-nav-item">Notifications</div>
        </NavLink>
      </nav>
      <div className="scroll-button scroll-button-right">
        <button
          onClick={() => handleNav('right')}
          aria-label="Scroll Right"
        ></button>
      </div>
    </div>
  )
}

MobileNav.propTypes = exact(propTypes)
MobileNav.defaultProps = defaultProps

export default React.memo(MobileNav)
