import React from 'react'
// import PropTypes from 'prop-types'
import exact from 'prop-types-exact'
import { NavLink } from 'react-router-dom'
import NewConnectionsLogo from '../../assets/images/SettingsIcons/newconnections.svg'
import NewMatchesLogo from '../../assets/images/SettingsIcons/newmatches.svg'
import NewMessagesLogo from '../../assets/images/SettingsIcons/newmessages.svg'

const propTypes = {}

const defaultProps = {}

function NotificationsSideSubNav() {
  return (
    <nav className="profile-settings-sub-nav">
      <div className="profile-settings-list">
        <NavLink
          to={`/notifications/new-connections`}
          activeClassName="is-active"
        >
          <div className="side-item">
            <img className="settings-nav-logo" src={NewConnectionsLogo}></img>
            <span className="profile-settings-label">New Connections</span>
          </div>
        </NavLink>
        <NavLink to={`/notifications/new-matches`} activeClassName="is-active">
          <div className="side-item">
            <img className="settings-nav-logo" src={NewMatchesLogo}></img>
            <span className="profile-settings-label">New Matches</span>
          </div>
        </NavLink>
        <NavLink to={`/notifications/new-messages`} activeClassName="is-active">
          <div className="side-item">
            <img className="settings-nav-logo" src={NewMessagesLogo}></img>
            <span className="profile-settings-label">New Messages</span>
          </div>
        </NavLink>
      </div>
    </nav>
  )
}

NotificationsSideSubNav.propTypes = exact(propTypes)
NotificationsSideSubNav.defaultProps = defaultProps

export default React.memo(NotificationsSideSubNav)
