import React, { useRef } from 'react'
// import PropTypes from 'prop-types'
import exact from 'prop-types-exact'
import { NavLink } from 'react-router-dom'

// import HorizontalScroll from 'react-scroll-horizontal'

const propTypes = {}

const defaultProps = {}

function ProfileSettingsMobileSubNav() {
  const navRef = useRef(null)

  const handleNav = (direction) => {
    if (direction === 'left') {
      navRef ? (navRef.current.scrollLeft -= 150) : null
    } else {
      navRef ? (navRef.current.scrollLeft += 150) : null
    }
  }

  return (
    <div className="mobile-sub-nav-and-button-container">
      <div className="scroll-button scroll-button-left">
        <button
          onClick={() => handleNav('left')}
          aria-label="Scroll Left"
        ></button>
      </div>
      <nav className="profile-settings-mobile-sub-nav" ref={navRef}>
        <NavLink
          to={`/connections/active-connections`}
          activeClassName="is-active"
        >
          <div className="mobile-nav-item">Active Connections</div>
        </NavLink>
        <NavLink
          to={`/connections/pending-connections`}
          activeClassName="is-active"
        >
          <div className="mobile-nav-item">Pending Connections</div>
        </NavLink>
        <NavLink
          to={`/connections/saved-for-later`}
          activeClassName="is-active"
        >
          <div className="mobile-nav-item">Saved For Later</div>
        </NavLink>
      </nav>
      <div className="scroll-button scroll-button-right">
        <button
          onClick={() => handleNav('right')}
          aria-label="Scroll Right"
        ></button>
      </div>
    </div>
  )
}

ProfileSettingsMobileSubNav.propTypes = exact(propTypes)
ProfileSettingsMobileSubNav.defaultProps = defaultProps

export default React.memo(ProfileSettingsMobileSubNav)
