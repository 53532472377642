import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import exact from 'prop-types-exact'
import {
  withFormikAdapterAndVisibilityInput,
  validate,
  formHelpers,
  validationPatterns,
  getIndustriesBySector,
} from '../utils'
import {
  DropdownCheckboxGroup as BaseDropownCheckboxGroup,
  Tooltip,
} from 'components'
import {
  Input as BaseInput,
  Select as BaseSelectInput,
  SubmitButton,
  Spinner,
} from '@launchpadlab/lp-components'
import { Formik, Form } from 'formik'

import * as api from 'api'
import { isEmpty } from 'lodash'

const Input = withFormikAdapterAndVisibilityInput()(BaseInput)
const DropdownCheckboxGroup = withFormikAdapterAndVisibilityInput()(
  BaseDropownCheckboxGroup
)
const Select = withFormikAdapterAndVisibilityInput()(BaseSelectInput)

const propTypes = {
  params: PropTypes.object,
  handleSubmit: PropTypes.func,
}

const defaultProps = {}

// Add an extra custom validation for the hidden spam input.
function validateForm(values) {
  const messages = validate(values, {
    location: function (value, attributes) {
      if (attributes.location === '')
        return {
          presence: {
            allowEmpty: false,
            message: '^Please provide a response',
          },
        }
      return {
        format: {
          pattern: validationPatterns.zipCodePattern,
          message: '^%{value} is not a valid zip code.',
        },
      }
    },
    // primarySectorId: {
    //   presence: { allowEmpty: false, message: '^Please select one' },
    // },
    primaryIndustryId: {
      presence: { allowEmpty: false, message: '^Please select one' },
    },
    businessProfileBusinessModels: {
      presence: { allowEmpty: false },
    },
    businessStageId: {
      presence: { allowEmpty: false, message: '^Please select one' },
    },
    businessAgeId: {
      presence: { allowEmpty: false, message: '^Please select one' },
    },
    businessProfileServedSectors: {
      presence: { allowEmpty: false },
    },
    businessServedLocations: {
      presence: { allowEmpty: false },
    },
    employeeSizeId: {
      presence: { allowEmpty: false, message: '^Please select one' },
    },
  })
  // mix custom stuff here w validate.js messages
  return {
    ...messages,
    //
  }
}

function BusinessOperationsEditForm({ params, handleSubmit }) {
  const [disabled, toggleDisabled] = useState(true)
  const [storedData, setStoredData] = useState()
  useEffect(() => {
    async function fetchData() {
      setStoredData(await api.getStoredData('/business/operations-overview'))
    }
    fetchData()
  }, [])
  const initialValues = {
    location: storedData?.location || '',
    primarySectorId: storedData?.primarySectorId || '',
    primaryIndustryId: storedData?.primaryIndustryId || '',
    businessProfileBusinessModels:
      storedData?.businessProfileBusinessModels || [],
    businessStageId: storedData?.businessStageId || '',
    businessAgeId: storedData?.businessAgeId || '',
    businessAgeVisibility: storedData?.businessAgeVisibility || false,
    businessProfileServedSectors:
      storedData?.businessProfileServedSectors || [],
    businessServedLocations: storedData?.businessServedLocations || [],
    employeeSizeId: storedData?.employeeSizeId || '',
    employeeSizeVisibility: storedData?.employeeSizeVisibility || false,
  }

  if (!storedData) {
    return <Spinner />
  }

  return (
    <Formik
      onSubmit={handleSubmit}
      validate={validateForm}
      initialValues={initialValues}
      validateOnChange={true}
    >
      {({ values, setFieldTouched, setFieldValue, handleReset }) => (
        <Form className="onboarding-form">
          {useEffect(() => {
            values.primarySectorId !== storedData.primarySectorId
              ? setFieldValue('primaryIndustryId', ' ')
              : null
          }, [values.primarySectorId])}
          <div className="row">
            <h1>Operations Overview</h1>
            {disabled ? (
              <button
                className="button-primary-outline"
                onClick={() => {
                  toggleDisabled(false)
                }}
              >
                Edit
              </button>
            ) : (
              <div className="edit-buttons-area">
                <button
                  className="button-primary-outline"
                  onClick={() => {
                    handleReset()
                    toggleDisabled(true)
                  }}
                >
                  Cancel
                </button>
                <SubmitButton>Submit Changes</SubmitButton>
              </div>
            )}
          </div>
          <div className="row">
            <Select
              name="primarySectorId"
              label="Primary Sector"
              tooltip={
                <Tooltip
                  name="primarySectorId"
                  text="Please select the most applicable sector for your business"
                />
              }
              options={formHelpers.formatOptions(params.sectorsIndustries)}
              enablePlaceholderOption={true}
              placeholder=" "
              disabled={true}
              emailNoticeText="primary sector"
            />
            <Select
              name="primaryIndustryId"
              label="Primary Industry"
              tooltip={
                <Tooltip
                  name="primaryIndustryId"
                  text="Please select the most applicable industry for your business"
                />
              }
              options={getIndustriesBySector(
                values.primarySectorId,
                params.sectorsIndustries
              )}
              enablePlaceholderOption={true}
              placeholder={
                isEmpty(values.primarySectorId) ? 'Must Select Sector' : ' '
              }
              disabled={disabled || values.primarySectorId === ''}
            />
          </div>
          <div className="row">
            <DropdownCheckboxGroup
              name="businessProfileBusinessModels"
              label="Business Model(s)"
              tooltip={
                <Tooltip
                  name="businessProfileBusinessModels"
                  text="Please select all that apply"
                />
              }
              options={formHelpers.formatOptions(params.businessModels)}
              setFieldValue={setFieldValue}
              setFieldTouched={setFieldTouched}
              errorMessage="Please select all that apply"
              values={values}
              tags={true}
              disabled={disabled}
            />
            <Select
              name="businessStageId"
              label="Business Stage"
              options={formHelpers.formatOptions(params.businessStages)}
              enablePlaceholderOption={true}
              placeholder=" "
              disabled={disabled}
            />
          </div>
          <div className="row">
            <Select
              name="businessAgeId"
              label="Business Age (Years)"
              options={formHelpers.formatOptions(params.years)}
              enablePlaceholderOption={true}
              placeholder=" "
              disabled={disabled}
            />
            <Select
              name="employeeSizeId"
              label="Employee Base Size"
              options={formHelpers.formatOptions(params.employeeSizes)}
              enablePlaceholderOption={true}
              placeholder=" "
              disabled={disabled}
            />
          </div>
          <div className="row">
            <DropdownCheckboxGroup
              name="businessServedLocations"
              label="Geographies Served"
              options={formHelpers.formatOptions(params.territoriesStates)}
              errorMessage="Please select all that apply"
              setFieldValue={setFieldValue}
              setFieldTouched={setFieldTouched}
              values={values}
              tags={true}
              disabled={disabled}
            />
            <DropdownCheckboxGroup
              name="businessProfileServedSectors"
              label="Sectors Served"
              errorMessage="Please select all that apply"
              options={formHelpers.formatOptions(params.sectorsIndustries)}
              setFieldValue={setFieldValue}
              setFieldTouched={setFieldTouched}
              values={values}
              tags={true}
              disabled={disabled}
            />
          </div>
          <div className="row">
            <Input
              name="location"
              label="Business Headquarters (Zip Code)"
              type="text"
              disabled={disabled}
            />
          </div>
        </Form>
      )}
    </Formik>
  )
}

BusinessOperationsEditForm.propTypes = exact(propTypes)
BusinessOperationsEditForm.defaultProps = defaultProps

export default BusinessOperationsEditForm
