import React, { useState } from 'react'
import PropTypes from 'prop-types'
import exact from 'prop-types-exact'
import { OptionCheckboxGroup as BaseOptionCheckboxGroup } from 'components'
import { withFormikAdapterAndVisibilityInput, formHelpers } from 'utils'
import { Checkbox as BaseCheckbox } from '@launchpadlab/lp-components'

const OptionCheckboxGroup = withFormikAdapterAndVisibilityInput()(
  BaseOptionCheckboxGroup
)
const Checkbox = withFormikAdapterAndVisibilityInput()(BaseCheckbox)

const propTypes = {
  name: PropTypes.string.isRequired,
  optionGroups: PropTypes.array.isRequired,
  setFieldValue: PropTypes.func.isRequired,
  input: PropTypes.object,
  meta: PropTypes.object,
  allowNoneValue: PropTypes.bool,
  allowSelectAll: PropTypes.bool,
  noneOptionIndex: PropTypes.number,
}

const defaultProps = {}

// convertToOptionGroupsFormat takes optionGroups and renames the sub-option array to params
const convertToOptionGroupsFormat = (optionGroups, key) => {
  let arrayOfFields = []

  optionGroups.forEach((optionGroup) => {
    arrayOfFields.push({
      id: optionGroup.id,
      name: optionGroup.name,
      params: optionGroup[key],
    })
  })

  return arrayOfFields
}

// option groups are an array of category objects that contain sub-arrays of category options
// [
//   {
//     "name": "Midwest",
//     "id": 1,
//     "states": [
//       {
//         "name": "Illinois",
//         "id": 1
//       },
//       {
//         "name": "Indiana",
//         "id": 2
//       },
//       ...
//     ]
//   },
//   {
//     "name": "Mountain West",
//     "id": 2,
//     "states": [...]
//   },
// ]

function OptionGroups({ optionGroups, allowNoneValue, ...props }) {
  const { setFieldValue, name, input, meta } = props
  const options = convertToOptionGroupsFormat(
    optionGroups,
    formHelpers.getKeyFromOptionGroups(optionGroups)
  )

  const [noneSelected, setNoneSelected] = useState(
    input.value.includes(getNoneOptionIndex(optionGroups))
  )

  function optionGroupsHasNone(optionGroups) {
    let hasNoneOptionGroup = false
    optionGroups.forEach((optionGroup) => {
      if (optionGroup.name === 'None') {
        hasNoneOptionGroup = true
      }
    })
    return hasNoneOptionGroup
  }

  function getNoneOptionIndex(optionGroups) {
    const noneOptionGroup = optionGroups.filter((optionGroup) => {
      return optionGroup.name === 'None'
    })[0]

    if (noneOptionGroup !== []) {
      const arrayKey = Object.keys(noneOptionGroup).filter(
        (key) => key !== 'name' && key !== 'id'
      )

      const values = noneOptionGroup[arrayKey]
      return values[0].id
    } else {
      return null
    }
  }

  function handleSelectNone() {
    if (noneSelected) {
      setNoneSelected(false)
      return
    }
    setNoneSelected(true)
    setFieldValue(name, [getNoneOptionIndex(optionGroups)])
  }
  return (
    <div className="option-checkbox-group">
      {allowNoneValue && optionGroupsHasNone(optionGroups) && (
        <Checkbox
          name={`${name}.select-none`}
          className="checkbox none"
          {...{
            input: {
              ...input,
              name: `${name}.select-none`,
              value: noneSelected,
              onChange: () => handleSelectNone(options),
            },
            meta: meta,
            label: 'None',
          }}
        />
      )}
      {options.map((option) => {
        if (option.name === 'None') {
          return
        }
        return (
          <div key={option.name} className="group">
            <OptionCheckboxGroup
              name={name}
              subCategoryId={option.id}
              subCategoryName={option.name}
              params={option.params}
              setFieldValue={setFieldValue}
              setNoneSelected={setNoneSelected}
              noneOptionIndex={
                optionGroupsHasNone(optionGroups)
                  ? getNoneOptionIndex(optionGroups)
                  : null
              }
              noneSelected={noneSelected}
              {...props}
            ></OptionCheckboxGroup>
          </div>
        )
      })}
    </div>
  )
}

OptionGroups.propTypes = exact(propTypes)
OptionGroups.defaultProps = defaultProps

export default React.memo(OptionGroups)
