import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import exact from 'prop-types-exact'
import { Card } from 'components'
import {
  MessagesActiveMessageThread,
  MessagesUserMessageInput,
} from 'components/messages'
import * as api from 'api'

const propTypes = {
  messages: PropTypes.array,
  currentUser: PropTypes.object,
  connection: PropTypes.object,
  connectionId: PropTypes.string,
  removedConnection: PropTypes.bool,
  deletedProfile: PropTypes.bool,
}

const defaultProps = {}

function MessagesUserMessagesArea({
  messages,
  currentUser,
  connection,
  connectionId,
  removedConnection,
  deletedProfile,
}) {
  const [latestMessages, setLatestMessages] = useState(messages)

  const addNewMessage = (message) => {
    const newMessages = latestMessages.concat([message])
    setLatestMessages(newMessages)
    const container = document.querySelector('.active-message-thread')
    container.scrollTo({ top: container.scrollHeight })
  }

  let syncMessages

  const syncMessageHandler = () => {
    async function fetchConversationDetails(connectionId) {
      const { messages } = await api.fetchConversation(connectionId)
      setLatestMessages(messages)
      syncMessages = setTimeout(syncMessageHandler, 3000)
    }
    fetchConversationDetails(connectionId)
  }

  useEffect(() => {
    syncMessages = setTimeout(syncMessageHandler, 3000)
    return () => {
      clearTimeout(syncMessages)
    }
  }, [])

  return (
    <Card removeTopRow={true}>
      <MessagesActiveMessageThread
        deletedThread={removedConnection || deletedProfile}
        connection={connection}
        messages={latestMessages}
        currentUser={currentUser}
      />
      {removedConnection || deletedProfile ? null : (
        <MessagesUserMessageInput
          connectionId={connectionId}
          addNewMessage={addNewMessage}
        />
      )}
    </Card>
  )
}

MessagesUserMessagesArea.propTypes = exact(propTypes)
MessagesUserMessagesArea.defaultProps = defaultProps

export default React.memo(MessagesUserMessagesArea)
