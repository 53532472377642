import React from 'react'
import PropTypes from 'prop-types'
import exact from 'prop-types-exact'
import NavBar from './NavBar'
import classnames from 'classnames'

const propTypes = {
  title: PropTypes.string,
  subtitle: PropTypes.node,
  children: PropTypes.node,
  userSession: PropTypes.object,
  className: PropTypes.string,
}

const defaultProps = {}

function Header({ title, subtitle, children, userSession, className }) {
  return (
    <header className={classnames('header', className)}>
      <div className="centered-content">
        <NavBar userSession={userSession} />

        <h1 className="title">{title}</h1>
        <h2 className="subtitle">{subtitle}</h2>
        <div className="buttonRow">{children}</div>
      </div>
    </header>
  )
}

Header.propTypes = exact(propTypes)
Header.defaultProps = defaultProps

export default React.memo(Header)
