import React, { useRef } from 'react'
// import PropTypes from 'prop-types'
import exact from 'prop-types-exact'
import { NavLink } from 'react-router-dom'

// import HorizontalScroll from 'react-scroll-horizontal'

const propTypes = {}

const defaultProps = {}

function NotificationsMobileSubNav() {
  const navRef = useRef(null)

  const handleNav = (direction) => {
    if (navRef) {
      direction === 'left'
        ? (navRef.current.scrollLeft -= 150)
        : (navRef.current.scrollLeft += 150)
    }
  }

  return (
    <div className="mobile-sub-nav-and-button-container">
      <div className="scroll-button scroll-button-left">
        <button
          onClick={() => handleNav('left')}
          aria-label="Scroll Left"
        ></button>
      </div>
      <nav className="profile-settings-mobile-sub-nav" ref={navRef}>
        <NavLink
          to={`/notifications/new-connections`}
          activeClassName="is-active"
        >
          <div className="mobile-nav-item">New Connections</div>
        </NavLink>
        <NavLink to={`/notifications/new-matches`} activeClassName="is-active">
          <div className="mobile-nav-item">New Matches</div>
        </NavLink>
        <NavLink to={`/notifications/new-messages`} activeClassName="is-active">
          <div className="mobile-nav-item">New Messages</div>
        </NavLink>
      </nav>
      <div className="scroll-button scroll-button-right">
        <button
          onClick={() => handleNav('right')}
          aria-label="Scroll Right"
        ></button>
      </div>
    </div>
  )
}

NotificationsMobileSubNav.propTypes = exact(propTypes)
NotificationsMobileSubNav.defaultProps = defaultProps

export default React.memo(NotificationsMobileSubNav)
