import React from 'react'
import PropTypes from 'prop-types'
import exact from 'prop-types-exact'
import { Footer } from 'components'
import {
  ProfileHeaderCard,
  BPCapitalExplorationProfileSection,
  BPOperationsOverviewProfileSection,
  BPFinancialOverviewProfileSection,
  BPAdditionalDetailsProfileSection,
  BPGeneralOverviewProfileSection,
  BPOperatingCriteriaProfileSection,
  BPFinancialCriteriaProfileSection,
} from 'components/profiles'

import { DashboardHeader } from 'components/dashboard'

const propTypes = {
  userSession: PropTypes.object,
  userData: PropTypes.object,
}

const defaultProps = {}

function BusinessProfileView(props) {
  const { userSession, userData } = props
  // Capital Exploration: Always visible
  // Operations Overview: Always visible
  // Financial Overview: Full section shown to investors and acquisitive businesses. Advisors only see EBIT Criteria Match
  const isProfileAcquisitive =
    userData?.seekingStatus === 'Seeking acquisitions'
  const isViewerAcquisitiveBusiness =
    userData?.viewerProfileType === 'BusinessProfile' &&
    userData.viewerSeekingStatus === 'seeking_acquisitions'
  const isViewerAdvisor = userData?.viewerProfileType === 'AdvisorProfile'
  const isViewerInvestor = userData?.viewerProfileType === 'InvestorProfile'
  const isViewerPassiveInvestorOnly = userData?.isViewerPassiveInvestorOnly
  return (
    <div className="profile-view-wrapper">
      <DashboardHeader userSession={userSession} />
      <div className="profile-view">
        <ProfileHeaderCard {...props} />
        <BPCapitalExplorationProfileSection
          sectionVisible={true}
          userData={userData}
          isAcquisitive={isProfileAcquisitive}
        />
        <BPOperationsOverviewProfileSection
          sectionVisible={
            !isProfileAcquisitive || isViewerAdvisor || isViewerInvestor
          }
          userData={userData}
        />
        <BPGeneralOverviewProfileSection
          sectionVisible={
            isProfileAcquisitive && !isViewerAdvisor && !isViewerInvestor
          }
          userData={userData}
        />
        <BPFinancialOverviewProfileSection
          sectionVisible={
            isViewerAcquisitiveBusiness ||
            (isViewerInvestor && !isViewerPassiveInvestorOnly) ||
            isViewerAdvisor
          }
          advisorRepresentsBusinesses={userData?.advisorRepresentsBusinesses}
          userData={userData}
        />
        <BPOperatingCriteriaProfileSection
          sectionVisible={
            isProfileAcquisitive && !isViewerAdvisor && !isViewerInvestor
          }
          userData={userData}
        />
        {/* <BPOperatingCriteriaForAdvisorProfileSection
          sectionVisible={
            isProfileAcquisitive && isViewerAdvisor && !isViewerInvestor
          }
          userData={userData}
        /> */}
        <BPFinancialCriteriaProfileSection
          sectionVisible={
            isProfileAcquisitive &&
            !isViewerInvestor &&
            isProfileAcquisitive &&
            !isViewerAdvisor
          }
          userData={userData}
        />
        <BPAdditionalDetailsProfileSection
          sectionVisible={
            !isProfileAcquisitive || isViewerAdvisor || isViewerInvestor
          }
          userData={userData}
        />
      </div>
      <Footer />
    </div>
  )
}

BusinessProfileView.propTypes = exact(propTypes)
BusinessProfileView.defaultProps = defaultProps

export default React.memo(BusinessProfileView)
