import React, { useState } from 'react'
import PropTypes from 'prop-types'
import exact from 'prop-types-exact'
import { Helmet } from 'react-helmet'
import { Header, Card, Footer } from '../components'
import { Button, Modal } from '@launchpadlab/lp-components'
import * as mailchimp from '../services/mailchimp'
import { SignUpForm } from '../forms'
import { scrollToSelector } from 'utils'
import PrivateCapital from '../../assets/images/privatecapital.svg'
import Lightbulb from '../../assets/images/lightbulb.svg'
import Door from '../../assets/images/door.svg'
import Investor from '../../assets/images/investor.svg'
import Investors from '../../assets/images/investors.svg'
import One from '../../assets/images/one.svg'
import Two from '../../assets/images/two.svg'
import Three from '../../assets/images/three.svg'
import Mailbox from '../../assets/images/mailbox.svg'
import LockCircle from '../../assets/images/lockcircle.svg'
import List from '../../assets/images/list.svg'
import Toggle from '../../assets/images/toggle.svg'
import ThreeBubble from '../../assets/images/threebubble.svg'
import ArrowMan from '../../assets/images/arrowman.svg'
import InvestorProfile from '../../assets/images/investor.png'
import InvestmentFundProfile from '../../assets/images/investmentfund.png'

const propTypes = { userSession: PropTypes.object }

const defaultProps = {}

function InvestorLanding({ userSession }) {
  const [signUpSucceeded, setSignUpSucceeded] = useState(false)
  const [exampleInvestorModalShown, setExampleInvestorModalShown] = useState(
    false
  )
  const [
    exampleInvestorFundModalShown,
    setExampleInvestorFundModalShown,
  ] = useState(false)
  return (
    <div className="investorLanding">
      <Helmet>
        <title>Investor Page</title>
      </Helmet>
      <Header
        title="Investors"
        subtitle={
          <span>
            Individual Investors <span>&#183;</span> Investment Funds
          </span>
        }
        userSession={userSession}
        className="investor-header"
      >
        <Button
          onClick={() => scrollToSelector('#sign-up-section')}
          style="primary"
          className="signUpButton"
          ariaLabel="Sign Up"
        >
          Sign Up
        </Button>
      </Header>
      <main>
        <Card
          title="How Investors Use Firm Value"
          className="investor-how"
          subtitle={
            <>
              <p>
                Firm Value helps investors make meaningful connections and
                access potential investment opportunities.
              </p>
            </>
          }
        >
          <div className="card-content thirds left">
            <img
              className="card-icon"
              src={PrivateCapital}
              alt="Private Capital Icon"
            />
            <h3 className="half-width">Build Your Investment Network</h3>
            <p>
              Access a community of active and passive investors, and investment
              funds interested in investing in U.S. small businesses
            </p>
          </div>

          <div className="card-content thirds left">
            <img className="card-icon" src={Lightbulb} alt="Lightbulb Icon" />
            <h3>Source New Opportunities</h3>
            <p>
              Save time & resources by developing meaningful relationships with
              businesses and replacing cold calls with inbound connections
            </p>
          </div>

          <div className="card-content thirds">
            <img className="card-icon" src={Door} alt="Door Icon" />
            <h3>Access Investments & Capital</h3>
            <p>
              Source potential solutions more efficiently by exploring or
              sharing active investment and capital raise opportunities
            </p>
          </div>
        </Card>

        <Card title="Who Firm Value Serves">
          <div className="small left-first">
            <div className="card-content">
              <img className="type-icon" src={Investor} alt="Investor Icon" />
              <h3>Individual Investors</h3>
              <p className="p-margin">Direct Investors</p>
              <p className="p-margin">Owner Operators</p>
              <p className="p-margin">Passive Investors</p>
            </div>
            <Button
              onClick={() => setExampleInvestorModalShown(true)}
              ariaLabel="See Example Investor"
            >
              View Example Profile
            </Button>
          </div>

          <div className="small">
            <div className="card-content">
              <img className="type-icon" src={Investors} alt="Investors Icon" />
              <h3>Investment Funds</h3>
              <p className="p-margin">Family Offices</p>
              <p className="p-margin">Private Equity</p>
              <p className="p-margin">Search Funds</p>
            </div>
            <Button
              onClick={() => setExampleInvestorFundModalShown(true)}
              ariaLabel="See Example Investment Fund"
            >
              View Example Profile
            </Button>
            {exampleInvestorModalShown && (
              <Modal onClose={() => setExampleInvestorModalShown(false)}>
                <h3>Example Investor Profile</h3>
                <h4>(As Shown To U.S. Small Businesses & Other Investors)</h4>
                <p>
                  Your profile defaults to showing whether or not your
                  investment criteria matches members' information or criteria,
                  rather than publicizing it. Want to publicize some or all of
                  it? Easily update your settings.
                </p>
                <img
                  className="modal-profile"
                  src={InvestorProfile}
                  alt="Investor Icon Profile"
                />
                <p className="subtitle">
                  Member presented is not a real Firm Value member and is
                  presented for illustrative purposes
                </p>
                <Button
                  onClick={() => setExampleInvestorModalShown(false)}
                  ariaLabel="Close Button"
                >
                  Close
                </Button>
              </Modal>
            )}
            {exampleInvestorFundModalShown && (
              <Modal onClose={() => setExampleInvestorFundModalShown(false)}>
                <h3>Example Investment Fund Profile</h3>
                <h4>(As Shown To U.S. Small Businesses & Other Investors)</h4>
                <p>
                  Your profile defaults to showing whether or not your
                  investment criteria matches members' information or criteria,
                  rather than publicizing it. Want to publicize some or all of
                  it? Easily update your settings.
                </p>
                <img
                  className="modal-profile"
                  src={InvestmentFundProfile}
                  alt="Investment Fund Profile"
                />
                <p className="subtitle">
                  Member presented is not a real Firm Value member and is
                  presented for illustrative purposes
                </p>
                <Button
                  onClick={() => setExampleInvestorFundModalShown(false)}
                  ariaLabel="Close Button"
                >
                  Close
                </Button>
              </Modal>
            )}
          </div>
        </Card>

        <Card title="How It Works For Investors">
          <div className="number-card">
            <div className="flex-card">
              <div className="number-header">
                <img className="number-icon" src={One} alt="Number 1" />
                <h3>Develop Your Profile</h3>
              </div>
              <div className="card-content">
                <p>
                  Your full name, city, contact information, and full criteria
                  are not shared with other members unless you choose to share
                  them
                </p>
              </div>
            </div>

            <div className="flex-card">
              <div className="number-header">
                <img className="number-icon" src={Two} alt="Number 2" />
                <h3>Explore & Discover</h3>
              </div>
              <div className="card-content">
                <p>
                  Discover potential near-term and longer-term opportunities,
                  and offer/receive connections to and from other members after
                  reviewing their criteria
                </p>
              </div>
            </div>

            <div className="flex-card">
              <div className="number-header">
                <img className="number-icon" src={Three} alt="Number 3" />
                <h3>Access & Communicate</h3>
              </div>
              <div className="card-content margin-bottom">
                <p>
                  Once mutual connection interest in confirmed, open the lines
                  of communication for discussions and build your long-term
                  relationships
                </p>
              </div>
            </div>
          </div>
        </Card>

        <Card title="Free Membership &#183; Premier Access">
          <div className="membership-card">
            <div className="subtitle">
              <p className="about-header">
                All members can explore the platform, offer/receive unlimited
                connections, and develop capital networks <span>for free.</span>{' '}
                For members with frequent use or active investment objectives,
                Firm Value Premier offers the following benefits:
              </p>
            </div>

            <div className="card-content membership">
              <div className="membership-left">
                <div className="membership-content">
                  <img src={Mailbox} alt="Mailbox Icon" />
                  <p className="italic">
                    <span>
                      Receive notifications when new members match your criteria
                    </span>{' '}
                    to save time rather than simply exploring
                  </p>
                </div>

                <div className="membership-content">
                  <img src={ArrowMan} alt="Arrows Around Man Icon" />
                  <p className="italic">
                    <span>Show members you're active</span> and elevate status
                    to “Actively Sourcing” or “Actively Developing Network”
                  </p>
                </div>

                <div className="membership-content">
                  <img src={LockCircle} alt="Lock in a Circle Icon" />
                  <p className="italic">
                    <span>
                      Choose whether to publicize information or show matching
                      criteria
                    </span>{' '}
                    defaults to other members
                  </p>
                </div>
              </div>

              <div className="membership-right">
                <div className="membership-content">
                  <img src={List} alt="List Icon" />
                  <p className="italic">
                    <span>
                      Post active investment or capital raise objectives
                    </span>{' '}
                    and increase potential viewership
                  </p>
                </div>

                <div className="membership-content">
                  <img src={ThreeBubble} alt="Three Bubble Icon" />
                  <p className="italic">
                    <span>Unlimited monthly connections offered/received</span>{' '}
                    to/from other members
                  </p>
                </div>

                <div className="membership-content">
                  <img src={Toggle} alt="Toggle Icon" />
                  <p className="italic">
                    <span>
                      Easily turn your Firm Value Premier membership on or off
                    </span>{' '}
                    as your objectives continue to evolve
                  </p>
                </div>
              </div>
            </div>
            <h3>Firm Value Premier Free For First 3 Months Post-Launch</h3>
          </div>
        </Card>

        <div id="sign-up-section">
          <div className="sign-up-information">
            <h2>Launching in Q1 2021</h2>
            <p>
              We're collecting membership interest to continue building the
              network around our founding members while we work towards a Q1
              2021 launch. Sign up today to stay up to date on our launch news.
            </p>
          </div>
          {signUpSucceeded ? (
            <p>Thank you for signing up!</p>
          ) : (
            <SignUpForm
              onSubmit={({ email, userType }) =>
                mailchimp
                  .signup({ email, userType })
                  .then(() => setSignUpSucceeded(true))
                  .catch(() =>
                    window.alert('Sign up failed, please try again.')
                  )
              }
            />
          )}
        </div>
      </main>
      <Footer />
    </div>
  )
}

InvestorLanding.propTypes = exact(propTypes)
InvestorLanding.defaultProps = defaultProps

export default React.memo(InvestorLanding)
