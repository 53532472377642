import React from 'react'
// import PropTypes from 'prop-types'
import exact from 'prop-types-exact'

import { ProfileSectionLineItem } from 'components/profiles'
import { isHumanizedBooleanValue, replaceNullValueWithPlaceholder } from 'utils'

const propTypes = {}

const defaultProps = {}

function IPFinancialCriteriaProfileSection({
  sectionVisible,
  userData,
  hovmOverride,
  advisorRepresentsBusinesses,
  viewerType,
}) {
  const isViewerAdvisor =
    userData?.viewerProfileType === 'AdvisorProfile' || viewerType === 'Advisor'

  if (sectionVisible || hovmOverride)
    if (isViewerAdvisor) {
      if (advisorRepresentsBusinesses || hovmOverride) {
        return (
          <div className="profile-section-wrapper centered-content">
            <h1>Investment Financial Criteria</h1>
            <div className="profile-view-section ">
              <div className="profile-view-section-col">
                <ProfileSectionLineItem
                  label="Annual Operating Income"
                  value={replaceNullValueWithPlaceholder(
                    userData.consideredAnnualEbit
                  )}
                  isCriteriaMatch={isHumanizedBooleanValue(
                    userData.consideredAnnualEbit
                  )}
                />
              </div>
              <div className="profile-view-section-col"></div>
            </div>
          </div>
        )
      } else {
        return null
      }
    }
  if (sectionVisible && !hovmOverride) {
    return (
      <div className="profile-section-wrapper centered-content">
        <h1>Investment Financial Criteria</h1>
        <div className="profile-view-section ">
          <div className="profile-view-section-col">
            <ProfileSectionLineItem
              label="Annual Revenue"
              value={replaceNullValueWithPlaceholder(
                userData?.consideredAnnualRevenues
              )}
              isCriteriaMatch={isHumanizedBooleanValue(
                userData.consideredAnnualRevenues
              )}
            />
            <ProfileSectionLineItem
              label="Annual EBITDA"
              value={replaceNullValueWithPlaceholder(
                userData?.consideredAnnualEbitda
              )}
              isCriteriaMatch={isHumanizedBooleanValue(
                userData.consideredAnnualEbitda
              )}
            />
          </div>
          <div className="profile-view-section-col">
            <ProfileSectionLineItem
              label="Annual Operating Income"
              value={replaceNullValueWithPlaceholder(
                userData.consideredAnnualEbit
              )}
              isCriteriaMatch={isHumanizedBooleanValue(
                userData.consideredAnnualEbit
              )}
            />
            <ProfileSectionLineItem
              label="Current Ownership"
              value={replaceNullValueWithPlaceholder(
                userData.consideredOwnerships
              )}
              isCriteriaMatch={isHumanizedBooleanValue(
                userData.consideredOwnerships
              )}
            />
          </div>
        </div>
      </div>
    )
  } else {
    return <></>
  }
}

IPFinancialCriteriaProfileSection.propTypes = exact(propTypes)
IPFinancialCriteriaProfileSection.defaultProps = defaultProps

export default React.memo(IPFinancialCriteriaProfileSection)
