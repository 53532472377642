import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import exact from 'prop-types-exact'
import { LabeledField, Checkbox } from '@launchpadlab/lp-components'

const propTypes = {
  params: PropTypes.object.isRequired,
  name: PropTypes.string.isRequired,
  input: PropTypes.object.isRequired,
  setFieldValue: PropTypes.func.isRequired,
  subCategoryId: PropTypes.number.isRequired,
  subCategoryName: PropTypes.string.isRequired,
  setNoneSelected: PropTypes.func,
  noneSelected: PropTypes.bool,
  noneOptionIndex: PropTypes.number,
  meta: PropTypes.object,
}

const defaultProps = {}

function getCategoryIds(optionValues) {
  return optionValues.map((value) => value.id)
}

function categoryIsAllSelected(superset, subset) {
  return subset.every(function (value) {
    return superset.indexOf(value) >= 0
  })
}

function OptionCheckboxGroup(props) {
  const {
    params,
    name,
    subCategoryId,
    subCategoryName,
    input,
    setFieldValue,
    setNoneSelected,
    noneSelected,
    noneOptionIndex,
    meta,
  } = props
  const [categoryAllSelected, setCategoryAll] = useState(false)
  const categoryIds = getCategoryIds(params)

  useEffect(() => {
    if (categoryIsAllSelected(input.value, categoryIds)) {
      setCategoryAll(true)
    }
    if (!categoryIsAllSelected(input.value, categoryIds)) {
      setCategoryAll(false)
    }
  }, [input.value])

  // on selection if category isn't all selected, set category checkbox to false
  function handleChange(option) {
    let selectedValues = input.value
    if (input.value.includes(option.id)) {
      selectedValues = input.value.filter((val) => {
        return val !== option.id
      })
    } else {
      selectedValues.push(option.id)
    }
    // console.log(name, selectedValues)

    if (selectedValues.includes(noneOptionIndex)) {
      setNoneSelected(false)
      selectedValues = selectedValues.filter(
        (value) => value !== noneOptionIndex
      )
    }

    setFieldValue(name, selectedValues)

    if (!categoryIsAllSelected(selectedValues, categoryIds)) {
      setCategoryAll(false)
    }

    if (categoryIsAllSelected(selectedValues, categoryIds)) {
      setCategoryAll(true)
    }
  }

  // upon toggle of category-all checkbox selector i.e. Midwest, select all in ids in category
  function handleCategoryToggle() {
    let currentlySelected = input.value
    let updatedValues

    // categoryAllSelected begins false, so the all id push happens first. Cannot rely on setCategoryAll hook to complete during function runtime
    if (categoryAllSelected) {
      setNoneSelected(false)
      updatedValues = []
    } else {
      updatedValues = currentlySelected
      setNoneSelected(false)
      updatedValues = updatedValues.filter((value) => value !== noneOptionIndex)
      categoryIds.forEach((id) => {
        if (!updatedValues.includes(id)) {
          updatedValues.push(id)
        }
      })
    }
    setFieldValue(name, updatedValues)
    setCategoryAll(!categoryAllSelected)
  }

  return (
    <LabeledField hideErrorLabel={true} className="CheckboxGroup" {...props}>
      <Checkbox
        id="category-toggle"
        {...{
          input: {
            ...input,
            name: `${subCategoryName}.${subCategoryId}`,
            value: categoryAllSelected && !noneSelected,
            onChange: () =>
              handleCategoryToggle(subCategoryId, subCategoryName),
          },
          meta: meta,
          label: subCategoryName,
        }}
      />
      {params.map((option) => {
        return (
          <Checkbox
            key={option.id}
            className="checkbox option-checkbox"
            {...{
              input: {
                ...input,
                name: `${option.name}.${option.id}`,
                value: input.value.includes(option.id),
                onChange: () => handleChange(option),
              },
              meta: meta,
              label: option.name,
            }}
          />
        )
      })}
    </LabeledField>
  )
}

OptionCheckboxGroup.propTypes = exact(propTypes)
OptionCheckboxGroup.defaultProps = defaultProps

export default React.memo(OptionCheckboxGroup)
