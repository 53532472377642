/* eslint-disable */
import React, { useState } from 'react'
import PropTypes from 'prop-types'
import exact from 'prop-types-exact'

import { Card, LabeledRadioButtons } from 'components'
import { SubmitButton } from '@launchpadlab/lp-components'
import { Formik, Form } from 'formik'

const propTypes = {
  handleSubmit: PropTypes.func,
}

const defaultProps = {}

function NotificationsNewMatchesForm({ handleSubmit, notificationSetting }) {
  const [disabled, toggleDisabled] = useState(true)

  const initialValues = {
    matchesNotificationCadence: notificationSetting || 'daily',
  }

  return (
    <Card>
      <Formik
        onSubmit={handleSubmit}
        initialValues={initialValues}
        enableReinitialize
      >
        {({ handleReset, values, setFieldValue }) => (
          <Form className="onboarding-form">
            <div className="row">
              <h1>New Matches</h1>
              {disabled ? (
                <button
                  className="button-primary-outline"
                  onClick={() => {
                    toggleDisabled(false)
                  }}
                >
                  Edit
                </button>
              ) : (
                <div className="edit-buttons-area">
                  <button
                    className="button-primary-outline"
                    onClick={() => {
                      handleReset()
                      toggleDisabled(true)
                    }}
                  >
                    Cancel
                  </button>
                  <SubmitButton>Submit Changes</SubmitButton>
                </div>
              )}
            </div>
            <div className="row frequency-radio-buttons">
              <LabeledRadioButtons
                label="Frequency Of Notifications"
                name="matchesNotificationCadence"
                values={[
                  {
                    label: 'Daily',
                    value: 'daily',
                  },
                  {
                    label: 'Weekly',
                    value: 'weekly',
                  },
                  {
                    label: 'No Notifications',
                    value: 'never',
                  },
                ]}
                disabled={disabled}
              />
            </div>
          </Form>
        )}
      </Formik>
    </Card>
  )
}

NotificationsNewMatchesForm.propTypes = exact(propTypes)
NotificationsNewMatchesForm.defaultProps = defaultProps

export default NotificationsNewMatchesForm
