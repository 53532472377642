import React from 'react'
import PropTypes from 'prop-types'
import exact from 'prop-types-exact'
import {
  withFormikAdapterAndVisibilityInput,
  validate,
  formHelpers,
  validationPatterns,
  getIndustriesBySector,
} from 'utils'
import {
  DropdownCheckboxGroup as BaseDropownCheckboxGroup,
  Tooltip,
} from 'components'
import {
  Input as BaseInput,
  Select as BaseSelectInput,
  Checkbox as BaseCheckbox,
  SubmitButton,
} from '@launchpadlab/lp-components'
import { Formik, Form } from 'formik'
import { isEmpty } from 'lodash'

const Input = withFormikAdapterAndVisibilityInput()(BaseInput)
const DropdownCheckboxGroup = withFormikAdapterAndVisibilityInput()(
  BaseDropownCheckboxGroup
)
const Select = withFormikAdapterAndVisibilityInput()(BaseSelectInput)
const Checkbox = withFormikAdapterAndVisibilityInput()(BaseCheckbox)

const propTypes = {
  params: PropTypes.object.isRequired,
  formValues: PropTypes.object.isRequired,
  handleSubmit: PropTypes.func.isRequired,
}

const defaultProps = {}

function validateForm(values) {
  const messages = validate(values, {
    firstName: function (value, attributes) {
      if (attributes.firstName === '')
        return {
          presence: {
            allowEmpty: false,
            message: '^Please provide a response',
          },
        }
      return {
        format: {
          pattern: validationPatterns.namePattern,
          message: 'cannot contain special characters',
        },
      }
    },
    lastName: function (value, attributes) {
      if (attributes.lastName === '')
        return {
          presence: {
            allowEmpty: false,
            message: '^Please provide a response',
          },
        }
      return {
        format: {
          pattern: validationPatterns.namePattern,
          message: 'cannot contain special characters',
        },
      }
    },
    roleWithinBusiness: {
      presence: {
        allowEmpty: false,
        message: '^Please provide a response',
      },
    },
    businessName: {
      presence: { allowEmpty: false, message: '^Please provide a response' },
      type: 'string',
    },
    location: function (value, attributes) {
      if (attributes.location === '')
        return {
          presence: {
            allowEmpty: false,
            message: '^Please provide a response',
          },
        }
      return {
        format: {
          pattern: validationPatterns.zipCodePattern,
          message: '^%{value} is not a valid zip code.',
        },
      }
    },
    primarySectorId: {
      presence: { allowEmpty: false, message: '^Please select one' },
    },
    primaryIndustryId: {
      presence: { allowEmpty: false, message: '^Please select one' },
    },
    businessProfileBusinessModels: {
      presence: { allowEmpty: false },
    },
    businessStageId: {
      presence: { allowEmpty: false, message: '^Please select one' },
    },
    businessAgeId: {
      presence: { allowEmpty: false, message: '^Please select one' },
    },
    businessProfileServedSectors: {
      presence: { allowEmpty: false },
    },
    businessServedLocations: {
      presence: { allowEmpty: false },
    },
    employeeSizeId: {
      presence: { allowEmpty: false, message: '^Please select one' },
    },
    usCitizen: {
      presence: {
        message:
          '^Use of Firm Value is currently restricted to U.S. citizens and entities domiciled in the U.S.',
      },
      inclusion: {
        within: [true],
        message:
          '^Use of Firm Value is currently restricted to U.S. citizens and entities domiciled in the U.S.',
      },
    },
    termsAndConditions: {
      presence: {
        message:
          '^You must agree to our Terms of Service and Privacy Policy to join',
      },
      inclusion: {
        within: [true],
        message:
          '^You must agree to our Terms of Service and Privacy Policy to join',
      },
    },
  })
  // mix custom stuff here w validate.js messages
  return {
    ...messages,
    //
  }
}

function BusinessPage1Form({ params, formValues, handleSubmit }) {
  return (
    <Formik
      onSubmit={handleSubmit}
      validate={validateForm}
      initialValues={formValues}
    >
      {({ values, errors, touched, setFieldTouched, setFieldValue }) => (
        <Form className="onboarding-form setup-form">
          <h1>Member Information</h1>
          <p className="disclaimer">
            Names, business names, contact information, and cities are{' '}
            <span id="underline">not</span> publicized to other members unless
            you choose to do so in your profile settings.
          </p>
          <div className="row">
            <Input name="firstName" type="text" />
            <Input name="lastName" type="text" />
          </div>
          <div className="row">
            <Select
              name="roleWithinBusiness"
              tooltip={
                <Tooltip
                  name="roleWithinBusiness"
                  text="Please select your role or the nearest equivalent"
                />
              }
              options={formHelpers.formatOptions(params.roleWithinBusiness)}
              enablePlaceholderOption={true}
              placeholder=" "
            />
            <Input name="businessName" type="text" />
          </div>
          <div className="row">
            <Input
              name="location"
              label="Business Headquarters (Zip Code)"
              type="text"
              maxLength={10}
            />
          </div>
          <div className="row">
            <Checkbox
              name="usCitizen"
              label="I am a U.S. Citizen or U.S. Domiciled Entity"
            />
            <Checkbox
              name="termsAndConditions"
              label={
                <div className="policy-agreement-label">
                  <span>I agree to the </span>
                  <a
                    href={'https://www.firmvalue.com/terms-of-service'}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    Terms of Service
                  </a>
                  <span> and </span>
                  <a
                    href={'https://www.firmvalue.com/privacy-policy'}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    Privacy Policy
                  </a>
                </div>
              }
            />
          </div>
          <h1>Operations Overview</h1>
          <div className="row">
            <Select
              name="primarySectorId"
              label="Primary Sector"
              tooltip={
                <Tooltip
                  name="primarySectorId"
                  text="Please select the most applicable sector for your business"
                />
              }
              options={formHelpers.formatOptions(params.sectorsIndustries)}
              enablePlaceholderOption={true}
              placeholder=" "
              onChange={() => {
                setFieldValue('primaryIndustryId', ' ')
              }}
            />

            <Select
              name="primaryIndustryId"
              label="Primary Industry"
              tooltip={
                <Tooltip
                  name="primaryIndustryId"
                  text="Please select the most applicable industry for your business"
                />
              }
              options={getIndustriesBySector(
                values.primarySectorId,
                params.sectorsIndustries
              )}
              enablePlaceholderOption={true}
              placeholder={
                isEmpty(values.primarySectorId) ? 'Must Select Sector' : ' '
              }
              disabled={values.primarySectorId === ''}
            />
          </div>
          <div className="row">
            <DropdownCheckboxGroup
              name="businessProfileBusinessModels"
              label="Business Model(s)"
              tooltip={
                <Tooltip
                  name="businessProfileBusinessModels"
                  text="Please select all that apply"
                />
              }
              options={formHelpers.formatOptions(params.businessModels)}
              values={values}
              setFieldValue={setFieldValue}
              setFieldTouched={setFieldTouched}
              errorMessage="Please select all that apply"
              tags={true}
            />
            <Select
              name="businessStageId"
              label="Business Stage"
              options={formHelpers.formatOptions(params.businessStages)}
              enablePlaceholderOption={true}
              placeholder=" "
            />
          </div>
          <div className="row">
            <Select
              name="businessAgeId"
              label="Business Age (Years)"
              options={formHelpers.formatOptions(params.years)}
              enablePlaceholderOption={true}
              placeholder=" "
            />
            <DropdownCheckboxGroup
              name="businessProfileServedSectors"
              label="Sectors Served"
              options={formHelpers.formatOptions(params.sectorsIndustries)}
              values={values}
              setFieldValue={setFieldValue}
              setFieldTouched={setFieldTouched}
              errorMessage="Please select all that apply"
              tags={true}
            />
          </div>
          <div className="row">
            <DropdownCheckboxGroup
              name="businessServedLocations"
              label="Geographies Served"
              options={formHelpers.formatOptions(params.territoriesStates)}
              values={values}
              setFieldValue={setFieldValue}
              setFieldTouched={setFieldTouched}
              errorMessage="Please select all that apply"
              tags={true}
            />
            <Select
              name="employeeSizeId"
              label="Employee Base Size"
              options={formHelpers.formatOptions(params.employeeSizes)}
              enablePlaceholderOption={true}
              placeholder=" "
            />
          </div>
          <div className="submission-error-wrapper">
            <div className="button-area">
              <SubmitButton className="form-button">Next</SubmitButton>
            </div>
            <div className="error-message-submission">
              {Object.keys(touched).some((key) => {
                return Object.keys(errors).includes(key)
              })
                ? 'Please fix the errors presented above'
                : null}
            </div>
          </div>
        </Form>
      )}
    </Formik>
  )
}

BusinessPage1Form.propTypes = exact(propTypes)
BusinessPage1Form.defaultProps = defaultProps

export default BusinessPage1Form
