import React from 'react'
// import PropTypes from 'prop-types'
import exact from 'prop-types-exact'
import { ErrorBoundary } from '../components'
import { Route, Switch, NavLink } from 'react-router-dom'
import { HOVMBusinessProfileView } from 'views'
const propTypes = {}

const defaultProps = {}

function HowOthersViewMeBusiness() {
  return (
    <div className="card">
      <ErrorBoundary>
        <div className="how-others-view-me-page-wrapper">
          <div className="hovm-title">
            <h2>How Others View Me</h2>
          </div>
          <div className="hovm-subtitle">
            Select from member types below to see how others will view your
            profile prior to connecting
          </div>
          <div className="hovm-link-row">
            <NavLink
              className="hovm-link"
              to="/edit-profile/how-others-view-me/small-business"
              activeClassName="hovm-active"
            >
              <div>Businesses</div>
            </NavLink>
            <NavLink
              className="hovm-link"
              to="/edit-profile/how-others-view-me/acquistive-business"
              activeClassName="hovm-active"
            >
              <div>Acquisitive Businesses</div>
            </NavLink>
            <NavLink
              className="hovm-link"
              to="/edit-profile/how-others-view-me/investor"
              activeClassName="hovm-active"
            >
              Investors
            </NavLink>
            <NavLink
              className="hovm-link"
              to="/edit-profile/how-others-view-me/advisor"
              activeClassName="hovm-active"
            >
              Advisors
            </NavLink>
          </div>
          <div className="hovm-view-area">
            <div className="hovm-view-box">
              <Switch>
                <Route
                  exact
                  path="/edit-profile/how-others-view-me/acquistive-business"
                >
                  <HOVMBusinessProfileView viewerType="AcquisitiveBusiness" />
                </Route>
                <Route exact path="/edit-profile/how-others-view-me/investor">
                  <HOVMBusinessProfileView viewerType="Investor" />
                </Route>
                <Route exact path="/edit-profile/how-others-view-me/advisor">
                  <HOVMBusinessProfileView viewerType="Advisor" />
                </Route>
                <Route path="/edit-profile/how-others-view-me/">
                  <HOVMBusinessProfileView viewerType="SmallBusiness" />
                </Route>
              </Switch>
            </div>
          </div>
        </div>
      </ErrorBoundary>
    </div>
  )
}

HowOthersViewMeBusiness.propTypes = exact(propTypes)
HowOthersViewMeBusiness.defaultProps = defaultProps

export default React.memo(HowOthersViewMeBusiness)
