import React from 'react'
import PropTypes from 'prop-types'
import exact from 'prop-types-exact'

const propTypes = {
  label: PropTypes.string,
  value: PropTypes.string || PropTypes.array,
}

const defaultProps = {}

function ProfileSectionLineItemTwoCol({ label, value }) {
  return (
    <div className="profile-view-section-line-item-stretch">
      <p className="profile-view-section-line-item-label">{label}</p>
      <p className="profile-view-section-line-item-value">{value}</p>
    </div>
  )
}

ProfileSectionLineItemTwoCol.propTypes = exact(propTypes)
ProfileSectionLineItemTwoCol.defaultProps = defaultProps

export default React.memo(ProfileSectionLineItemTwoCol)
