import React, { useState } from 'react'
import PropTypes from 'prop-types'
import exact from 'prop-types-exact'
import { Helmet } from 'react-helmet'
import { Header, Card, Footer } from '../components'
import { Button, Modal } from '@launchpadlab/lp-components'
import * as mailchimp from '../services/mailchimp'
import { SignUpForm } from '../forms'
import { scrollToSelector } from 'utils'
import PrivateCapital from '../../assets/images/privatecapital.svg'
import Lightbulb from '../../assets/images/lightbulb.svg'
import Door from '../../assets/images/door.svg'
import Briefcase from '../../assets/images/briefcase.svg'
import Briefcases from '../../assets/images/twobriefcase.svg'
import One from '../../assets/images/one.svg'
import Two from '../../assets/images/two.svg'
import Three from '../../assets/images/three.svg'
import Mailbox from '../../assets/images/mailbox.svg'
import LockCircle from '../../assets/images/lockcircle.svg'
import List from '../../assets/images/list.svg'
import Toggle from '../../assets/images/toggle.svg'
import ThreeBubble from '../../assets/images/threebubble.svg'
import ArrowMan from '../../assets/images/arrowman.svg'
import BusinessProfile from '../../assets/images/businessprofile.png'
import BusinessesProfile from '../../assets/images/businessesprofile.png'

const propTypes = { userSession: PropTypes.object }

const defaultProps = {}

function BusinessLanding({ userSession }) {
  const [signUpSucceeded, setSignUpSucceeded] = useState(false)
  const [exampleBusinessModalShown, setExampleBusinessModalShown] = useState(
    false
  )
  const [
    exampleAcquisitiveBusinessModalShown,
    setExampleAcquisitiveBusinessModalShown,
  ] = useState(false)
  return (
    <div className="businessLanding">
      <Helmet>
        <title>Business Page</title>
      </Helmet>
      <Header
        title="Businesses"
        subtitle={
          <span>
            U.S. Small Businesses <span>&#183;</span> Acquisitive Businesses
          </span>
        }
        userSession={userSession}
        className="business-header"
      >
        <Button
          onClick={() => scrollToSelector('#sign-up-section')}
          style="primary"
          className="signUpButton"
          ariaLabel="Sign Up"
        >
          Sign Up
        </Button>
      </Header>
      <main>
        <Card
          title="How Businesses Use Firm Value"
          className="business-how"
          subtitle={
            <>
              <p>
                Firm Value helps businesses make meaningful connections, prepare
                for longer-term capital objectives, and access potential private
                capital solutions.
              </p>
            </>
          }
        >
          <div className="card-content thirds left">
            <img
              className="card-icon"
              src={PrivateCapital}
              alt="Private Capital Icon"
            />
            <h3 className="half-width">Build Your Capital Network</h3>
            <p>
              Access a community of investors and other businesses, and build
              your network through our data-driven matching system
            </p>
          </div>

          <div className="card-content thirds left">
            <img className="card-icon" src={Lightbulb} alt="Lightbulb Icon" />
            <h3>Prepare For The Future</h3>
            <p>
              Engage with potential long-term partners after connections are
              confirmed, and find an advisor if your needs evolve
            </p>
          </div>

          <div className="card-content thirds">
            <img className="card-icon" src={Door} alt="Door Icon" />
            <h3>Access Capital & Investments</h3>
            <p>
              Raise or deploy capital efficiently by posting full sale, equity,
              and/or debt raise objectives, and acquisition criteria
            </p>
          </div>
        </Card>

        <Card title="Who Firm Value Serves">
          <div className="small left-first">
            <div className="card-content">
              <img className="type-icon" src={Briefcase} alt="Briefcase Icon" />
              <h3>U.S. Small Businesses</h3>
              <p className="p-margin">Business Owners</p>
              <p className="p-margin">CEOs & CFOs</p>
            </div>
            <Button
              onClick={() => setExampleBusinessModalShown(true)}
              ariaLabel="See Example Business"
            >
              View Example Profile
            </Button>
          </div>

          <div className="small">
            <div className="card-content">
              <img
                className="type-icon"
                src={Briefcases}
                alt="Two Briefcases Icon"
              />
              <h3>Acquisitive Businesses</h3>
              <p className="p-margin">Business Owners</p>
              <p className="p-margin">CEOs & CFOs</p>
            </div>
            <Button
              onClick={() => setExampleAcquisitiveBusinessModalShown(true)}
              ariaLabel="See Example Acquisitive Business"
            >
              View Example Profile
            </Button>

            {exampleBusinessModalShown && (
              <Modal onClose={() => setExampleBusinessModalShown(false)}>
                <h3>Example U.S. Small Business Profile</h3>
                <h4>(As Shown To Investors & Acquisitive Businesses)</h4>
                <p>
                  Your profile defaults to showing whether or not sensitive
                  information matches a viewing member’s criteria, rather than
                  publicizing it. Want to publicize some or all of it? Easily
                  update your settings.
                </p>
                <img
                  className="modal-profile"
                  src={BusinessProfile}
                  alt="Business Profile"
                />
                <p className="subtitle">
                  Member presented is not a real Firm Value member and is
                  presented for illustrative purposes
                </p>
                <Button
                  onClick={() => setExampleBusinessModalShown(false)}
                  ariaLabel="Close Button"
                >
                  Close
                </Button>
              </Modal>
            )}

            {exampleAcquisitiveBusinessModalShown && (
              <Modal
                onClose={() => setExampleAcquisitiveBusinessModalShown(false)}
              >
                <h3>Example Acquisitive Business Profile</h3>
                <h4>(As Shown To U.S. Small Businesses)</h4>
                <p>
                  Your profile defaults to showing whether or not your
                  acquisition criteria matches a business’s information, rather
                  than publicizing it. Want to publicize some or all of it?
                  Easily update your settings.
                </p>
                <img
                  className="modal-profile"
                  src={BusinessesProfile}
                  alt="Businesses Profile"
                />
                <p className="subtitle">
                  Member presented is not a real Firm Value member and is
                  presented for illustrative purposes
                </p>
                <Button
                  onClick={() => setExampleAcquisitiveBusinessModalShown(false)}
                  ariaLabel="Close Button"
                >
                  Close
                </Button>
              </Modal>
            )}
          </div>
        </Card>

        <Card title="How It Works For Businesses">
          <div className="number-card">
            <div className="flex-card">
              <div className="number-header">
                <img className="number-icon" src={One} alt="Number 1" />
                <h3>Develop Your Profile</h3>
              </div>
              <div className="card-content">
                <p>
                  Your business's name, city, contact information, and full
                  financials are not shared with other members unless you choose
                  to share them
                </p>
              </div>
            </div>

            <div className="flex-card">
              <div className="number-header">
                <img className="number-icon" src={Two} alt="Number 2" />
                <h3>Explore & Discover</h3>
              </div>
              <div className="card-content">
                <p>
                  Discover potential near-term and/or longer-term partners, and
                  offer/receive connections to and from other members after
                  reviewing their criteria
                </p>
              </div>
            </div>

            <div className="flex-card">
              <div className="number-header">
                <img className="number-icon" src={Three} alt="Number 3" />
                <h3>Access & Communicate</h3>
              </div>
              <div className="card-content margin-bottom">
                <p>
                  Once mutual connection interest is confirmed, open the lines
                  of communication for discussions and build your long-term
                  relationships
                </p>
              </div>
            </div>
          </div>
        </Card>

        <Card title="Free Membership &#183; Premier Access">
          <div className="membership-card">
            <div className="subtitle">
              <p className="about-header">
                All members can explore the platform, offer/receive unlimited
                connections, and develop capital networks <span>for free.</span>{' '}
                For members with more frequent use or active capital objectives,
                Firm Value Premier offers the following benefits:
              </p>
            </div>

            <div className="card-content membership">
              <div className="membership-left">
                <div className="membership-content">
                  <img src={List} alt="List Icon" />
                  <p className="italic">
                    <span>
                      Post active capital raise or acquisition objectives
                    </span>{' '}
                    and increase potential viewership
                  </p>
                </div>

                <div className="membership-content">
                  <img src={ArrowMan} alt="Arrows Around Man Icon" />
                  <p className="italic">
                    <span>Show members you're active</span> and elevate status
                    to “Actively Sourcing” or “Actively Developing Network”
                  </p>
                </div>

                <div className="membership-content">
                  <img src={LockCircle} alt="Lock in a Circle Icon" />
                  <p className="italic">
                    <span>
                      Choose whether to publicize information or show matching
                      criteria
                    </span>{' '}
                    defaults to other members
                  </p>
                </div>
              </div>

              <div className="membership-right">
                <div className="membership-content">
                  <img src={Mailbox} alt="Mailbox Icon" />
                  <p className="italic">
                    <span>
                      Receive notifications when new members match your criteria
                    </span>{' '}
                    to save time rather than simply exploring
                  </p>
                </div>

                <div className="membership-content">
                  <img src={ThreeBubble} alt="Three Bubble Icon" />
                  <p className="italic">
                    <span>Unlimited monthly connections offered/received</span>{' '}
                    to/from other members
                  </p>
                </div>

                <div className="membership-content">
                  <img src={Toggle} alt="Toggle Icon" />
                  <p className="italic">
                    <span>
                      Easily turn your Firm Value Premier membership on or off
                    </span>{' '}
                    as your objectives continue to evolve
                  </p>
                </div>
              </div>
            </div>
            <h3>Firm Value Premier Free For First 3 Months Post-Launch</h3>
          </div>
        </Card>

        <div id="sign-up-section">
          <div className="sign-up-information">
            <h2>Launching in Q1 2021</h2>
            <p>
              We're collecting membership interest to continue building the
              network around our founding members while we work towards a Q1
              2021 launch. Sign up today to stay up to date on our launch news.
            </p>
          </div>
          {signUpSucceeded ? (
            <p>Thank you for signing up!</p>
          ) : (
            <SignUpForm
              onSubmit={({ email, userType }) =>
                mailchimp
                  .signup({ email, userType })
                  .then(() => setSignUpSucceeded(true))
                  .catch(() =>
                    window.alert('Sign up failed, please try again.')
                  )
              }
            />
          )}
        </div>
      </main>
      <Footer />
    </div>
  )
}

BusinessLanding.propTypes = exact(propTypes)
BusinessLanding.defaultProps = defaultProps

export default React.memo(BusinessLanding)
