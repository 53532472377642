import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import exact from 'prop-types-exact'
import {
  withFormikAdapterAndVisibilityInput,
  validate,
  formHelpers,
} from '../utils'
import {
  DropdownCheckboxGroup as BaseDropownCheckboxGroup,
  Tooltip,
} from 'components'
import { SubmitButton, Spinner } from '@launchpadlab/lp-components'
import { Formik, Form } from 'formik'

import * as api from 'api'
import { Select as BaseSelectInput } from '@launchpadlab/lp-components/lib/forms/inputs'

const DropdownCheckboxGroup = withFormikAdapterAndVisibilityInput()(
  BaseDropownCheckboxGroup
)
const Select = withFormikAdapterAndVisibilityInput()(BaseSelectInput)

const propTypes = {
  params: PropTypes.object,
  handleSubmit: PropTypes.func,
}

const defaultProps = {}

function AdvisorAdvisoryOfferingOverviewEditForm({ params, handleSubmit }) {
  const [disabled, toggleDisabled] = useState(true)
  const [storedData, setStoredData] = useState()

  useEffect(() => {
    async function fetchData() {
      setStoredData(
        await api.getStoredData('/advisor/advisory-offering-overview')
      )
    }
    fetchData()
  }, [])
  const initialValues = {
    advisorServedClients: storedData?.advisorServedClients || [],
    advisorServedLocations: storedData?.advisorServedLocations || [],
    advisorServedSectors: storedData?.advisorServedSectors || [],
    advisorServedOperatingModels:
      storedData?.advisorServedOperatingModels || [],
    advisorServedBusinessStages: storedData?.advisorServedBusinessStages || [],
    advisorFeeStructures: storedData?.advisorFeeStructures || [],
    maxEbitdaServedId: storedData?.maxEbitdaServedId || '',
    minEbitdaServedId: storedData?.minEbitdaServedId || '',
  }

  if (!storedData) {
    return <Spinner />
  }

  function servesBusinesses(values) {
    return values.advisorServedClients.includes(3)
  }

  function validateForm(values) {
    const servesBusinessesValidation = servesBusinesses(values)
      ? { presence: { allowEmpty: false } }
      : {}
    const messages = validate(values, {
      advisorServedClients: {
        presence: { allowEmpty: false },
      },
      advisorServedLocations: {
        presence: { allowEmpty: false },
      },
      advisorFeeStructures: {
        presence: { allowEmpty: false },
      },
      advisorServedBusinessStages: servesBusinessesValidation,
      advisorServedSectors: servesBusinessesValidation,
      advisorServedOperatingModels: servesBusinessesValidation,
      maxEbitdaServedId: {
        presence: {
          allowEmpty: false,
          message: '^Please select one',
        },
      },
      minEbitdaServedId: {
        presence: {
          allowEmpty: false,
          message: '^Please select one',
        },
      },
    })
    // mix custom stuff here w validate.js messages
    return {
      ...messages,
      //
    }
  }

  return (
    <Formik
      onSubmit={handleSubmit}
      validate={validateForm}
      initialValues={initialValues}
    >
      {({ values, setFieldTouched, setFieldValue, handleReset }) => (
        <Form className="onboarding-form">
          {useEffect(() => {
            if (!servesBusinesses(values)) {
              setFieldValue('advisorServedSectors', [])
              setFieldValue('advisorServedOperatingModels', [])
              setFieldValue('advisorServedBusinessStages', [])
            }
          }, [values.advisorServedClients])}
          <div className="row">
            <h1>Advisory Offering Overview</h1>
            {disabled ? (
              <button
                className="button-primary-outline"
                onClick={() => {
                  toggleDisabled(false)
                }}
              >
                Edit
              </button>
            ) : (
              <div className="edit-buttons-area">
                <button
                  className="button-primary-outline"
                  onClick={() => {
                    handleReset()
                    toggleDisabled(true)
                  }}
                >
                  Cancel
                </button>
                <SubmitButton>Submit Changes</SubmitButton>
              </div>
            )}
          </div>
          <div className="row">
            <DropdownCheckboxGroup
              name="advisorServedClients"
              label="Client Served"
              options={formHelpers.formatOptions(params.clientTypes)}
              values={values}
              tags={true}
              setFieldValue={setFieldValue}
              setFieldTouched={setFieldTouched}
              errorMessage="Please select all that apply"
              disabled={disabled}
            />
            <DropdownCheckboxGroup
              name="advisorServedLocations"
              label="Geographies Served"
              options={formHelpers.formatOptions(params.territories)}
              values={values}
              tags={true}
              setFieldValue={setFieldValue}
              setFieldTouched={setFieldTouched}
              errorMessage="Please select all that apply"
              disabled={disabled}
            />
          </div>
          {servesBusinesses(values) ? (
            <>
              <div className="row">
                <DropdownCheckboxGroup
                  name="advisorServedSectors"
                  label="Business Sectors Served"
                  options={formHelpers.formatOptions(params.sectorsIndustries)}
                  values={values}
                  tags={true}
                  setFieldValue={setFieldValue}
                  setFieldTouched={setFieldTouched}
                  errorMessage="Please select all that apply"
                  disabled={disabled}
                />
                <DropdownCheckboxGroup
                  name="advisorServedOperatingModels"
                  label="Business Models Served"
                  options={formHelpers.formatOptions(params.businessModels)}
                  values={values}
                  tags={true}
                  setFieldValue={setFieldValue}
                  setFieldTouched={setFieldTouched}
                  errorMessage="Please select all that apply"
                  disabled={disabled}
                />
              </div>
              <div className="row">
                <DropdownCheckboxGroup
                  name="advisorServedBusinessStages"
                  label="Business Stages Served"
                  options={formHelpers.formatOptions(params.businessStages)}
                  values={values}
                  tags={true}
                  setFieldValue={setFieldValue}
                  setFieldTouched={setFieldTouched}
                  errorMessage="Please select all that apply"
                  disabled={disabled}
                />
                <DropdownCheckboxGroup
                  name="advisorFeeStructures"
                  label="Fee Structure(s) Offered"
                  tooltip={
                    <Tooltip
                      name="advisorFeeStructures"
                      text="Please select all that are applicable"
                    />
                  }
                  options={formHelpers.formatOptions(params.feeStructures)}
                  values={values}
                  tags={true}
                  setFieldValue={setFieldValue}
                  setFieldTouched={setFieldTouched}
                  errorMessage="Please select all that apply"
                  disabled={disabled}
                />
              </div>
            </>
          ) : (
            <div className="row">
              <DropdownCheckboxGroup
                name="advisorFeeStructures"
                label="Fee Structure(s) Offered"
                tooltip={
                  <Tooltip
                    name="advisorFeeStructures"
                    text="Please select all that are applicable"
                  />
                }
                options={formHelpers.formatOptions(params.feeStructures)}
                values={values}
                tags={true}
                setFieldValue={setFieldValue}
                setFieldTouched={setFieldTouched}
                errorMessage="Please select all that apply"
                disabled={disabled}
              />
            </div>
          )}
          <h3>Operating Income Sizes Served</h3>
          <div className="row">
            <Select
              name="minEbitdaServedId"
              label="Minimum"
              options={formHelpers.formatOptions(params.financialMetrics)}
              enablePlaceholderOption={true}
              placeholder=" "
              disabled={disabled}
              onChange={() => {
                if (values.minEbitdaServedId > values.maxEbitdaServedId)
                  setFieldValue('maxEbitdaServedId', '')
              }}
            />
            <Select
              name="maxEbitdaServedId"
              label="Maximum"
              options={formHelpers.getMaxOptionsByMinValue(
                values.minEbitdaServedId,
                params.financialMetrics
              )}
              enablePlaceholderOption={true}
              placeholder={
                values.minEbitdaServedId === '' ? 'Must Select Minimum' : ' '
              }
              disabled={disabled || values.minEbitdaServedId === ''}
            />
          </div>
        </Form>
      )}
    </Formik>
  )
}

AdvisorAdvisoryOfferingOverviewEditForm.propTypes = exact(propTypes)
AdvisorAdvisoryOfferingOverviewEditForm.defaultProps = defaultProps

export default AdvisorAdvisoryOfferingOverviewEditForm
