/* eslint-disable */
import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import exact from 'prop-types-exact'
import { withFormikAdapterAndVisibilityInput } from '../utils'

import bindPasswordValidators from '../packs/sign_up'

import { Card } from 'components'
import { Input as BaseInput, SubmitButton } from '@launchpadlab/lp-components'
import { Formik, Form } from 'formik'

const Input = withFormikAdapterAndVisibilityInput()(BaseInput)

const propTypes = {
  handleSubmit: PropTypes.func,
}

const defaultProps = {}

function EditPasswordForm({ handleSubmit }) {
  const [disabled, toggleDisabled] = useState(true)

  const initialValues = {
    currentPassword: '',
    password: '',
    passwordConfirmation: '',
  }

  useEffect(() => {
    bindPasswordValidators()
  }, [])

  return (
    <Card>
      <Formik onSubmit={handleSubmit} initialValues={initialValues}>
        {({ handleReset }) => (
          <Form className="onboarding-form">
            <div className="row">
              <h1>Password</h1>
              {disabled ? (
                <button
                  className="button-primary-outline"
                  onClick={() => {
                    toggleDisabled(false)
                  }}
                >
                  Edit
                </button>
              ) : (
                <div className="edit-buttons-area">
                  <button
                    className="button-primary-outline"
                    onClick={() => {
                      handleReset()
                      toggleDisabled(true)
                    }}
                  >
                    Cancel
                  </button>
                  <SubmitButton>Submit Changes</SubmitButton>
                </div>
              )}
            </div>
            <div className="row">
              <Input
                name="currentPassword"
                type="password"
                disabled={disabled}
              />
            </div>
            <div className="row">
              <div className="col field">
                <Input name="password" type="password" disabled={disabled}>
                  <span className="password-checkmark hidden"></span>
                </Input>
                <span className="password-req-text">
                  Password must be at least 8 characters, contain uppercase and
                  lowercase letters, at least 1 digit and 1 special character
                </span>
              </div>
              <br />
            </div>
            <div className="row">
              <div className="col field">
                <Input
                  name="passwordConfirmation"
                  type="password"
                  disabled={disabled}
                >
                  <span className="password-confirmation-checkmark hidden"></span>
                </Input>
              </div>
            </div>
          </Form>
        )}
      </Formik>
    </Card>
  )
}

EditPasswordForm.propTypes = exact(propTypes)
EditPasswordForm.defaultProps = defaultProps

export default EditPasswordForm
