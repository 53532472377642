import React from 'react'
import PropTypes from 'prop-types'
import exact from 'prop-types-exact'
import { NavBar } from 'components'

const propTypes = { userSession: PropTypes.object }

const defaultProps = {}

function DashboardHeader({ userSession }) {
  return (
    <header className="dashboard-header">
      <div className="centered-content">
        <NavBar userSession={userSession} />
      </div>
    </header>
  )
}

DashboardHeader.propTypes = exact(propTypes)
DashboardHeader.defaultProps = defaultProps

export default React.memo(DashboardHeader)
