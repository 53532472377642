import React, { useState } from 'react'
import PropTypes from 'prop-types'
import exact from 'prop-types-exact'
import { Card, BlurhashErrorBoundary } from 'components'
import { ConnectionsCardLineItem } from 'components/connections'
import { Button } from '@launchpadlab/lp-components'
import * as api from 'api'
import { Blurhash } from 'react-blurhash'

const propTypes = {
  cardData: PropTypes.object,
}

const defaultProps = {}

function ExploreCardInvestor({ cardData }) {
  const [requestLoading, setRequestLoading] = useState(false)
  const [savedForLater, setSavedForLater] = useState(cardData.savedForLater)

  const profileId = cardData.userId

  const handleViewProfile = () => {
    window.open(`/users/profile?user=${cardData.userId}`)
    return null
  }

  const handleSave = () => {
    setRequestLoading(true)
    api
      .saveUser('users/save-user', {
        userId: profileId,
      })
      .then(() => {
        setSavedForLater(true)
      })
      .catch(() => {
        setSavedForLater(false)
        setRequestLoading(false)
        throw new Error('Could not save user')
      })
    setRequestLoading(false)
  }

  const handleUnsave = () => {
    setRequestLoading(true)
    api
      .unsaveUser('users/remove-saved-user', {
        userId: profileId,
      })
      .then(() => {
        setSavedForLater(false)
      })
      .catch(() => {
        setSavedForLater(true)
        setRequestLoading(false)
        throw new Error('Could not remove saved user')
      })
    setRequestLoading(false)
  }

  const blurredImage = cardData.blurredImage

  return (
    <Card className="explore-card" removeTopRow={true}>
      <div className="connections-card-content">
        <div className="connections-display-details">
          {blurredImage ? (
            <BlurhashErrorBoundary width="80px" height="80px">
              <Blurhash
                className="profile-picture"
                hash={cardData.imageUrl}
                width={80}
                height={80}
                resolutionX={32}
                resolutionY={32}
                punch={1}
              />
            </BlurhashErrorBoundary>
          ) : (
            <div className="profile-picture">
              <img src={cardData.imageUrl} alt="User Profile Image" />
            </div>
          )}

          <div className="profile-information">
            <div className="connections-profile-details">
              <h1>{cardData.name}</h1>
              <h4 className="state-subheader">{cardData.state}</h4>
              <div className="two-col-flexbox">
                <div className="left-col">
                  <ConnectionsCardLineItem
                    label="Years Experience: "
                    value={cardData.yearsOfExperience}
                  ></ConnectionsCardLineItem>
                  <ConnectionsCardLineItem
                    label="Member Status: "
                    value={cardData.membershipStatus}
                  ></ConnectionsCardLineItem>
                  <ConnectionsCardLineItem
                    label="Total Connections: "
                    value={cardData.totalConnections}
                  ></ConnectionsCardLineItem>
                </div>
                <div className="right-col">
                  <ConnectionsCardLineItem
                    label="Accredited Investor: "
                    value={cardData.accreditedInvestor ? 'Yes' : 'No'}
                  ></ConnectionsCardLineItem>
                  <ConnectionsCardLineItem
                    label="Business Location Criteria Match: "
                    value={cardData.locationCriteriaMatch}
                  ></ConnectionsCardLineItem>
                  <ConnectionsCardLineItem
                    label="Sector Criteria Match: "
                    value={cardData.sectorCriteriaMatch}
                  ></ConnectionsCardLineItem>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="connections-buttons">
          <div className="main-buttons">
            <Button className="connections-button" onClick={handleViewProfile}>
              View Profile
            </Button>
            {savedForLater ? (
              <Button
                className="connections-button button-primary-outline"
                submitting={requestLoading}
                onClick={handleUnsave}
              >
                Unsave
              </Button>
            ) : (
              <Button
                className="connections-button button-primary-outline"
                onClick={() => handleSave()}
                submitting={requestLoading}
              >
                Save For Later
              </Button>
            )}
          </div>
        </div>
      </div>
    </Card>
  )
}

ExploreCardInvestor.propTypes = exact(propTypes)
ExploreCardInvestor.defaultProps = defaultProps

export default React.memo(ExploreCardInvestor)
