import React from 'react'
import PropTypes from 'prop-types'
import exact from 'prop-types-exact'
import {
  ProfileSettingsSubSideNav,
  ProfileSettingsMobileSubNav,
  Card,
  Footer,
} from 'components'
import { Route, Switch } from 'react-router-dom'

import {
  BusinessMemberInformationEditForm,
  InvestorMemberInformationEditForm,
  AdvisorMemberInformationEditForm,
  EditPasswordForm,
  DeleteAccountForm,
} from 'forms'

import * as api from 'api'

const propTypes = {
  params: PropTypes.object,
  profileType: PropTypes.string,
  user: PropTypes.object,
  profileData: PropTypes.object,
}

const defaultProps = {}

function EditAccountSettings({ profileType, profileData, user, params }) {
  const memberForms = {
    businessProfile: (
      <Card>
        <BusinessMemberInformationEditForm
          params={params}
          storedData={user[profileType]}
          profileData={profileData}
          user={user}
          handleSubmit={(values) => {
            handleProfileUpdate(values)
          }}
        />
      </Card>
    ),
    investorProfile: (
      <Card>
        <InvestorMemberInformationEditForm
          params={params}
          user={user}
          profileData={profileData}
          storedData={user[profileType]}
          handleSubmit={(values) => {
            handleProfileUpdate(values)
          }}
        />
      </Card>
    ),
    advisorProfile: (
      <Card>
        <AdvisorMemberInformationEditForm
          params={params}
          storedData={user[profileType]}
          profileData={profileData}
          handleSubmit={(values) => {
            handleProfileUpdate(values)
          }}
        />
      </Card>
    ),
  }

  const handleProfileUpdate = (values) => {
    const requestObj = {
      [profileType]: {
        ...values,
      },
    }
    /* eslint-disable */
    api
      .updateProfile(requestObj)
      .then((res) => console.log('response', res))
      .catch(console.log)
      .then(() => {
        window.location.reload(true)
      })
    /* eslint-enable  */
  }

  const handlePasswordEdit = (values, setErrors) => {
    api
      .updatePassword({ user: { ...values } })
      .then(() => {
        window.location.reload(true)
      })
      .catch(function (error) {
        const data = error.response.data.errors
        const errors = Object.keys(data).reduce((obj, key) => {
          if (key === 'currentPassword') {
            obj[key] = 'Current password does not match'
          }
          return obj
        }, {})
        setErrors(errors)
      })
  }

  const handleDeleteAccount = () => {}

  return (
    <>
      <div className="edit-profile-settings">
        <ProfileSettingsMobileSubNav />
        <ProfileSettingsSubSideNav />
        <div className="settings-area">
          <div className="settings-wrapper">
            <Switch>
              <Route path="/account-settings/password">
                <EditPasswordForm
                  handleSubmit={(values, { setErrors, resetForm }) => {
                    handlePasswordEdit(values, setErrors, resetForm)
                  }}
                />
              </Route>
              <Route path="/account-settings/delete-account">
                <DeleteAccountForm handleDeleteAccount={handleDeleteAccount} />
              </Route>
              <Route>{memberForms[profileType]}</Route>
            </Switch>
          </div>
        </div>
      </div>
      <Footer></Footer>
    </>
  )
}

EditAccountSettings.propTypes = exact(propTypes)
EditAccountSettings.defaultProps = defaultProps

export default React.memo(EditAccountSettings)
