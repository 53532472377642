import React from 'react'
import PropTypes from 'prop-types'
import exact from 'prop-types-exact'
import ReactPaginate from 'react-paginate'

const propTypes = {
  handlePageClick: PropTypes.func,
  activePage: PropTypes.number,
  totalPages: PropTypes.number,
}

const defaultProps = {}

function PaginationControls({ handlePageClick, activePage, totalPages }) {
  return (
    <ReactPaginate
      initialPage={activePage}
      previousLabel={'<'}
      nextLabel={'>'}
      breakLabel={'...'}
      breakClassName={'break-me'}
      pageCount={totalPages}
      marginPagesDisplayed={1}
      pageRangeDisplayed={2}
      onPageChange={handlePageClick}
      containerClassName={'pagination'}
      subContainerClassName={'pages pagination'}
      activeClassName={'active'}
    />
  )
}

PaginationControls.propTypes = exact(propTypes)
PaginationControls.defaultProps = defaultProps

export default React.memo(PaginationControls)
