import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import exact from 'prop-types-exact'
import {
  withFormikAdapterAndVisibilityInput,
  validate,
  formHelpers,
} from '../utils'
import {
  DropdownCheckboxGroup as BaseDropownCheckboxGroup,
  Tooltip,
} from 'components'
import {
  Select as BaseSelectInput,
  SubmitButton,
  Spinner,
} from '@launchpadlab/lp-components'
import { Formik, Form } from 'formik'

import * as api from 'api'

const DropdownCheckboxGroup = withFormikAdapterAndVisibilityInput()(
  BaseDropownCheckboxGroup
)
const Select = withFormikAdapterAndVisibilityInput()(BaseSelectInput)

const propTypes = {
  params: PropTypes.object,
  handleSubmit: PropTypes.func,
}

const defaultProps = {}

function InvestorInvestmentOpportunityCriteriaEditForm({
  params,
  handleSubmit,
}) {
  const [disabled, toggleDisabled] = useState(true)
  const [storedData, setStoredData] = useState()

  useEffect(() => {
    async function fetchData() {
      setStoredData(
        await api.getStoredData('/investor/investment-opportunity-criteria')
      )
    }
    fetchData()
  }, [])

  const initialValues = {
    investorConsideredInvestmentMethods:
      storedData?.investorConsideredInvestmentMethods || [],
    investorConsideredOfferingTypes:
      storedData?.investorConsideredOfferingTypes || [],
    investorConsideredEquityStakes:
      storedData?.investorConsideredEquityStakes || [],
    minConsideredEquityCheckId: storedData?.minConsideredEquityCheck || '',
    maxConsideredEquityCheckId: storedData?.maxConsideredEquityCheck || '',
    investorConsideredCapitalNeedReasons:
      storedData?.investorConsideredCapitalNeedReasons || [],
    investorPostInvestmentIntentions:
      storedData?.investorPostInvestmentIntentions || [],

    consideredOfferingTypesVisibility:
      storedData?.consideredOfferingTypesVisibility || false,
    consideredEquityStakesVisibility:
      storedData?.consideredEquityStakesVisibility || false,
    minConsideredEquityCheckVisibility:
      storedData?.minConsideredEquityCheckVisibility || false,

    consideredCapitalNeedReasonsVisibility:
      storedData?.consideredCapitalNeedReasonsVisibility || false,
    postInvestmentIntentionsVisibility:
      storedData?.postInvestmentIntentionsVisibility || false,
  }

  if (!storedData) {
    return <Spinner />
  }

  function hasNonFundPartnerships(values) {
    return values.investorConsideredInvestmentMethods.some((el) => {
      return el === 1 || el === 2
    })
  }

  function isConsideringDirectInvestment(values) {
    return values.investorConsideredInvestmentMethods.some((el) =>
      [1].includes(el)
    )
  }

  function validateForm(values) {
    const isConsideringNonFundPartnershipsValidation = hasNonFundPartnerships(
      values
    )
      ? { presence: { allowEmpty: false } }
      : {}

    const isConsideringNonFundPartnershipsWithDirectInvestmentValidations = isConsideringDirectInvestment(
      values
    )
      ? { presence: { allowEmpty: false } }
      : {}

    const messages = validate(values, {
      investorConsideredInvestmentMethods: {
        presence: { allowEmpty: false },
      },
      minConsideredEquityCheckId: {
        presence: {
          allowEmpty: false,
          message: '^Please select one',
        },
      },
      maxConsideredEquityCheckId: {
        presence: {
          allowEmpty: false,
          message: '^Please select one',
        },
      },
      investorConsideredOfferingTypes: isConsideringNonFundPartnershipsValidation,
      investorConsideredEquityStakes: isConsideringNonFundPartnershipsValidation,
      investorConsideredCapitalNeedReasons: isConsideringNonFundPartnershipsValidation,
      investorPostInvestmentIntentions: isConsideringNonFundPartnershipsWithDirectInvestmentValidations,
    })
    // mix custom stuff here w validate.js messages
    return {
      ...messages,
      //
    }
  }
  return (
    <Formik
      onSubmit={handleSubmit}
      validate={validateForm}
      initialValues={initialValues}
    >
      {({ values, setFieldTouched, setFieldValue, handleReset }) => (
        <Form className="onboarding-form">
          {useEffect(() => {
            if (!isConsideringDirectInvestment(values)) {
              setFieldValue('investorPostInvestmentIntentions', [])
            }
          }, [values.investorConsideredInvestmentMethods])}
          <div className="row">
            <h1>Investment Opportunity Criteria</h1>
            {disabled ? (
              <button
                className="button-primary-outline"
                onClick={() => {
                  toggleDisabled(false)
                }}
              >
                Edit
              </button>
            ) : (
              <div className="edit-buttons-area">
                <button
                  className="button-primary-outline"
                  onClick={() => {
                    handleReset()
                    window.location.reload(true)
                    toggleDisabled(true)
                  }}
                >
                  Cancel
                </button>
                <SubmitButton>Submit Changes</SubmitButton>
              </div>
            )}
          </div>
          <p className="disclaimer">
            Full criteria is <span id="underline">not</span> publicized to other
            members. Members only see whether or not each other's criteria
            matches.
          </p>
          <div className="row">
            <DropdownCheckboxGroup
              name="investorConsideredInvestmentMethods"
              label="Investment Methods Considered"
              errorMessage="Please select all that apply"
              options={formHelpers.formatOptions(params.investmentMethods)}
              setFieldValue={setFieldValue}
              setFieldTouched={setFieldTouched}
              values={values}
              tags={true}
              disabled={disabled}
            />
            {hasNonFundPartnerships(values) && (
              <DropdownCheckboxGroup
                name="investorConsideredOfferingTypes"
                label="Offering Type(s) Considered"
                errorMessage="Please select all that apply"
                options={formHelpers.formatOptions(params.offeringTypes)}
                setFieldValue={setFieldValue}
                setFieldTouched={setFieldTouched}
                values={values}
                tags={true}
                disabled={disabled}
                hasVisibilityField
              />
            )}
          </div>
          {hasNonFundPartnerships(values) && (
            <div className="row">
              <DropdownCheckboxGroup
                name="investorConsideredCapitalNeedReasons"
                label="Reasons For Capital Need Considered"
                options={formHelpers.formatOptions(params.capitalNeedReasons)}
                setFieldValue={setFieldValue}
                setFieldTouched={setFieldTouched}
                errorMessage="Please select all that apply"
                values={values}
                tags={true}
                disabled={disabled}
                hasVisibilityField
              />
              <DropdownCheckboxGroup
                name="investorConsideredEquityStakes"
                label="Likely Equity Stakes Considered"
                errorMessage="Please select all that apply"
                tooltip={
                  <Tooltip
                    name="investorConsideredEquityStakes"
                    text="Please select the amount of equity offered by a business that you would consider"
                  />
                }
                options={formHelpers.formatOptions(params.equityStakes)}
                setFieldValue={setFieldValue}
                setFieldTouched={setFieldTouched}
                values={values}
                tags={true}
                disabled={disabled}
                hasVisibilityField
              />
            </div>
          )}
          <div className="row">
            <Select
              name="minConsideredEquityCheckId"
              label="Likely Minimum Considered Equity Check"
              options={formHelpers.formatOptions(params.investorCapital)}
              enablePlaceholderOption={true}
              placeholder=" "
              disabled={disabled}
              onChange={() => {
                if (
                  values.minConsideredEquityCheckId >
                  values.maxConsideredEquityCheckId
                ) {
                  setFieldValue('maxConsideredEquityCheckId', '')
                }
              }}
            />
            <Select
              name="maxConsideredEquityCheckId"
              label="Likely Maximum Considered Equity Check"
              options={formHelpers.getMaxOptionsByMinValue(
                values.minConsideredEquityCheckId,
                params.investorCapital
              )}
              enablePlaceholderOption={true}
              placeholder={
                values.minConsideredEquityCheckId === ''
                  ? 'Must Select Minimum'
                  : ' '
              }
              disabled={disabled || values.minConsideredEquityCheckId === ''}
              hasVisibilityField
              visibilityName="minConsideredEquityCheckId"
            />
          </div>
          {hasNonFundPartnerships(values) &&
            isConsideringDirectInvestment(values) && (
              <div className="row">
                <DropdownCheckboxGroup
                  name="investorPostInvestmentIntentions"
                  label="Potential Intentions Post-Investment"
                  errorMessage="Please select all that apply"
                  options={formHelpers.formatOptions(
                    params.postInvestmentIntentions
                  )}
                  setFieldValue={setFieldValue}
                  setFieldTouched={setFieldTouched}
                  values={values}
                  tags={true}
                  disabled={disabled}
                  hasVisibilityField
                />
              </div>
            )}
        </Form>
      )}
    </Formik>
  )
}

InvestorInvestmentOpportunityCriteriaEditForm.propTypes = exact(propTypes)
InvestorInvestmentOpportunityCriteriaEditForm.defaultProps = defaultProps

export default InvestorInvestmentOpportunityCriteriaEditForm
