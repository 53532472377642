import React from 'react'
import PropTypes from 'prop-types'
import exact from 'prop-types-exact'
import {
  withFormikAdapterAndVisibilityInput,
  validate,
  formHelpers,
} from '../utils'
import {
  DropdownCheckboxGroup as BaseDropownCheckboxGroup,
  ImageUploadPreview,
  Tooltip,
} from 'components'
import {
  Select as BaseSelectInput,
  Button,
  Checkbox as BaseCheckbox,
  SubmitButton,
} from '@launchpadlab/lp-components'
import { Formik, Form } from 'formik'
import { isEmpty } from 'lodash'

const DropdownCheckboxGroup = withFormikAdapterAndVisibilityInput()(
  BaseDropownCheckboxGroup
)
const Select = withFormikAdapterAndVisibilityInput()(BaseSelectInput)
const Checkbox = withFormikAdapterAndVisibilityInput()(BaseCheckbox)

const propTypes = {
  params: PropTypes.object.isRequired,
  formValues: PropTypes.object.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  handleBack: PropTypes.func.isRequired,
}

const defaultProps = {}

function AdvisorPage2Form({ params, formValues, handleSubmit, handleBack }) {
  function servesBusinesses(values) {
    return values.advisorServedClients.includes(3)
  }
  function validateForm(values) {
    const servesBusinessesValidation = servesBusinesses(values)
      ? { presence: { allowEmpty: false } }
      : {}

    const defaultSectorIconSelected = values.defaultImage

    const messages = validate(values, {
      advisorServedClients: {
        presence: { allowEmpty: false },
      },
      advisorServedLocations: {
        presence: { allowEmpty: false },
      },
      advisorServedBusinessStages: servesBusinessesValidation,
      advisorServedSectors: servesBusinessesValidation,
      advisorServedOperatingModels: servesBusinessesValidation,
      minEbitdaServedId: {
        presence: {
          allowEmpty: false,
          message: '^Please select one',
        },
      },
      maxEbitdaServedId: {
        presence: {
          allowEmpty: false,
          message: '^Please select one',
        },
      },
      advisorFeeStructures: {
        presence: { allowEmpty: false },
      },
      membershipStatusId: {
        presence: {
          allowEmpty: false,
          message: '^Please select one',
        },
      },
      defaultImage: function (value, attributes) {
        if (attributes.profileImageUrl === '') {
          return { presence: { allowEmpty: false } }
        } else {
          return { presence: { allowEmpty: true } }
        }
      },
      profileImageUrl: function () {
        if (defaultSectorIconSelected) {
          return
        } else {
          return {
            presence: { allowEmpty: false, message: '^Profile image required' },
          }
        }
      },
    })
    // mix custom stuff here w validate.js messages
    return {
      ...messages,
      //
    }
  }
  return (
    <Formik
      onSubmit={(values) => handleSubmit(values, true)}
      validate={validateForm}
      initialValues={formValues}
    >
      {({ values, errors, setFieldValue, setFieldTouched, touched }) => (
        <Form className="onboarding-form setup-form">
          <h1>Advisory Offering Overview</h1>
          {servesBusinesses(values) ? (
            <>
              <div className="row">
                <DropdownCheckboxGroup
                  name="advisorServedClients"
                  label="Client Served"
                  options={formHelpers.formatOptions(params.clientTypes)}
                  values={values}
                  tags={true}
                  setFieldValue={setFieldValue}
                  setFieldTouched={setFieldTouched}
                  errorMessage="Please select all that apply"
                />
                <DropdownCheckboxGroup
                  name="advisorServedLocations"
                  label="Geographies Served"
                  options={formHelpers.formatOptions(params.territories)}
                  values={values}
                  tags={true}
                  setFieldValue={setFieldValue}
                  setFieldTouched={setFieldTouched}
                  errorMessage="Please select all that apply"
                />
              </div>
              <div className="row">
                <DropdownCheckboxGroup
                  name="advisorServedSectors"
                  label="Business Sectors Served"
                  options={formHelpers.formatOptions(params.sectorsIndustries)}
                  values={values}
                  tags={true}
                  setFieldValue={setFieldValue}
                  setFieldTouched={setFieldTouched}
                  errorMessage="Please select all that apply"
                />
                <DropdownCheckboxGroup
                  name="advisorServedOperatingModels"
                  label="Business Models Served"
                  options={formHelpers.formatOptions(params.businessModels)}
                  values={values}
                  tags={true}
                  setFieldValue={setFieldValue}
                  setFieldTouched={setFieldTouched}
                  errorMessage="Please select all that apply"
                />
              </div>
              <div className="row">
                <DropdownCheckboxGroup
                  name="advisorServedBusinessStages"
                  label="Business Stages Served"
                  options={formHelpers.formatOptions(params.businessStages)}
                  values={values}
                  tags={true}
                  setFieldValue={setFieldValue}
                  setFieldTouched={setFieldTouched}
                  errorMessage="Please select all that apply"
                />
                <DropdownCheckboxGroup
                  name="advisorFeeStructures"
                  label="Fee Structure(s) Offered"
                  tooltip={
                    <Tooltip
                      name="advisorFeeStructures"
                      text="Please select all that are applicable"
                    />
                  }
                  options={formHelpers.formatOptions(params.feeStructures)}
                  values={values}
                  tags={true}
                  setFieldValue={setFieldValue}
                  setFieldTouched={setFieldTouched}
                  errorMessage="Please select all that apply"
                />
              </div>
            </>
          ) : (
            <>
              <div className="row">
                <DropdownCheckboxGroup
                  name="advisorServedClients"
                  label="Client Served"
                  options={formHelpers.formatOptions(params.clientTypes)}
                  values={values}
                  tags={true}
                  setFieldValue={setFieldValue}
                  setFieldTouched={setFieldTouched}
                  errorMessage="Please select all that apply"
                />
                <DropdownCheckboxGroup
                  name="advisorServedLocations"
                  label="Geographies Served"
                  options={formHelpers.formatOptions(params.territories)}
                  values={values}
                  tags={true}
                  setFieldValue={setFieldValue}
                  setFieldTouched={setFieldTouched}
                  errorMessage="Please select all that apply"
                />
              </div>
              <div className="row">
                <DropdownCheckboxGroup
                  name="advisorFeeStructures"
                  label="Fee Structure(s) Offered"
                  tooltip={
                    <Tooltip
                      name="advisorFeeStructures"
                      text="Please select all that are applicable"
                    />
                  }
                  options={formHelpers.formatOptions(params.feeStructures)}
                  values={values}
                  tags={true}
                  setFieldValue={setFieldValue}
                  setFieldTouched={setFieldTouched}
                  errorMessage="Please select all that apply"
                />
              </div>
            </>
          )}
          <h3>Operating Income Sizes Served</h3>
          <div className="row">
            <Select
              name="minEbitdaServedId"
              label="Minimum"
              options={formHelpers.formatOptions(params.financialMetrics)}
              enablePlaceholderOption={true}
              placeholder=" "
              onChange={() => {
                if (values.minEbitdaServedId > values.maxEbitdaServedId)
                  setFieldValue('maxEbitdaServedId', '')
              }}
            />
            <Select
              name="maxEbitdaServedId"
              label="Maximum"
              options={formHelpers.getMaxOptionsByMinValue(
                values.minEbitdaServedId,
                params.financialMetrics
              )}
              enablePlaceholderOption={true}
              placeholder={
                isEmpty(values.minEbitdaServedId) ? 'Must Select Minimum' : ' '
              }
              disabled={values.minEbitdaServedId === ''}
            />
          </div>
          <h1>Advisory Exploration Overview</h1>
          <div className="row">
            <Select
              name="membershipStatusId"
              label="Membership Status"
              options={formHelpers.formatOptions(params.membershipStatuses)}
              enablePlaceholderOption={true}
              placeholder=" "
            />
          </div>
          <h1>Profile Image</h1>
          <div className="row">
            <div className="column">
              <Checkbox
                name="defaultImage"
                label="Use Default Sector Icon"
                onChange={(e) => {
                  if (e.target.value === true) {
                    setFieldValue('profileImageUrl', '')
                  }
                }}
              />
            </div>
            <div className="column">
              {values.defaultImage ? (
                <div className="image-upload-preview">
                  <img
                    src={params.defaultImage}
                    alt="Default Advisor Image"
                    style={{ width: '60px' }}
                  />
                </div>
              ) : (
                <div className="profile-image-error">
                  <ImageUploadPreview
                    name="profileImageUrl"
                    hasStoredValue={values.profileImageUrl}
                  />
                </div>
              )}
            </div>
          </div>
          <p className="disclaimer submission-disclaimer">
            By clicking submit you certify that you have only posted factual
            information about yourself, your company, and/or your investment
            fund.
          </p>
          <div className="submission-error-wrapper">
            <div className="button-area">
              <Button
                className="form-button button-secondary"
                onClick={() => handleBack(values)}
              >
                Back
              </Button>
              <SubmitButton className="form-button submit">Submit</SubmitButton>
            </div>
            <div className="error-message-submission">
              {values.defaultImage &&
              Object.keys(errors).length === 1 &&
              Object.keys(errors).includes('profileImageUrl')
                ? null
                : Object.keys(touched).some((key) => {
                    return Object.keys(errors).includes(key)
                  })
                ? 'Please fix the errors presented above'
                : null}
            </div>
          </div>
        </Form>
      )}
    </Formik>
  )
}

AdvisorPage2Form.propTypes = exact(propTypes)
AdvisorPage2Form.defaultProps = defaultProps

export default AdvisorPage2Form
