import React from 'react'
import PropTypes from 'prop-types'
import exact from 'prop-types-exact'
import { Helmet } from 'react-helmet'
import { Header, Card, Footer } from '../components'

const defaultProps = {}

const propTypes = { userSession: PropTypes.object }

function TermsConditions({ userSession }) {
  return (
    <>
      <Helmet>
        <title>Terms of Service</title>
      </Helmet>
      <Header
        className="mini-header"
        title="Terms of Service"
        userSession={userSession}
      />

      <Card>
        <div className={'policy-text'}>
          <p>POSTED DATE: November 11, 2020</p>
          <p>
            The following Terms of Service (“Terms”) are entered into between
            you, or the organization that you represent (hereinafter “you” or
            “your”) and Firm Value, LLC (hereinafter “Firm Value,” “us,” or
            “we”), regarding your use of Firm Value’s website and online
            platform (the “Platform”) and services owned and operated by Firm
            Value (collectively with the Platform, the “Services”). If you do
            not agree to these Terms, do not access or use the Services.
          </p>
          <p>
            <u>
              <b>Uses of the Services and Key Limitations</b>
            </u>
          </p>
          <p>
            The Services are designed so that officers and owners of private
            companies, private investors, financial advisors and accountants can
            confidentially identify, connect, and be found by one another for
            the purposes of identifying and engaging with relevant capital
            partners, originating and managing investment, fund raise, or merger
            and acquisition opportunities or generally surveying the landscape
            of future investment or engagement opportunities. Users of the
            Services (“Members”) have access to a variety of tools and functions
            that can be used for these purposes. These include the ability to
            create a Member profile that can be viewed by other Members, the
            ability to explore other Members’ profiles, the ability to share
            descriptions of active capital or investment related objectives on
            your own or your clients’ behalf, a data-driven matching system, the
            ability to offer and receive connection requests, and a messaging
            platform for use post-connection.
          </p>
          <p>
            Firm Value does not control the information or material provided by
            other Members that is made available through the Services. None of
            the information submitted on the Services with respect to any Member
            profile or Transaction Opportunity (defined below) constitutes a
            solicitation, offer, opinion, or recommendation by Firm Value to buy
            or sell any assets or securities or other financial instruments,
            endorse financial advisors or accountants or their services, or to
            provide legal, tax, accounting, or investment advice regarding the
            suitability or profitability of any security of any kind, investment
            or transaction. Firm Value makes no recommendations and offers no
            opinion in connection with the merit of any Transaction Opportunity.
            For these reasons, all Members of the Services agree to be
            responsible for their own due diligence and legal and regulatory
            compliance in connection with any Transaction Opportunity, as
            further discussed below in “Securities Laws”. Firm Value is not a
            party to any transaction or relationship between sellers, acquirers,
            purchasers, investors, and/or financial advisors or accountants
            (whether person or entity) that may occur between Members of the
            Platform. All members are encouraged to seek professional counsel
            and/or advice with respect to all applicable laws, statutes,
            ordinances, rules and regulations.
          </p>
          <p>
            Members are required to provide true and accurate information, and
            to update and maintain such information, but Firm Value cannot and
            does not confirm the accuracy of information provided.
          </p>
          <p>
            The information contained on the Platform has been prepared by
            Members without reference to any particular Member's investment
            requirements or financial situation. The information and services
            provided on the Platform are not provided to, and may not be used
            by, any person or entity in any jurisdiction where the provision or
            use thereof would be contrary to applicable laws, rules or
            regulations of any governmental authority or where Firm Value is not
            authorized to provide such information or services. Use of the
            Platform is restricted to citizens of the United States, in the case
            of individuals, and businesses domiciled in the United States.
          </p>
          <p>
            <u>
              <b>Privacy</b>
            </u>
          </p>
          <p>
            Your use of the Services is subject to our{' '}
            <a href={'/privacy-policy'} target={'_blank'} rel="noreferrer">
              Privacy Policy
            </a>
            , which is expressly incorporated into these Terms by reference.
          </p>
          <p>
            <u>
              <b>Age Requirement</b>
            </u>
          </p>
          <p>
            In order to create a membership account or use the Services, you
            represent and warrant that you are at least 18 years of age. If you
            are under age 18, you may not, under any circumstances or for any
            reason, use the Services.
          </p>
          <p>
            <u>
              <b>Membership Accounts</b>
            </u>
          </p>
          <p>
            You must sign up for a membership account by providing all required
            information in order to access or use certain components of the
            Services. You agree to be solely responsible for maintaining: (i)
            true, accurate, current and complete information as prompted by the
            membership sign up process; and (ii) the confidentiality of the
            email address associated with your membership, password, account
            credentials, and other sensitive information. Once you sign up for a
            membership account, you are permitted to utilize our Services, which
            include (a) building a member profile, (b) accessing the community
            of businesses, investors and advisors on the Platform, as limited by
            restrictions on viewing of and communication with certain Members as
            selected by such Members and (c) posting transaction, equity and/or
            debt raise objectives, co-investment opportunities, private
            investment fund fundraising objectives, acquisition criteria,
            investment criteria and any of the foregoing in this sentence by an
            advisor on behalf of a client (any of the foregoing postings in this
            subsection (c), a “Transaction Opportunity”).
          </p>
          <p>
            <u>
              <b>Fees and Payment</b>
            </u>
          </p>
          <p>
            We reserve the right to adjust our fees for the Services from time
            to time, without providing prior notice. You are required to agree
            to a fee schedule when creating a membership account, or when
            upgrading your level of Services. The current fee schedule and
            payment terms are available on the respective Member overview pages
            for{' '}
            <a href={'/investors'} target={'_blank'} rel="noreferrer">
              investors
            </a>
            ,{' '}
            <a href={'/business'} target={'_blank'} rel="noreferrer">
              businesses
            </a>{' '}
            and{' '}
            <a href={'/advisors'} target={'_blank'} rel="noreferrer">
              advisors
            </a>
            , and they are fully incorporated herein by reference. The
            incorporation of the fee schedule, by reference, includes any
            changes to pricing or availability of choices. You are responsible
            for all fees and costs associated with your use of the Services,
            including any fees for “premier” services that you select.
          </p>
          <p>
            <u>
              <b>Compliance with Laws</b>
            </u>
          </p>
          <p>
            You agree that you will comply with all applicable laws and
            regulations in connection with your use of the Platform and with any
            Transaction Opportunity referenced on the Platform. Without limiting
            the foregoing, federal securities law requires securities sold in
            the United States to be registered with the Securities and Exchange
            Commission (“SEC”), unless the sale qualifies for one of the
            available exemptions under applicable regulations. The following are
            examples of (but by no means an exhaustive list of) relevant
            variables for you as a potential issuer or purchaser of securities
            to consider when determining if a prospective sale or purchase of
            securities falls within an available exemption: (a) whether all of
            the investors are “accredited investors” or “qualified purchasers”,
            (b) whether there is a pre-existing relationship between the issuer
            and investor and (c) whether the issuer, or any person acting on
            such issuer’s behalf, offers or sells the securities by any form of
            “general solicitation”. We specifically note that any posting of a
            capital raise objective or co-investment opportunity to your profile
            would possibly constitute a “general solicitation”. Issuers
            utilizing general solicitation commonly seek an exemption under Rule
            506(c) of Regulation D, which would require you to take reasonable
            steps to verify that all of the investors in an offering of your
            securities are “accredited investors”. We are not responsible for
            verifying that that any investor is an “accredited investor”, a
            “qualified purchaser”, or otherwise authorized or appropriate to
            invest in your securities even if Members present themselves as
            such, for determining whether any use of Firm Value constitutes a
            general solicitation of securities or an exempt securities offering,
            or for making any other determination or verification under the
            securities laws of the United States, or the laws of any state or
            other jurisdiction, including foreign jurisdictions. Furthermore,
            many states and foreign jurisdictions have their own regulations
            governing securities transactions that must be observed. You must
            make your own assessment regarding regulatory requirements as may be
            applied to your activities on the Platform. We encourage all Members
            to seek professional counsel and/or advice with respect to all
            applicable laws, statutes, ordinances, rules and regulations.
          </p>
          <p>
            For the avoidance of doubt, and consistent with “Indemnity” below,
            you agree to indemnify and hold harmless Firm Value, our affiliates
            and each of our and their respective officers, directors, advisors,
            agents, and employees, from and against any suit, action, claim,
            demand, penalty or loss, including reasonable attorneys’ fees, made
            by or resulting from any third party (including any Governmental
            Entity) due to or arising out of your violation of any law or
            regulation (including any securities laws or regulations).
          </p>
          <p>
            “Governmental Entity” shall mean any court, tribunal, arbitrator,
            authority, agency, commission, official or other instrumentality of
            the government of the United States or of any foreign country, any
            state or any political subdivision of any such government (whether
            state, provincial, county, city, municipal or otherwise).
          </p>
          <p>
            <u>
              <b>Intellectual Property and Copyright</b>
            </u>
          </p>
          <p>
            All intellectual property, including copyrights, trademarks, service
            marks, patents, trade secrets, know-how and other confidential
            information, trade dress, trade names, logos, corporate names,
            domain names, text, designs, graphics, button icons, images, audio
            clips, digital downloads, interfaces, data compilations, software,
            code, and arrangement of our Services, together with all of the
            goodwill associated therewith, shall be exclusively owned by Firm
            Value. All intellectual property rights in our Services are
            protected by applicable United States and international intellectual
            property laws and treaties.
          </p>
          <p>
            Neither the Services nor any portion thereof may be modified,
            copied, reproduced, downloaded, posted, transmitted, transferred,
            sold, or distributed in any form except as described in these Terms.
            You agree to comply with all copyright and other laws worldwide in
            your use of our Services and to prevent any unauthorized copying of
            any content from our Services.
          </p>
          <p>
            Any use of the Services not expressly permitted by these Terms is a
            breach of the Terms and may violate copyright, trademark, and other
            laws. If you print, copy, modify, download, or otherwise use or
            provide any other person with access to any part of the Services in
            a manner that breaches these Terms, Firm Value retains the right in
            its sole and absolute discretion to immediately terminate your right
            to use the Services.
          </p>
          <p>
            <u>
              <b>Acceptable Use</b>
            </u>
          </p>
          <p>You agree not to:</p>
          <ul>
            <li>
              knowingly enter any false information about a company or person;
            </li>
            <li>
              intentionally misrepresent your relationship to a deal or
              Transaction Opportunity;
            </li>
            <li>
              provide any false or misleading information to us or any other
              Member; or
            </li>
            <li>engage in any fraudulent activity of any kind.</li>
          </ul>
          <p>
            You may not (and may not permit any third party to) input any
            information or content into the Services that:
          </p>
          <ul>
            <li>you know is false, misleading, untruthful or inaccurate;</li>
            <li>
              is promotional in nature, including solicitations for funds or
              businesses, without the prior written authorization of Firm Value;
            </li>
            <li>
              constitutes junk mail, spam, chain letters, pyramid schemes or the
              like;
            </li>
            <li>
              is unlawful, harmful, threatening, abusive, harassing, tortious,
              defamatory, vulgar, obscene, libelous, deceptive, fraudulent,
              invasive of another’s privacy, offensive, profane, hateful or is
              racially, ethnically or otherwise objectionable as determined by
              us in our sole discretion, or otherwise violates the legal rights
              of others;
            </li>
            <li>
              makes available any information under any law or under contractual
              or fiduciary relationships (such as inside information,
              proprietary and confidential information learned or disclosed as
              part of employment relationships or under nondisclosure
              agreements);
            </li>
            <li>
              infringes any patent, trademark, trade secret, copyright, right of
              publicity or other proprietary rights of any party or violates any
              law or contractual duty;
            </li>
            <li>
              impersonates any person or entity, including any of our employees
              or representatives; or contains software viruses or any other
              computer code, files, or programs designed to interrupt, destroy,
              gain access to or limit the functionality of any computer software
              or hardware, or telecommunications equipment.
            </li>
          </ul>
          <p>
            You shall not: (i) take any action that imposes or may impose (as
            determined by us in our reasonable discretion) an unreasonable or
            disproportionately large load or burden on our (or our third party
            providers’) infrastructure; (ii) interfere or attempt to interfere
            with the proper working of the Services or any activities conducted
            on the Services; (iii) bypass, circumvent or attempt to bypass or
            circumvent any measures we may use to prevent or restrict access to
            the Services (or other accounts, computer systems or networks
            connected to the Services); (iv) use manual or automated software,
            devices, or other processes to “crawl” or “spider” any page of the
            website; (v) harvest or scrape any content from the Services; or
            (vi) otherwise take any action in violation of these Terms.
          </p>
          <p>
            You shall not (directly or indirectly): (i) decipher, decompile,
            disassemble, reverse engineer or otherwise attempt to derive any
            source code or underlying ideas or algorithms of any part of the
            Services (including without limitation any application), except to
            the limited extent applicable laws specifically prohibit such
            restriction; (ii) modify, translate, or otherwise create derivative
            works of any part of the Services; or (iii) copy, rent, lease,
            distribute, or otherwise transfer any of the rights that you receive
            hereunder.
          </p>
          <p>
            We reserve the right to access, read, preserve, and disclose
            information (other than any information that you designate as
            confidential) to the extent necessary to (i) satisfy any applicable
            law, regulation, legal process or governmental request; (ii) enforce
            these Terms, including without limitation, investigation of
            potential violations hereof; (iii) detect, prevent, or otherwise
            address fraud, security or technical issues; (iv) respond to Member
            support requests; or (v) protect the rights, property or safety of
            us, our Members, and the public.
          </p>
          <p>
            <u>
              <b>Member License</b>
            </u>
          </p>
          <p>
            Subject to these Terms, we grant you a worldwide, non-exclusive,
            non-sublicensable and non-transferable license to use (i.e., to
            download and display locally) the Services. Any unauthorized use,
            reproduction, modification, distribution, transmission,
            republication, display or performance of the Services is strictly
            prohibited. You may copy information from the Services only as
            strictly necessary for your own use of the Services. Otherwise, no
            portion of the Services may be reproduced, duplicated, copied, sold,
            resold, licensed, rented or otherwise exploited for any commercial
            purpose that is not expressly permitted by Firm Value. The
            commercial use or public dissemination of any information and data
            gathered from Firm Value is strictly prohibited, unless specifically
            authorized in writing.
          </p>
          <p>
            <u>
              <b>Member Content</b>
            </u>
          </p>
          <p>
            All content added, created, uploaded, submitted, distributed, or
            posted to the Services by Members (collectively, “Member Content”),
            whether publicly posted or privately transmitted, is the sole
            responsibility of the person who originated such Member Content. You
            represent and warrant that you have all rights to provide the Member
            Content and grant the licenses set forth below to us without
            infringement or violation of any third party rights, including
            without limitation, any privacy rights, publicity rights,
            copyrights, droit moral rights, trademarks, contract rights, or any
            other intellectual property or proprietary rights.
          </p>
          <p>
            <u>
              <b>License to Firm Value</b>
            </u>
          </p>
          <p>
            You grant to Firm Value a limited, non-exclusive, non-sublicensable
            and non-transferable license to use any information disclosed to
            Firm Value for the purposes of marketing and improving the Services,
            provided that in no event shall Firm Value use the actual names of
            members in its marketing without their prior consent. In addition,
            Firm Value shall be permitted to aggregate all such information for
            internal or commercial purposes, provided that in no event shall any
            such information include the actual names of members.
          </p>
          <p>
            <u>
              <b>Availability of Content</b>
            </u>
          </p>
          <p>
            We do not guarantee that any content will be made available on the
            Services or through the Services. Firm Value reserves the right, in
            its sole discretion, to (i) remove, edit or modify any content, at
            any time, without notice to you and for any reason (including, but
            not limited to, upon receipt of claims or allegations from third
            parties or authorities that such content infringes or appears to
            infringe on intellectual property rights owned by others or if we
            are concerned that you may have violated the Terms), or for no
            reason at all; and (ii) to remove or block any Content from the
            Services.
          </p>
          <p>
            <u>
              <b>Copyright Infringement</b>
            </u>
          </p>
          <p>
            If you believe that any material contained in the Services infringes
            your copyright, please send us a notice of the copyright
            infringement to 188 Front Street, Suite116-162, Franklin, TN 37064.
            Firm Value will review such notice and take those actions it deems
            appropriate in its sole and absolute discretion against validated
            infringers.
          </p>
          <p>
            <u>
              <b>Links & Attachments</b>
            </u>
          </p>
          <p>
            The Services may contain links or access to third party websites,
            resources, or attachments sent by other Members outside of Firm
            Value’s control. By using the Services, you acknowledge and agree
            that Firm Value is not responsible or liable for: (i) the
            availability or accuracy of such websites, attachments, or
            resources; or (ii) the content, products, or services on or
            available from such websites, attachments, or resources. The
            inclusion of links or access to such websites, attachments, or
            resources does not imply Firm Value’s endorsement of such websites,
            attachments, or resources or the content, products, or services
            available therein. By using the Services, you acknowledge that you
            are solely responsible for and assume all risk arising from your use
            of any such third-party websites, attachments, or resources.
          </p>
          <p>
            You may provide a link to our Services, provided you do so in a way
            that is fair and legal and does not damage our reputation or take
            advantage of it, but you must not establish a link in such a way as
            to suggest any form of association, approval, or endorsement on our
            part.
          </p>
          <p>Subject to the foregoing, you must not:</p>
          <ul>
            <li>
              Other than with respect to social media posts establish a link to
              our Services from any website that is not owned by you;
            </li>
            <li>
              Cause our Services or any portions of them to be displayed, or
              appear to be displayed, by any other site, for example, framing,
              deep linking, or in-line thinking, on any other site;
            </li>
            <li>
              Otherwise take any action with respect to our Services that is
              inconsistent with these Terms.
            </li>
          </ul>
          <p>
            You agree to cooperate with us and immediately cease any linking
            when directed to do so by Firm Value. We reserve the right to
            withdraw linking permission without notice.
          </p>
          <p>
            <u>
              <b>Suspension and Termination</b>
            </u>
          </p>
          <p>
            We may suspend and/or terminate your access to all or any part of
            the Services at any time, without advance notice and with or without
            cause, for any reason including without limitation your breach or
            default of any obligation set forth in the Terms, in our sole
            discretion.
          </p>
          <p>
            <u>
              <b>Disclaimer of Warranties</b>
            </u>
          </p>
          <p>
            You understand that we cannot and do not guarantee or warrant that
            files available for downloading from the Services will be free of
            viruses or other destructive code. You are responsible for
            implementing sufficient procedures and checkpoints to satisfy your
            particular requirements for anti-virus protection and accuracy of
            data input and output, and for maintaining a means external to our
            site for any reconstruction of any lost data. TO THE FULLEST EXTENT
            PROVIDED BY LAW, WE WILL NOT BE LIABLE FOR ANY LOSS OR DAMAGE CAUSED
            BY A DISTRIBUTED DENIAL-OF-SERVICE ATTACK, VIRUSES, OR OTHER
            TECHNOLOGICALLY HARMFUL MATERIAL THAT MAY INFECT YOUR COMPUTER
            EQUIPMENT, COMPUTER PROGRAMS, DATA, OR OTHER PROPRIETARY MATERIAL
            DUE TO YOUR USE OF THE SERVICES OR TO YOUR DOWNLOADING OF ANY
            MATERIAL POSTED ON THEM, OR ON ANY WEBSITE LINKED TO THEM.
          </p>
          <p>
            YOUR USE OF THE SERVICES IS AT YOUR OWN RISK. THE SERVICES ARE
            PROVIDED ON AN “AS IS” AND “AS AVAILABLE” BASIS, WITHOUT ANY
            WARRANTIES OF ANY KIND, EITHER EXPRESS OR IMPLIED. NEITHER FIRM
            VALUE NOR ANY PERSON ASSOCIATED WITH FIRM VALUE MAKES ANY WARRANTY
            OR REPRESENTATION WITH RESPECT TO THE COMPLETENESS, SECURITY,
            RELIABILITY, QUALITY, ACCURACY, OR AVAILABILITY OF THE SERVICES.
            WITHOUT LIMITING THE FOREGOING, NEITHER THE FIRM VALUE NOR ANYONE
            ASSOCIATED WITH FIRM VALUE REPRESENTS OR WARRANTS THAT THE SERVICES
            OR THEIR CONTENT WILL BE ACCURATE, RELIABLE, ERROR-FREE, OR
            UNINTERRUPTED, THAT DEFECTS WILL BE CORRECTED, THAT OUR SERVICES ARE
            FREE OF VIRUSES OR OTHER HARMFUL COMPONENTS, OR THAT THE SERVICES
            WILL OTHERWISE MEET YOUR NEEDS OR EXPECTATIONS.
          </p>
          <p>
            TO THE FULLEST EXTENT PROVIDED BY LAW, FIRM VALUE HEREBY DISCLAIMS
            ALL WARRANTIES OF ANY KIND, WHETHER EXPRESS OR IMPLIED, STATUTORY,
            OR OTHERWISE, INCLUDING BUT NOT LIMITED TO ANY WARRANTIES OF
            MERCHANTABILITY, NON-INFRINGEMENT, AND FITNESS FOR PARTICULAR
            PURPOSE.
          </p>
          <p>
            THE FOREGOING DOES NOT AFFECT ANY WARRANTIES THAT CANNOT BE EXCLUDED
            OR LIMITED UNDER APPLICABLE LAW.
          </p>
          <p>
            <u>
              <b>Limitations of Liability</b>
            </u>
          </p>
          <p>
            TO THE FULLEST EXTENT PERMITTED BY LAW, UNDER NO CIRCUMSTANCES SHALL
            FIRM VALUE OR ITS SUBSIDIARIES, AFFILIATES, OFFICERS, DIRECTORS,
            ADVISORS, MANAGERS, MEMBERS, EMPLOYEES, OR REPRESENTATIVES, OR THEIR
            RESPECTIVE SUCCESSORS OR ASSIGNS BE LIABLE FOR DAMAGES OF ANY KIND,
            UNDER ANY LEGAL THEORY, INCLUDING, WITHOUT LIMITATION, DIRECT,
            INDIRECT, INCIDENTAL, SPECIAL, PUNITIVE, EXEMPLARY, OR CONSEQUENTIAL
            DAMAGES, INCLUDING, WITHOUT LIMITATION, ANY LOST PROFITS, BUSINESS
            INTERRUPTION, LOST SAVINGS, LOSS OF PROGRAMS, OR OTHER LOSSES,
            COSTS, OR DAMAGES SUFFERED OR INCURRED AS A RESULT OF THE USE OF OR
            INABILITY TO USE THE SERVICES, OR SUFFERED OR INCURRED AS A RESULT
            OF FAULTS, INTERRUPTIONS, OR DELAYS IN THE USE OF THE SERVICES OR
            ANY COMPONENT OF THE SERVICES, OR OUT OF ANY INACCURACIES, ERRORS,
            OR OMISSIONS IN THE CONTENT OF THE SERVICES, OR ANY OTHER
            INFORMATION IN THE SERVICES, REGARDLESS OF HOW SUCH FAULTS,
            INTERRUPTIONS, DELAYS, INACCURACIES, ERRORS, OR OMISSIONS ARISE,
            EVEN IF FORESEEABLE AND EVEN IF WE HAVE BEEN EXPRESSLY ADVISED OF
            THE POSSIBILITY OF SUCH DAMAGES.
          </p>
          <p>
            <u>
              <b>Indemnity</b>
            </u>
          </p>
          <p>
            You agree to indemnify and hold harmless Firm Value, our affiliates
            and each of our and their respective officers, directors, advisors,
            agents, and employees, from and against any suit, action, claim,
            demand, penalty or loss, including reasonable attorneys’ fees, made
            by or resulting from any third party (including any Governmental
            Entity) due to or arising out of your use or misuse of the Services,
            breach of the Terms, or violation of any law (including any
            securities law), regulation (including any securities regulation),
            order or other legal mandate or the rights of a third party. We
            reserve the right to assume the exclusive defense and control of any
            matter otherwise subject to indemnification by you, in which event
            you will assist and cooperate with us in asserting any available
            defenses.
          </p>
          <p>
            <u>
              <b>Choice of Law</b>
            </u>
          </p>
          <p>
            All Terms and any claim or action arising out of or in any way
            related thereto will be governed by the laws of the State of
            Tennessee without regard to or application of its conflict of law
            provisions. To the extent a court in any state, county, municipality
            or other jurisdiction disregards “Arbitration” below, all claims,
            legal proceedings, or litigation arising out of or in connection
            with or related to the Services will be brought solely in a court of
            competent jurisdiction in or around Franklin, Tennessee.
          </p>
          <p>
            <u>
              <b>Arbitration</b>
            </u>
          </p>
          <p>
            Any controversy or claim arising out of or relating to the Terms or
            Services shall be settled by binding arbitration in accordance with
            the commercial arbitration rules of the American Arbitration
            Association. Any such controversy or claim shall be arbitrated on an
            individual basis and shall not be consolidated in any arbitration
            with any claim or controversy of any other party. The decision of
            the arbitrator shall be final and unappealable. The arbitration
            shall be conducted in the State of Tennessee, and judgment on the
            arbitration award may be entered into any court having jurisdiction
            thereof. Notwithstanding anything to the contrary, Firm Value may at
            any time seek injunctions or other forms of equitable relief from
            any court of competent jurisdiction.
          </p>
          <p>
            <u>
              <b>Waiver and Severability</b>
            </u>
          </p>
          <p>
            Firm Value’s failure to exercise or enforce any right or provision
            of these Terms will not be deemed as a waiver of its right to the
            benefits associated with such right(s) or provision(s). No waiver by
            Firm Value of any term or condition set forth in these Terms shall
            be deemed a further or continuing waiver of such term or condition
            or a waiver of any other term or condition.
          </p>
          <p>
            In the event that any provision of these Terms is held to be invalid
            or unenforceable by any law, rule, order, or regulation of any
            government or by the final determination of any state or federal
            court, any such invalidity shall not affect the enforceability of
            any other provision in these Terms.
          </p>
          <p>
            <u>
              <b>Contact Info</b>
            </u>
          </p>
          <p>
            If you have any questions or concerns regarding these Terms, please
            contact us at:
          </p>
          <p>
            Email:{' '}
            <a href={'mailto:membership@firmvalue.com'}>
              membership@firmvalue.com
            </a>
          </p>
          <p>Address: 188 Front Street, Suite 116-162, Franklin, TN 37064</p>
        </div>
      </Card>
      <Footer />
    </>
  )
}

TermsConditions.propTypes = exact(propTypes)
TermsConditions.defaultProps = defaultProps

export default React.memo(TermsConditions)
