import React from 'react'
// import PropTypes from 'prop-types'
import exact from 'prop-types-exact'
import FVLogo from '../../assets/images/FVLogoUpdated.png'

const propTypes = {}

const defaultProps = {}

function Logo() {
  return <img className="fvLogo" src={FVLogo} alt="Firm Value" />
}

Logo.propTypes = exact(propTypes)
Logo.defaultProps = defaultProps

export default React.memo(Logo)
