import React from 'react'
import PropTypes from 'prop-types'
import exact from 'prop-types-exact'
import { ErrorBoundary } from 'components'
import {
  AdvisorProfileCardDetails,
  InvestorProfileCardDetails,
  BusinessProfileCardDetails,
} from 'components/profiles'

const propTypes = {
  userData: PropTypes.object,
  profileType: PropTypes.string,
}

const defaultProps = {}

function HOVMProfileHeaderCard({ userData, profileType }) {
  return (
    <ErrorBoundary>
      {profileType === 'advisorProfile' ? (
        <AdvisorProfileCardDetails userData={userData} />
      ) : profileType === 'investorProfile' ? (
        <InvestorProfileCardDetails userData={userData} />
      ) : (
        <BusinessProfileCardDetails userData={userData} />
      )}
    </ErrorBoundary>
  )
}

HOVMProfileHeaderCard.propTypes = exact(propTypes)
HOVMProfileHeaderCard.defaultProps = defaultProps

export default React.memo(HOVMProfileHeaderCard)
