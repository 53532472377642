import React from 'react'
import PropTypes from 'prop-types'
import exact from 'prop-types-exact'
import { DashboardHeaderLabel } from 'components/dashboard'
import { formatDate, getConnectionCountLink } from 'utils'

import * as Types from 'types'

const propTypes = {
  user: Types.user,
  children: PropTypes.node,
}

const defaultProps = {}

function AdvisorDashboardDetails({ user, children }) {
  return (
    <div className="profile-information">
      <div className="profile-details">
        <h1>{user.advisorProfile.displayName}</h1>
        <h2 className="profile-state-subheader">{user.state.name}</h2>
        <DashboardHeaderLabel
          label="Firm: "
          value={user.advisorProfile.firmName}
        />
        <DashboardHeaderLabel
          label="Title: "
          value={user.advisorProfile.title}
        />
        <DashboardHeaderLabel
          label="Member Status: "
          value={user.advisorProfile.membershipStatus}
        />
        <DashboardHeaderLabel
          label="Firm Value Member Since: "
          value={formatDate(user.advisorProfile.createdAt)}
        />

        <div className="connections-details">
          <DashboardHeaderLabel
            label="Pending Connections: "
            value={getConnectionCountLink(
              user.pendingConnections,
              '/dashboard/connections/pending-connections'
            )}
          />
          <DashboardHeaderLabel
            label="Total Connections: "
            value={getConnectionCountLink(
              user.totalConnections,
              '/dashboard/connections/active-connections'
            )}
          />
          <DashboardHeaderLabel
            label="Potential Matches: "
            value="Coming soon"
          />
        </div>
      </div>
      <div className="profile-buttons">
        <div className="button-area">{children}</div>
      </div>
    </div>
  )
}

AdvisorDashboardDetails.propTypes = exact(propTypes)
AdvisorDashboardDetails.defaultProps = defaultProps

export default React.memo(AdvisorDashboardDetails)
