import React from 'react'
import PropTypes from 'prop-types'
import exact from 'prop-types-exact'
import { withFormikAdapterAndVisibilityInput } from '../utils'
import {
  Input as BaseInput,
  HiddenInput as BaseHiddenInput,
  Select as BaseSelect,
  SubmitButton,
} from '@launchpadlab/lp-components'
import { Formik, Form } from 'formik'
import { noop } from 'lodash'

const Input = withFormikAdapterAndVisibilityInput()(BaseInput)
const HiddenInput = withFormikAdapterAndVisibilityInput()(BaseHiddenInput)
const Select = withFormikAdapterAndVisibilityInput()(BaseSelect)

const propTypes = {
  onSubmit: PropTypes.func,
}

const defaultProps = {
  onSubmit: noop,
}

// Add an extra custom validation for the hidden spam input.
function validateForm({ hiddenInput }) {
  if (hiddenInput)
    return { hiddenInput: 'You were not supposed to fill this out.' }
}

function SignUpForm({ onSubmit }) {
  return (
    <Formik
      validate={validateForm}
      onSubmit={onSubmit}
      initialValues={{ email: '', userType: '' }}
    >
      {() => (
        <Form>
          <div>
            <Input
              name="sign-up-email"
              label="Email"
              type="email"
              requiredIndicator="*"
              required
            />
            <Select
              name="userType"
              label="Member Type"
              placeholder="Select One"
              options={['Business', 'Investor', 'Advisor']}
              requiredIndicator="*"
              required
            />
            {/* Catch spam with a hidden input */}
          </div>
          <HiddenInput name="hiddenInput" />
          <SubmitButton>Submit</SubmitButton>
        </Form>
      )}
    </Formik>
  )
}

SignUpForm.propTypes = exact(propTypes)
SignUpForm.defaultProps = defaultProps

export default SignUpForm
