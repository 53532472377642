import React from 'react'
// import PropTypes from 'prop-types'
import exact from 'prop-types-exact'
import PropTypes from 'prop-types'
import UserDropdownMenu from './UserDropdownMenu'

const propTypes = { userSession: PropTypes.object }

const defaultProps = {}

function isSignUpEnabled() {
  // Handle case where variable is not defined (will throw error)
  try {
    return process.env.SIGN_UP_ENABLED === 'true'
  } catch (e) {
    return false
  }
}

function NavLinks({ userSession }) {
  return (
    <nav className="navLinks">
      {/* <a href="/business">Businesses</a>
      <a href="/investors">Investors</a>
      <a href="/advisors">Advisors</a>
      <a href="/about">About</a> */}
      {isSignUpEnabled() && (
        <>
          {userSession.loggedIn ? (
            <UserDropdownMenu userSession={userSession} />
          ) : (
            <>
              <a href="/users/log-in">Log In</a>
            </>
          )}
        </>
      )}
    </nav>
  )
}

NavLinks.propTypes = exact(propTypes)
NavLinks.defaultProps = defaultProps

export default React.memo(NavLinks)
