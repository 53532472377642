import React, { useState } from 'react'
import PropTypes from 'prop-types'
import exact from 'prop-types-exact'
import { FormCard, Footer } from '../components'
import NavBar from '../components/NavBar'

import { AdvisorPage1Form, AdvisorPage2Form } from 'forms'

import * as api from 'api'

import { scrollToSelector } from 'utils'

import { isEqual } from 'lodash'

const propTypes = {
  userSession: PropTypes.object,
  profileSetupFields: PropTypes.object,
  storedData: PropTypes.object,
}
const defaultProps = {}

const getNestedValues = (profile) => {
  return Object.values(profile)[0]
}

function ProfileSetup({ userSession, profileSetupFields, storedData }) {
  const [isNextPageActive, setNextPageActive] = useState(false)
  const profile = profileSetupFields
  const profileType = 'advisorProfile'

  const initialValues = {
    firstName: storedData?.firstName || '',
    lastName: storedData?.lastName || '',

    usCitizen: storedData?.usCitizen || '',
    termsAndConditions: storedData?.termsAndConditions || '',

    firmName: storedData?.firmName || '',
    title: storedData?.title || '',
    location: storedData?.location || '',
    firmWebsite: storedData?.firmWebsite || '',
    advisorTypeId: storedData?.advisorTypeId || '',
    yearsExperienceId: storedData?.yearsExperienceId || '',
    advisorPriorExperiences: storedData?.advisorPriorExperiences || [],
    advisorLocationExperiences: storedData?.advisorLocationExperiences || [],
    description: storedData?.description || '',

    advisorServedClients: storedData?.advisorServedClients || [],
    advisorFeeStructures: storedData?.advisorFeeStructures || [],
    advisorServedBusinessStages: storedData?.advisorServedBusinessStages || [],
    advisorServedLocations: storedData?.advisorServedLocations || [],
    advisorServedSectors: storedData?.advisorServedSectors || [],
    advisorServedOperatingModels:
      storedData?.advisorServedOperatingModels || [],
    membershipStatusId: storedData?.membershipStatusId || '',
    minEbitdaServedId: storedData?.minEbitdaServedId || '',
    maxEbitdaServedId: storedData?.maxEbitdaServedId || '',

    defaultImage: storedData?.defaultImage || false,
    profileImageUrl: storedData?.profileImageUrl || '',
    profileImageMeta: storedData?.profileImageMeta || {},
  }

  const [formValues, setFormValues] = useState(initialValues)
  function onlyServesIndividualClientTypes(values) {
    return isEqual(values.advisorServedClients, [1])
  }
  const handleProfileUpdate = async (values, isFullSubmit) => {
    let updateValues = values

    const requestObj = isFullSubmit
      ? {
          [profileType]: {
            profileComplete: true,
            ...updateValues,
          },
        }
      : {
          [profileType]: {
            ...updateValues,
          },
        }
    /* eslint-disable */
    await api
      .updateProfile(requestObj, true)
      .then((res) => console.log('response', res))
      .catch(console.log)
    /* eslint-enable  */
    if (isFullSubmit) window.location.replace('/dashboard')
  }
  function handleSubmit(values, isFullSubmit) {
    let updateValues = values
    if (onlyServesIndividualClientTypes(values)) {
      updateValues = {
        ...values,
        advisorServedSectors: [],
        advisorServedOperatingModels: [],
        advisorServedBusinessStages: [],
      }
    }
    const valuesOnChange = {
      ...updateValues,
    }
    setFormValues(valuesOnChange)
    handleProfileUpdate(valuesOnChange, isFullSubmit)
  }

  function handleBack(values) {
    const valuesOnChange = {
      ...values,
    }
    setFormValues(valuesOnChange)
    handleProfileUpdate(valuesOnChange, false)
    setNextPageActive(false)
    scrollToSelector('.signup-header')
  }

  return (
    <div className="profile-setup">
      <div className="signup-header">
        <div className="centered-content">
          <NavBar userSession={userSession} />
        </div>
      </div>
      <div className="centered-content">
        <FormCard isNextPageActive={isNextPageActive}>
          {isNextPageActive ? (
            <AdvisorPage2Form
              formValues={formValues}
              params={getNestedValues(profile)}
              setNextPageActive={setNextPageActive}
              setFormValues={setFormValues}
              handleBack={handleBack}
              handleSubmit={(values) => {
                handleSubmit(values, true)
              }}
            />
          ) : (
            <AdvisorPage1Form
              formValues={formValues}
              params={getNestedValues(profile)}
              setNextPageActive={setNextPageActive}
              setFormValues={setFormValues}
              handleProfileUpdate={(values) => handleProfileUpdate(values)}
              handleSubmit={(values) => {
                handleSubmit(values)
                setNextPageActive(true)
                scrollToSelector('.signup-header')
              }}
            />
          )}
        </FormCard>
      </div>
      <Footer />
    </div>
  )
}

ProfileSetup.propTypes = exact(propTypes)
ProfileSetup.defaultProps = defaultProps

export default React.memo(ProfileSetup)
