import React from 'react'
import PropTypes from 'prop-types'
import exact from 'prop-types-exact'
import { MessagesActiveThreadLink } from 'components/messages'
import { Button } from '@launchpadlab/lp-components'
// import * as api from 'api'
import { useHistory } from 'react-router-dom'

const propTypes = {
  connections: PropTypes.array,
  conversationIds: PropTypes.array,
  activeConnectionId: PropTypes.number,
}

const defaultProps = {}

// const handleSubmit = (
//   e,
//   history,
//   setStartNewConversation,
//   setActiveConversationIds,
//   activeConversationIds
// ) => {
//   e.preventDefault()
//   const form = e.target
//   const text = form.elements['text'].value
//   const connectionId = parseInt(form.elements['user_connection_id'].value)
//   if (!text) return
//   async function saveMessage() {
//     await api.saveMessage({ text, connectionId })
//     history.push(`/messages/${connectionId}`)
//     setStartNewConversation(false)
//     setActiveConversationIds(activeConversationIds.concat([connectionId]))
//   }
//   saveMessage()
// }

function MessagesSideSubNav({
  connections,
  conversationIds,
  activeConnectionId,
}) {
  let history = useHistory()

  const queryId = parseInt(history.location.pathname.split('/').slice(2)[0])

  // const [startNewConversation, setStartNewConversation] = useState(false)
  const activeConversationIds = [...new Set(conversationIds.concat(queryId))]

  // const queryConnection = connections.find(
  //   (connection) => connection.id === queryId
  // )

  let connectionsWithConversations = []
  let connectionsWithoutConversations = []

  connections.forEach((connection) => {
    const match = activeConversationIds.some((_id) => connection.id === _id)
    if (match) return connectionsWithConversations.push(connection)
    connectionsWithoutConversations.push(connection)
  })

  const handleNavigateToActiveConnections = () => {
    window.location.replace(`/dashboard/connections/active-connections`)
  }

  return (
    <nav className="profile-settings-sub-nav">
      <div className="profile-settings-list">
        <div className="links-subnav">
          <div className="active-links-list">
            {connectionsWithConversations.map((connection) => (
              <MessagesActiveThreadLink
                key={connection.id}
                connection={connection}
                isActive={activeConnectionId == connection.id}
              />
            ))}
          </div>
        </div>
        <Button
          onClick={handleNavigateToActiveConnections}
          className="new-conversation-button button-primary-outline"
        >
          + New Conversation
        </Button>
      </div>
    </nav>
  )
}

MessagesSideSubNav.propTypes = exact(propTypes)
MessagesSideSubNav.defaultProps = defaultProps

export default React.memo(MessagesSideSubNav)
