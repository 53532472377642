import React, { useState } from 'react'
import PropTypes from 'prop-types'
import exact from 'prop-types-exact'
import { withFormikAdapterAndVisibilityInput, validate } from '../utils'
import { ImageUploadPreview, LabeledRadioButtons } from 'components'
import {
  Checkbox as BaseCheckbox,
  Input as BaseInput,
  SubmitButton,
} from '@launchpadlab/lp-components'
import { Formik, Form } from 'formik'

const Checkbox = withFormikAdapterAndVisibilityInput()(BaseCheckbox)
const Input = withFormikAdapterAndVisibilityInput()(BaseInput)

const propTypes = {
  storedData: PropTypes.object,
  handleSubmit: PropTypes.func,
  useDefaultSectorIcon: PropTypes.bool,
  profileImageUrl: PropTypes.string,
}

const defaultProps = {}

// Add an extra custom validation for the hidden spam input.
function validateForm(values) {
  const defaultSectorIconSelected = values.defaultImage
  const messages = validate(values, {
    businessName: {
      presence: { allowEmpty: false, message: '^Please provide a response' },
      type: 'string',
    },
    defaultImage: function (value, attributes) {
      if (attributes.profileImageUrl === '') {
        return { presence: { allowEmpty: false } }
      } else {
        return { presence: { allowEmpty: true } }
      }
    },
    profileImageUrl: function () {
      if (defaultSectorIconSelected) {
        return
      } else {
        return {
          presence: { allowEmpty: false, message: '^Profile image required' },
        }
      }
    },
  })
  // mix custom stuff here w validate.js messages
  return {
    ...messages,
    //
  }
}

function BusinessProfileDetailsEditForm({
  storedData,
  handleSubmit,
  profileImageUrl,
}) {
  const [disabled, toggleDisabled] = useState(true)
  const values = {
    defaultImage: storedData?.useDefaultSectorIcon || false,
    profileImageUrl: storedData?.profileImageUrl || '',

    businessName: storedData?.businessName || '',
    showNamePublic: storedData?.showNamePublic ? 'showName' : 'hideName',
    blurImagePublic: storedData?.blurImagePublic ? 'blurImage' : 'showImage',
    showNameConnections: storedData?.showNameConnections
      ? 'showName'
      : 'hideName',
    blurImageConnections: storedData?.blurImageConnections
      ? 'blurImage'
      : 'showImage',
  }
  const isImageADefaultSectorIcon = (image) =>
    image.includes('/assets/DefaultIcons')

  return (
    <Formik
      onSubmit={(values) => {
        handleSubmit(values)
      }}
      validate={validateForm}
      initialValues={values}
    >
      {({ values, setFieldValue, handleReset }) => (
        <Form className="onboarding-form">
          <div className="row">
            <h1>General Overview</h1>
            {disabled ? (
              <button
                className="button-primary-outline"
                onClick={() => {
                  toggleDisabled(false)
                }}
              >
                Edit
              </button>
            ) : (
              <div className="edit-buttons-area">
                <button
                  className="button-primary-outline"
                  onClick={() => {
                    handleReset()
                    toggleDisabled(true)
                  }}
                >
                  Cancel
                </button>
                <SubmitButton>Submit Changes</SubmitButton>
              </div>
            )}
          </div>
          <div className="row">
            <Input name="businessName" type="text" disabled={disabled} />
          </div>

          <h1>Profile Image Selection</h1>
          <div className="row">
            You may either choose from pre-made icons based on Sector, or upload
            your own image.
          </div>
          <div className="row photo-edit-row">
            <div className="column">
              <Checkbox
                name="defaultImage"
                onChange={(e) => {
                  if (e.target.value === true) {
                    setFieldValue('profileImageUrl', '')
                  }
                }}
                disabled={disabled}
              />
            </div>
            <div className="column">
              {values.defaultImage ? (
                <div className="image-upload-preview">
                  <img
                    src={storedData?.defaultImageUrl}
                    alt="Default Sector Image"
                    style={{ width: '60px' }}
                  />
                </div>
              ) : (
                <ImageUploadPreview
                  disabled={disabled}
                  name="profileImageUrl"
                  hasStoredValue={values.profileImageUrl}
                  setFieldValue={setFieldValue}
                />
              )}
            </div>
          </div>
          <h1>Visibility Options</h1>
          <div className="row general-overview-radio-row">
            <div className="general-overview-radio-col">
              <h3>Public</h3>
              <LabeledRadioButtons
                label="Display Name"
                name="showNamePublic"
                values={[
                  {
                    label: `${storedData.publicDisplayName}`,
                    value: 'hideName',
                  },
                  {
                    label: `${storedData.privateDisplayName}`,
                    value: 'showName',
                  },
                ]}
                disabled={disabled}
              />
              {values.defaultImage ||
              isImageADefaultSectorIcon(values.profileImageUrl) ? null : (
                <LabeledRadioButtons
                  label="Profile Image"
                  name="blurImagePublic"
                  values={[
                    {
                      label: `${
                        values.profileImageUrl
                          ? values.profileImageUrl
                          : profileImageUrl
                      }`,
                      value: 'blurImage',
                    },
                    {
                      label: `${
                        values.profileImageUrl
                          ? values.profileImageUrl
                          : profileImageUrl
                      }`,
                      value: 'showImage',
                    },
                  ]}
                  disabled={disabled}
                  isImageSelector={true}
                />
              )}
            </div>
            <div className="general-overview-radio-col">
              <h3>Shown To Connections</h3>
              <LabeledRadioButtons
                label="Display Name"
                name="showNameConnections"
                values={[
                  {
                    label: `${storedData.publicDisplayName}`,
                    value: 'hideName',
                  },
                  {
                    label: `${storedData.privateDisplayName}`,
                    value: 'showName',
                  },
                ]}
                disabled={disabled}
              />
              {values.defaultImage ||
              isImageADefaultSectorIcon(values.profileImageUrl) ? null : (
                <LabeledRadioButtons
                  label="Profile Image"
                  name="blurImageConnections"
                  values={[
                    {
                      label: `${
                        values.profileImageUrl
                          ? values.profileImageUrl
                          : profileImageUrl
                      }`,
                      value: 'blurImage',
                    },
                    {
                      label: `${
                        values.profileImageUrl
                          ? values.profileImageUrl
                          : profileImageUrl
                      }`,
                      value: 'showImage',
                    },
                  ]}
                  disabled={disabled}
                  isImageSelector={true}
                />
              )}
            </div>
          </div>
        </Form>
      )}
    </Formik>
  )
}

BusinessProfileDetailsEditForm.propTypes = exact(propTypes)
BusinessProfileDetailsEditForm.defaultProps = defaultProps

export default BusinessProfileDetailsEditForm
