import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import exact from 'prop-types-exact'
import {
  withFormikAdapterAndVisibilityInput,
  validate,
  formHelpers,
  getIndustriesByAllSelectedSectors,
} from '../utils'
import { DropdownCheckboxGroup as BaseDropownCheckboxGroup } from 'components'
import { SubmitButton, Spinner } from '@launchpadlab/lp-components'
import { Formik, Form } from 'formik'

import * as api from 'api'

const DropdownCheckboxGroup = withFormikAdapterAndVisibilityInput()(
  BaseDropownCheckboxGroup
)

const propTypes = {
  params: PropTypes.object,
  handleSubmit: PropTypes.func,
}

const defaultProps = {}

// Add an extra custom validation for the hidden spam input.
function validateForm(values) {
  const messages = validate(values, {
    investorConsideredBusinessLocations: {
      presence: { allowEmpty: false },
    },
    investorConsideredPrimarySectors: {
      presence: { allowEmpty: false },
    },
    investorConsideredPrimaryIndustries: {
      presence: { allowEmpty: false },
    },
    investorConsideredBusinessModels: {
      presence: { allowEmpty: false },
    },

    investorConsideredBusinessStages: {
      presence: { allowEmpty: false },
    },
  })
  // mix custom stuff here w validate.js messages
  return {
    ...messages,
    //
  }
}

function InvestorOperatingInvestmentCriteriaEditForm({ params, handleSubmit }) {
  const [disabled, toggleDisabled] = useState(true)
  const [storedData, setStoredData] = useState()

  useEffect(() => {
    async function fetchData() {
      setStoredData(
        await api.getStoredData('/investor/operating-investment-criteria')
      )
    }
    fetchData()
  }, [])

  const initialValues = {
    investorConsideredBusinessLocations:
      storedData?.investorConsideredBusinessLocations || [],
    investorConsideredPrimarySectors:
      storedData?.investorConsideredPrimarySectors || [],
    investorConsideredPrimaryIndustries:
      storedData?.investorConsideredPrimaryIndustries || [],

    investorConsideredBusinessModels:
      storedData?.investorConsideredBusinessModels || [],
    investorConsideredBusinessStages:
      storedData?.investorConsideredBusinessStages || [],
    // investorConsideredBusinessAges:
    //   storedData?.investorConsideredBusinessAges || [],
    // minConsideredEmployeeBaseId: storedData?.minConsideredEmployeeBaseId || [],
    // maxConsideredEmployeeBaseId: storedData?.maxConsideredEmployeeBaseId || [],

    consideredBusinessLocationsVisibility:
      storedData?.consideredBusinessLocationsVisibility || false,
    consideredPrimarySectorsVisibility:
      storedData?.consideredPrimarySectorsVisibility || false,

    consideredBusinessModelsVisibility:
      storedData?.consideredBusinessModelsVisibility || false,
    consideredBusinessStagesVisibility:
      storedData?.consideredBusinessStagesVisibility || false,
    consideredBusinessAgesVisibility:
      storedData?.consideredBusinessAgesVisibility || false,
    consideredEmployeeSizesVisibility:
      storedData?.consideredEmployeeSizesVisibility || false,
  }

  if (!storedData) {
    return <Spinner />
  }

  return (
    <Formik
      onSubmit={handleSubmit}
      validate={validateForm}
      initialValues={initialValues}
    >
      {({ values, setFieldTouched, setFieldValue, handleReset }) => (
        <Form className="onboarding-form">
          <div className="row">
            <h1>Operating Investment Criteria</h1>

            {disabled ? (
              <button
                className="button-primary-outline"
                onClick={() => {
                  toggleDisabled(false)
                }}
              >
                Edit
              </button>
            ) : (
              <div className="edit-buttons-area">
                <button
                  className="button-primary-outline"
                  onClick={() => {
                    handleReset()
                    toggleDisabled(true)
                  }}
                >
                  Cancel
                </button>
                <SubmitButton>Submit Changes</SubmitButton>
              </div>
            )}
          </div>
          <p className="disclaimer">
            Full operating investment criteria is{' '}
            <span id="underline">not</span> publicized to other members. Members
            only see whether or not your criteria matches their criteria or
            operating details.
          </p>
          <div className="row">
            <DropdownCheckboxGroup
              name="investorConsideredPrimarySectors"
              label="Primary Sectors"
              errorMessage="Please select all that apply"
              options={formHelpers.formatOptions(params.sectorsIndustries)}
              setFieldValue={setFieldValue}
              setFieldTouched={setFieldTouched}
              values={values}
              tags={true}
              disabled={disabled}
              hasVisibilityField
            />
            <DropdownCheckboxGroup
              name="investorConsideredPrimaryIndustries"
              label="Primary Industries"
              options={getIndustriesByAllSelectedSectors(
                values.investorConsideredPrimarySectors,
                params
              )}
              disabledText={
                !values.investorConsideredPrimarySectors.length
                  ? 'Must Select Sector'
                  : values.investorConsideredPrimaryIndustries
                  ? 'Selected Options Below'
                  : null
              }
              errorMessage="Please select all that apply"
              setFieldValue={setFieldValue}
              setFieldTouched={setFieldTouched}
              values={values}
              tags={true}
              connectedFieldName="businessConsideredPrimarySectors"
              disabled={
                !values.investorConsideredPrimarySectors.length || disabled
              }
              allowSelectAll={true}
            />
          </div>
          <div className="row">
            <DropdownCheckboxGroup
              name="investorConsideredBusinessLocations"
              label="Business Locations"
              errorMessage="Please select all that apply"
              options={formHelpers.formatOptions(params.territoriesStates)}
              setFieldValue={setFieldValue}
              setFieldTouched={setFieldTouched}
              values={values}
              tags={true}
              disabled={disabled}
              hasVisibilityField
            />
            <DropdownCheckboxGroup
              name="investorConsideredBusinessModels"
              label="Business Models"
              errorMessage="Please select all that apply"
              options={formHelpers.formatOptions(params.businessModels)}
              setFieldValue={setFieldValue}
              setFieldTouched={setFieldTouched}
              values={values}
              tags={true}
              disabled={disabled}
              hasVisibilityField
            />
          </div>
          <div className="row">
            <DropdownCheckboxGroup
              name="investorConsideredBusinessStages"
              label="Business Stages"
              errorMessage="Please select all that apply"
              options={formHelpers.formatOptions(params.businessStages)}
              setFieldValue={setFieldValue}
              setFieldTouched={setFieldTouched}
              values={values}
              tags={true}
              disabled={disabled}
              hasVisibilityField
            />
          </div>
        </Form>
      )}
    </Formik>
  )
}

InvestorOperatingInvestmentCriteriaEditForm.propTypes = exact(propTypes)
InvestorOperatingInvestmentCriteriaEditForm.defaultProps = defaultProps

export default InvestorOperatingInvestmentCriteriaEditForm
