import React from 'react'
import PropTypes from 'prop-types'
import exact from 'prop-types-exact'
import classnames from 'classnames'

const propTypes = {
  selection: PropTypes.string.isRequired,
  removeTag: PropTypes.func.isRequired,
  disabled: PropTypes.bool.isRequired,
}

const defaultProps = {}

function MultiSelectTag({ selection, removeTag, disabled }) {
  return (
    <div
      className={classnames('multi-select-tag', { 'disabled-tag': disabled })}
      onClick={() => removeTag(selection)}
    >
      <div className="tag-text">{selection}</div>
      <div className="tag-close">x</div>
    </div>
  )
}

MultiSelectTag.propTypes = exact(propTypes)
MultiSelectTag.defaultProps = defaultProps

export default React.memo(MultiSelectTag)
