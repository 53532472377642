import React from 'react'
// import PropTypes from 'prop-types'
import exact from 'prop-types-exact'
import { NavLink } from 'react-router-dom'
import ActiveConnectionsLogo from '../../../assets/images/SettingsIcons/active.svg'
import PendingConnectionsLogo from '../../../assets/images/SettingsIcons/pending.svg'
import SavedForLaterLogo from '../../../assets/images/SettingsIcons/saved.svg'

const propTypes = {}

const defaultProps = {}

function ConnectionsSubSideNav() {
  return (
    <nav className="profile-settings-sub-nav">
      <div className="profile-settings-list">
        <NavLink
          to={`/connections/active-connections`}
          activeClassName="is-active"
        >
          <div className="side-item">
            <img
              className="settings-nav-logo"
              src={ActiveConnectionsLogo}
            ></img>
            <span className="profile-settings-label">Active Connections</span>
          </div>
        </NavLink>
        <NavLink
          to={`/connections/pending-connections`}
          activeClassName="is-active"
        >
          <div className="side-item">
            <img
              className="settings-nav-logo"
              src={PendingConnectionsLogo}
            ></img>
            <span className="profile-settings-label">Pending Connections</span>
          </div>
        </NavLink>
        <NavLink
          to={`/connections/saved-for-later`}
          activeClassName="is-active"
        >
          <div className="side-item">
            <img className="settings-nav-logo" src={SavedForLaterLogo}></img>
            <span className="profile-settings-label">Saved For Later</span>
          </div>
        </NavLink>
      </div>
    </nav>
  )
}

ConnectionsSubSideNav.propTypes = exact(propTypes)
ConnectionsSubSideNav.defaultProps = defaultProps

export default React.memo(ConnectionsSubSideNav)
