import React, { useRef } from 'react'
import PropTypes from 'prop-types'
import exact from 'prop-types-exact'
import { MessagesActiveThreadLink } from 'components/messages'
import { Button } from '@launchpadlab/lp-components'
// import * as api from 'api'
import { useHistory } from 'react-router-dom'

// import HorizontalScroll from 'react-scroll-horizontal'

const propTypes = {
  connections: PropTypes.object,
  conversationIds: PropTypes.array,
  activeConnectionId: PropTypes.number,
}

const defaultProps = {}

function ProfileSettingsMobileSubNav({
  connections,
  conversationIds,
  activeConnectionId,
}) {
  const navRef = useRef(null)

  const handleNav = (direction) => {
    if (direction === 'left') {
      navRef ? (navRef.current.scrollLeft -= 150) : null
    } else {
      navRef ? (navRef.current.scrollLeft += 150) : null
    }
  }

  let history = useHistory()

  const queryId = parseInt(history.location.pathname.split('/').slice(2)[0])

  // const [startNewConversation, setStartNewConversation] = useState(false)
  const activeConversationIds = [...new Set(conversationIds.concat(queryId))]

  // const queryConnection = connections.find(
  //   (connection) => connection.id === queryId
  // )

  let connectionsWithConversations = []
  let connectionsWithoutConversations = []

  connections.forEach((connection) => {
    const match = activeConversationIds.some((_id) => connection.id === _id)
    if (match) return connectionsWithConversations.push(connection)
    connectionsWithoutConversations.push(connection)
  })

  const handleNavigateToActiveConnections = () => {
    window.location.replace(`/dashboard/connections/active-connections`)
  }
  return (
    <div className="mobile-sub-nav-and-button-container">
      <div className="scroll-button scroll-button-left">
        <button
          onClick={() => handleNav('left')}
          aria-label="Scroll Left"
        ></button>
      </div>
      <nav className="profile-settings-mobile-sub-nav" ref={navRef}>
        <Button
          onClick={handleNavigateToActiveConnections}
          className="new-conversation-button button-primary-outline"
        >
          + New Conversation
        </Button>
        {connectionsWithConversations.map((connection) => (
          <MessagesActiveThreadLink
            key={connection.id}
            connection={connection}
            isActive={activeConnectionId == connection.id}
          />
        ))}
      </nav>
      <div className="scroll-button scroll-button-right">
        <button
          onClick={() => handleNav('right')}
          aria-label="Scroll Right"
        ></button>
      </div>
    </div>
  )
}

ProfileSettingsMobileSubNav.propTypes = exact(propTypes)
ProfileSettingsMobileSubNav.defaultProps = defaultProps

export default React.memo(ProfileSettingsMobileSubNav)
