import React from 'react'
// import PropTypes from 'prop-types'
import exact from 'prop-types-exact'

import { ProfileSectionLineItem } from 'components/profiles'
import { replaceNullValueWithPlaceholder } from 'utils'

const propTypes = {}

const defaultProps = {}

function AdvisorAdvisoryStatusProfileSection({ sectionVisible, userData }) {
  if (sectionVisible) {
    return (
      <div className="profile-section-wrapper centered-content">
        <h1>Exploration Status</h1>
        <div className="profile-view-section ">
          <div className="profile-view-section-col">
            <ProfileSectionLineItem
              label="Member Status"
              value={replaceNullValueWithPlaceholder(userData.membershipStatus)}
            />
          </div>
          <div className="profile-view-section-col"></div>
        </div>
      </div>
    )
  } else {
    return <></>
  }
}

AdvisorAdvisoryStatusProfileSection.propTypes = exact(propTypes)
AdvisorAdvisoryStatusProfileSection.defaultProps = defaultProps

export default React.memo(AdvisorAdvisoryStatusProfileSection)
