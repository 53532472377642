import React, { useState } from 'react'
// import PropTypes from 'prop-types'
import exact from 'prop-types-exact'
import { withFormikAdapterAndVisibilityInput, validate } from '../utils'
import classnames from 'classnames'
import { Card } from 'components'
import { Input as BaseInput, SubmitButton } from '@launchpadlab/lp-components'
import { Formik, Form } from 'formik'
import { deleteAccount } from '../services/api'
import { isEmpty } from 'lodash'
import closeIcon from '../../assets/images/close.svg'

const Input = withFormikAdapterAndVisibilityInput()(BaseInput)

const propTypes = {}

const defaultProps = {}

function DeleteAccountForm() {
  const [showModal, toggleShowModal] = useState(false)
  const [disabled, setDisabled] = useState(true)
  const [serverError, setServerError] = useState(false)

  const initialValues = {
    deleteConfirmation: '',
  }

  const handleDeleteAccount = (values) => {
    deleteAccount(values).then((res) => {
      if (res.status === 400) {
        setServerError(true)
        setDisabled(true)
      } else {
        window.location.reload(true)
      }
    })
  }

  function validateForm(values) {
    setDisabled(true)
    const messages = validate(values, {
      deleteConfirmation: function (value, attributes) {
        if (attributes.deleteConfirmation === '')
          return {
            presence: {
              allowEmpty: false,
              message: '^',
            },
          }

        if (attributes.deleteConfirmation === 'DELETE') {
          return
        } else {
          return {
            format: {
              pattern: '/^DELETE/',
              flags: 'i',
              message: '^Must match DELETE',
            },
          }
        }
      },
    })
    if (isEmpty(messages)) {
      setDisabled(false)
    }
    // mix custom stuff here w validate.js messages
    return {
      ...messages,
      //
    }
  }

  return (
    <Card>
      <div className="delete-membership-content onboarding-form row">
        <h1>Change Membership</h1>
        <p>Do you want to delete your account?</p>
        <button
          onClick={() => {
            toggleShowModal(!showModal)
          }}
          className="delete-button"
        >
          Delete
        </button>
        <div
          className={classnames('delete-modal', { 'modal-open': showModal })}
        >
          <div className="modal-styles">
            <Formik
              onSubmit={(values) => handleDeleteAccount(values)}
              validate={validateForm}
              initialValues={initialValues}
              validateOnBlur={false}
            >
              {({ resetForm }) => (
                <Form className="onboarding-form">
                  <div className="top-row">
                    <button
                      onClick={() => {
                        resetForm({ errors: {}, touched: {} })
                        toggleShowModal(false)
                        setDisabled(true)
                        setServerError(false)
                      }}
                    >
                      <img src={closeIcon} alt="Close Icon" />
                    </button>
                  </div>
                  <div className="row">
                    <h1>Are you sure you want to delete your account?</h1>
                    <p>Once you complete this action it cannot be undone.</p>
                  </div>
                  <div className="row">
                    <Input
                      label='Type "DELETE" to confirm'
                      name="deleteConfirmation"
                      type="text"
                    />
                    {serverError && (
                      <div className="error-message">
                        Unable to delete account. Please contact admin at
                        admin@firmvalue.com.
                      </div>
                    )}
                  </div>
                  <div className="button-area">
                    <SubmitButton
                      className={classnames({ 'disabled-submit': disabled })}
                    >
                      Permanently Delete
                    </SubmitButton>
                    <a
                      onClick={() => {
                        resetForm({ errors: {}, touched: {} })
                        toggleShowModal(false)
                        setDisabled(true)
                        setServerError(false)
                      }}
                    >
                      Cancel
                    </a>
                  </div>
                </Form>
              )}
            </Formik>
          </div>
        </div>
      </div>
    </Card>
  )
}

DeleteAccountForm.propTypes = exact(propTypes)
DeleteAccountForm.defaultProps = defaultProps

export default DeleteAccountForm
