import React from 'react'
// import PropTypes from 'prop-types'
import exact from 'prop-types-exact'

import { ProfileSectionLineItem } from 'components/profiles'
import { isHumanizedBooleanValue, replaceNullValueWithPlaceholder } from 'utils'

const propTypes = {}

const defaultProps = {}

function IPOperatingCriteriaProfileSection({
  sectionVisible,
  userData,
  hovmOverride,
}) {
  const isViewerAdvisor = userData?.viewerProfileType === 'AdvisorProfile'
  if (sectionVisible) {
    return (
      <div className="profile-section-wrapper centered-content">
        <h1>Investment Operating Criteria</h1>
        <div className="profile-view-section ">
          <div className="profile-view-section-col">
            <ProfileSectionLineItem
              label="Business Location"
              value={replaceNullValueWithPlaceholder(
                userData?.consideredLocations
              )}
              isCriteriaMatch={isHumanizedBooleanValue(
                userData.consideredLocations
              )}
            />
            <ProfileSectionLineItem
              label="Primary Sector"
              value={replaceNullValueWithPlaceholder(
                userData?.consideredPrimarySectors
              )}
              isCriteriaMatch={isHumanizedBooleanValue(
                userData.consideredPrimarySectors
              )}
            />
            {!isViewerAdvisor && !hovmOverride && (
              <ProfileSectionLineItem
                label="Primary Industry"
                value={replaceNullValueWithPlaceholder(
                  userData?.consideredPrimaryIndustries
                )}
                isCriteriaMatch={isHumanizedBooleanValue(
                  userData.consideredPrimaryIndustries
                )}
              />
            )}
          </div>
          <div className="profile-view-section-col">
            <ProfileSectionLineItem
              label="Business Stage"
              value={replaceNullValueWithPlaceholder(
                userData.consideredBusinessStages
              )}
              isCriteriaMatch={isHumanizedBooleanValue(
                userData.consideredBusinessStages
              )}
            />
            <ProfileSectionLineItem
              label="Business Model(s)"
              value={replaceNullValueWithPlaceholder(
                userData?.consideredBusinessModels
              )}
              isCriteriaMatch={isHumanizedBooleanValue(
                userData.consideredBusinessModels
              )}
            />
          </div>
        </div>
      </div>
    )
  } else {
    return <></>
  }
}

IPOperatingCriteriaProfileSection.propTypes = exact(propTypes)
IPOperatingCriteriaProfileSection.defaultProps = defaultProps

export default React.memo(IPOperatingCriteriaProfileSection)
