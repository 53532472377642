import React from 'react'
import { InputLabel } from '@launchpadlab/lp-components'
import {
  DropdownSelect,
  OptionGroups,
  CustomCheckboxGroup,
  SelectionTags,
} from 'components'

function DropdownCheckboxGroup({
  disabled,
  disabledText,
  tags,
  allowNoneValue,
  allowSelectAll,
  tooltip,
  ...props
}) {
  const {
    input: { name, value },
    meta,
    label,
    options,
    optionGroups,
    errorMessage,
    setFieldValue,
    setFieldTouched,
    connectedFieldName,
    allPlaceholderText,
  } = props
  const val = value === '' ? [] : value

  /* eslint-disable */

  /* - label removes internal label for the checkbox grouping
      - optionGroups are objects with nested arrays of objects representing category groups
      - {
          id: 1,
          name: "Midwest",
​​          states: [ {name: 'Illinois', id: 1}, {…}, {…}, … ]
        }
      - checkbox level meta information cleared in favor of component level meta
  */

  /* eslint-enable */
  return (
    <fieldset className="input-label-fieldset" id="dropdown-checkbox-group">
      <InputLabel
        name={`${name}-input-label`}
        tooltip={tooltip}
        {...{ label, name }}
      />
      <DropdownSelect
        name={name}
        selectedValues={val}
        label={false}
        meta={meta}
        errorMessage={errorMessage}
        setFieldTouched={setFieldTouched}
        disabledText={disabledText}
        disabled={disabled}
        connectedFieldName={connectedFieldName}
        allPlaceholderText={allPlaceholderText}
        {...props}
      >
        {optionGroups ? (
          <OptionGroups
            {...props}
            allowNoneValue={allowNoneValue}
            allowSelectAll={allowSelectAll}
            label={false}
            optionGroups={optionGroups}
            meta={{ touched: false, error: null, invalid: false }}
          ></OptionGroups>
        ) : (
          <CustomCheckboxGroup
            {...props}
            allowNoneValue={allowNoneValue}
            allowSelectAll={allowSelectAll}
            label={false}
            meta={{ touched: false, error: null, invalid: false }}
          />
        )}
      </DropdownSelect>
      {tags && (
        <SelectionTags
          name={name}
          options={options}
          optionGroups={optionGroups}
          selectedValues={val}
          setFieldValue={setFieldValue}
          disabled={disabled}
          allowNoneValue={allowNoneValue}
        />
      )}
    </fieldset>
  )
}

export default DropdownCheckboxGroup
