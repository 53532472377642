import React from 'react'
import { wrapDisplayName } from 'recompose'
import { useField, ErrorMessage } from 'formik'
import { Checkbox, SubmitButton } from '@launchpadlab/lp-components'
import { camelCase } from 'lodash'

import classnames from 'classnames'

// Returns true if the argument is an event
// Copied from https://github.com/erikras/redux-form/blob/master/src/events/isEvent.js

function isEvent(value) {
  return !!(value && value.stopPropagation && value.preventDefault)
}

// Allow both the default formik onChange and a custom onChange to be called.
function composeChangeHandlers(fieldName, customOnChange, defaultOnChange) {
  return function onChange(value) {
    // Create a fake event if necessary since formik expects an event object
    const changeEvent = isEvent(value)
      ? value
      : { target: { name: fieldName, value } }
    defaultOnChange(changeEvent)
    // Call custom onChange handler
    if (customOnChange) customOnChange(changeEvent)
  }
}

function formatVisibilityName(fieldName) {
  if (fieldName === 'targetCapitalRaise') {
    return 'targetCapitalRaiseNotDisclosed'
  }

  if (fieldName === 'businessLikelyEquityStakes') {
    return 'businessLikelyEquityStakesNotDisclosed'
  }
  if (fieldName === 'targetFundSize') {
    return 'targetFundSizeNotDisclosed'
  }

  let formattedFieldName = fieldName
  if (fieldName.slice(-2) === 'Id') {
    return formattedFieldName.slice(0, -2) + 'Visibility'
  }

  // remove prefixes
  formattedFieldName = formattedFieldName.replace('businessProfile', '')
  formattedFieldName = formattedFieldName.replace('investorProfile', '')
  // remove prefix for considereds
  formattedFieldName = formattedFieldName.replace('business', '')
  formattedFieldName = formattedFieldName.replace('investor', '')

  return camelCase(formattedFieldName) + 'Visibility'
}

// Adapts a redux-form input to use formik instead by passing the correct props.
function withFormikAdapterAndVisibilityInput() {
  return (InputComponent) => {
    function WrappedInputComponent({
      onChange,
      hasVisibilityField,
      onVisibilityChange,
      disclosureDisabled,
      visibilityName,
      emailNoticeText,
      serverError,
      pendingRequestEmail,
      hasActiveAdvisorConnection,
      disableButtons,
      submissionMessage,
      ...rest
    }) {
      const [field, meta] = useField(rest)
      const visibilityFieldName = visibilityName
        ? formatVisibilityName(visibilityName)
        : formatVisibilityName(field.name)
      const [visibilityField, visibilityMeta] = useField(visibilityFieldName)
      const isDisabled = rest.disabled
      const submitting = rest.submitting
      if (
        field.name === 'description' ||
        field.name === 'sign-up-email' ||
        field.name === 'userType' ||
        field.name === 'defaultImage'
      ) {
        return (
          <div className="full-width-visibility-input-wrapper">
            <InputComponent
              input={{
                name: field.name,
                value: field.value,
                onChange: composeChangeHandlers(
                  field.name,
                  onChange,
                  field.onChange
                ),
                onBlur: field.onBlur,
              }}
              meta={{
                error: meta.error,
                touched: meta.touched,
                invalid: !!meta.error,
              }}
              {...rest}
            />
          </div>
        )
      }

      if (field.name === 'advisorEmail') {
        // console.log(submitting)
        return (
          <div className="advisor-email-full">
            <div className="advisor-email-visibility-input-wrapper">
              <InputComponent
                input={{
                  name: field.name,
                  value: field.value,
                  onChange: composeChangeHandlers(
                    field.name,
                    onChange,
                    field.onChange
                  ),
                  onBlur: field.onBlur,
                }}
                meta={{
                  error: '',
                  touched: '',
                  invalid: '',
                }}
                {...rest}
              />
              {pendingRequestEmail && (
                <SubmitButton
                  className={classnames({ disabled: disableButtons })}
                  disabled={disableButtons}
                  submitting={submitting}
                >
                  Cancel
                </SubmitButton>
              )}
              {hasActiveAdvisorConnection && (
                <SubmitButton
                  className={classnames({ disabled: disableButtons })}
                  disabled={disableButtons}
                  submitting={submitting}
                >
                  Remove
                </SubmitButton>
              )}
              {!pendingRequestEmail && !hasActiveAdvisorConnection && (
                <SubmitButton
                  className={classnames({ disabled: disableButtons })}
                  disabled={disableButtons}
                  submitting={submitting}
                >
                  Add
                </SubmitButton>
              )}
            </div>
            {serverError && (
              <p className="error-message">
                <ErrorMessage>
                  {() => <div>{serverError.errors}</div>}
                </ErrorMessage>
              </p>
            )}
            {!serverError && !submissionMessage && (
              <p className="error-message">
                <ErrorMessage name="advisorEmail" />
              </p>
            )}
            {submissionMessage && (
              <p className="submission-message">{submissionMessage}</p>
            )}
          </div>
        )
      }

      return (
        <div className="with-visibility-input-wrapper">
          <InputComponent
            input={{
              name: field.name,
              value: field.value,
              onChange: composeChangeHandlers(
                field.name,
                onChange,
                field.onChange
              ),
              onBlur: field.onBlur,
            }}
            meta={{
              error: meta.error,
              touched: meta.touched,
              invalid: !!meta.error,
            }}
            {...rest}
          />
          {emailNoticeText && (
            <div className="email-notice">
              Please email{' '}
              <a href="mailto:membership@firmvalue.com?subject=Change my email address">
                membership@firmvalue.com
              </a>
              {` to update your ${emailNoticeText}`}
            </div>
          )}
          {hasVisibilityField && (
            <Checkbox
              input={{
                name: visibilityField.name,
                value: visibilityField.value,
                onChange: composeChangeHandlers(
                  visibilityField.name,
                  onVisibilityChange,
                  visibilityField.onChange
                ),
                onBlur: visibilityField.onBlur,
              }}
              meta={{
                error: visibilityMeta.error,
                touched: visibilityMeta.touched,
                invalid: !!visibilityMeta.error,
              }}
              label={
                visibilityField.name === 'targetCapitalRaiseNotDisclosed' ||
                visibilityField.name ===
                  'businessLikelyEquityStakesNotDisclosed' ||
                visibilityField.name === 'targetFundSizeNotDisclosed'
                  ? 'Do Not Disclose To Other Members'
                  : 'Show To Other Members'
              }
              disabled={
                visibilityField.name === 'targetCapitalRaiseNotDisclosed' ||
                visibilityField.name ===
                  'businessLikelyEquityStakesNotDisclosed' ||
                visibilityField.name === 'targetFundSizeNotDisclosed'
                  ? disclosureDisabled
                  : isDisabled
              }
            />
          )}
        </div>
      )
    }
    WrappedInputComponent.displayName = wrapDisplayName(
      InputComponent,
      'withFormikAdapterAndVisibilityInput'
    )
    return WrappedInputComponent
  }
}

export default withFormikAdapterAndVisibilityInput
