import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import exact from 'prop-types-exact'
import {
  MessagesUserMessagesArea,
  MessagesHeaderCard,
  MessagesUserNotes,
} from 'components/messages'
import * as api from 'api'

const propTypes = {
  connection: PropTypes.object,
  messages: PropTypes.array,
  currentUser: PropTypes.object,
  match: PropTypes.object,
}

const defaultProps = {}

function MessagesUserPage({
  match: {
    params: { connectionId },
  },
  messages,
  currentUser,
  connection,
}) {
  const [connectionWithUser, setConnectionWithUser] = useState(connection)
  const [messagesWithUser, setMessagesWithUser] = useState(messages)
  const [conversationCurrentUser, setConversationCurrentUser] = useState(
    currentUser
  )
  const [isLoaded, setIsLoaded] = useState(connectionWithUser)

  useEffect(() => {
    setIsLoaded(false)
    async function fetchConversationDetails(connectionId) {
      const {
        connection,
        messages,
        currentUser,
        profile_data,
      } = await api.fetchConversation(connectionId)
      setMessagesWithUser(messages)
      setConversationCurrentUser(currentUser)
      setConnectionWithUser({ ...connection, ...profile_data })
      setIsLoaded(true)
    }
    fetchConversationDetails(connectionId)
  }, [connectionId])

  return (
    <div className="messages-viewport">
      {isLoaded && (
        <div className="messages-user-page">
          <MessagesHeaderCard
            user={connectionWithUser}
            removedConnection={connectionWithUser.removedConnection}
            deletedProfile={connectionWithUser.deleted}
          />
          <MessagesUserNotes
            userId={connectionWithUser.userId}
            removedConnection={connectionWithUser.removedConnection}
            deletedProfile={connectionWithUser.deleted}
          />
          <MessagesUserMessagesArea
            connection={connectionWithUser}
            messages={messagesWithUser}
            currentUser={conversationCurrentUser}
            connectionId={connectionId}
            removedConnection={connectionWithUser.removedConnection}
            deletedProfile={connectionWithUser.deleted}
          />
        </div>
      )}
    </div>
  )
}

MessagesUserPage.propTypes = exact(propTypes)
MessagesUserPage.defaultProps = defaultProps

export default React.memo(MessagesUserPage)
