import React, { useState, useEffect } from 'react'
// import PropTypes from 'prop-types' from 'react'
// import PropTypes from 'prop-types'
import exact from 'prop-types-exact'
import {
  ConnectionsSortRow,
  ConnectionsSavedForLaterCard,
} from 'components/connections'
import { PaginationControls } from 'components'
import * as api from 'api'
import { scrollToSelector } from 'utils'
import { isEmpty } from 'lodash'

const propTypes = {}

const defaultProps = {}

function ConnectionsSavedForLater() {
  const [savedConnections, setSavedConnections] = useState([])
  const [activePage, setActivePage] = useState(0) // 0 index in Pagination
  const [sortOption, setSortOption] = useState('most-recent') // 0 index in Pagination

  useEffect(() => {
    const params = {
      page: activePage + 1,
      sortOption: sortOption,
    }

    async function fetchSavedConnections() {
      setSavedConnections(
        await api.getConnections('users/saved-for-later', {
          params: params,
        })
      )
    }
    fetchSavedConnections()
  }, [activePage, sortOption])

  const cards = savedConnections.data

  const totalPages = savedConnections.totalPages

  const handleSortChange = (sortValue) => {
    scrollToSelector('.dashboard-header')
    setSortOption(sortValue)
  }

  const handlePage = (pageNumber) => {
    scrollToSelector('.dashboard-header')
    setActivePage(pageNumber.selected)
  }

  return (
    <div className="connections-viewport">
      <ConnectionsSortRow handleSortChange={handleSortChange} />
      {isEmpty(cards) ? (
        <div className="no-connections">No profiles saved for later</div>
      ) : (
        <>
          {cards?.map((card, index) => {
            return <ConnectionsSavedForLaterCard key={index} cardData={card} />
          })}
          <PaginationControls
            activePage={activePage}
            totalPages={totalPages}
            handlePageClick={handlePage}
          />
        </>
      )}
    </div>
  )
}

ConnectionsSavedForLater.propTypes = exact(propTypes)
ConnectionsSavedForLater.defaultProps = defaultProps

export default React.memo(ConnectionsSavedForLater)
