import React, { useState } from 'react'
import PropTypes from 'prop-types'
import exact from 'prop-types-exact'
import { Helmet } from 'react-helmet'
import { Header, Card, Footer } from '../components'
import { Button, Modal } from '@launchpadlab/lp-components'
import * as mailchimp from '../services/mailchimp'
import { SignUpForm } from '../forms'
import { scrollToSelector } from 'utils'
import PrivateCapital from '../../assets/images/privatecapital.svg'
import Lightbulb from '../../assets/images/lightbulb.svg'
import Door from '../../assets/images/door.svg'
import Compass from '../../assets/images/compass.svg'
import Accountants from '../../assets/images/accountants.svg'
import One from '../../assets/images/one.svg'
import Two from '../../assets/images/two.svg'
import Three from '../../assets/images/three.svg'
import ThreeBubble from '../../assets/images/threebubble.svg'
import ArrowMan from '../../assets/images/arrowman.svg'
import List from '../../assets/images/list.svg'
import Checkbox from '../../assets/images/checkbox.svg'
import ArrowCircle from '../../assets/images/arrowcircle.svg'
import AdvisorProfile from '../../assets/images/advisorprofile.png'
import AdvisorsProfile from '../../assets/images/advisorsprofile.png'

const propTypes = { userSession: PropTypes.object }

const defaultProps = {}

function AdvisorLanding({ userSession }) {
  const [signUpSucceeded, setSignUpSucceeded] = useState(false)
  const [exampleAdvisorModalShown, setExampleAdvisorModalShown] = useState(
    false
  )
  const [
    exampleAccountantModalShown,
    setExampleAccountantModalShown,
  ] = useState(false)
  return (
    <div className="advisorLanding">
      <Helmet>
        <title>Advisor Page</title>
      </Helmet>
      <Header
        title="Advisors"
        subtitle={
          <span>
            Financial Advisors <span>&#183;</span> Accountants
          </span>
        }
        userSession={userSession}
      >
        <Button
          onClick={() => scrollToSelector('#sign-up-section')}
          style="primary"
          className="signUpButton"
          ariaLabel="Sign Up"
        >
          Sign Up
        </Button>
      </Header>
      <main>
        <Card
          title="How Advisors Use Firm Value"
          className="advisor-how"
          subtitle={
            <>
              <p>
                Firm Value helps advisors & their clients make meaningful
                connections and access potential opportunities & solutions.
              </p>
            </>
          }
        >
          <div className="card-content thirds left">
            <img
              className="card-icon"
              src={PrivateCapital}
              alt="Private Capital Icon"
            />
            <h3 className="quarter-width">Help Build Clients' Networks</h3>
            <p>
              Confirm clients’ profile information and enhance their
              opportunities for making connections with members
            </p>
          </div>

          <div className="card-content thirds left">
            <img className="card-icon" src={Lightbulb} alt="Lightbulb Icon" />
            <h3>Source New Advisory Clients</h3>
            <p>
              Provide an overview of your offering to businesses and investors
              seeking advisors for their strategic objectives
            </p>
          </div>

          <div className="card-content thirds">
            <img className="card-icon" src={Door} alt="Door Icon" />
            <h3 className="half-width">Access Solutions For Clients</h3>
            <p>
              Help clients raise capital by linking their full sale, equity,
              and/or debt raise objectives with your profile
            </p>
          </div>
        </Card>

        <Card title="Who Firm Value Serves">
          <div className="small left-first">
            <div className="card-content">
              <img className="type-icon" src={Compass} alt="Compass Icon" />
              <h3>Financial Advisors</h3>
              <p className="p-margin">Business Brokers</p>
              <p className="p-margin">M&A Advisors</p>
            </div>
            <Button
              onClick={() => setExampleAdvisorModalShown(true)}
              ariaLabel="See Example Financial Advisor"
            >
              View Example Profile
            </Button>
          </div>

          <div className="small">
            <div className="card-content">
              <img
                className="type-icon"
                src={Accountants}
                alt="Calculator Icon"
              />
              <h3>Accountants</h3>
              <p className="p-margin">Serving Businesses</p>
              <p className="p-margin">Serving Investors</p>
            </div>
            <Button
              onClick={() => setExampleAccountantModalShown(true)}
              ariaLabel="See Example Accountant"
            >
              View Example Profile
            </Button>
            {exampleAdvisorModalShown && (
              <Modal onClose={() => setExampleAdvisorModalShown(false)}>
                <h3>Example Financial Advisor Profile</h3>
                <p>
                  Only joining to confirm client profiles and don't want to
                  share your offering? Easily turn off your public settings.
                </p>
                <img
                  className="modal-profile"
                  src={AdvisorProfile}
                  alt="Financial Advisor Profile"
                />
                <p className="subtitle">
                  Member presented is not a real Firm Value member and is
                  presented for illustrative purposes
                </p>
                <Button
                  onClick={() => setExampleAdvisorModalShown(false)}
                  ariaLabel="Close Button"
                >
                  Close
                </Button>
              </Modal>
            )}
            {exampleAccountantModalShown && (
              <Modal onClose={() => setExampleAccountantModalShown(false)}>
                <h3>Example Accountant Profile</h3>
                <p>
                  Only joining to confirm client profiles and don't want to
                  share your offering? Easily turn off your public settings.
                </p>
                <img
                  className="modal-profile"
                  src={AdvisorsProfile}
                  alt="Accountant Profile"
                />
                <p className="subtitle">
                  Member presented is not a real Firm Value member and is
                  presented for illustrative purposes
                </p>
                <Button
                  onClick={() => setExampleAccountantModalShown(false)}
                  ariaLabel="Close Button"
                >
                  Close
                </Button>
              </Modal>
            )}
          </div>
        </Card>

        <Card title="How It Works For Advisors">
          <div className="number-card">
            <div className="flex-card">
              <div className="number-header">
                <img className="number-icon" src={One} alt="Number 1" />
                <h3>Develop Your Profile</h3>
              </div>
              <div className="card-content">
                <p>
                  Businesses and investors can view your advisor profile, offer
                  connections, and send messages after mutual connection
                  interest is expressed (as desired)
                </p>
              </div>
            </div>

            <div className="flex-card">
              <div className="number-header">
                <img className="number-icon" src={Two} alt="Number 2" />
                <h3>Confirm & Link Client Profiles</h3>
              </div>
              <div className="card-content">
                <p>
                  Confirm clients' profile information and/or receive
                  administrative rights to lead projects through your own
                  account during active capital raises
                </p>
              </div>
            </div>

            <div className="flex-card">
              <div className="number-header">
                <img className="number-icon" src={Three} alt="Number 3" />
                <h3>Explore, Discover, Access</h3>
              </div>
              <div className="card-content margin-bottom">
                <p>
                  Discover potential partners for your clients and engage in
                  discussions after reviewing requests to engage on active
                  capital raises
                </p>
              </div>
            </div>
          </div>
        </Card>

        <Card title="Free Membership &#183; Premier Access">
          <div className="membership-card">
            <div className="subtitle">
              <p>
                All advisors receive the following benefits of Firm Value
                membership <span>for free</span>:
              </p>
            </div>

            <div className="card-content membership">
              <div className="membership-left">
                <div className="membership-content">
                  <img
                    src={Checkbox}
                    alt="Checkbox Icon"
                    className="checkbox-icon"
                  />
                  <p className="italic">
                    <span>Help clients develop their capital networks</span> by
                    confirming their profile information
                  </p>
                </div>

                <div className="membership-content">
                  <img src={Lightbulb} alt="Ligthbulb Icon" />
                  <p className="italic">
                    <span>Share an overview of your advisory offering</span>{' '}
                    with businesses and investors seeking advisors
                  </p>
                </div>

                <div className="membership-content">
                  <img src={ArrowMan} alt="Arrows Around Man Icon" />
                  <p className="italic">
                    <span>Show members if you're active</span> and elevate your
                    status to “Actively Sourcing Clients"
                  </p>
                </div>
              </div>

              <div className="membership-right">
                <div className="membership-content">
                  <img src={List} alt="List Icon" />
                  <p className="italic">
                    <span>
                      Link to clients' profiles and lead advisory projects
                    </span>{' '}
                    for active full sale, equity, and/or debt capital raises
                  </p>
                </div>

                <div className="membership-content">
                  <img src={ThreeBubble} alt="Three Bubble Icon" />
                  <p className="italic">
                    <span>Unlimited monthly connections offered/received</span>{' '}
                    to/from businesses, investors, and other advisors
                  </p>
                </div>

                <div className="membership-content">
                  <img src={ArrowCircle} alt="Arrows in Circle Icon" />
                  <p className="italic">
                    <span>
                      If needed, pay clients' marketing expenses by linking
                      payments
                    </span>{' '}
                    to their Firm Value Premier profiles
                  </p>
                </div>
              </div>
            </div>
          </div>
        </Card>

        <div id="sign-up-section">
          <div className="sign-up-information">
            <h2>Launching in Q1 2021</h2>
            <p>
              We're collecting membership interest to continue building the
              network around our founding members while we work towards a Q1
              2021 launch. Sign up today to stay up to date on our launch news.
            </p>
          </div>
          {signUpSucceeded ? (
            <p>Thank you for signing up!</p>
          ) : (
            <SignUpForm
              onSubmit={({ email, userType }) =>
                mailchimp
                  .signup({ email, userType })
                  .then(() => setSignUpSucceeded(true))
                  .catch(() =>
                    window.alert('Sign up failed, please try again.')
                  )
              }
            />
          )}
        </div>
      </main>
      <Footer />
    </div>
  )
}

AdvisorLanding.propTypes = exact(propTypes)
AdvisorLanding.defaultProps = defaultProps

export default React.memo(AdvisorLanding)
