import React from 'react'
import PropTypes from 'prop-types'
import exact from 'prop-types-exact'
import { Button } from '@launchpadlab/lp-components'
import { CarouselCardLineItem } from 'components/carousels'

const propTypes = {
  card: PropTypes.object,
}

const defaultProps = {}

function CarouselCard({ card }) {
  const handleViewProfile = () => {
    window.open(`/users/profile?user=${card.userId}`)
  }
  return (
    <div className="carousel-card">
      <div className="inner">
        <div className="carousel-img-row">
          <img src={card.imageUrl} className="carousel-img" />
        </div>
        <div className="carousel-title">
          <h1>{card.name}</h1>
        </div>
        <div className="carousel-subtitle">
          <p>{card.state}</p>
        </div>
        <div className="carousel-card-fields">
          <CarouselCardLineItem
            label="Advisory Firm"
            value={card.advisoryFirm}
          />
          <CarouselCardLineItem label="Title" value={card.title} />
          <CarouselCardLineItem
            label="Years Experience"
            value={card.yearsOfExperience}
          />
          <CarouselCardLineItem label="Status" value={card.membershipStatus} />
        </div>
      </div>
      <Button onClick={handleViewProfile}>View</Button>
    </div>
  )
}

CarouselCard.propTypes = exact(propTypes)
CarouselCard.defaultProps = defaultProps

export default React.memo(CarouselCard)
