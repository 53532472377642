import React from 'react'
// import PropTypes from 'prop-types'
import exact from 'prop-types-exact'

import { ProfileSectionLineItem } from 'components/profiles'

const propTypes = {}

const defaultProps = {}

function BPOperationsOverviewProfileSection({ sectionVisible, userData }) {
  if (sectionVisible) {
    return (
      <div className="profile-section-wrapper centered-content">
        <h1> Operations Overview</h1>
        <div className="profile-view-section ">
          <div className="profile-view-section-col">
            {userData?.primarySector && (
              <ProfileSectionLineItem
                label="Primary Sector"
                value={userData?.primarySector}
              />
            )}
            {userData?.primaryIndustry && (
              <ProfileSectionLineItem
                label="Primary Industry"
                value={userData.primaryIndustry}
              />
            )}
            {userData?.geographiesServed && (
              <ProfileSectionLineItem
                label="Geographies Served"
                value={userData.geographiesServed}
              />
            )}
            {userData?.sectorsServed && (
              <ProfileSectionLineItem
                label="Sectors Served"
                value={userData.sectorsServed}
              />
            )}
          </div>
          <div className="profile-view-section-col">
            {userData?.businessStage && (
              <ProfileSectionLineItem
                label="Business Stage"
                value={userData.businessStage}
              />
            )}
            {userData?.businessModels && (
              <ProfileSectionLineItem
                label="Business Model(s)"
                value={userData.businessModels}
              />
            )}
            {userData?.businessAge && (
              <ProfileSectionLineItem
                label="Business Age (Years)"
                value={userData.businessAge}
              />
            )}
            {userData?.employeeSize && (
              <ProfileSectionLineItem
                label="Size of Employee Base"
                value={userData.employeeSize}
              />
            )}
          </div>
        </div>
      </div>
    )
  } else {
    return <></>
  }
}

BPOperationsOverviewProfileSection.propTypes = exact(propTypes)
BPOperationsOverviewProfileSection.defaultProps = defaultProps

export default React.memo(BPOperationsOverviewProfileSection)
