import React from 'react'
import PropTypes from 'prop-types'
import exact from 'prop-types-exact'
import { ErrorBoundary, Footer } from '../components'
import { DashboardProfileCard } from '../components/dashboard'
import {
  CarouselAdvisorCarouselArea,
  CarouselBusinessCarouselArea,
  CarouselInvestorCarouselArea,
} from '../components/carousels'
import { Button } from '@launchpadlab/lp-components'
import { ProfileTypes } from 'config'
import { Link } from 'react-router-dom'

const propTypes = {
  profileType: PropTypes.string.isRequired,
  user: PropTypes.object.isRequired,
}

const defaultProps = {}

function DashboardHome({ profileType, user }) {
  const hasActiveObjective = user.seekingStatus

  // function linkToHOVM() {
  //   return window.location.replace('/dashboard/edit-profile/how-others-view-me')
  // }
  function linkToCapitalExploration() {
    return window.location.replace(
      '/dashboard/edit-profile/capital-exploration'
    )
  }
  function linkToInvestmentExplorationOverview() {
    return window.location.replace(
      '/dashboard/edit-profile/investment-exploration-overview'
    )
  }

  // function linkToAdvisorConnection() {
  //   return window.location.replace('/dashboard/edit-profile/advisor-connection')
  // }

  function linkToInviteMembers() {
    return window.open('https://www.firmvalue.com/invitations')
  }

  const CarouselMap = {
    [ProfileTypes.BusinessProfile]: (
      <CarouselBusinessCarouselArea user={user} />
    ),
    [ProfileTypes.AdvisorProfile]: <CarouselAdvisorCarouselArea user={user} />,
    [ProfileTypes.InvestorProfile]: (
      <CarouselInvestorCarouselArea user={user} />
    ),
  }

  return (
    <ErrorBoundary>
      {profileType === ProfileTypes.BusinessProfile ? (
        <DashboardProfileCard user={user}>
          <Button onClick={() => linkToCapitalExploration()}>
            {hasActiveObjective
              ? 'View Active Objectives'
              : 'Add Active Objectives'}
          </Button>
          <Button
            className="button-primary-outline"
            onClick={() => linkToInviteMembers()}
          >
            Invite A Member
          </Button>

          <Link to="/edit-profile/how-others-view-me">
            How Others View My Profile
          </Link>
        </DashboardProfileCard>
      ) : profileType === ProfileTypes.InvestorProfile ? (
        <DashboardProfileCard user={user}>
          <Button onClick={() => linkToInvestmentExplorationOverview()}>
            {hasActiveObjective
              ? 'View Active Objectives'
              : 'Add Active Objectives'}
          </Button>
          <Button
            className="button-primary-outline"
            onClick={() => linkToInviteMembers()}
          >
            Invite A Member
          </Button>
          <Link to="/edit-profile/how-others-view-me">
            How Others View My Profile
          </Link>
        </DashboardProfileCard>
      ) : profileType === ProfileTypes.AdvisorProfile ? (
        <DashboardProfileCard user={user}>
          <Button
            className="button-primary"
            onClick={() => linkToInviteMembers()}
          >
            Invite A Member
          </Button>
          <Link to="/edit-profile/how-others-view-me">
            How Others View My Profile
          </Link>
        </DashboardProfileCard>
      ) : null}
      {CarouselMap[profileType]}
      <Footer></Footer>
    </ErrorBoundary>
  )
}

DashboardHome.propTypes = exact(propTypes)
DashboardHome.defaultProps = defaultProps

export default React.memo(DashboardHome)
