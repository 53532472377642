import React from 'react'
// import PropTypes from 'prop-types'
import exact from 'prop-types-exact'

import { ProfileSectionLineItem } from 'components/profiles'
import { isHumanizedBooleanValue, replaceNullValueWithPlaceholder } from 'utils'

const propTypes = {}

const defaultProps = {}

function IPInvestmentOpportunityCriteriaProfileSection({
  sectionVisible,
  userData,
  viewerType,
  isPassiveInvestor,
}) {
  // const isViewerCapitalBusinessOrInvestor =
  //   (userData.viewerProfileType === 'BusinessProfile' &&
  //     userData.viewerSeekingStatus === 'Seeking Capital') ||
  //   userData.viewerProfileType === 'InvestorProfile'

  const isViewerInvestor =
    viewerType === 'Investor' ||
    userData.viewerProfileType === 'InvestorProfile'

  const isViewerBusiness =
    viewerType === 'Business' ||
    userData.viewerProfileType === 'BusinessProfile'

  if (sectionVisible) {
    if (isPassiveInvestor) {
      return (
        <div className="profile-section-wrapper centered-content">
          <h1>Investment Opportunity Criteria</h1>
          <div className="profile-view-section ">
            <div className="profile-view-section-col">
              <ProfileSectionLineItem
                label="Equity Checks Considered"
                value={replaceNullValueWithPlaceholder(
                  userData?.consideredEquityChecks
                )}
                isCriteriaMatch={isHumanizedBooleanValue(
                  userData.consideredEquityChecks
                )}
              />
            </div>
            <div className="profile-view-section-col"></div>
          </div>
        </div>
      )
    }

    if (isViewerInvestor || !userData.postInvestmentIntentions) {
      return (
        <div className="profile-section-wrapper centered-content">
          <h1>Investment Opportunity Criteria</h1>
          <div className="profile-view-section ">
            <div className="profile-view-section-col">
              {userData.consideredOfferingTypes && (
                <ProfileSectionLineItem
                  label="Offering Type(s) Considered"
                  value={replaceNullValueWithPlaceholder(
                    userData?.consideredOfferingTypes
                  )}
                  isCriteriaMatch={isHumanizedBooleanValue(
                    userData.consideredOfferingTypes
                  )}
                />
              )}
              {userData.consideredEquityStakes &&
                !isHumanizedBooleanValue(userData.consideredEquityStakes) && (
                  <ProfileSectionLineItem
                    label="Likely Equity Stakes Considered"
                    value={replaceNullValueWithPlaceholder(
                      userData?.consideredEquityStakes
                    )}
                    isCriteriaMatch={isHumanizedBooleanValue(
                      userData.consideredEquityStakes
                    )}
                  />
                )}
              {userData.consideredEquityStakes &&
                isHumanizedBooleanValue(userData.consideredEquityStakes) && (
                  <ProfileSectionLineItem
                    label="Likely Equity Stakes Considered"
                    value={replaceNullValueWithPlaceholder(
                      userData?.consideredEquityStakes
                    )}
                    isCriteriaMatch={isHumanizedBooleanValue(
                      userData.consideredEquityStakes
                    )}
                  />
                )}
            </div>
            <div className="profile-view-section-col">
              {userData.consideredEquityChecks && (
                <ProfileSectionLineItem
                  label="Equity Checks Considered"
                  value={replaceNullValueWithPlaceholder(
                    userData?.consideredEquityChecks
                  )}
                  isCriteriaMatch={isHumanizedBooleanValue(
                    userData.consideredEquityChecks
                  )}
                />
              )}
              {userData.consideredCapitalNeedReasons && (
                <ProfileSectionLineItem
                  label="Reasons For Capital Need"
                  value={replaceNullValueWithPlaceholder(
                    userData.consideredCapitalNeedReasons
                  )}
                  isCriteriaMatch={isHumanizedBooleanValue(
                    userData.consideredCapitalNeedReasons
                  )}
                />
              )}
            </div>
          </div>
        </div>
      )
    }

    return (
      <div className="profile-section-wrapper centered-content">
        <h1>Investment Opportunity Criteria</h1>
        <div className="profile-view-section ">
          <div className="profile-view-section-col">
            {userData.consideredOfferingTypes && (
              <ProfileSectionLineItem
                label="Offering Type(s) Considered"
                value={replaceNullValueWithPlaceholder(
                  userData?.consideredOfferingTypes
                )}
                isCriteriaMatch={isHumanizedBooleanValue(
                  userData.consideredOfferingTypes
                )}
              />
            )}
            {userData.consideredEquityStakes &&
              !isHumanizedBooleanValue(userData.consideredEquityStakes) && (
                <ProfileSectionLineItem
                  label="Likely Equity Stakes Considered"
                  value={replaceNullValueWithPlaceholder(
                    userData?.consideredEquityStakes
                  )}
                  isCriteriaMatch={isHumanizedBooleanValue(
                    userData.consideredEquityStakes
                  )}
                />
              )}
            {userData.consideredEquityStakes &&
              isHumanizedBooleanValue(userData.consideredEquityStakes) && (
                <ProfileSectionLineItem
                  label="Likely Equity Stakes Considered"
                  value={replaceNullValueWithPlaceholder(
                    userData?.consideredEquityStakes
                  )}
                  isCriteriaMatch={isHumanizedBooleanValue(
                    userData.consideredEquityStakes
                  )}
                />
              )}
            {userData.consideredEquityChecks && !isViewerBusiness && (
              <ProfileSectionLineItem
                label="Equity Checks Considered"
                value={replaceNullValueWithPlaceholder(
                  userData?.consideredEquityChecks
                )}
                isCriteriaMatch={isHumanizedBooleanValue(
                  userData.consideredEquityChecks
                )}
              />
            )}
          </div>
          <div className="profile-view-section-col">
            {userData.consideredCapitalNeedReasons && (
              <ProfileSectionLineItem
                label="Reasons For Capital Need"
                value={replaceNullValueWithPlaceholder(
                  userData.consideredCapitalNeedReasons
                )}
                isCriteriaMatch={isHumanizedBooleanValue(
                  userData.consideredCapitalNeedReasons
                )}
              />
            )}
            {userData.postInvestmentIntentions && (
              <ProfileSectionLineItem
                label="Potential Intentions Post-Investment"
                value={replaceNullValueWithPlaceholder(
                  userData.postInvestmentIntentions
                )}
                isCriteriaMatch={isHumanizedBooleanValue(
                  userData.postInvestmentIntentions
                )}
              />
            )}
          </div>
        </div>
      </div>
    )
  } else {
    return <></>
  }
}

IPInvestmentOpportunityCriteriaProfileSection.propTypes = exact(propTypes)
IPInvestmentOpportunityCriteriaProfileSection.defaultProps = defaultProps

export default React.memo(IPInvestmentOpportunityCriteriaProfileSection)
