import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import exact from 'prop-types-exact'
import {
  withFormikAdapterAndVisibilityInput,
  validate,
  formHelpers,
} from '../utils'
import {
  DropdownCheckboxGroup as BaseDropownCheckboxGroup,
  Tooltip,
} from 'components'
import {
  Select as BaseSelectInput,
  SubmitButton,
  Spinner,
} from '@launchpadlab/lp-components'
import { Formik, Form } from 'formik'

import * as api from 'api'

const DropdownCheckboxGroup = withFormikAdapterAndVisibilityInput()(
  BaseDropownCheckboxGroup
)
const Select = withFormikAdapterAndVisibilityInput()(BaseSelectInput)

const propTypes = {
  params: PropTypes.object,
  handleSubmit: PropTypes.func,
}

const defaultProps = {}

// Add an extra custom validation for the hidden spam input.
function validateForm(values) {
  const messages = validate(values, {
    annualRevenueId: {
      presence: { allowEmpty: false, message: '^Please select one' },
    },
    annualEbitdaId: {
      presence: { allowEmpty: false, message: '^Please select one' },
    },
    annualEbitId: {
      presence: { allowEmpty: false, message: '^Please select one' },
    },
    businessCurrentOwnerships: {
      presence: { allowEmpty: false },
    },
  })
  // mix custom stuff here w validate.js messages
  return {
    ...messages,
    //
  }
}

function BusinessFinancialOverviewEditForm({ params, handleSubmit }) {
  const [disabled, toggleDisabled] = useState(true)
  const [storedData, setStoredData] = useState()

  useEffect(() => {
    async function fetchData() {
      setStoredData(await api.getStoredData('/business/financial-overview'))
    }
    fetchData()
  }, [])

  const initialValues = {
    annualRevenueId: storedData?.annualRevenueId || '',
    annualEbitdaId: storedData?.annualEbitdaId || '',
    annualEbitId: storedData?.annualEbitId || '',
    businessCurrentOwnerships: storedData?.businessCurrentOwnerships || [],
    annualRevenueVisibility: storedData?.annualRevenueVisibility || false,
    annualEbitdaVisibility: storedData?.annualEbitdaVisibility || false,
    annualEbitVisibility: storedData?.annualEbitVisibility || false,
    currentOwnershipsVisibility:
      storedData?.currentOwnershipsVisibility || false,
  }

  if (!storedData) {
    return <Spinner />
  }

  return (
    <Formik
      onSubmit={handleSubmit}
      validate={validateForm}
      initialValues={initialValues}
    >
      {({ values, setFieldTouched, setFieldValue, handleReset }) => (
        <Form className="onboarding-form">
          <div className="row">
            <h1>Financial Overview</h1>
            {disabled ? (
              <button
                className="button-primary-outline"
                onClick={() => {
                  toggleDisabled(false)
                }}
              >
                Edit
              </button>
            ) : (
              <div className="edit-buttons-area">
                <button
                  className="button-primary-outline"
                  onClick={() => {
                    handleReset()
                    toggleDisabled(true)
                  }}
                >
                  Cancel
                </button>
                <SubmitButton>Submit Changes</SubmitButton>
              </div>
            )}
          </div>
          <p className="disclaimer">
            Financial Data is <span id="underline">not</span> publicized to
            other members. Members only see whether or not their criteria
            matches instead of actual ranges selected.
          </p>
          <div className="row">
            <Select
              name="annualRevenueId"
              label="Annual Revenue"
              tooltip={
                <Tooltip
                  name="annualRevenueId"
                  text="Please select the applicable range of your most recent annual revenue"
                />
              }
              options={formHelpers.formatOptions(params.financialMetrics)}
              enablePlaceholderOption={true}
              placeholder=" "
              disabled={disabled}
              hasVisibilityField={true}
            />
            <Select
              name="annualEbitdaId"
              label="Annual EBITDA"
              tooltip={
                <Tooltip
                  name="annualEbitdaId"
                  text="Defined as most recent annual net income + taxes + interest + depreciation & amortization"
                />
              }
              options={formHelpers.formatOptions(params.financialMetrics)}
              enablePlaceholderOption={true}
              placeholder=" "
              disabled={disabled}
              hasVisibilityField={true}
            />
          </div>
          <div className="row">
            <Select
              name="annualEbitId"
              label="Annual Operating Income"
              tooltip={
                <Tooltip
                  name="annualEbitId"
                  text="Defined as most recent annual net income + taxes + interest"
                />
              }
              options={formHelpers.formatOptions(params.financialMetrics)}
              enablePlaceholderOption={true}
              placeholder=" "
              disabled={disabled}
              hasVisibilityField={true}
            />
            <DropdownCheckboxGroup
              name="businessCurrentOwnerships"
              label="Current Ownership"
              options={formHelpers.formatOptions(params.currentOwnerships)}
              values={values}
              setFieldValue={setFieldValue}
              setFieldTouched={setFieldTouched}
              errorMessage="Please select all that apply"
              tags={true}
              disabled={disabled}
              hasVisibilityField={true}
            />
          </div>
        </Form>
      )}
    </Formik>
  )
}

BusinessFinancialOverviewEditForm.propTypes = exact(propTypes)
BusinessFinancialOverviewEditForm.defaultProps = defaultProps

export default BusinessFinancialOverviewEditForm
