import React from 'react'
import PropTypes from 'prop-types'
import exact from 'prop-types-exact'

const propTypes = {
  label: PropTypes.string,
  value: PropTypes.string,
}

const defaultProps = {}

function DashboardHeaderLabel({ label, value }) {
  return (
    <div className="dashboard-header-display-field">
      <div className="dashboard-header-display-label">
        <h4>{label}</h4>
      </div>
      <span className="dashboard-header-display-value">{value}</span>
    </div>
  )
}

DashboardHeaderLabel.propTypes = exact(propTypes)
DashboardHeaderLabel.defaultProps = defaultProps

export default React.memo(DashboardHeaderLabel)
