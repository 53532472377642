/* eslint-disable */
import React, { useState } from 'react'
// import PropTypes from 'prop-types'
import exact from 'prop-types-exact'
import ScrollMenu from 'react-horizontal-scrolling-menu'
import { NavLink } from 'react-router-dom'

const propTypes = {}

const defaultProps = {}

function UpdatedMobileNav() {
  const list = [
    { name: 'item1' },
    { name: 'item2' },
    { name: 'item3' },
    { name: 'item4' },
    { name: 'item5' },
    { name: 'item6' },
    { name: 'item7' },
    { name: 'item8' },
    { name: 'item9' },
  ]
  // One item component
  // selected prop will be passed
  const MenuItem = ({ text, selected }) => {
    return <div className={`menu-item ${selected ? 'active' : ''}`}>{text}</div>
  }

  // All items component
  // Important! add unique key
  const Menu = (list, selected) =>
    list.map((el) => {
      const { name } = el

      return <MenuItem text={name} key={name} selected={selected} />
    })

  const Arrow = ({ text, className }) => {
    return <div className={className}>{text}</div>
  }

  const ArrowLeft = Arrow({ text: '<', className: 'arrow-prev' })
  const ArrowRight = Arrow({ text: '>', className: 'arrow-next' })

  const [selected, setSelected] = useState('item1')

  const onSelect = (key) => {
    console.log(key)
    setSelected(key)
  }

  return (
    <ScrollMenu
      arrowLeft={<div style={{ fontSize: '30px' }}>{' < '}</div>}
      arrowRight={<div style={{ fontSize: '30px' }}>{' > '}</div>}
      data={[
        <NavLink exact to="/" activeClassName="active-setting">
          <div className="mobile-nav-item">Dashboard</div>
        </NavLink>,
        <NavLink to="/explore" activeClassName="active-setting">
          <div className="mobile-nav-item">Explore</div>
        </NavLink>,
        <NavLink to="/messages" activeClassName="active-setting">
          <div className="mobile-nav-item">Messages</div>
        </NavLink>,
        <NavLink to="/connections" activeClassName="active-setting">
          <div className="mobile-nav-item">Connections</div>
        </NavLink>,
        <NavLink to="/edit-profile" activeClassName="active-setting">
          <div className="mobile-nav-item">Profile Settings</div>
        </NavLink>,
        <NavLink to="/account-settings" activeClassName="active-setting">
          <div className="mobile-nav-item">Account Settings</div>
        </NavLink>,
        <NavLink to="/notifications" activeClassName="active-setting">
          <div className="mobile-nav-item">Notifications</div>
        </NavLink>,
      ]}
    />
  )
}

UpdatedMobileNav.propTypes = exact(propTypes)
UpdatedMobileNav.defaultProps = defaultProps

export default React.memo(UpdatedMobileNav)
