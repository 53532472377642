import React, { useState } from 'react'
import PropTypes from 'prop-types'
import exact from 'prop-types-exact'
import * as api from 'api'
import attachmentIcon from '../../../assets/images/paperclip.svg'

const propTypes = {
  connectionId: PropTypes.string,
  addNewMessage: PropTypes.func,
}

const defaultProps = {}

const handleSubmit = (
  e,
  connectionId,
  callback,
  setIsUploading,
  setCharacterCount
) => {
  e.preventDefault()
  const tagName = e.target.tagName
  async function saveMessage({ form, fileContents, text, contentType }) {
    for (let i = 0; i < form.elements.length; i++) {
      form.elements[i].disabled = true
    }
    const message = await api.saveMessage({
      text,
      fileContents,
      connectionId,
      contentType,
    })
    for (let i = 0; i < form.elements.length; i++) {
      form.elements[i].disabled = false
      form.elements[i].value = ''
    }
    form.elements[0].focus()
    setCharacterCount(0)
    if (setIsUploading) setIsUploading(false)
    callback(message)
  }
  if (tagName === 'FORM') {
    const form = e.target
    const text = form.elements['message[text]'].value
    if (!text) return
    saveMessage({ form, text, connectionId })
  } else if (tagName === 'TEXTAREA') {
    const form = e.target.form
    const text = form.elements['message[text]'].value
    if (!text) return
    saveMessage({ form, text, connectionId })
  } else if (tagName === 'INPUT') {
    const form = e.target.form
    const fileInputEl = form.elements['message[file]']
    const proceedWithUpload = confirm(
      'Are you sure you want to upload this file?'
    )
    if (!proceedWithUpload) {
      fileInputEl.value = ''
      return
    }
    const file = fileInputEl.files[0]
    if (file.size > 5000000)
      return alert(
        'Maximum file upload size is 5 MB. Please select a different file or compress the selected file.'
      )
    setIsUploading(true)
    const text = file.name
    const contentType = file.type
    const reader = new FileReader()
    reader.readAsBinaryString(file)
    reader.onload = () => {
      const fileContents = btoa(reader.result)
      saveMessage({ form, text, fileContents, connectionId, contentType })
    }
  }
}

const MESSAGE_CHARACTER_LIMIT = 500

// const handleChange = (e, handler) => {
//   handler(e.target.value.length)
// }

function MessagesUserMessageInput({ connectionId, addNewMessage }) {
  const [isUploading, setIsUploading] = useState(false)
  // const [characterCount, setCharacterCount] = useState(0)

  const acceptedFileFormats =
    '.doc,.docm,.dox,.dot,.dotx,.docx,application/msword,application/vnd.openxmlformats-officedocument.wordprocessingml.document,.xlsx,.pptx,.pdf'

  return (
    <div className="messages-user-message-input-area">
      <form onSubmit={(e) => handleSubmit(e, connectionId, addNewMessage)}>
        {/* <p className="character-count">
          {characterCount} / {MESSAGE_CHARACTER_LIMIT} characters
        </p> */}
        <textarea
          name="message[text]"
          className="message-input"
          type="text"
          autoComplete="false"
          placeholder="Type here..."
          // onChange={(e) => handleChange(e, setCharacterCount)}
          onKeyDown={(e) => {
            if (e.key === 'Enter')
              handleSubmit(
                e,
                connectionId,
                addNewMessage,
                setIsUploading
                // setCharacterCount
              )
          }}
          maxLength={MESSAGE_CHARACTER_LIMIT}
        />
        <img
          src={attachmentIcon}
          onClick={() => document.getElementById('message-file-input').click()}
          className="message-file-attachment"
        />
        {isUploading && <span>Uploading...</span>}
        <input
          id="message-file-input"
          type="file"
          name="message[file]"
          accept={acceptedFileFormats}
          onChange={(e) =>
            handleSubmit(e, connectionId, addNewMessage, setIsUploading)
          }
          style={{ display: 'none' }}
        />
        <p className="disclaimer">
          Press enter to send your message (500 character limit)
        </p>
      </form>
    </div>
  )
}

MessagesUserMessageInput.propTypes = exact(propTypes)
MessagesUserMessageInput.defaultProps = defaultProps

export default React.memo(MessagesUserMessageInput)
