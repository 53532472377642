import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import exact from 'prop-types-exact'
import {
  HOVMProfileHeaderCard,
  APAdvisoryStatusProfileSection,
  APGeneralOverviewProfileSection,
  APAdvisoryOfferingOverviewProfileSection,
} from 'components/profiles'
import { Spinner } from '@launchpadlab/lp-components'
import * as api from 'api'

const propTypes = {
  viewerType: PropTypes.string,
}

const defaultProps = {}

function HOVMAdvisorProfileView({ viewerType }) {
  const [storedData, setStoredData] = useState()
  const params = {
    user_type: viewerType,
  }
  useEffect(() => {
    async function fetchData() {
      setStoredData(
        await api.getHOVMProfileData(`/advisor/hovm`, { params: params })
      )
    }
    fetchData()
  }, [])

  if (!storedData) {
    return <Spinner />
  }

  return (
    <div className="profile-view-wrapper hovm-profile-view-wrapper">
      <div className="profile-view">
        <HOVMProfileHeaderCard
          userData={storedData}
          profileType="advisorProfile"
        />

        <APAdvisoryStatusProfileSection
          sectionVisible={true}
          userData={storedData}
        />
        <APGeneralOverviewProfileSection
          sectionVisible={true}
          userData={storedData}
        />
        <APAdvisoryOfferingOverviewProfileSection
          sectionVisible={true}
          userData={storedData}
        />
      </div>
    </div>
  )
}

HOVMAdvisorProfileView.propTypes = exact(propTypes)
HOVMAdvisorProfileView.defaultProps = defaultProps

export default HOVMAdvisorProfileView
