import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import exact from 'prop-types-exact'
import {
  withFormikAdapterAndVisibilityInput,
  validate,
  formHelpers,
} from '../utils'
import {
  DropdownCheckboxGroup as BaseDropownCheckboxGroup,
  Tooltip,
} from 'components'
import {
  Select as BaseSelectInput,
  Textarea as BaseTextarea,
  SubmitButton,
  Spinner,
} from '@launchpadlab/lp-components'
import { Formik, Form } from 'formik'

import * as api from 'api'

const DropdownCheckboxGroup = withFormikAdapterAndVisibilityInput()(
  BaseDropownCheckboxGroup
)
const Select = withFormikAdapterAndVisibilityInput()(BaseSelectInput)
const Textarea = withFormikAdapterAndVisibilityInput()(BaseTextarea)

const propTypes = {
  params: PropTypes.object,
  handleSubmit: PropTypes.func,
}

const defaultProps = {}

// Add an extra custom validation for the hidden spam input.
function validateForm(values) {
  const messages = validate(values, {
    businessSpecialDesignations: {
      presence: {
        allowEmpty: false,
        message: '^Please select all that apply',
      },
    },
    meetsSba: {
      presence: {
        allowEmpty: false,
        message: '^Please select one',
      },
    },
    businessLocations: {
      presence: { allowEmpty: false },
    },
    description: {
      presence: { allowEmpty: true },
    },
  })
  // mix custom stuff here w validate.js messages
  return {
    ...messages,
    //
  }
}

function BusinessAdditionalDetailsEditForm({ params, handleSubmit }) {
  const [disabled, toggleDisabled] = useState(true)
  const [storedData, setStoredData] = useState()
  useEffect(() => {
    async function fetchData() {
      setStoredData(await api.getStoredData('/business/additional-details'))
    }
    fetchData()
  }, [])

  const initialValues = {
    businessSpecialDesignations: storedData?.businessSpecialDesignations || [],
    meetsSba:
      storedData?.meetsSba === false
        ? false
        : storedData?.meetsSba === true
        ? true
        : '',
    businessLocations: storedData?.businessLocations || [],
    description: storedData?.description || '',
  }

  if (!storedData) {
    return <Spinner />
  }

  const linkToExternal = () => {
    window.open(
      'https://www.sba.gov/document/support--table-size-standards',
      '_blank'
    )
  }

  return (
    <Formik
      onSubmit={handleSubmit}
      validate={validateForm}
      initialValues={initialValues}
    >
      {({ values, setFieldTouched, setFieldValue, handleReset }) => (
        <Form className="onboarding-form">
          <div className="row">
            <h1>Additional Details</h1>
            {disabled ? (
              <button
                className="button-primary-outline"
                onClick={() => {
                  toggleDisabled(false)
                }}
              >
                Edit
              </button>
            ) : (
              <div className="edit-buttons-area">
                <button
                  className="button-primary-outline"
                  onClick={() => {
                    handleReset()
                    toggleDisabled(true)
                  }}
                >
                  Cancel
                </button>
                <SubmitButton>Submit Changes</SubmitButton>
              </div>
            )}
          </div>
          <div className="row">
            <DropdownCheckboxGroup
              name="businessSpecialDesignations"
              label="Special Designation(s)?"
              tooltip={
                <Tooltip
                  name="businessSpecialDesignations"
                  text="Please select all that apply"
                />
              }
              options={formHelpers.formatOptions(params.specialDesignations)}
              setFieldValue={setFieldValue}
              setFieldTouched={setFieldTouched}
              values={values}
              tags={true}
              disabled={disabled}
              allowNoneValue
            />
            <Select
              name="meetsSba"
              label="Do You Meet SBA Size Standards?"
              tooltip={
                <Tooltip
                  name="meetsSba"
                  text={[
                    'Do You Meet SBA Size Standards? Please refer ',
                    {
                      key: 1,
                      value: (
                        <span className="tooltip-link" onClick={linkToExternal}>
                          here
                        </span>
                      ),
                    },
                    ' for additional information',
                  ]}
                />
              }
              options={[
                { key: 'Yes', value: true },
                { key: 'No', value: false },
              ]}
              enablePlaceholderOption={true}
              placeholder=" "
              disabled={disabled}
            />
          </div>
          <div className="row">
            <DropdownCheckboxGroup
              name="businessLocations"
              label="Additional Physical Locations"
              tooltip={
                <Tooltip
                  name="businessLocations"
                  text="Please select all regions with additional locations beyond your company headquarters"
                />
              }
              options={formHelpers.formatOptions(params.territoriesStates)}
              setFieldValue={setFieldValue}
              setFieldTouched={setFieldTouched}
              errorMessage="Please select all that apply"
              values={values}
              tags={true}
              disabled={disabled}
              allowNoneValue
            />
          </div>
          <div className="row">
            <Textarea
              className="text-area"
              name="description"
              label="Brief Description of Your Business "
              hint="  (Shown to Other Members) "
              maxLength={500}
              disabled={disabled}
            />
            <p className="disclaimer description-disclaimer">
              Only post factual information about yourself, your company, and/or
              your investment fund. Do not include any forward-looking
              statements, including, but not limited to (i) projections or
              predictions about future performance of your company and/or
              investments, and (ii) forecasts or opinions about the future value
              of your company’s securities and/or investments.
            </p>
          </div>
        </Form>
      )}
    </Formik>
  )
}

BusinessAdditionalDetailsEditForm.propTypes = exact(propTypes)
BusinessAdditionalDetailsEditForm.defaultProps = defaultProps

export default BusinessAdditionalDetailsEditForm
