import fetchJsonp from 'fetch-jsonp'
import { stringify } from 'query-string'

// Status codes used by mailchimp API
const MailchimpStatusCodes = {
  SUCCESS: 'success',
  ERROR: 'error',
}

// Helper for making jsonp requests (which is the only way mailchimp accepts AJAX)
function jsonp(url, data, options) {
  const queryString = stringify(data)
  return fetchJsonp(url + '&' + queryString, options).then((res) => res.json())
}

// Create a signup with the mailchimp signup API.
// Returns a promise that resolves or rejects with a message based on the result status.
// Basic approach copied from here: https://stackoverflow.com/a/50611587
export async function signup({ email, userType }) {
  // Set API url from env var

  // consider a different list per type
  const mailchimpUrl = process.env.REACT_APP_MAILCHIMP_URL
  const data = {
    EMAIL: email,
    USER_TYPE: userType,
    subscribe: 'Subscribe',
    skip_duplicate_check: true,
  }
  const response = await jsonp(mailchimpUrl, data, {
    jsonpCallback: 'c',
  })
  if (response.result === MailchimpStatusCodes.ERROR) {
    if (
      response.msg.includes('already subscribed') ||
      response.msg.includes('too many recent signup requests')
    ) {
      return
    }

    throw new Error(response.msg)
  }

  return response
}
