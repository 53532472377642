import React from 'react'
import PropTypes from 'prop-types'
import exact from 'prop-types-exact'
import { ProfileTypes } from 'config'
import * as Types from 'types'

import {
  BusinessDashboardDetails,
  AdvisorDashboardDetails,
  InvestorDashboardDetails,
} from 'components/dashboard'

const propTypes = {
  user: Types.user.isRequired,
  children: PropTypes.node,
}

const defaultProps = {}

function DashboardProfileCard({ user, children }) {
  const profileType = user.profileType
  if (!Object.values(ProfileTypes).includes(profileType)) {
    throw new Error('Unhandled Profile Type')
  }
  return (
    <div className="dashboard-profile-card">
      <div className="profile-picture">
        <img src={user.profileImageUrl} alt="User Profile Image" />
      </div>
      {profileType === ProfileTypes.BusinessProfile ? (
        <BusinessDashboardDetails user={user}>
          {children}
        </BusinessDashboardDetails>
      ) : profileType === ProfileTypes.InvestorProfile ? (
        <InvestorDashboardDetails user={user}>
          {children}
        </InvestorDashboardDetails>
      ) : (
        <AdvisorDashboardDetails user={user}>
          {children}
        </AdvisorDashboardDetails>
      )}
    </div>
  )
}

DashboardProfileCard.propTypes = exact(propTypes)
DashboardProfileCard.defaultProps = defaultProps

export default React.memo(DashboardProfileCard)
