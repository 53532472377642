import React, { useState, useEffect } from 'react'
// import PropTypes from 'prop-types'
import exact from 'prop-types-exact'
import {
  NotificationsSideSubNav,
  NotificationsMobileSubNav,
  Footer,
} from 'components'
import {
  NotificationsNewConnectionsForm,
  NotificationsNewMatchesForm,
  NotificationsNewMessagesForm,
} from 'forms'
import { Route, Switch } from 'react-router-dom'
import * as api from 'api'

const propTypes = {}

const defaultProps = {}

function Notifications() {
  const [storedData, setStoredData] = useState()

  useEffect(() => {
    async function fetchData() {
      setStoredData(await api.getStoredData('users/get_notification_settings'))
    }
    fetchData()
  }, [])

  const handleSubmit = (values) => {
    const requestObj = {
      user: {
        ...values,
      },
    }
    /* eslint-disable */

    api
      .setNotificationSettings('users/set_notification_settings', requestObj)
      .then((res) => console.log('response', res))
      .then(() => {
        window.location.reload(true)
      })
    /* eslint-enable  */
  }

  return (
    <div className="edit-profile-settings">
      <NotificationsMobileSubNav />
      <NotificationsSideSubNav />
      <div className="settings-area">
        <div className="settings-wrapper">
          <Switch>
            <Route path="/notifications/new-connections">
              <NotificationsNewConnectionsForm
                handleSubmit={(values) => {
                  handleSubmit(values)
                }}
                notificationSetting={storedData?.connectionNotificationCadence}
              />
            </Route>
            <Route path="/notifications/new-matches">
              <NotificationsNewMatchesForm
                handleSubmit={(values) => {
                  handleSubmit(values)
                }}
                notificationSetting={storedData?.matchesNotificationCadence}
              />
            </Route>
            <Route path="/notifications/new-messages">
              <NotificationsNewMessagesForm
                handleSubmit={(values) => {
                  handleSubmit(values)
                }}
                notificationSetting={storedData?.messagesNotificationCadence}
              />
            </Route>
          </Switch>
        </div>
        <Footer></Footer>
      </div>
    </div>
  )
}

Notifications.propTypes = exact(propTypes)
Notifications.defaultProps = defaultProps

export default React.memo(Notifications)
