import React, { useEffect, useRef } from 'react'
import PropTypes from 'prop-types'
import exact from 'prop-types-exact'
import { useClickAway } from 'react-use'

const propTypes = {
  text: PropTypes.string || PropTypes.array,
  name: PropTypes.string,
}

const defaultProps = {}

function Tooltip({ name, text }) {
  const ref = useRef(name)

  useEffect(() => {}, [])

  useClickAway(
    ref,
    () => {
      if (ref.current.id === `tool-tip-${name}`) {
        const tooltipEl = document
          .getElementById(`tool-tip-${name}`)
          .closest('.tooltip-content.is-active')
        tooltipEl.classList.remove('is-active')
      }
    },
    ['mousedown', 'touchstart']
  )

  if (Array.isArray(text)) {
    return (
      <div id={`tool-tip-${name}`} className={`tooltip`} ref={ref}>
        {text.map((line, index) => {
          if (line.key) {
            return <span key={index}>{line.value}</span>
          }
          return <span key={index}>{line}</span>
        })}
      </div>
    )
  }
  return (
    <div id={`tool-tip-${name}`} className={`tooltip`} ref={ref}>
      {text}
    </div>
  )
}

Tooltip.propTypes = exact(propTypes)
Tooltip.defaultProps = defaultProps

export default React.memo(Tooltip)
