import React from 'react'
// import PropTypes from 'prop-types'
import exact from 'prop-types-exact'

import { ProfileSectionLineItem } from 'components/profiles'
import { replaceNullValueWithPlaceholder } from 'utils'

const propTypes = {}

const defaultProps = {}

function APAdvisoryOfferingOverviewProfileSection({
  sectionVisible,
  userData,
}) {
  const servesBusinesses = userData?.servedClients?.includes('Businesses')
  if (sectionVisible) {
    return (
      <div className="profile-section-wrapper centered-content">
        <h1>Advisory Offering</h1>
        <div className="profile-view-section ">
          <div className="profile-view-section-col">
            <ProfileSectionLineItem
              label="Clients Served"
              value={replaceNullValueWithPlaceholder(userData?.servedClients)}
            />
            {servesBusinesses && (
              <>
                <ProfileSectionLineItem
                  label="Sectors Served"
                  value={replaceNullValueWithPlaceholder(
                    userData?.servedSectors
                  )}
                />
                <ProfileSectionLineItem
                  label="Geographies Served"
                  value={replaceNullValueWithPlaceholder(
                    userData?.servedLocations
                  )}
                />
                <ProfileSectionLineItem
                  label="Operating Income Sizes Served"
                  value={replaceNullValueWithPlaceholder(
                    userData?.operatingIncomeSizesServed
                  )}
                />
              </>
            )}
            {!servesBusinesses && (
              <ProfileSectionLineItem
                label="Geographies Served"
                value={replaceNullValueWithPlaceholder(
                  userData?.servedLocations
                )}
              />
            )}
          </div>
          <div className="profile-view-section-col">
            {servesBusinesses && (
              <>
                <ProfileSectionLineItem
                  label="Business Stages Served"
                  value={replaceNullValueWithPlaceholder(
                    userData?.servedBusinessStages
                  )}
                />
                <ProfileSectionLineItem
                  label="Business Models Served"
                  value={replaceNullValueWithPlaceholder(
                    userData?.servedBusinessModels
                  )}
                />
              </>
            )}

            <ProfileSectionLineItem
              label="Fee Structures Offered"
              value={replaceNullValueWithPlaceholder(userData?.feeStructures)}
            />
          </div>
        </div>
      </div>
    )
  } else {
    return <></>
  }
}

APAdvisoryOfferingOverviewProfileSection.propTypes = exact(propTypes)
APAdvisoryOfferingOverviewProfileSection.defaultProps = defaultProps

export default React.memo(APAdvisoryOfferingOverviewProfileSection)
