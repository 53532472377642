import React from 'react'
import PropTypes from 'prop-types'
import exact from 'prop-types-exact'
import { ProfileTypes } from 'config'
import { camelCase } from 'lodash'
import { ErrorBoundary } from 'components'
import {
  AdvisorProfileCardDetails,
  InvestorProfileCardDetails,
  BusinessProfileCardDetails,
} from 'components/profiles'

const propTypes = {
  userData: PropTypes.object,
}

const defaultProps = {}

function ProfileHeaderCard(props) {
  const { userData } = props
  const profileType = camelCase(userData.profileType)
  if (!Object.values(ProfileTypes).includes(profileType)) {
    throw new Error('Unhandled Profile Type')
  }
  return (
    <ErrorBoundary>
      {profileType === 'advisorProfile' ? (
        <AdvisorProfileCardDetails userData={userData} />
      ) : profileType === 'investorProfile' ? (
        <InvestorProfileCardDetails userData={userData} />
      ) : (
        <BusinessProfileCardDetails userData={userData} />
      )}
    </ErrorBoundary>
  )
}

ProfileHeaderCard.propTypes = exact(propTypes)
ProfileHeaderCard.defaultProps = defaultProps

export default React.memo(ProfileHeaderCard)
