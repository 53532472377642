import React from 'react'
// import PropTypes from 'prop-types'
import { NavLink } from 'react-router-dom'
import exact from 'prop-types-exact'
const propTypes = {}

const defaultProps = {}

function SideNav() {
  return (
    <nav className="side-nav">
      <ul>
        <NavLink exact to="/" activeClassName="active-setting">
          <li className="side-item">Dashboard</li>
        </NavLink>
        <NavLink to="/explore" activeClassName="active-setting">
          <li className="side-item">Explore</li>
        </NavLink>
        <NavLink to="/messages" activeClassName="active-setting">
          <li className="side-item">Messages</li>
        </NavLink>
        <NavLink
          to="/connections/active-connections"
          activeClassName="active-setting"
        >
          <li className="side-item">Connections</li>
        </NavLink>
        <NavLink to="/edit-profile" activeClassName="active-setting">
          <li className="side-item">Profile Settings</li>
        </NavLink>
        <NavLink to="/account-settings" activeClassName="active-setting">
          <li className="side-item">Account Settings</li>
        </NavLink>
        <NavLink to="/notifications" activeClassName="active-setting">
          <li className="side-item">Notifications</li>
        </NavLink>
      </ul>
    </nav>
  )
}

SideNav.propTypes = exact(propTypes)
SideNav.defaultProps = defaultProps

export default React.memo(SideNav)
