import React from 'react'
// import PropTypes from 'prop-types'
import exact from 'prop-types-exact'
import { CarouselRow } from 'components/carousels'

const propTypes = {}

const defaultProps = {}

function CarouselsArea() {
  return (
    <div className="carousels-area">
      <CarouselRow />
      <CarouselRow />
      <CarouselRow />
      <CarouselRow />
    </div>
  )
}

CarouselsArea.propTypes = exact(propTypes)
CarouselsArea.defaultProps = defaultProps

export default React.memo(CarouselsArea)
